import { useState, useContext, useEffect } from "react";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import { FilterComponent } from "../FilterComponent";
import { HeadCell, TableComponent } from "../TableComponent";
import { UserContext } from "../../context/userContext";
import { UseInvoicesByCompanyId, UseInvoicesByGroupId, getUrlS3 } from "../../lib/usersBEClient";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FilesModalDownload } from "./filesModalDownload";
import { useParams } from "react-router-dom";

export const InvoiceTableComponent = () => {
   const { groupSelected } = useContext(UserContext);
   const [invoicesFiltered, setInvoicesFiltered] = useState([]);
   const [originalInvoice, setOriginalInvoice] = useState([]);
   const [open, setOpen] = useState<boolean>(false);
   const [file1, setFile1] = useState<string>("");
   const [file2, setFile2] = useState<string>("");
   const [fileName1, setFileName1] = useState(null);
   const [fileName2, setFileName2] = useState(null);
   const [idInvoice, setIdInvoice] = useState(null);
   const [nameInvoice, setNameInvoice] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   const { groupId, companyId } = useParams();
   const group = groupSelected?.invoiceCompany || null;

   const getFile1 = async (clientId: string, year: string, month: string, file1: string, id: string, name: string) => {
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         { folder: `invoices/${clientId}/${year}/${month}` },
         file1
      );
      setNameInvoice(name);
      setIdInvoice(id);
      setFile1(fileData1);
      setFileName1(file1);
   };

   const getFile2 = async (clientId: string, year: string, month: string, file2: string) => {
      const fileData2: string = await getUrlS3(
         "files-lecosy",
         { folder: `invoices/${clientId}/${year}/${month}` },
         file2
      );
      setFile2(fileData2);
      setFileName2(file2);
   };

   useEffect(() => {
      const formatInvoices = (invoices) => {
         return invoices.map((e) => ({
            companyId: e.companyId || "",
            companyName: e.companyName || "",
            _id: e._id,
            name: e.name,
            startPeriod: e.startPeriod,
            unformattedDate: new Date(e.startPeriod.split("T")[0]).getTime(),
            endPeriod: e.endPeriod,
            unformattedDate2: new Date(e.endPeriod.split("T")[0]).getTime(),
            file1: e.file1,
            file2: e.file2,
         }));
      };

      const fetchInvoicesByGroup = async () => {
         const responseInvoice = await UseInvoicesByGroupId(groupSelected._id);
         const invoicesData = formatInvoices(responseInvoice);
         setOriginalInvoice(invoicesData);
         setInvoicesFiltered(invoicesData);
         setIsLoading(false);
      };

      const fetchInvoicesByCompany = async (company) => {
         const invoiceFetch = await UseInvoicesByCompanyId(company);
         const listInvoices = formatInvoices(invoiceFetch);
         setOriginalInvoice(listInvoices);
         setInvoicesFiltered(listInvoices);
         setIsLoading(false);
      };

      if (groupSelected && groupSelected._id) {
         fetchInvoicesByGroup();
      } else if (group || companyId) {
         fetchInvoicesByCompany(group || companyId);
      }

      // eslint-disable-next-line
   }, [group, groupSelected, groupId]);

   const branchesColumns = (): HeadCell[] => {
      const headers: HeadCell[] = [{ field: "name", headerName: "Nombre", type: "string", width: 50 }];
      const headers2: HeadCell[] = [
         { field: "startPeriod", headerName: "Inicio", type: "date", width: 50 },
         { field: "endPeriod", headerName: "Cierre", type: "date", width: 50 },
         {
            field: "files",
            headerName: "Ver",
            type: "button",
            width: 50,
            icon: <VisibilityIcon fontSize="small" />,
            onClick: async (e, row) => {
               setOpen(true);
               await getFile1(
                  row.companyId,
                  row.startPeriod.split("-")[0],
                  row.startPeriod.split("-")[1],
                  row.file1,
                  row._id,
                  row.name
               );
               await getFile2(row.companyId, row.startPeriod.split("-")[0], row.startPeriod.split("-")[1], row.file2);
            },
         },
      ];
      if (groupSelected) {
         headers.push({ field: "companyName", headerName: "Empresa", type: "string", width: 50 });
      }
      return [...headers, ...headers2];
   };

   const invoiceColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string", width: 50 },
      { field: "startPeriod", headerName: "Inicio", type: "date", width: 50 },
      { field: "endPeriod", headerName: "Cierre", type: "date", width: 50 },
      {
         field: "files",
         headerName: "Ver",
         type: "button",
         width: 50,
         icon: <VisibilityIcon fontSize="small" />,
         onClick: async (e, row) => {
            setOpen(true);
            await getFile1(
               group._id,
               row.startPeriod.split("-")[0],
               row.startPeriod.split("-")[1],
               row.file1,
               row._id,
               row.name
            );
            await getFile2(group._id, row.startPeriod.split("-")[0], row.startPeriod.split("-")[1], row.file2);
         },
      },
   ];

   return (
      <>
         <Box sx={{ width: "100%" }}>
            <Box sx={{ bgcolor: "white", py: 1, boxShadow: 2, borderRadius: 0 }}>
               {!isLoading ? (
                  <>
                     <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontSize={20} fontWeight={500} mx={5}>
                           Facturas
                        </Typography>
                        <FilterComponent
                           originaltableOneRows={originalInvoice}
                           tableOneFilteredRows={invoicesFiltered}
                           setTableOneFilteredRows={setInvoicesFiltered}
                           period={true}
                           filters={["daterange"]}
                           searchBar={
                              <Box width={"100%"} sx={{ display: "flex", flexDirection: "row", flex: 1 }}></Box>
                           }
                        />
                     </Box>
                     <Divider />

                     <TableComponent
                        defaultColumnToOrder="name"
                        defaultOrder="asc"
                        defaultRowsPerPage={5}
                        emptyDataText="No hay facturas registradas por el momento"
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        loader={false}
                        headers={group ? invoiceColumns : branchesColumns()}
                        rows={invoicesFiltered}
                        disableBorders
                        onClick={() => {}}
                     />
                  </>
               ) : (
                  <>
                     <Box display={"flex"}>
                        <CircularProgress sx={{ display: "flex", flex: 1, justifyContent: "center" }} />
                     </Box>
                  </>
               )}
            </Box>
         </Box>
         <FilesModalDownload
            setOpen={setOpen}
            open={open}
            file1URL={file1}
            file2URL={file2}
            file1Name={fileName1}
            file2Name={fileName2}
            id={idInvoice}
            name={nameInvoice}
            invoice={true}
         />
      </>
   );
};
