import { Box, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import ListComponent from "../../../../ListComponent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";

const changeFormatToAString = (stringValue) => {
   return stringValue.substring(2).replaceAll("-", " ").replace("_", ".");
};

const votesNull = (affair) => affair.votes === null || affair.votes === undefined || affair.votes.length === 0;

const sumPositiveNegativeVotes = (users) => {
   let positive = 0;
   let negative = 0;

   users?.forEach((user) => (user.vote ? (positive += user.numberOfVotes) : (negative += user.numberOfVotes)));

   return [positive, negative];
};

const isValidOldSesionQuorum = (totalVotes, quoromV, positiveVotes) => (positiveVotes / totalVotes) * 100 >= quoromV;

const isPositiveNumberHigher = (positive, negative) => positive > negative;

const getResolution = (selectedSession, totalVotes, quoromV, positiveVotes, negativesVotes) => {
   if (votesNull(selectedSession) || !isValidOldSesionQuorum(totalVotes, quoromV, positiveVotes))
      return "Votación inválida";
   return isPositiveNumberHigher(positiveVotes, negativesVotes) ? "Positiva" : "Negativa";
};

const getPercentage = (selectedSession, totalVotes, positiveVotes, quoromV, negativesVotes) => {
   if (votesNull(selectedSession)) return 0;
   if (!isValidOldSesionQuorum(totalVotes, quoromV, positiveVotes))
      return totalVotes === 0 ? 0 : ((positiveVotes / totalVotes) * 100).toFixed(0);
   return totalVotes === 0
      ? 0
      : isPositiveNumberHigher(positiveVotes, negativesVotes)
      ? ((positiveVotes / totalVotes) * 100).toFixed(0)
      : ((negativesVotes / totalVotes) * 100).toFixed(0);
};

const GoverningBodyList = ({ selectedSession }) => {
   const filteredusers = selectedSession.votes?.filter((user) => user.numberOfVotes);

   const affairsList = {};

   if (selectedSession?.affairs) {
      selectedSession.affairs.forEach((affair, index) => {
         const affairVotes = selectedSession.votes?.filter((vote) => {
            return vote.affair.substring(2) === affair._id;
         });

         const involvedVotes = [];
         for (const involved of selectedSession.involved) {
            if (!involved) continue;
            affairVotes?.find((vote) => {
               return vote.user === involved._id;
            });

            involvedVotes.push({
               id: involved._id,
               name: involved.fullName,
               vote: affairVotes?.find((vote) => vote.user === involved._id)?.vote || null,
            });
         }

         const users = filteredusers?.filter((user) => user.affair[0] === `${index}`);

         const totalVotes = users?.reduce((acc, value) => acc + value.numberOfVotes, 0);

         let positiveVotes = sumPositiveNegativeVotes(users)[0];
         let negativesVotes = sumPositiveNegativeVotes(users)[1];

         affairsList[affair.title + " " + index] = {
            resolution: getResolution(selectedSession, totalVotes, 100, positiveVotes, negativesVotes),
            percentage: getPercentage(selectedSession, totalVotes, positiveVotes, 100, negativesVotes),
            involved: involvedVotes,
         };
      });
   } else if (selectedSession?.newAffairs) {
      selectedSession.newAffairs.forEach((affair, index) => {
         const involvedVotes = [];
         for (const involved of selectedSession.involved) {
            if (!involved) continue;
            involvedVotes.push({
               id: involved._id,
               name: involved.fullName,
               vote: affair.users?.find((user) => {
                  return user.user === involved._id && user?.answer;
               }),
            });
         }
         const answers: { [key: string]: number } = {};

         affair.answers?.forEach((answer: string) => (answers[answer] = 0));

         if (affair.users) {
            affair.users.forEach((user: { answer: string }) => {
               if (user.answer) {
                  answers[user.answer] += 1;
               }
            });
         }

         let mostVotedAnswer: string | null = null;
         let maxVotes = 0;
         let totalVotes = 0;

         if (answers) {
            totalVotes = Object.values(answers).reduce((sum, value) => sum + value, 0);
         }

         for (const [answer, count] of Object.entries(answers)) {
            if (count > maxVotes) {
               maxVotes = count;
               mostVotedAnswer = answer;
            }
         }

         affairsList[changeFormatToAString(affair.affair.orderId + " " + index)] = {
            resolution: affair.resolutionPercentage === "100" ? mostVotedAnswer : "Votación inválida",
            percentage:
               affair.resolutionPercentage === undefined
                  ? 0
                  : affair.resolutionPercentage === "100"
                  ? ((maxVotes / totalVotes) * 100).toFixed(0)
                  : affair.resolutionPercentage,
            involved: involvedVotes,
         };
      });
   }

   const uniqueInvolvedIds = new Set<string>();

   const [showDeliberation, setShowDeliberation] = useState(false);

   const renderAffairs = (affairs, isNew = false) =>
      affairs.map((affair) => (
         <AffairListItem
            key={
               affair.affair?.orderId + affair?.description + Math.random() ||
               affair.title + affair?.description + Math.random()
            }
            affair={affair}
            isNew={isNew}
         />
      ));

   const erraseIndex = (str) => {
      return str.slice(0, -2);
   };

   return (
      <Box sx={{ padding: "20px", height: "475px" }}>
         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ color: "#152c44", fontWeight: 700, fontSize: "18px", ml: "10px" }}>
               Detalles de la asamblea
            </Typography>
            <Typography sx={{ fontSize: "15px", mr: "20px" }}>No. escritura</Typography>
         </Box>
         <List
            sx={{
               px: 1,
            }}
         >
            <Grid container spacing={2} sx={{ pl: 1 }}>
               <Grid
                  item
                  xs={6}
                  sx={{
                     bgcolor: "#e4e6e8",
                     mt: "10px",
                     height: "30px",
                     pt: "0px !important",
                     borderTopLeftRadius: "3px",
                     borderBottomLeftRadius: "3px",
                     position: "relative",
                     "&::after": {
                        content: '""',
                        position: "absolute",
                        top: "5px",
                        bottom: "5px",
                        right: 0,
                        width: "1px",
                        backgroundColor: "#c4c6ca",
                     },
                  }}
               >
                  <Typography
                     variant="subtitle2"
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        borderLeft: "2px",
                        height: "30px",
                        color: "#303b4b",
                        fontSize: "13px",
                     }}
                  >
                     Asunto tratado
                  </Typography>
               </Grid>
               <Grid
                  item
                  xs={6}
                  sx={{
                     bgcolor: "#e4e6e8",
                     mt: "10px",
                     height: "30px",
                     pt: "0px !important",
                     borderTopRightRadius: "3px",
                     borderBottomRightRadius: "3px",
                  }}
               >
                  <Typography
                     variant="subtitle2"
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                        borderLeft: "2px",
                        height: "30px",
                        color: "#303b4b",
                        fontSize: "13px",
                     }}
                  >
                     Descripción
                  </Typography>
               </Grid>
            </Grid>

            <Stack
               sx={{
                  height: "115px",
                  overflowY: "auto",
                  "&::-webkit-scrollbar-thumb": {
                     backgroundColor: "#46566a",
                     borderRadius: "4px",
                  },
               }}
            >
               {selectedSession?.affairs?.length > 0 ? (
                  renderAffairs(selectedSession.affairs)
               ) : selectedSession?.newAffairs?.length > 0 ? (
                  renderAffairs(selectedSession.newAffairs, true)
               ) : (
                  <Typography>No existen temas tratados</Typography>
               )}
            </Stack>
         </List>
         <Stack direction={"column"} display={"flex"}>
            <Stack sx={{ bgcolor: "#e3e5e7" }}>
               <Typography variant="caption" sx={{ fontWeight: 600, py: 0.45, textAlign: "center", fontSize: "13px" }}>
                  Votaciones y deliberaciones
               </Typography>
            </Stack>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
               <Stack
                  display={"flex"}
                  borderRadius={1}
                  onClick={() => setShowDeliberation((value) => !value)}
                  direction={"row"}
                  bgcolor={"#2d4357"}
                  color={"white"}
                  sx={{ ":hover": { cursor: "pointer" } }}
                  px={1}
                  mr={showDeliberation ? 0 : "170px"}
                  width={showDeliberation ? `${selectedSession.involved.length * 100}px` : "45px"}
                  maxWidth={"400px"}
                  marginY={-0.65}
                  zIndex={120}
               >
                  {showDeliberation && (
                     <Typography fontSize={"14px"} width={"100%"} textAlign={"center"} my={0}>
                        Deliberación
                     </Typography>
                  )}
                  <Box
                     py={0.5}
                     sx={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: showDeliberation ? "flex-end" : "center",
                        width: !showDeliberation ? "100%" : "auto",
                     }}
                  >
                     <ArrowForwardIosIcon
                        sx={{
                           transform: `rotate(${showDeliberation ? "180deg" : "0deg"})`,
                           fontSize: "14px",
                        }}
                     />
                  </Box>
               </Stack>
            </Box>
            <Box maxWidth={"705px"} height={"225px"} sx={{ overflowx: "auto", overflowy: "auto" }}>
               <ListComponent
                  maxColumns={6}
                  newStyles={{
                     bgcolorTitleRow: "#D9D9D9",
                     bgcolorTableRows: "white",
                     borderBottomTableRows: "2px solid #D9D9D9",
                     mlAffairs: "4px",
                     percentageDiv: {
                        bgcolor: "#868fa0",
                        pxPercentage: "8px",
                        borderRadiusPercentage: "6px",
                        colorPercentage: "white",
                        fontSizePercentage: "13px",
                     },
                  }}
                  maxHeightList={"225px"}
                  headers={
                     showDeliberation
                        ? [
                             { headerTitle: "Asunto", type: "text", bodyPosition: "center" },
                             ...Object.values(affairsList).flatMap((affair: any) => {
                                const involvedArray: any[] = [];
                                for (const involved of affair.involved) {
                                   if (!uniqueInvolvedIds.has(involved.id)) {
                                      uniqueInvolvedIds.add(involved.id);
                                      involvedArray.push({
                                         headerTitle: involved.name,
                                         type: "text",
                                         bodyPosition: "center",
                                      });
                                   }
                                }
                                return involvedArray;
                             }),
                             { headerTitle: "Porcentaje", type: "percentage", bodyPosition: "center" },
                          ]
                        : [
                             { headerTitle: "Asunto", type: "text", bodyPosition: "center" },
                             { headerTitle: "Porcentaje", type: "percentage", bodyPosition: "center" },
                             { headerTitle: "Resolución", type: "positive", bodyPosition: "center" },
                          ]
                  }
                  rows={Object.keys(affairsList).map((affair) => {
                     if (!showDeliberation)
                        return {
                           affairTitle: erraseIndex(affair),
                           percentage: affairsList[affair].percentage,
                           resolution: affairsList[affair].resolution,
                        };

                     const involvedList = {};
                     for (const involved of affairsList[affair].involved) {
                        if (involved.vote?.abstention) involvedList[involved._id] = "ABS";
                        involvedList[involved.id] = involved.vote?.answer || "PEN";
                     }
                     return {
                        affairTitle: erraseIndex(affair),
                        ...involvedList,
                        percentage: affairsList[affair].percentage,
                     };
                  })}
               />
            </Box>
         </Stack>
      </Box>
   );
};

export const AffairListItem = ({ affair, isNew }) => (
   <ListItem
      key={
         affair.affair?.orderId + affair?.description + Math.random() ||
         affair.title + affair?.description + Math.random()
      }
      disablePadding
      sx={{
         border: 0.5,
         borderColor: "#C8C8C8",
         borderLeft: 7,
         borderLeftColor: "#46566a",
         borderBottomLeftRadius: 5,
         py: 0.5,
         px: 1,
         my: 0.3,
      }}
   >
      <Grid container spacing={2}>
         <Grid
            item
            xs={6}
            sx={{
               position: "relative",
               "&::after": {
                  content: '""',
                  position: "absolute",
                  top: "14px",
                  bottom: "0px",
                  right: 0,
                  width: "1px",
                  backgroundColor: "#c4c6ca",
               },
            }}
         >
            <Typography variant="caption" fontWeight={600}>
               {isNew ? changeFormatToAString(affair.affair.orderId) : affair.title || "Sin título"}
            </Typography>
         </Grid>
         <Grid item xs={6}>
            <Typography variant="caption">
               {isNew ? affair.affair.description : affair.description || "Sin descripción"}
            </Typography>
         </Grid>
      </Grid>
   </ListItem>
);

export default GoverningBodyList;
