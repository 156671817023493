import { useCallback, useEffect, useState } from "react";
import XMLParser from "react-xml-parser";

function sortByPubDate(array) {
   return array.sort((a, b) => {
      const date1 = new Date(a.children.find((child) => child.name === "pubDate").value).getTime();
      const date2 = new Date(b.children.find((child) => child.name === "pubDate").value).getTime();
      return date2 - date1;
   });
}

const getImageUrl = (description) => {
   const match = description.match(/src=["']([^"']+\.(jpg|jpeg|png|gif))["']/i);
   return match ? match[1] : null;
};

const capitalizeFirstLetter = (string) => {
   return string.charAt(0).toUpperCase() + string.slice(1);
};

const getMonthName = (monthNumber) => {
   const date = new Date();
   date.setMonth(monthNumber);
   return date.toLocaleString("es-ES", { month: "long" });
};

const urls = ["https://blog.lecosy.com.mx/index.php/feed/"];
const toolsCategories = ["Formularios y herramientas", "Consejos prácticos"];

const useGetInsights = (modules: string[]) => {
   const [xml, setXml] = useState(null);
   const [insights, setInsights] = useState([]);
   const [guides, setGuides] = useState([]);
   const categories = [...modules, ...toolsCategories];

   const getWordpressBlog = useCallback(async () => {
      const fetchAndParse = async (url) => {
         const response = await fetch(url);
         const data = await response.text();
         const xml = new XMLParser().parseFromString(data);
         const itemsFound = xml.children[0].children[xml.children[0].children.length - 1].children.filter((i) =>
            i.children.some((c) => c.name === "category" && categories.some((cat) => c.value.includes(cat)))
         );
         return itemsFound;
      };
      const itemsPromises = urls.map(fetchAndParse);
      Promise.all(itemsPromises)
         .then((itemsArrays) => {
            const itemsFound = itemsArrays.flat();
            const orderByPubDate = sortByPubDate(itemsFound);
            setXml(orderByPubDate);
         })
         .catch((error) => {
            console.error("Error al obtener los datos:", error);
         });
   }, []);

   useEffect(() => {
      getWordpressBlog();
   }, [getWordpressBlog]);

   useEffect(() => {
      if (xml) {
         const xmlObject = xml.map((element) => {
            const title = element.children.find((prop) => prop.name === "title").value;
            const image = getImageUrl(element.children.find((prop) => prop.name === "description").value);
            const dateRaw = new Date(element.children.find((prop) => prop.name === "pubDate").value);
            const date =
               (dateRaw.getDate().toString().length === 1 ? "0" + dateRaw.getDate().toString() : dateRaw.getDate()) +
               " " +
               capitalizeFirstLetter(getMonthName(dateRaw.getMonth().toString())) +
               " " +
               dateRaw.getFullYear();
            const link = element.children.find((prop) => prop.name === "link").value;
            const categories = element.children
               .filter((prop) => prop.name === "category")
               .map((prop) => prop.value.replace(" >", ""));
            const insights = {
               title: title,
               image: image,
               date: date,
               link: link,
               category: categories,
            };
            return insights;
         });
         setInsights(xmlObject.filter((c) => !toolsCategories.some((cat) => c.category.includes(cat))));
         setGuides(xmlObject.filter((c) => toolsCategories.some((cat) => c.category.includes(cat))));
      }
   }, [xml]);

   return { insights, guides };
};

export default useGetInsights;
