import { useContext, useEffect, useState } from "react";
import { SupportContext } from "../../context/supportContext";
import { UserContext } from "../../context/userContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { sendSupportTicketMail } from "../../lib/lecosyBackendClient";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { Box, Button, CircularProgress, Drawer, Grid, MenuItem, Toolbar, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { supportSchema } from "../../lib/validations/inputSchemas";
import { InputSelectField } from "../Inputs/InputSelectField";
import { InputTextField } from "../Inputs/InputTextField";
import { SystemThemeConfig } from "../../config/systemTheme.config";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../lib/s3Client";
import { deleteFolderS3, getUrlS3, sendSupportEmail } from "../../lib/usersBEClient";
import { InputImage } from "../Inputs/InputImage";
import { InputFile } from "../Inputs/InputFile";
import { FileWithDateInterface } from "../../types/BaseTypes";

interface SupportDrawerProps {
   toolbar: boolean;
}

export const SupportDrawer = (props: SupportDrawerProps) => {
   const { supportOpen, setSupportOpen, setUpdateData, updateData } = useContext(SupportContext);
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [uploadedEvidence, setUploadedEvidence] = useState<FileWithDateInterface>();
   const [uploadedDocument, setUploadedDocument] = useState<FileWithDateInterface>();
   const [sendingEmail, setSendingEmail] = useState<boolean>(false);
   const [supType, setSupType] = useState<string>("");

   const feedbackOptions = ["Haz una pregunta", "Informa de un error", "Sugiere una mejora", "Actualizar información"];

   const handleSubmitFiles = async (evidence?: File, document?: File) => {
      const files = {};
      const tempFolder = Math.floor(Math.random() * 100000000).toString();

      if (evidence) {
         const evidencePresignedUrl = await UseGetPresignedURLUpload({
            bucket: "images-lecosy",
            folder: `support-temp/${tempFolder}`,
            name: "evidence.png",
         });
         await uploadFileToS3(evidencePresignedUrl.data, evidence);
         const evidenceUrl: string = await getUrlS3(
            "images-lecosy",
            { folder: `support-temp/${tempFolder}` },
            "evidence.png"
         );
         Object.assign(files, { evidence: evidenceUrl });
      }
      if (document) {
         const documentPresignedUrl = await UseGetPresignedURLUpload({
            bucket: "images-lecosy",
            folder: `support-temp/${tempFolder}`,
            name: "document.pdf",
         });
         await uploadFileToS3(documentPresignedUrl.data, document);
         const documentUrl: string = await getUrlS3(
            "images-lecosy",
            { folder: `support-temp/${tempFolder}` },
            "document.pdf"
         );
         Object.assign(files, { document: documentUrl });
      }
      return { files, tempFolder };
   };

   const handleSendEmail = async (values) => {
      setSendingEmail(true);
      try {
         const url = await handleSubmitFiles(uploadedEvidence?.file, uploadedDocument?.file);
         await sendSupportEmail(values.title, values.type, url.files, values.description, values.email);
         sendSupportTicketMail(values.email);
         if (Object.keys(url).length > 0) await deleteFolderS3("images-lecosy", `support-temp/${url.tempFolder}`);
         setUploadedEvidence(undefined);
         setUploadedDocument(undefined);
         setSupportOpen(false);
         setUpdateData(false);
         showSnackBar("Correo enviado exitosamente", false);
      } catch (error) {
         console.log(error);
         setSupportOpen(false);
         setUpdateData(false);
         setUploadedEvidence(undefined);
         setUploadedDocument(undefined);
         showSnackBar("Ha ocurrido un error, intentelo de nuevo mas tarde", true);
      }
      setSendingEmail(false);
   };

   const FormObserver: React.FC = () => {
      const { values, setFieldValue } = useFormikContext();
      useEffect(() => {
         const valuess: any = values;
         const admin = user.group
            ? user.group.admin._id === user.id
            : user.companies.some((company) => company.company_details.admin._id === user.id);
         if (valuess.type === "Actualizar información" && !admin) {
            setFieldValue("type", "");
            showSnackBar("Solo el administrador del sistema puede solicitar un cambio", true);
         }
         setSupType(valuess.type);
         // eslint-disable-next-line
      }, [values]);
      return null;
   };

   return (
      <SystemThemeConfig>
         <Drawer anchor={"right"} open={supportOpen} hideBackdrop={true}>
            <>
               {props.toolbar ? <Toolbar /> : <></>}
               <Box sx={{ width: "380px", height: "100%", display: "flex", flexDirection: "row" }}>
                  <Box
                     display="flex"
                     alignItems="center"
                     onClick={() => {
                        setSupportOpen(false);
                        setUpdateData(false);
                     }}
                     sx={{
                        "&:hover": {
                           bgcolor: "#F7F7F7",
                        },
                     }}
                  >
                     <ChevronRightIcon fontSize="small" />
                  </Box>
                  <Box>
                     <Formik
                        initialValues={{
                           title: "",
                           description: "",
                           email: user.email,
                           type: updateData ? "Actualizar información" : "",
                        }}
                        onSubmit={handleSendEmail}
                        validationSchema={supportSchema(supType)}
                     >
                        {(formProps: FormikProps<any>) => (
                           <Form>
                              <FormObserver />
                              <Grid container sx={{ alignItems: "center" }}>
                                 <Grid item xs={12} sx={{ px: 2 }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", py: 2 }}>
                                       <Typography
                                          variant="h5"
                                          textAlign={"justify"}
                                          sx={{ fontWeight: "bold", color: "#162c44", py: 1 }}
                                       >
                                          Soporte
                                       </Typography>
                                       <InputSelectField
                                          label={formProps.values.type === "" ? "Retroalimenta a Lecosy" : ""}
                                          labelId="type"
                                          id="type"
                                          fullWidth
                                          name="type"
                                          variant={"standard"}
                                       >
                                          {feedbackOptions.map((option) => {
                                             return (
                                                <MenuItem key={option} value={option}>
                                                   {option}
                                                </MenuItem>
                                             );
                                          })}
                                       </InputSelectField>
                                       <InputTextField
                                          label={
                                             formProps.values.type === "Haz una pregunta"
                                                ? "Descríbenos tu pregunta"
                                                : formProps.values.type === "Informa de un error"
                                                ? "Título del problema"
                                                : formProps.values.type === "Sugiere una mejora"
                                                ? "¿Qué tipo de mejora nos sugieres?"
                                                : formProps.values.type === "Actualizar información"
                                                ? "¿Qué requieres actualizar?"
                                                : "Título"
                                          }
                                          id="title"
                                          name="title"
                                          variant={"standard"}
                                          fullWidth
                                          type="text"
                                          multiline
                                          placeholder={
                                             formProps.values.type === "Haz una pregunta"
                                                ? "¿Qué te gustaría preguntarle al equipo de lecosy?"
                                                : formProps.values.type === "Informa de un error"
                                                ? "¿Cómo explicarías de manera breve lo ocurrido?"
                                                : formProps.values.type === "Sugiere una mejora"
                                                ? "ej. Mejora visual, mejora de funcionalidad, etc."
                                                : formProps.values.type === "Actualizar información"
                                                ? "ej. Mi lecosy - información general, etc."
                                                : ""
                                          }
                                          sx={{ my: 2 }}
                                       />
                                       {formProps.values.type !== "Haz una pregunta" ? (
                                          <InputTextField
                                             label={
                                                formProps.values.type === "Actualizar información"
                                                   ? "Descríbenos la información a actualizar"
                                                   : formProps.values.type !== "Haz una pregunta"
                                                   ? "Descríbenos tus comentarios"
                                                   : "Descripción"
                                             }
                                             multiline
                                             id="description"
                                             name="description"
                                             type="text"
                                             fullWidth={true}
                                             variant="standard"
                                             placeholder={
                                                formProps.values.type === "Informa de un error"
                                                   ? "Descríbenos el problema que se presentó y como has intentado solucionarlo"
                                                   : formProps.values.type === "Sugiere una mejora"
                                                   ? "ej. una posible mejora para el sistema lecosy sería implementar…"
                                                   : formProps.values.type === "Actualizar información"
                                                   ? "ej. El número de teléfono de la empresa cambio a xxxxxxxxxx."
                                                   : ""
                                             }
                                             sx={{ my: 2 }}
                                          />
                                       ) : (
                                          <></>
                                       )}
                                       <Typography fontSize={14} pt={2} pb={1}>
                                          {formProps.values.type === "Haz una pregunta"
                                             ? "Queremos asegurarnos de que obtengas la información que necesitas."
                                             : formProps.values.type === "Actualizar información"
                                             ? "Queremos asegurarnos de que se actualice la información correcta."
                                             : "Queremos asegurarnos de brindar una adecuada solución."}
                                       </Typography>
                                       <InputTextField
                                          label={"Correo electrónico de seguimiento"}
                                          id="email"
                                          name="email"
                                          type="text"
                                          fullWidth={true}
                                          variant="standard"
                                          placeholder="ej. correo@correo.com.mx"
                                          sx={{ my: 1 }}
                                       />
                                    </Box>
                                 </Grid>
                                 {formProps.values.type === "Actualizar información" && user.auth ? (
                                    <Grid item xs={12} mb={2}>
                                       <Box display={"flex"} alignItems={"center"}>
                                          <Typography
                                             variant="body1"
                                             sx={{
                                                color: "#162c44",
                                                px: 2,
                                                py: 1,
                                             }}
                                          >
                                             <strong>Adjuntar documento</strong> (opcional)
                                          </Typography>
                                       </Box>
                                       <Box sx={{ display: "flex", mx: 1 }}>
                                          <InputFile
                                             file={uploadedDocument?.file}
                                             setFile={setUploadedDocument}
                                             keyName="uploadedDoc"
                                          />
                                       </Box>
                                    </Grid>
                                 ) : (
                                    <></>
                                 )}
                                 {user.auth ? (
                                    <Grid item xs={12}>
                                       <Box display={"flex"} alignItems={"center"}>
                                          <Typography
                                             variant="body1"
                                             sx={{
                                                color: "#162c44",
                                                px: 2,
                                                py: 1,
                                             }}
                                          >
                                             <strong>Imagen de evidencia</strong> (opcional)
                                          </Typography>
                                       </Box>
                                       <Box sx={{ display: "flex", mx: 3 }}>
                                          <InputImage
                                             image={uploadedEvidence?.file}
                                             setImage={setUploadedEvidence}
                                             keyName="evidence"
                                          />
                                       </Box>
                                    </Grid>
                                 ) : null}
                                 <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                                    <Button
                                       type="submit"
                                       sx={{
                                          my: 2,
                                          alignSelf: "flex-end",
                                          bgcolor: "#162c44",
                                          textTransform: "none",
                                          "&:hover": {
                                             bgcolor: "#162c44",
                                          },
                                       }}
                                    >
                                       {sendingEmail ? (
                                          <CircularProgress color="success" size={25} />
                                       ) : (
                                          <Typography color={"white"}>Enviar</Typography>
                                       )}
                                    </Button>
                                 </Grid>
                              </Grid>
                           </Form>
                        )}
                     </Formik>
                  </Box>
               </Box>
            </>
         </Drawer>
      </SystemThemeConfig>
   );
};
