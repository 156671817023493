import BeneficiaryModule from "./BeneficiaryModule";
import ComplaintChannelModule from "./ComplaintChannelModule";
import GovernanceModule from "./GovernanceModule";

const UseModules = (module) => {
   switch (module.service.serviceName) {
      case "Canal de denuncias":
         return <ComplaintChannelModule module={module} />;
      case "Gobierno corporativo":
         return <GovernanceModule module={module} />;
      case "Beneficiario controlador":
         return <BeneficiaryModule module={module} />;
      default:
         return;
   }
};

export default UseModules;
