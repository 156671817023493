import {
   Box,
   Button,
   FormControl,
   FormControlLabel,
   FormLabel,
   Radio,
   RadioGroup,
   Stack,
   Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { useContext, useState } from "react";
import { UpdateVotes, updateVotesVotation } from "../../../lib/gobCorpBEClient";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";

export const VoteModal = ({ user, setOpenModalCreateModal }) => {
   const { socket, session, state, dispatch, isPendingVote } = useContext(GovernanceSessionContext);
   const [handleError, setHandleError] = useState(false);
   const { mutate } = UpdateVotes();

   const handleVotation = (voteValue, userId) => {
      dispatch({
         type: "voteUserInAffairVotation",
         orderInfo: {
            orderId: state.vote.affair.orderId,
            answer: voteValue,
            user: userId,
         },
         ...(state.vote.company && { company: state.vote.company }),
      });
      socket.emit("refresh-affair-votes", {
         sessionId: session._id,
         orderInfo: {
            orderId: state.vote.affair.orderId,
            answer: voteValue,
            user: userId,
         },
         ...(state.vote.company && { company: state.vote.company }),
      });
      setOpenModalCreateModal(false);
   };

   const handleAbstentionVote = (userId) => {
      dispatch({
         type: "voteUserInAffairVotation",
         orderInfo: { orderId: state.vote.affair.orderId, user: userId, abstention: true },
         ...(state.vote.company && { company: state.vote.company }),
      });
      socket.emit("refresh-affair-votes", {
         sessionId: session._id,
         orderInfo: { orderId: state.vote.affair.orderId, user: userId, abstention: true },
         ...(state.vote.company && { company: state.vote.company }),
      });
      setOpenModalCreateModal(false);
   };

   async function addVoteToState(values) {
      let data = values.votes;
      if (isPendingVote) data = (await updateVotesVotation(values)).votes;
      const userVoteInfo = data.find((vote) => vote.userId === user.id);
      socket.emit("refresh-additional-votes", {
         sessionId: session._id,
         votationId: values._id,
         voteInfo: { ...userVoteInfo },
      });
   }
   const handleSubmit = (values) => {
      if (state.vote.affair) {
         if (values["nullVote"] === false && values["vote"] === "") return setHandleError(true);
         if (values["nullVote"]) return handleAbstentionVote(user.id);
         else return handleVotation(values["vote"], user.id);
      }
      const userVote = {
         votationId: state.vote._id,
         voteData: {
            votes: [
               {
                  ...(values["nullVote"] && { abstention: true }),
                  userId: user.id,
                  userName: user.firstName + " " + user.lastName,
                  ...(!values["nullVote"] && values["vote"] && { answer: values["vote"] }),
                  avaliableVotes: state.vote.votes.find((userV) => userV.userId === user.id).avaliableVotes,
               },
            ],
         },
      };
      if (Object.keys(userVote).length === 0) return;
      mutate(userVote, {
         onError: (error: any) => {
            console.error(error);
         },
         onSuccess: (data) => {
            addVoteToState(data);
            setOpenModalCreateModal(false);
         },
      });
   };

   if (!state.vote) return;
   return (
      <Formik initialValues={{ vote: "", nullVote: false }} onSubmit={handleSubmit}>
         {(formProps: FormikProps<any>) => {
            return (
               <Form>
                  <Box
                     sx={{
                        py: 1,
                        bgcolor: "#152c44",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Votación</Typography>
                  </Box>
                  <Stack
                     spacing={3}
                     sx={{
                        bgcolor: "background.paper",
                        py: 3,
                        px: 6,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                     }}
                  >
                     <Typography sx={{ fontWeight: 600, color: "#64748B", fontSize: 16 }}>
                        {state.vote.affair ? state.vote.affair.description : state.vote.title}
                     </Typography>
                     <Stack spacing={1}>
                        <FormControl>
                           <FormLabel>Selecciona una respuesta</FormLabel>
                           <RadioGroup onChange={(e) => formProps.setFieldValue("vote", e.target.value)}>
                              {state.vote.answers.map((answer) => {
                                 return (
                                    <FormControlLabel
                                       value={answer}
                                       control={<Radio />}
                                       label={answer}
                                       key={answer}
                                       disabled={formProps.values.nullVote}
                                    />
                                 );
                              })}
                           </RadioGroup>
                        </FormControl>
                        <InputCheckBox
                           id={"nullVote"}
                           label={"Abstenerse del voto"}
                           name={"nullVote"}
                           sx={{ fontSize: 8 }}
                           size="small"
                        />
                     </Stack>
                     <Box>
                        {handleError && (
                           <Typography sx={{ fontSize: 12, color: "red", textAlign: "center" }}>
                              Por favor seleccione una opción
                           </Typography>
                        )}
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                           <Button
                              variant="contained"
                              type="submit"
                              sx={{ bgcolor: "#2D4357", color: "white", width: "70%" }}
                           >
                              Enviar respuesta
                           </Button>
                        </Box>
                     </Box>
                  </Stack>
               </Form>
            );
         }}
      </Formik>
   );
};
