import {
   AddCircle,
   EditNote,
   ExpandMore,
   InfoRounded,
   KeyboardArrowDown,
   KeyboardArrowUp,
   Percent,
   PictureAsPdf,
} from "@mui/icons-material";
import {
   Box,
   Button,
   CircularProgress,
   Container,
   Drawer,
   Stack,
   Popover,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getFileByGovernanceAndFoldername, getUrlS3 } from "../../lib/usersBEClient";
import { UserContext } from "../../context/userContext";
import { formatTextUpperLower, getDateAndTimeFromISODate } from "../../const/globalConst";
import { EditGCUserDrawer } from "./EditGCUserDrawer";
import { AddShareholderModal } from "./ModalsGovernanceModule/AddShareholderModal";
import { StepperGovernBodyUsers } from "./StepperGovernBodyUsers";
import { CustomModalComponent } from "../CustomModalComponent";
import { StructureDataDisplay } from "./StructureDataDisplay";
import { useGetMembersInfo } from "../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { EditShareholderModal } from "./ModalsGovernanceModule/EditShareholderModal";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { SnackBarContext } from "../../context/snackBarContext";

interface GoverningBodiesComponentProps {
   setDocument?: Function;
   setShareholderBodyId?: Function;
   bodiesSeed: number;
}

export interface GovernBodyData {
   title: string;
   bodyId: string;
   structureData?: StructureData;
   file?: File;
   functions?: string;
}

interface UserModifyData {
   bodyId: string;
   bodyTitle: string;
   userId: string;
   firstName: string;
   lastName: string;
   phoneNumber: string;
   email: string;
   charge: string;
   chargeName: string;
   characters: string[];
   specialPermission: boolean;
}

export interface BodyUserFormat {
   _id: string;
   firstName: string;
   lastName: string;
   phoneNumber: string;
   email: string;
   charge: string;
   chargeName: string;
   characters: string[];
   user: string;
}

export interface StructureData {
   attendancePercentage: number;
   chargesDuration: string;
   compositionI: number;
   compositionP: number;
   compositionR: number;
   confirmationDays: number;
   integrationMax: number;
   integrationMin: number;
   quorum: number;
   resolutionVotes: number;
   sendAnnouncementDays: number;
}

export const GoverningBodiesComponent = (props: GoverningBodiesComponentProps) => {
   const { formatedMembers } = useGetMembersInfo(false);
   const { companySelected, user } = useContext(UserContext);

   const [bodyData, setBodyData] = useState<GovernBodyData>({
      title: "",
      bodyId: "",
      structureData: null,
      file: null,
      functions: "",
   });
   const [bodyUsers, setBodyUsers] = useState<BodyUserFormat[]>([]);
   const [userDataModify, setUserDataModify] = useState<UserModifyData>({
      bodyId: "",
      bodyTitle: "",
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      charge: "",
      chargeName: "",
      characters: [],
      specialPermission: false,
   });
   const { affairs, series, bodies, setSeed } = useContext(CorporateDataContext);
   const [openStepper, setOpenStepper] = useState(false);
   const [openModalShareholder, setOpenModalShareholder] = useState(false);
   const [stepperPhase, setStepperPhase] = useState(0);
   const { showSnackBar } = useContext(SnackBarContext);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [titles, setTitles] = useState([]);
   const [bodyEdition, setBodyEdition] = useState(false);
   const [openEditShareholder, setOpenEditShareholder] = useState(false);
   const [selectedShareholder, setSelectedShareholder] = useState(null);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const [isLoadingFile, setIsLoadingFile] = useState(false);
   const onlyHasBeneficiary =
      !user.modules.includes("Gobierno corporativo") && user.modules.includes("Beneficiario controlador");

   const chargesOrder = ["presidente", "vicepresidente", "secretario", "vocal", "consultor"];
   const getChargeKey = (chargeName) => {
      if (!chargeName) return;
      if (chargeName.toLowerCase().includes("vocal")) return "vocal";
      if (chargeName.toLowerCase().includes("presidente")) return "presidente";
      if (chargeName.toLowerCase().includes("secretario")) return "secretario";
      if (chargeName.toLowerCase().includes("vicepresidente")) return "vicepresidente";
      return "consultor";
   };

   useEffect(() => {
      if (bodies?.length > 0) {
         const asamblea: any = bodies.find((b) => b.bodiesData.title === "Asamblea de accionistas");
         if (asamblea) {
            props.setDocument(asamblea?.bodiesData?.regulationFile || null);
            props.setShareholderBodyId(asamblea?.bodiesData?._id || null);
         }
      }
   }, [bodies, companySelected]);

   const getFile = async (governingBodyId: string, title: string, fileName: string) => {
      setIsLoadingFile(true);
      let folderName = "";
      if (title === "Asamblea de accionistas") folderName = "Acta constitutiva";
      else if (title === "Consejo de administración") folderName = "Reglamento de consejo";
      else folderName = "Reglamento de comité";

      const file = await getFileByGovernanceAndFoldername(governingBodyId, folderName);
      if (file === undefined) return showSnackBar("Favor de actualizar documento", true);
      const fileDirection = file.fileDirection;
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         {
            folder: fileDirection,
         },
         fileName
      );
      setIsLoadingFile(false);
      window.open(fileData1, "_blank", "noopener,noreferrer");
   };

   const handleCloseStepper = () => {
      setOpenStepper(false);
      setBodyData({ title: "", bodyId: "", structureData: null, file: null, functions: "" });
   };

   const bodiesTitles = () => {
      const titleArray = bodies.map((body) => body.bodiesData.title);
      setTitles(titleArray);
   };

   return (
      <Container
         sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "white",
            boxShadow: 2,
            borderRadius: 1,
         }}
      >
         <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, alignItems: "center" }}>
            <Typography fontWeight={"bold"} fontSize={17}>
               Órganos de gobierno
            </Typography>
            <Button
               startIcon={<AddCircle />}
               variant="contained"
               onClick={() => {
                  bodiesTitles();
                  setStepperPhase(0);
                  setOpenStepper(true);
                  setBodyEdition(false);
               }}
            >
               Agregar órgano
            </Button>
         </Box>
         {!formatedMembers ? (
            <Typography sx={{ p: 2 }}>Sin órganos de gobierno</Typography>
         ) : (
            <TableContainer sx={{ display: "flex", width: "100%", px: 1, pb: 2 }}>
               <Table>
                  <TableBody>
                     {bodies.length === 0 ? (
                        <Box
                           sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              alignContent: "center",
                           }}
                        >
                           <CircularProgress sx={{ my: 3 }} />
                        </Box>
                     ) : (
                        bodies.map((body, index) => {
                           const openPopover = Boolean(anchorEl);
                           const handlePopoverOpen = (event: any) => setAnchorEl(event.currentTarget);
                           const handlePopoverClose = () => setAnchorEl(null);
                           return (
                              <Accordion
                                 key={index}
                                 disableGutters
                                 elevation={0}
                                 sx={{
                                    boxShadow: 0,
                                    "&:before": { backgroundColor: "transparent !important" },
                                    borderBottom: 0,
                                    my: 0.5,
                                 }}
                              >
                                 <AccordionSummary
                                    expandIcon={
                                       <ExpandMore
                                          sx={{
                                             color: "white",
                                          }}
                                       />
                                    }
                                    sx={{ bgcolor: "#162c44", borderRadius: "20px !important" }}
                                 >
                                    <Box
                                       sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          columnGap: 1,
                                       }}
                                    >
                                       <Typography sx={{ fontSize: 14, color: "white" }}>
                                          {body.bodiesData.title}
                                       </Typography>
                                       <InfoRounded
                                          id={body.bodiesData.title}
                                          onMouseEnter={handlePopoverOpen}
                                          sx={{ color: "#D4D4D4", fontSize: 18 }}
                                       />
                                       <Popover
                                          id={body.bodiesData.title}
                                          open={openPopover && anchorEl.id === body.bodiesData.title}
                                          anchorEl={anchorEl}
                                          onClose={handlePopoverClose}
                                          disableAutoFocus
                                       >
                                          <Stack
                                             sx={{
                                                p: 2,
                                                maxWidth: 700,
                                                maxHeight: 400,
                                                overflow: "auto",
                                                gap: 2,
                                             }}
                                             onMouseLeave={handlePopoverClose}
                                          >
                                             {body.bodiesData.title === "Asamblea de accionistas" ? (
                                                affairs.length > 0 ? (
                                                   affairs.map((a, i) => (
                                                      <Typography
                                                         key={`afairs-${i}`}
                                                         sx={{ fontSize: 14, textAlign: "justify" }}
                                                      >
                                                         {i + 1}. {a.title}
                                                      </Typography>
                                                   ))
                                                ) : (
                                                   <Typography sx={{ fontSize: 14 }}>
                                                      No se han registrado asuntos
                                                   </Typography>
                                                )
                                             ) : (
                                                body.bodiesData.functions.map((o, i) => (
                                                   <Typography
                                                      key={`afairsGovernance-${i}`}
                                                      fontSize={14}
                                                      sx={{ textAlign: "justify" }}
                                                   >
                                                      {i + 1}. {o}
                                                   </Typography>
                                                ))
                                             )}
                                          </Stack>
                                       </Popover>
                                    </Box>
                                 </AccordionSummary>
                                 <AccordionDetails>
                                    <>
                                       <Table size="small" sx={{ display: "flex", flexDirection: "column" }}>
                                          <TableHead>
                                             <TableRow sx={{ display: "flex" }}>
                                                {formatTextUpperLower(body.bodiesData.title) ===
                                                "Asamblea de accionistas" ? (
                                                   <>
                                                      <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                         Socio / Accionista
                                                      </TableCell>
                                                      <TableCell sx={{ flex: 3, fontWeight: 600 }}>
                                                         Correo electrónico
                                                      </TableCell>
                                                      <TableCell sx={{ flex: 2, fontWeight: 600 }}>Teléfono</TableCell>
                                                      <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                         Fecha de creación
                                                      </TableCell>
                                                      <TableCell
                                                         sx={{
                                                            flex: 2,
                                                            fontWeight: 600,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                         }}
                                                      >
                                                         Porcentaje
                                                      </TableCell>
                                                   </>
                                                ) : (
                                                   <>
                                                      <TableCell sx={{ flex: 2.5, fontWeight: 600 }}>
                                                         Cargo que desempeña
                                                      </TableCell>
                                                      <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                         Nombre de Usuario
                                                      </TableCell>
                                                      <TableCell sx={{ flex: 3, fontWeight: 600 }}>
                                                         Correo electronico
                                                      </TableCell>
                                                      <TableCell sx={{ flex: 2, fontWeight: 600 }}>Teléfono</TableCell>
                                                      <TableCell sx={{ flex: 2, fontWeight: 600 }}>
                                                         Fecha de creación
                                                      </TableCell>
                                                   </>
                                                )}
                                             </TableRow>
                                          </TableHead>
                                          <TableBody>
                                             {body.usersData
                                                .sort((a, b) => {
                                                   const aKey = getChargeKey(a.chargeName);
                                                   const bKey = getChargeKey(b.chargeName);
                                                   return chargesOrder.indexOf(aKey) - chargesOrder.indexOf(bKey);
                                                })
                                                .map((user, subIndex) => (
                                                   <TableRow
                                                      sx={{
                                                         display: "flex",
                                                         bgcolor: "#F7F9FB",
                                                         borderRadius: 2,
                                                         m: 1,
                                                         ":hover": { cursor: "pointer" },
                                                      }}
                                                      onClick={
                                                         formatTextUpperLower(body.bodiesData.title) !==
                                                         "Asamblea de accionistas"
                                                            ? () => {
                                                                 setUserDataModify({
                                                                    bodyId: body.bodiesData._id,
                                                                    bodyTitle: body.bodiesData.title,
                                                                    userId: user.user,
                                                                    firstName: user.firstName,
                                                                    lastName: user.lastName,
                                                                    phoneNumber: user.phoneNumber,
                                                                    email: user.email,
                                                                    charge: user.charge,
                                                                    chargeName: user.chargeName,
                                                                    characters: user.characters,
                                                                    specialPermission: user.specialPermission || false,
                                                                 });
                                                                 setBodyUsers(body.usersData);
                                                                 setBodyData({
                                                                    title: body.bodiesData.title,
                                                                    bodyId: body.bodiesData._id,
                                                                 });
                                                                 setOpenDrawer(true);
                                                              }
                                                            : () => {
                                                                 setSelectedShareholder(user);
                                                                 setOpenEditShareholder(true);
                                                              }
                                                      }
                                                      key={`${body.bodiesData.title}${subIndex}`}
                                                   >
                                                      {formatTextUpperLower(body.bodiesData.title) ===
                                                      "Asamblea de accionistas" ? (
                                                         <>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.firstName} {user.lastName}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 3,
                                                                  borderBottom: 0,
                                                                  overflow: "hidden",
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.email}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.phoneNumber}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {getDateAndTimeFromISODate(user.createdAt)}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  justifyContent: "center",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               <Typography
                                                                  sx={{
                                                                     display: "flex",
                                                                     alignItems: "center",
                                                                     px: 1,
                                                                     fontSize: 15,
                                                                     bgcolor: "#162C44",
                                                                     color: "white",
                                                                     borderRadius: 1,
                                                                     maxHeight: 25,
                                                                  }}
                                                               >
                                                                  {formatedMembers[subIndex]?.propertyPercentage}
                                                                  <Percent sx={{ pl: 1 }} />
                                                               </Typography>
                                                            </TableCell>
                                                         </>
                                                      ) : (
                                                         <>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2.5,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.chargeName}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.firstName} {user.lastName}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 3,
                                                                  borderBottom: 0,
                                                                  overflow: "hidden",
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.email}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {user.phoneNumber}
                                                            </TableCell>
                                                            <TableCell
                                                               sx={{
                                                                  flex: 2,
                                                                  borderBottom: 0,
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {getDateAndTimeFromISODate(user.createdAt)}
                                                            </TableCell>
                                                         </>
                                                      )}
                                                   </TableRow>
                                                ))}

                                             <TableRow sx={{ display: "flex" }}>
                                                <TableCell sx={{ flex: 1, borderBottom: 0, display: "flex" }}>
                                                   {formatTextUpperLower(body.bodiesData.title) ===
                                                   "Asamblea de accionistas" ? (
                                                      <Button
                                                         startIcon={<AddCircle />}
                                                         variant="contained"
                                                         sx={{
                                                            flex: 5,
                                                            mr: 1,
                                                            height: 30,
                                                            alignSelf: "center",
                                                            bgcolor: "#E5E6EB",
                                                            color: "black",
                                                            borderRadius: 2,
                                                            boxShadow: 0,
                                                            ":hover": { bgcolor: "#E5E6EB" },
                                                         }}
                                                         onClick={() => {
                                                            if (series.length > 0) setOpenModalShareholder(true);
                                                            else
                                                               showSnackBar(
                                                                  "Favor de crear series antes de agregar accionistas",
                                                                  true
                                                               );
                                                         }}
                                                      >
                                                         Agregar socio / accionista
                                                      </Button>
                                                   ) : (
                                                      <Button
                                                         startIcon={<AddCircle />}
                                                         variant="contained"
                                                         sx={{
                                                            flex: 5,
                                                            mr: 1,
                                                            height: 30,
                                                            alignSelf: "center",
                                                            bgcolor: "#E5E6EB",
                                                            color: "black",
                                                            borderRadius: 2,
                                                            boxShadow: 0,
                                                            ":hover": { bgcolor: "#E5E6EB" },
                                                         }}
                                                         onClick={() => {
                                                            setBodyData({
                                                               title: body.bodiesData.title,
                                                               bodyId: body.bodiesData._id,
                                                            });
                                                            setBodyUsers(body.usersData);
                                                            setOpenStepper(true);
                                                            setStepperPhase(1);
                                                         }}
                                                      >
                                                         Añadir nuevo usuario
                                                      </Button>
                                                   )}
                                                   {!onlyHasBeneficiary && (
                                                      <Button
                                                         endIcon={<PictureAsPdf />}
                                                         variant="contained"
                                                         sx={{
                                                            flex: 1,
                                                            ml: 1,
                                                            height: 30,
                                                            alignSelf: "center",
                                                            bgcolor: "#E5E6EB",
                                                            color: "black",
                                                            borderRadius: 2,
                                                            boxShadow: 0,
                                                            ":hover": { bgcolor: "#E5E6EB" },
                                                         }}
                                                         disabled={isLoadingFile}
                                                         onClick={() => {
                                                            getFile(
                                                               body.bodiesData._id,
                                                               body.bodiesData.title,
                                                               body.bodiesData.regulationFile.name
                                                            );
                                                         }}
                                                      >
                                                         {formatTextUpperLower(body.bodiesData.title) ===
                                                         "Asamblea de accionistas"
                                                            ? "Acta constitutiva"
                                                            : "Reglamento"}
                                                      </Button>
                                                   )}
                                                </TableCell>
                                             </TableRow>
                                             {!onlyHasBeneficiary && (
                                                <Box sx={{ bgcolor: "#F0F0F0", m: 2, borderRadius: 2.5, px: 4, py: 2 }}>
                                                   <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                                      <Typography fontWeight={600}>Información del órgano</Typography>
                                                      <Stack
                                                         direction={"row"}
                                                         gap={2}
                                                         sx={{
                                                            ":hover": { cursor: "pointer", bgcolor: "#E5E6EB" },
                                                            borderRadius: 2,
                                                         }}
                                                      >
                                                         <Typography
                                                            fontWeight={600}
                                                            fontSize={14}
                                                            onClick={() => {
                                                               setOpenStepper(true);
                                                               setStepperPhase(0);
                                                               setBodyEdition(true);
                                                               setBodyData({
                                                                  title: body.bodiesData.title,
                                                                  bodyId: body.bodiesData._id,
                                                                  structureData: body.bodiesData.structure,
                                                                  file: body.bodiesData.regulationFile,
                                                                  functions: body.bodiesData.functions,
                                                               });
                                                            }}
                                                         >
                                                            Editar Información
                                                         </Typography>
                                                         <EditNote />
                                                      </Stack>
                                                   </Box>
                                                   <StructureDataDisplay
                                                      structureData={body.bodiesData.structure}
                                                      bodyTitle={body.bodiesData.title}
                                                   />
                                                </Box>
                                             )}
                                          </TableBody>
                                       </Table>
                                    </>
                                 </AccordionDetails>
                              </Accordion>
                           );
                        })
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         )}

         <CustomModalComponent
            open={openStepper}
            title={bodyEdition ? "Editar información: " + bodyData.title : "Agregar órgano de gobierno"}
            setOpen={handleCloseStepper}
            timeStamp
         >
            <StepperGovernBodyUsers
               phase={stepperPhase}
               setPhase={setStepperPhase}
               state={openStepper}
               setSeed={setSeed}
               setState={setOpenStepper}
               company={companySelected}
               titlesArray={titles}
               bodyData={bodyData}
               bodyUsers={bodyUsers}
               setBodyUsers={setBodyUsers}
               setBodyData={setBodyData}
               bodyEdition={bodyEdition}
            />
         </CustomModalComponent>

         {formatedMembers && series.length > 0 && (
            <>
               <AddShareholderModal setState={setOpenModalShareholder} state={openModalShareholder} />
               <EditShareholderModal
                  state={openEditShareholder}
                  setState={setOpenEditShareholder}
                  user={selectedShareholder}
                  setUser={setSelectedShareholder}
               />
            </>
         )}

         <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)} sx={{ maxWidth: 450 }}>
            <EditGCUserDrawer
               company={companySelected._id}
               bodyData={bodyData}
               state={openDrawer}
               setState={setOpenDrawer}
               userData={userDataModify}
               bodyUsers={bodyUsers}
               setSeed={null}
            />
         </Drawer>
      </Container>
   );
};
