import { useCallback, useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import { getManyCompaniesById, getManyCorporateData, GetManyGroupsById, getUrlS3 } from "../../../lib/usersBEClient";
import { HeadCell, TableComponent } from "../../../components/TableComponent";

let columns: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "name", headerName: "Nombre", type: "string" },
   { field: "type", headerName: "Tipo", type: "string" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "status", headerName: "Estatus", type: "boolean" },
];

export const OfficeCompaniesTable = () => {
   const { companySelected } = useContext(UserContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const [filteredCompanies, setFilteredCompanies] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();

   const fetchCompaniesInfo = useCallback(async () => {
      if (!companySelected?.company_details?.office) return;
      setIsLoading(true);
      try {
         const companyIds = companySelected.company_details.office.companies;
         const [companiesFound, companyCorporateDatas, groupsFound] = await Promise.all([
            getManyCompaniesById(companyIds),
            getManyCorporateData(companyIds),
            GetManyGroupsById(companyIds),
         ]);

         const groupLogos = await Promise.all(groupsFound.map((group) => getDataS3(`group/${group._id}`)));
         const groupData = groupsFound.map((group, index) => ({
            _id: group._id,
            logo: groupLogos[index],
            name: group.name,
            status: group.disabled,
            councilType: "---",
            type: "Grupo empresarial",
            group: true,
         }));

         const companyData = companiesFound.map((company) => ({
            _id: company._id,
            logo: (
               <img
                  defaultValue={company.logo}
                  src={company.logo}
                  alt="logo"
                  style={{ maxWidth: "140px", maxHeight: "50px" }}
               />
            ),
            name: company.person_details.comercialName,
            status: company.disabled,
            type: "Empresa",
            councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
         }));

         const combinedData = [...groupData, ...companyData];
         setCompaniesRow(combinedData);
         setFilteredCompanies(combinedData);
      } catch (error) {
         console.error("Error fetching companies info:", error);
      } finally {
         setIsLoading(false);
      }
   }, [companySelected]);

   useEffect(() => {
      fetchCompaniesInfo();
   }, [fetchCompaniesInfo]);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return <img defaultValue={logoUrl} src={logoUrl} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />;
   };

   return (
      <Box sx={{ width: "100%", bgcolor: "white", boxShadow: 2 }}>
         <TableComponent
            defaultColumnToOrder="empresa"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            emptyDataText={
               companiesRow.length > 0 ? "No se encontró un cliente con ese nombre" : "No se han registrado clientes"
            }
            headers={columns}
            loader={isLoading}
            rows={filteredCompanies}
            rowsPerPageOptions={[5, 10, 20]}
            disableBorders
            onClick={(_e, row) => {
               if (row.type === "Grupo empresarial") navigate(`/mi-lecosy/grupo/${row._id}/inicio`);
               else navigate(`/mi-lecosy/${row._id}/inicio`);
            }}
         />
      </Box>
   );
};
