import { Box, Button, IconButton, List, ListItem, Stack, TextField, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { useContext, useState } from "react";
import ActionSeriesComponent from "./SocietarySubComponents/ActionsSeriesComponents";
import { SnackBarContext } from "../../../context/snackBarContext";
import { AffairsInterface, CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { handleAffairs } from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { Delete } from "@mui/icons-material";

const SocietaryAnalysis = () => {
   const { companySelected, handleUpdateEditingSection } = useContext(UserContext);
   const { affairs, setAffairs, currentAffair, setCurrentAffair, deleteUnsaveAffair } =
      useContext(CorporateDataContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleSubmit = async () => {
      const affairTitlesList = [];
      for (const affair of affairs) {
         if (affair?.isNew) return showSnackBar("Existe un asunto sin nombre", true);
         if (affair.classification.trim().length === 0) return showSnackBar("Existe un asunto sin clasificación", true);
         if (affairTitlesList.includes(affair.title))
            return showSnackBar("Existen dos o más asuntos con el mismo nombre", true);
         affairTitlesList.push(affair.title);
      }
      const response = await handleAffairs(affairs);
      setAffairs(response);
      handleUpdateEditingSection("societary", true);
      return showSnackBar("Asuntos guardados con éxito", false);
   };

   //#region affairs

   const handleAddAffair = () => {
      const newAffair: AffairsInterface = {
         _id: Math.floor(Math.random() * 9999999).toString(),
         title: "",
         series: [],
         classification: "",
         isNew: true,
         companyId: companySelected._id,
      };
      setAffairs(affairs.concat(newAffair));
      handleUpdateEditingSection("societary");
   };

   const handleSetAffair = (index: number) => {
      setCurrentAffair(affairs[index]);
   };

   const handleUpdateAffairInfo = (affair: AffairsInterface, index?: number) => {
      const tempAffairList = affairs;
      let localIndex = index;
      if (localIndex === undefined) {
         const affairsList = tempAffairList.map((affair) => affair._id);
         localIndex = affairsList.indexOf(affair._id);
      }
      tempAffairList[localIndex] = affair;
      setAffairs(tempAffairList);
   };

   //#endregion

   return (
      <>
         <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
            <Box
               flex={1}
               sx={{ boxShadow: 1, bgcolor: "white", borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}
            >
               <Box
                  display={"flex"}
                  width={"100%"}
                  bgcolor={"#162c44"}
                  color={"white"}
                  alignItems={"center"}
                  px={2}
                  py={1}
                  sx={{ borderTopRightRadius: 7, borderTopLeftRadius: 7 }}
               >
                  <Typography justifySelf={"center"} fontWeight={600} flex={1} textAlign={"center"}>
                     Asuntos
                  </Typography>
                  <IconButton onClick={handleAddAffair} sx={{ color: "white" }}>
                     <AddCircleOutlineOutlinedIcon sx={{ justifySelf: "flex-end" }} />
                  </IconButton>
               </Box>
               <Box sx={{ overflow: "auto", maxHeight: "400px" }}>
                  <List disablePadding>
                     {affairs &&
                        affairs.length > 0 &&
                        affairs.map((a, i) => (
                           <AffairTitle
                              a={a}
                              i={i}
                              handleSetAffair={handleSetAffair}
                              handleUpdateAffairInfo={handleUpdateAffairInfo}
                              key={a._id}
                           />
                        ))}
                  </List>
               </Box>
            </Box>
            <Box
               flex={3}
               sx={{ boxShadow: 1, bgcolor: "white", borderBottomLeftRadius: 7, borderBottomRightRadius: 7 }}
            >
               <Box
                  display={"flex"}
                  width={"100%"}
                  bgcolor={"#162c44"}
                  color={"white"}
                  alignItems={"center"}
                  py={2}
                  sx={{ borderTopRightRadius: 7, borderTopLeftRadius: 7 }}
               >
                  <Typography justifySelf={"center"} fontWeight={600} flex={1} textAlign={"center"}>
                     INFORMACIÓN ASOCIADA
                  </Typography>

                  {currentAffair !== null && currentAffair._id?.length <= 10 && (
                     <IconButton
                        sx={{ color: "white", mr: 1 }}
                        onClick={() => {
                           deleteUnsaveAffair(currentAffair._id);
                        }}
                     >
                        <Delete />
                     </IconButton>
                  )}
               </Box>
               <Box minHeight={"400px"} display={"flex"} key={currentAffair?._id || "noSelectedAffair"}>
                  {currentAffair ? (
                     <ActionSeriesComponent affair={currentAffair} handleUpdateAffairInfo={handleUpdateAffairInfo} />
                  ) : (
                     <Stack direction={"column"} width={"100%"} display={"flex"} justifySelf={"center"} m={"auto"}>
                        <Box
                           display={"flex"}
                           flexDirection={"column"}
                           justifyContent={"center"}
                           alignItems={"center"}
                           sx={{ opacity: 0.2 }}
                           rowGap={1}
                        >
                           <InfoIcon sx={{ fontSize: "50px" }} />
                           <Typography fontWeight={600} fontSize={"18px"} textAlign={"center"} maxWidth={"270px"}>
                              Sin información para mostrar Seleccionar asunto.
                           </Typography>
                        </Box>
                     </Stack>
                  )}
               </Box>
            </Box>
         </Stack>
         <Box display={"flex"} justifyContent={"flex-end"} py={2}>
            <Button
               sx={{ bgcolor: "#162d44", color: "white", px: 5, ":hover": { bgcolor: "#162c44" } }}
               onClick={handleSubmit}
            >
               Guardar
            </Button>
         </Box>
      </>
   );
};

const AffairTitle = ({ a, i, handleUpdateAffairInfo, handleSetAffair }) => {
   const { currentAffair } = useContext(CorporateDataContext);
   const { handleUpdateEditingSection } = useContext(UserContext);
   const [editable, setEditable] = useState<boolean>(a.isNew);
   const [localAffair, setLocalAffair] = useState<AffairsInterface>(a);

   const handleChangeEditor = () => setEditable((s) => !s);

   const handleOnChange = (e) => {
      const tempAffair = localAffair;
      localAffair.title = e.target.value;
      localAffair.isNew = false;
      setLocalAffair(tempAffair);
   };

   const handleBlur = () => {
      handleChangeEditor();
      handleUpdateAffairInfo(localAffair, i);
      if (a.isNew) return;
      handleSetAffair(i);
      handleUpdateEditingSection("societary");
   };

   return (
      <ListItem
         dense
         key={a._id}
         sx={{
            bgcolor: currentAffair?._id === a._id ? "#D9D9D9" : "white",
            py: 3,
            alignItems: "center",
            borderBottom: 1,
            borderColor: "gray",
            ":hover": { bgcolor: "#D9D9D9", cursor: "pointer" },
         }}
         onClick={() => !localAffair.isNew && handleSetAffair(i)}
         onDoubleClick={() => !editable && handleChangeEditor()}
      >
         <Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
            <Typography>{i + 1}</Typography>
            {editable ? (
               <TextField
                  autoFocus
                  size="small"
                  variant="outlined"
                  defaultValue={localAffair.title}
                  onChange={handleOnChange}
                  onBlur={handleBlur}
               />
            ) : (
               <Typography>{localAffair.title}</Typography>
            )}
         </Stack>
      </ListItem>
   );
};

export default SocietaryAnalysis;
