import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { DoughnutChart } from "../../../charts/doughnutChart";
import { textCenter } from "../../../../const/globalConst";
import { TableGrid } from "./TableGrid";

interface CommitmentsGraphProps {
   filteredCommitments: any[];
   isLoading: boolean;
   commitmentCount: number;
   hasNoShadow?: boolean;
   chartRef?: any;
}

export const CommitmentsGraph = (props: CommitmentsGraphProps) => {
   const { filteredCommitments, isLoading, commitmentCount, hasNoShadow, chartRef } = props;

   const commitments = useMemo(() => {
      const commitmentsObject = {
         pending: filteredCommitments?.filter((commitment) => commitment.status === "PENDING").length,
         process: filteredCommitments?.filter((commitment) => commitment.status === "PROCESS").length,
         review: filteredCommitments?.filter((commitment) => commitment.status === "REVIEW").length,
         complete: filteredCommitments?.filter((commitment) => commitment.status === "COMPLETE").length,
         expired: filteredCommitments?.filter((commitment) => commitment.status === "EXPIRED").length,
      };
      return commitmentsObject;
   }, [filteredCommitments]);

   return (
      <Stack
         sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: hasNoShadow ? 0 : 1,
            borderRadius: 2,
         }}
      >
         <Typography fontWeight={600} sx={{ p: 1 }} align="center">
            Estatus de acuerdos y compromisos
         </Typography>
         <Box sx={{ maxWidth: 180, px: 1 }} key={filteredCommitments.length}>
            {!isLoading ? (
               <DoughnutChart
                  chartRef={chartRef}
                  scores={[
                     commitments.pending,
                     commitments.process,
                     commitments.review,
                     commitments.complete,
                     commitments.expired,
                  ]}
                  labels={["Por hacer", "En proceso", "En revisión", "Completadas", "Vencidos"]}
                  colors={["#8a95a0", "#5C79E3", "#F39C12", "#28B463", "#D20000"]}
                  cutout={55}
                  textCenterProp={textCenter(
                     {
                        size: "15",
                        text: "Totales",
                        color: "#737373",
                     },
                     {
                        size: "25",
                        text: filteredCommitments.length,
                        color: "#000000",
                     }
                  )}
                  borderRadius
               />
            ) : (
               <CircularProgress />
            )}
         </Box>
         <Box sx={{ width: "100%", px: 2, py: 2 }}>
            <TableGrid
               data={[
                  {
                     text: "Pendiente",
                     type: "Dot",
                     color: "#8a95a0",
                  },
                  {
                     text: commitments.pending,
                  },
                  {
                     text: commitmentCount === 0 ? 0 : ((commitments.pending / commitmentCount) * 100).toFixed(2),
                     type: "%",
                  },
                  {
                     text: "En proceso",
                     type: "Dot",
                     color: "#5E77F9",
                  },
                  {
                     text: commitments.process,
                  },
                  {
                     text: commitmentCount === 0 ? 0 : ((commitments.process / commitmentCount) * 100).toFixed(2),
                     type: "%",
                  },
                  {
                     text: "En revisión",
                     type: "Dot",
                     color: "#F39C12",
                  },
                  {
                     text: commitments.review,
                  },
                  {
                     text: commitmentCount === 0 ? 0 : ((commitments.review / commitmentCount) * 100).toFixed(2),
                     type: "%",
                  },
                  {
                     text: "Completadas",
                     type: "Dot",
                     color: "#28B463",
                  },
                  {
                     text: commitments.complete,
                  },
                  {
                     text: commitmentCount === 0 ? 0 : ((commitments.complete / commitmentCount) * 100).toFixed(2),
                     type: "%",
                  },
                  {
                     text: "Vencidos",
                     type: "Dot",
                     color: "#D20000",
                  },
                  {
                     text: commitments.expired,
                  },
                  {
                     text: commitmentCount === 0 ? 0 : ((commitments.expired / commitmentCount) * 100).toFixed(2),
                     type: "%",
                  },
               ]}
            />
         </Box>
      </Stack>
   );
};
