import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import rolesByService from "../../const/rolesByService.json";
import { Box, Button, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { AddUserModal } from "./AddUserModal";
import { UserContext } from "../../context/userContext";
import { getMemberChargesByCompany } from "../../lib/gobCorpBEClient";
import { useNavigate } from "react-router-dom";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";

interface PropsUserByModuleComponent {
   company: any;
   service: any;
   edit?: boolean;
}

const getServiceCode = (service: string) => {
   switch (service) {
      case "Canal de denuncias":
         return "CD";
      case "Programa de cumplimiento":
         return "PC";
      case "Gobierno corporativo":
         return "GC";
      case "Beneficiario controlador":
         return "BC";
      default:
         return "LECOSY";
   }
};

export const UserByModuleComponent = (props: PropsUserByModuleComponent) => {
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const { user, companySelected } = useContext(UserContext);
   const { consultiveGroup } = useContext(CorporateDataContext);
   const navigate = useNavigate();

   const isMemberOfGroup = useMemo(() => {
      if (consultiveGroup) return consultiveGroup?.companies.some((c) => c === companySelected._id);
      else return false;
   }, [consultiveGroup]);

   const usersColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
   ];

   // if (props.edit) {
   //    usersColumns.push({
   //       field: "modify",
   //       headerName: "Modificar",
   //       type: "button",
   //       width: 50,
   //       icon: <BorderColorIcon fontSize="small" />,
   //       onClick: async (e, row) => {
   //          e.stopPropagation();
   //          if (userSelected && userSelected.uid === row.uid) {
   //             setisLoadingModules(false);
   //          }
   //          setUserSelected(row);
   //          setOpenModifySubUserDrawer(true);
   //       },
   //    });
   // }

   const getCompanyRoles = (roles: any[], serviceCode: string) => {
      const rolesArray = roles
         .filter((role) => role.company === companySelected._id)
         .flatMap((role) => role.roles.filter((r) => rolesByService[serviceCode].includes(r.name)).map((r) => r.name));
      return Array.from(new Set(rolesArray));
   };

   const fetchUserData = useCallback(async () => {
      setIsLoading(true);
      const checkContext = [
         ...props.company.company_details.users,
         ...(companySelected.company_details.admin ? [props.company.company_details.admin] : []),
      ];
      const serviceName = props.service.serviceId.service;
      const serviceCode = getServiceCode(serviceName);

      const usersData: any[] = (checkContext as any[]).map((user) => ({
         _id: user._id,
         uid: user.uid,
         firstName: user.firstName,
         lastName: user.lastName,
         phoneNumber: user.phoneNumber,
         name: user.firstName + " " + user.lastName,
         email: user.email,
         role: user.role,
         roles: getCompanyRoles(user.role, serviceCode) || [],
      }));

      const gcUserIds = usersData
         .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
         .map((user) => user._id);

      if (gcUserIds.length > 0) {
         const GcChargesResponse = await getMemberChargesByCompany(gcUserIds, companySelected._id);
         usersData.forEach((user) => {
            const gcCharge = GcChargesResponse.find((c) => c.user === user._id)?.charges;
            if (gcCharge) {
               if (isMemberOfGroup) user.roles = ["Accionista"];
               else user.roles = user.roles.concat(gcCharge).filter((r) => !rolesByService[serviceCode].includes(r));
            }
         });
      }
      const finalUserFilter = usersData.filter((a) => a._id !== user.id && a.roles.length > 0);

      setUserArray(finalUserFilter);
      setIsLoading(false);
   }, [companySelected]);

   useEffect(() => {
      fetchUserData();
   }, [fetchUserData]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
               Usuarios del módulo
            </Typography>
            <Button variant="contained" sx={{ borderRadius: 1, px: 3 }} onClick={() => setOpenModal(true)}>
               <AddCircleIcon sx={{ mr: 1 }} />
               Agregar usuario
            </Button>
         </Stack>
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={(_e, row) => navigate(`/mi-lecosy/usuarios/0/${row.uid}`)}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
         <AddUserModal
            company={props.company}
            open={openModal}
            setOpen={setOpenModal}
            module={true}
            usersByModule={userArray}
            service={props.service}
         />
         {/* <Drawer
            anchor={"right"}
            open={openModifySubUserDrawer}
            hideBackdrop={true}
            onClose={() => setOpenModifySubUserDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            {userInformation && (
               <ModifyUserRoles
                  addModule={false}
                  userInfo={userInformation}
                  modulesAndRoles={modulesAndRoles}
                  open={openModifySubUserDrawer}
                  setOpen={setOpenModifySubUserDrawer}
                  isLoadingModules={isLoadingModules}
                  setisLoadingModules={setisLoadingModules}
                  fetchData={() => {}}
               />
            )}
         </Drawer> */}
      </Box>
   );
};
