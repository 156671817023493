import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CustomModalComponent } from "../../../../CustomModalComponent";
import { TabPanel } from "../../../../../screens/Mi Lecosy/ClientInfoScreenNew";
import CompanyCoordinatorUserComponent from "./GovernanceCoordination/CompanyCoordinatorUserComponent";

const CompanyCoordination = (props: { beneficiary?: boolean }) => {
   const { beneficiary } = props;
   const [openModal, setOpenModal] = useState(false);
   const [value, setValue] = useState(0);

   return (
      <Box
         sx={{
            px: 3,
            py: 2,
            borderRadius: 1,
            alignItems: "center",
            boxShadow: 2,
            backgroundColor: "white",
         }}
      >
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography fontWeight={600} sx={{ px: 1 }}>
               {`Coordinación de ${!beneficiary ? "gobierno corporativo" : "beneficiario controlador"}`}
            </Typography>
            <Button
               variant="contained"
               onClick={() => {
                  setOpenModal(true);
               }}
            >
               Abrir
            </Button>
         </Box>
         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            title={`Coordinación de ${!beneficiary ? "gobierno corporativo" : "beneficiario controlador"}`}
         >
            <Box sx={{ minWidth: 800 }}>
               <TabPanel value={value} index={beneficiary ? 0 : 1}>
                  <Box sx={{ px: 2 }}>
                     {/* {group ? (
                        <GroupCoordinatorComponent beneficiary={beneficiary} />
                     ) : (
                        <CompanyCoordinatorUserComponent beneficiary={beneficiary} />
                     )} */}
                     <CompanyCoordinatorUserComponent beneficiary={beneficiary} />
                  </Box>
               </TabPanel>
            </Box>
         </CustomModalComponent>
      </Box>
   );
};

export default CompanyCoordination;
