import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

interface IVotationAnswerOptions {
   votationAnswers: string[];
   setVotationAnswers: Function;
   maxHeight?: string;
}

const VotationAnswerOptions = (props: IVotationAnswerOptions) => {
   const { votationAnswers, setVotationAnswers, maxHeight } = props;

   const handleAddAnswer = () => {
      setVotationAnswers((s) => [...s, ""]);
   };

   const handleUpdateAnswerValue = (value, index) => {
      const temp = votationAnswers;
      temp[index] = value;
      setVotationAnswers(temp);
   };

   const handleDeleteAnswer = (index) => {
      const temp = votationAnswers;
      const newArray = [...temp.slice(0, index), ...temp.slice(index + 1, temp.length)];
      setVotationAnswers(newArray);
   };
   return (
      <Stack
         direction={"column"}
         spacing={1}
         width={"100%"}
         display={"flex"}
         key={`votationAnswers-${votationAnswers.length}`}
      >
         <Stack maxHeight={maxHeight || "175px"} overflow={"auto"} spacing={1} pt={1}>
            {votationAnswers.length > 0 &&
               votationAnswers.map((vote, index) => {
                  return (
                     <Stack direction={"row"} width={"100%"} spacing={1} key={`vote-${index}`}>
                        <Box flex={1} display={"flex"} flexDirection={"row"}>
                           <TextField
                              size="small"
                              fullWidth
                              label={"Respuesta"}
                              defaultValue={vote}
                              onChange={(e) => handleUpdateAnswerValue(e.target.value, index)}
                              inputProps={{ maxLength: 100 }}
                              multiline
                           />
                        </Box>
                        <IconButton onClick={() => handleDeleteAnswer(index)} disableRipple>
                           <DeleteForeverIcon color="error" />
                        </IconButton>
                     </Stack>
                  );
               })}
         </Stack>
         {votationAnswers.length < 4 && (
            <Stack
               direction={"row"}
               width={"100%"}
               display={"flex"}
               justifyContent={"flex-end"}
               alignContent={"center"}
               spacing={0.5}
            >
               <Typography alignSelf={"center"}>Agregar respuesta</Typography>
               <IconButton onClick={handleAddAnswer}>
                  <AddCircleIcon sx={{ color: "#162c44" }} />
               </IconButton>
            </Stack>
         )}
      </Stack>
   );
};

export default VotationAnswerOptions;
