import { Chart as ChartJS, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { externalTooltipHandler } from "../../hooks/useCreateCharts";

ChartJS.register(LinearScale, BarElement, ChartDataLabels, Title, Tooltip, Legend, Filler);

export const FilteredBarChart = (props) => {
   return (
      <Bar
         ref={props.chartRef}
         data={{
            datasets: props.scores.map((e: [], index) => {
               return {
                  label: props.labelNames[index],
                  data: e,
                  backgroundColor: props.colors[index],
                  hoverBackgroundColor: props.onClick ? `${props.colors[index]}80` : props.colors[index],
                  barThickness: props.barThickness ? props.barThickness : undefined,
                  borderRadius: props.borderRadius ? 4 : 0,
                  normalized: true,
               };
            }),
            labels: props.labels,
         }}
         options={{
            onHover: props.onClick
               ? (event: any, chartElement) => {
                    event.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
                 }
               : undefined,
            interaction: props.intersection ? { intersect: false, mode: "index" } : undefined,
            onClick: props.onClick,
            plugins: {
               legend: { display: false },
               datalabels: {
                  display: false,
                  font: { size: 15, weight: "bold" },
                  backgroundColor: "#ffffff",
                  borderRadius: 3,
                  borderWidth: 3,
                  align: "top",
                  color: "black",
                  // formatter: (value) => {
                  //    return value || null;
                  // },
               },
               tooltip: {
                  enabled: !props.customTooltip,
                  position: props.customTooltip ? "nearest" : undefined,
                  external: props.customTooltip
                     ? (context) => externalTooltipHandler(context, props.tooltipColorBox)
                     : undefined,
               },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
               x: {
                  min: 0,
                  grid: { color: "transparent" },
                  title: {
                     display: true,
                     align: "center",
                     color: "#A3A3A3",
                     text: props.xTitle,
                     font: {
                        family: "Open Sans",
                        size: 16,
                        style: "normal",
                     },
                  },
               },
               y: {
                  grid: props.transparedBgBarChart
                     ? { color: "transparent" }
                     : { color: props.gridColor || "whitesmoke", drawBorder: !props.disableTicks },
                  max: props.maxY || undefined,
                  ticks: props.percentage
                     ? { format: { style: "percent", maximumSignificantDigits: 2 } }
                     : props.disableTicks
                     ? { display: false }
                     : undefined,
                  title: {
                     display: true,
                     align: "center",
                     color: "#A3A3A3",
                     text: props.yTitle,
                     font: {
                        family: "Open Sans",
                        size: 16,
                        style: "normal",
                     },
                  },
               },
            },
         }}
      />
   );
};
