import { Box, Button, Collapse, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { useGetMembersInfo } from "../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { SnackBarContext } from "../../context/snackBarContext";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { AddShareholderModal } from "../../components/MiLecosy/ModalsGovernanceModule/AddShareholderModal";
import { EditShareholderModal } from "../../components/MiLecosy/ModalsGovernanceModule/EditShareholderModal";
import { FilterComponent } from "../../components/FilterComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency, getDateFromISODate } from "../../const/globalConst";
import { UpdateModal } from "../../components/MiLecosy/ModalsGovernanceModule/updateModal";
import { BeneficiaryControllerContext } from "../../context/beneficiaryContext/beneficiaryContollerContext";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { Check, Close, DownloadRounded, Folder, Remove } from "@mui/icons-material";
import { IBeneficiary } from "../../types/beneficiaryTypes";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";

const headers: HeadCell[] = [
   { field: "check", headerName: "check", type: "check", width: 50, align: "center" },
   { field: "name", headerName: "Nombre", type: "string", width: 170, align: "left" },
   { field: "founder", headerName: "Fundador", type: "string", align: "center" },
   { field: "rfc", headerName: "RFC", type: "string", align: "left" },
   { field: "propertyPercentage", headerName: "Porcentaje de propiedad", type: "string", align: "center" },
   { field: "totalActions", headerName: "Total acciones", type: "string", align: "center" },
   { field: "socialCapital", headerName: "Capital social", type: "string", align: "center" },
   { field: "createdAt", headerName: "Fecha de registro", type: "string", align: "center" },
   { field: "updatedAt", headerName: "Última modificación", type: "string", align: "center" },
   { field: "active", headerName: "Estatus", type: "string", width: 70 },
   { field: "file", headerName: "Expediente", type: "string", width: 100 },
];

export const ShareHolderScreen = () => {
   const { refetch, shareHolders, handleDownload } = useContext(BeneficiaryControllerContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { series } = useContext(CorporateDataContext);
   const { formatedMembers, members } = useGetMembersInfo(false);
   const [filteredMembers, setFilteredMembers] = useState([]);
   const [openDownloadModal, setOpenDownloadModal] = useState(false);
   const [downloadProgress, setDownloadProgress] = useState(0);
   const [selectedUser, setSelectedUser] = useState(null);
   const [openModal, setOpenModal] = useState(false);
   const [openEditModal, setOpenEditModal] = useState(false);
   const [isUpdateModal, setUpdateModal] = useState(false);
   const [selectedRows, setSelectedRows] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [download, setDownload] = useState("");
   const [alreadyDownloading, setAlreadyDownloading] = useState(false);
   const { companyId } = useParams();
   const navigate = useNavigate();
   const location = useLocation();

   const handleUpdateConfig = () => navigate(`/beneficiario-controlador/${companyId}/configuracion`);
   const handleUpdateClick = () => setUpdateModal(true);
   const handleFolderClick = (user: IBeneficiary) =>
      navigate(`${location.pathname.replace(/[^/]+$/, `expediente/${user?.folderId}`)}`);

   const getStatusInfo = (status) => {
      switch (status) {
         case "Habilitado":
            return { text: "Habilitado", icon: <Check color="success" fontSize="small" /> };
         case "Inhabilitado":
            return { text: "Inhabilitado", icon: <Close color="warning" fontSize="small" /> };
         default:
            return { text: "Pendiente", icon: <Remove color="disabled" fontSize="small" /> };
      }
   };

   const rows = useMemo(() => {
      if (!formatedMembers || !shareHolders) return [];
      const formattedRows = formatedMembers
         .map((member) => {
            const beneficiaryInfoFound = shareHolders.find((b) => b.user?._id === member.userId);
            const { text, icon } = getStatusInfo(
               !beneficiaryInfoFound ? "Pendiente" : beneficiaryInfoFound.active ? "Habilitado" : "Inhabilitado"
            );
            return {
               ...member,
               beneficiary: beneficiaryInfoFound,
               founder: member.founder && <Check />,
               propertyPercentage: member.propertyPercentage + "%",
               socialCapital: "$" + formatCurrency(member.socialCapital),
               createdAt: beneficiaryInfoFound?.createdAt
                  ? getDateFromISODate(beneficiaryInfoFound?.createdAt)
                  : "Sin datos",
               updatedAt: beneficiaryInfoFound?.updatedAt
                  ? getDateFromISODate(beneficiaryInfoFound?.updatedAt)
                  : "Sin datos",
               active: (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     {text}&nbsp;
                     {icon}
                  </Box>
               ),
               file: (
                  <IconButton
                     onClick={() => handleFolderClick(beneficiaryInfoFound)}
                     disabled={beneficiaryInfoFound === undefined}
                  >
                     <Folder
                        style={{ cursor: "pointer" }}
                        sx={{ color: beneficiaryInfoFound === undefined ? "#c4c4c4" : "#F1B44C", fontSize: 27 }}
                     />
                  </IconButton>
               ),
               _id: beneficiaryInfoFound?._id,
               folderId: beneficiaryInfoFound?.folderId,
               rfc: member?.rfc ?? beneficiaryInfoFound?.rfc,
            };
         })
         .sort((a, b) => (b.folderId !== undefined ? 1 : 0) - (a.folderId !== undefined ? 1 : 0));

      setFilteredMembers(formattedRows);
      setIsLoading(false);
      return formattedRows;
   }, [formatedMembers, shareHolders]);

   const formattedShareholders = useMemo(() => {
      if (!shareHolders) return [];
      function addCollapsible(item, formattedRows) {
         const searchParent = formattedRows.filter((beneficiary) => beneficiary.moralAssociation?._id === item._id);
         if (searchParent.length === 0) return item;
         searchParent.forEach((element) => {
            if (element.moralAssociation) {
               const value = addCollapsible(element, formattedRows);
               if (value._id === element._id) {
                  item.collapsible = item.collapsible ? [...item.collapsible, value] : [value];
               }
            }
         });
         return item;
      }
      const formattedRows = shareHolders.reduce((acc, beneficiary) => {
         const formattedRow = {
            ...beneficiary,
            active: !beneficiary.active,
            name:
               beneficiary.businessName ||
               (beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name),
            identifier: `${beneficiary.identificationSub ? `${beneficiary.identificationSub}-` : ""}${
               (beneficiary.identification as any).text
            }`,
            nameOg: beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name,
         };
         acc.push(formattedRow);
         return acc;
      }, []);
      const newValues = formattedRows
         .filter((element) => !element.moralAssociation)
         .map((element) => addCollapsible(element, formattedRows));

      setIsLoading(false);
      return newValues;
   }, [shareHolders]);

   return (
      <Stack>
         <Box
            sx={{
               justifyContent: "center",
               display: "flex",
               flexDirection: "column",
               paddingTop: 1,
               paddingBottom: 2,
            }}
         >
            <Stack
               direction="row"
               justifyContent="space-between"
               alignItems="center"
               sx={{
                  columnGap: 2,
               }}
            >
               <Collapse in={selectedRows.length > 0} unmountOnExit>
                  <Button variant="contained" onClick={() => setOpenDownloadModal(true)}>
                     <DownloadRounded />
                  </Button>
               </Collapse>
               <FilterComponent
                  fullWidth
                  originaltableOneRows={rows}
                  tableOneFilteredRows={filteredMembers}
                  setTableOneFilteredRows={setFilteredMembers}
                  filters={[]}
                  disableRefresh
                  searchBar={
                     <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={handleUpdateConfig}
                        >
                           Configurar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={handleUpdateClick}
                        >
                           Solicitar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3 }}
                           onClick={() => {
                              if (series?.length > 0) setOpenModal(!openModal);
                              else showSnackBar("Favor de crear series antes de agregar accionistas", true);
                           }}
                        >
                           Agregar nuevo
                        </Button>
                     </Stack>
                  }
               />
            </Stack>
         </Box>

         <TableComponent
            headerColor
            headers={headers}
            rows={filteredMembers || []}
            defaultColumnToOrder="name"
            defaultOrder="desc"
            defaultRowsPerPage={10}
            emptyDataText={rows?.length > 0 ? "No se encontraron accionistas" : "No existen accionistas registrados"}
            loader={isLoading}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onClick={(e, row) => {
               if (e.target.value === "on") return;
               const userFound = members.find((m) => m.user === row.userId);
               if (userFound) {
                  setOpenEditModal(true);
                  setSelectedUser(userFound);
               }
            }}
            selectedRows={selectedRows}
            setSelected={setSelectedRows}
            multiselect
         />

         {series.length > 0 && !isLoading && (
            <EditShareholderModal
               state={openEditModal}
               setState={setOpenEditModal}
               user={selectedUser}
               setUser={setSelectedUser}
            />
         )}
         {isUpdateModal && !isLoading && (
            <UpdateModal
               state={isUpdateModal}
               setState={setUpdateModal}
               onConfirm={() => {
                  refetch();
                  setUpdateModal(false);
               }}
               companyId={companyId}
               members={members}
               beneficiaries={formattedShareholders}
            />
         )}

         {series.length > 0 && !isLoading && <AddShareholderModal state={openModal} setState={setOpenModal} />}

         {openDownloadModal && !isLoading && (
            <ConfirmationModal
               open={openDownloadModal}
               onConfirm={() =>
                  handleDownload(
                     setOpenDownloadModal,
                     rows,
                     selectedRows.filter(Boolean),
                     setDownloadProgress,
                     download,
                     setAlreadyDownloading
                  )
               }
               setOpen={setOpenDownloadModal}
               title={"Descargar"}
               isLoading={alreadyDownloading}
               body={
                  <Stack sx={{ justifyContent: "center", alignItems: "center", rowGap: 1 }}>
                     {alreadyDownloading && downloadProgress === 0 && download !== "expedient" ? (
                        <Stack>
                           <Typography>Creando fichas, espere un momento.</Typography>
                        </Stack>
                     ) : alreadyDownloading && download !== "sheet" ? (
                        <>
                           <Typography>Descargando expediente(s)</Typography>
                           <Typography variant="caption" sx={{ color: "red" }}>
                              Esta acción puede tardar algunos minutos en completarse.
                           </Typography>
                           {downloadProgress > 0 && (
                              <>
                                 <Typography>Progreso estimado:</Typography>
                                 <CircularProgressWithLabel value={downloadProgress} size={60} />
                              </>
                           )}
                        </>
                     ) : (
                        <>
                           <Typography>Seleccionar lo que se desea descargar</Typography>
                           <Select
                              value={download}
                              fullWidth
                              placeholder="Seleccionar"
                              size="small"
                              onChange={(event) => {
                                 const {
                                    target: { value },
                                 } = event;
                                 setDownload(value);
                              }}
                           >
                              <MenuItem value={"expedientAndSheet"}>Expediente(s) y ficha(s)</MenuItem>
                              <MenuItem value={"sheet"}>Ficha(s)</MenuItem>
                              <MenuItem value={"expedient"}>Expediente(s)</MenuItem>
                           </Select>
                        </>
                     )}
                  </Stack>
               }
            />
         )}
      </Stack>
   );
};
