import { Outlet, Route } from "react-router-dom";
import { PrivateRoute } from "../components/RouteComponents/PrivateRoute";
import { MainScreen } from "../screens/Gobierno corporativo/MainScreen";
import { GovernanceProvider } from "../context/governanceContext/governanceContext";
import { SchedulerScreen } from "../screens/Gobierno corporativo/SchedulerScreen";
import { MyNotesScreen } from "../screens/Gobierno corporativo/MyNotesScreen";
import { ProfileScreen } from "../screens/Gobierno corporativo/ProfileScreen";
import { AgreementsAndCommitmentsScreen } from "../screens/Gobierno corporativo/AgreementsAndCommitmentsScreen";
import { MembersScreen } from "../screens/Gobierno corporativo/MembersScreen";
import { MyFilesScreen } from "../screens/Gobierno corporativo/MyFilesScreen";
import { FileRedirect } from "../components/Gobierno corporativo/MyFilesScreen/FileRedirect";
import { FolderScreen } from "../screens/Gobierno corporativo/FolderScreen";
import { FilesProvider } from "../context/governanceContext/filesContext";
import { ChatComponent } from "../components/Gobierno corporativo/ChatScreen/ChatComponent";
import { Chat } from "../components/Gobierno corporativo/ChatScreen/Chat";
import { DashboardComponent } from "../components/Gobierno corporativo/DashboardScreen/DashboardComponent";
import { DashboardByGB } from "../components/Gobierno corporativo/DashboardScreen/DashboardByGB";
import { ReportsScreen } from "../screens/Gobierno corporativo/ReportsScreen";
import { FolderVerificationScreen } from "../screens/Gobierno corporativo/FolderVerificationScreen";
import { FilesVerificationScreen, FilesVerifiedScreen } from "../screens/Gobierno corporativo/FilesVerificationScreen";
import { ChatProvider } from "../context/governanceContext/ChatContext";
import GoverningBodiesReportScreen from "../screens/Gobierno corporativo/GoverningBodiesReportScreen";
import { AdvicesScreen } from "../screens/Gobierno corporativo/AdvicesScreen";
import { SessionScreen } from "../screens/Gobierno corporativo/SessionScreen";
import { GovernanceSessionProvider } from "../context/governanceContext/governanceSessionContext";
import { EvaluationControlScreen } from "../screens/Gobierno corporativo/EvaluationControlScreen";
import { EvaluationConfigProvider } from "../context/governanceContext/evaluationConfigContext";
import { GovernanceTheSequelProvider } from "../context/governanceContext/governanceTheSequelContext";
import { CreateProfileProvider } from "../context/beneficiaryContext/createProfileContext";

export const useGovernanceRoutes = () => {
   return (
      <Route element={<PrivateRoute section="Gobierno corporativo" module={true} />}>
         <Route
            path="/gobierno-corporativo"
            element={
               <GovernanceProvider>
                  <GovernanceTheSequelProvider>
                     <GovernanceSessionProvider>
                        <CreateProfileProvider>
                           <MainScreen />
                        </CreateProfileProvider>
                     </GovernanceSessionProvider>
                  </GovernanceTheSequelProvider>
               </GovernanceProvider>
            }
         >
            <Route path=":companyId" element={<Outlet />}>
               <Route path="mi-cuenta" element={<Outlet />}>
                  <Route path="dashboard" element={<DashboardComponent page={null} />} />
                  <Route path="calendario" element={<SchedulerScreen />} />
                  <Route path="notas" element={<MyNotesScreen />} />
                  <Route
                     path="archivos"
                     element={
                        <FilesProvider>
                           <FileRedirect />
                        </FilesProvider>
                     }
                  >
                     <Route path="mis-archivos" element={<MyFilesScreen />} index />
                     <Route path="folder/:folderId" element={<FolderScreen />} />
                     <Route path="folder/archivosPorVerificar" element={<FolderVerificationScreen />} />
                     <Route path="folder/archivosPorVerificar/:personalFilesId" element={<FilesVerificationScreen />} />
                     <Route path="folder/documentosVerificados" element={<FolderVerificationScreen />} />
                     <Route path="folder/documentosVerificados/:personalFilesId" element={<FilesVerifiedScreen />} />
                  </Route>
                  <Route path="acuerdos" element={<AgreementsAndCommitmentsScreen />} />
                  <Route path="perfil" element={<ProfileScreen />} />
                  <Route path="reportes" element={<ReportsScreen />} />

                  <Route
                     path="reportes/evaluation"
                     element={
                        <EvaluationConfigProvider>
                           <EvaluationControlScreen />
                        </EvaluationConfigProvider>
                     }
                  />
                  <Route
                     path="chat"
                     element={
                        <ChatProvider>
                           <ChatComponent />
                        </ChatProvider>
                     }
                  >
                     <Route path=":id" element={<Chat />} />
                  </Route>
                  <Route
                     path="miembros"
                     element={
                        <ChatProvider>
                           <MembersScreen />
                        </ChatProvider>
                     }
                  />
               </Route>
               <Route path=":id">
                  <Route path="dashboard" element={<DashboardByGB />} />
                  <Route path="calendario" element={<SchedulerScreen />} />
                  <Route path="tablero" element={<AdvicesScreen />} />

                  <Route
                     path="documentos"
                     element={
                        <FilesProvider>
                           <FileRedirect />
                        </FilesProvider>
                     }
                  >
                     <Route path="inicio" element={<MyFilesScreen />} />
                     <Route path="folder/:folderId" element={<FolderScreen />} />
                  </Route>
                  <Route path="acuerdos" element={<AgreementsAndCommitmentsScreen />} />
                  <Route path="reportes" element={<GoverningBodiesReportScreen />} />
               </Route>
               <Route path="sesion/:sessionId" element={<SessionScreen />} />
            </Route>
         </Route>
      </Route>
   );
};
