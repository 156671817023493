import { useContext, useState } from "react";
import { ClientCreationContext } from "../../../../../context/officeContexts/clientCreationContext";
import { Companies } from "../../../../../types/BaseTypes";
import { Box } from "@mui/material";
import ModuleCheck from "./ModuleCheck";
import { UserContext } from "../../../../../context/userContext";
import CompanyCoordination from "./CompanyCoordination";

interface ServiceLicencesComponentProps {
   service: any;
   modify: boolean;
}

function getService(services: any[], serviceId: string) {
   for (const service of services) {
      if (service?.serviceId === serviceId || service?.serviceId?._id === serviceId) {
         return service;
      }
   }
}

export const ServiceLicencesComponent = (props: ServiceLicencesComponentProps) => {
   const { selectedClient } = useContext(ClientCreationContext);
   const { companySelected } = useContext(UserContext);
   const clientInfo = (selectedClient?.data as Companies) || (companySelected as Companies);
   const serviceDetails = getService(clientInfo?.company_details?.servicesDetails, props.service._id);
   const [checked, setChecked] = useState<boolean>(serviceDetails !== undefined && !serviceDetails?.disable);

   return (
      <>
         <Box
            sx={{
               px: 3,
               py: 2,
               borderBottomRightRadius: 5,
               borderBottomLeftRadius: 5,
               alignItems: "center",
               boxShadow: 2,
               backgroundColor: "white",
            }}
         >
            <ModuleCheck
               service={{ _id: props.service._id, name: props.service.service }}
               checked={checked}
               setChecked={setChecked}
            />
         </Box>

         {props.service.service === "Gobierno corporativo" && props.modify && checked && (
            <>
               <Box
                  sx={{
                     px: 3,
                     py: 2,
                     borderRadius: 1,
                     alignItems: "center",
                     boxShadow: 2,
                     backgroundColor: "white",
                  }}
               >
                  <Box sx={{ opacity: checked ? 1 : 0.5, pointerEvents: checked ? "auto" : "none" }}>
                     <CompanyCoordination />
                  </Box>
               </Box>
            </>
         )}
      </>
   );
};
