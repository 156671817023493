import { useContext, useEffect, useState } from "react";
import { ClientCreationContext } from "../../../../context/officeContexts/clientCreationContext";
import { Group, Users } from "../../../../types/BaseTypes";
import { SnackBarContext } from "../../../../context/snackBarContext";
import {
   addUserRoleGroup,
   GetAllUserRoles,
   getUserByEmail,
   removeRoleFromUser,
   updateCompanyAdminUser,
   UpdateGroupAdmin,
   useCreateUserOffice,
} from "../../../../lib/usersBEClient";
import { catchAxiosError } from "../../../../lib/lecosyBackendClient";
import { Form, Formik, FormikProps } from "formik";
import { Box, Button, Typography } from "@mui/material";
import { createUserSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../Inputs/InputTextField";
import { LoadingButton } from "../../../LoadingButton";

export const UserAdminGroupComponent = ({ onComplete, value }) => {
   const { selectedClient, setSelectedClient, refetch } = useContext(ClientCreationContext);
   const group = selectedClient.data as Group;

   const { data: roles } = GetAllUserRoles();
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingCreate } = useCreateUserOffice();
   const [foundByEmail, setFoundByEmail] = useState<Users>(null);

   const handleCancel = () => setSelectedClient(null);

   const handleSubmit = async (values) => {
      try {
         if (foundByEmail) {
            const data = {
               group: group._id,
               roles: roles.find((r) => r.name === "Administrador del Sistema")._id,
            };
            await addUserRoleGroup(data, foundByEmail._id);

            await removeRoleFromUser(
               group.admin._id,
               group._id,
               roles.find((r) => r.name === "Administrador del Sistema")._id,
               true
            );

            await UpdateGroupAdmin(foundByEmail._id, group._id);
            showSnackBar("El administrador fue agregado correctamente.", false);
            refetch();
            onComplete();
         } else {
            if (group.admin) {
               const adminGroupUser = group.admin;
               if (adminGroupUser.email === values.email) {
                  //mismo correo se actualiza el usuario que ya existe
                  try {
                     const response = await updateCompanyAdminUser(
                        adminGroupUser.uid,
                        values.firstName,
                        values.lastName,
                        values.email,
                        values.phoneNumber
                     );
                     if (response.data) {
                        refetch();
                        showSnackBar("Usuario modificado exitosamente", false);
                     }
                  } catch (error) {
                     const errorMessage = catchAxiosError(error);
                     if (errorMessage.message.includes("no user found")) {
                        showSnackBar("Error, intente de nuevo", true);
                     }
                     if (errorMessage.message.includes("email-already-exists")) {
                        showSnackBar("Correo electrónico en uso", true);
                     }
                  }
                  return;
               } else {
                  mutate(
                     {
                        userData: {
                           firstName: values.firstName,
                           lastName: values.lastName,
                           email: values.email,
                           phoneNumber: values.phone,
                           role: null,
                        },
                        additionalData: {
                           admin: true,
                           group: group._id,
                        },
                     },
                     {
                        onError: (error: any) => {
                           error.response.data.message === "email already in use"
                              ? showSnackBar("Ya existe un usuario con ese correo", true)
                              : showSnackBar("Error al agregar administrador.", true);
                        },
                        onSuccess: async () => {
                           showSnackBar("El administrador fue agregado correctamente.", false);
                           onComplete();
                        },
                     }
                  );
                  return;
               }
            } else {
               mutate(
                  {
                     userData: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phone,
                        role: null,
                     },
                     additionalData: {
                        admin: true,
                        group: group._id,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar administrador.", true);
                     },
                     onSuccess: async () => {
                        showSnackBar("El administrador fue agregado correctamente.", false);
                        onComplete();
                     },
                  }
               );
            }
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   const searchUserByEmail = async (email: string, setFieldValue) => {
      if (!email || email === group?.admin?.email) {
         return setFoundByEmail(null);
      }
      const userFound = await getUserByEmail(email);
      if (!userFound) return setFoundByEmail(null);
      setFoundByEmail(userFound);

      setFieldValue("firstName", userFound.firstName);
      setFieldValue("lastName", userFound.lastName);
      setFieldValue("email", userFound.email);
      setFieldValue("phone", userFound.phoneNumber);
   };

   return (
      <Box flex={1}>
         <Formik
            initialValues={{
               firstName: group.admin ? group.admin.firstName : "",
               lastName: group.admin ? group.admin.lastName : "",
               phone: group.admin ? group.admin.phoneNumber : "",
               email: group.admin ? group.admin.email : "",
            }}
            validationSchema={createUserSchema}
            onSubmit={handleSubmit}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 2,
                        flex: 1,
                     }}
                  >
                     <Box sx={{ p: 2 }}>
                        <Typography fontWeight={600} variant="h6">
                           {value === 2 ? `Registro de administrador de sistema` : `Registro de usuarios`}
                        </Typography>
                     </Box>
                     <Box
                        sx={{
                           p: 4,
                           borderTop: 1,
                           borderColor: "#E0E0E0",
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                           maxHeight: "420px",
                           overflowY: "auto",
                        }}
                     >
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="firstName"
                              id="firstName"
                              type="text"
                              label="Nombre"
                              fullWidth={true}
                              disabled={!!foundByEmail}
                           />
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="lastName"
                              id="lastName"
                              type="text"
                              label="Apellido"
                              fullWidth={true}
                              disabled={!!foundByEmail}
                           />
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="phone"
                              id="phone"
                              type="text"
                              label="Teléfono"
                              fullWidth={true}
                              disabled={!!foundByEmail}
                           />
                           <InputTextField
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              name="email"
                              id="email"
                              type="text"
                              label="Correo electrónico"
                              fullWidth={true}
                              onBlur={() => searchUserByEmail(formProps.values.email, formProps.setFieldValue)}
                           />
                        </Box>
                        <InputTextField
                           sx={{ my: 0.5 }}
                           variant="outlined"
                           size="small"
                           value="DefaultPassword"
                           name="comercialName"
                           id="comercialName"
                           type="password"
                           label="Contraseña"
                           disabled
                           fullWidth={true}
                        />
                     </Box>
                  </Box>
                  <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                     <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                        Cancelar
                     </Button>
                     <LoadingButton label={"Guardar"} isLoading={isLoadingCreate} />
                  </Box>
               </Form>
            )}
         </Formik>
      </Box>
   );
};
