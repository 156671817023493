import { CircularProgress, Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import useGetGovernmentBodyUsersInfo from "../../hooks/gob-corp/governmentBody/useGetGovernmentBodyUsersInfo";
import AgreementCommitmentSection from "../../components/Gobierno corporativo/ReportsScreen/AgreementCommitmentSection";
import MembersListSection from "../../components/Gobierno corporativo/ReportsScreen/MembersListSection";
import SessionAttendanceSection from "../../components/Gobierno corporativo/ReportsScreen/SessionAttendanceSection";
import EffectivenessSection from "../../components/Gobierno corporativo/ReportsScreen/EffectivenessSection";
import SessionInfoSection from "../../components/Gobierno corporativo/ReportsScreen/SessionInfoSection";
import { useContext, useEffect, useState } from "react";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import {
   addBasicInfoToUserDetails,
   assignCompanyDetailsToGoverningBodies,
   getAllUsersDetailsByGovernance,
   getClassificationById,
} from "../../lib/gobCorpBEClient";
import { UserContext } from "../../context/userContext";
import { getUsersBasicInfoByIds } from "../../lib/usersBEClient";
import { EvaluationResults } from "../../components/Gobierno corporativo/ReportsScreen/EvaluationResults";
import {
   ReportGenerationSection,
   TrimesterReportsSection,
} from "../../components/Gobierno corporativo/ReportsScreen/ReportGenerationComponent";

const GoverningBodiesReportScreen = () => {
   const { selectedGovernance, companySelected } = useContext(GovernanceContext);
   const { companySelected: companyInfo } = useContext(UserContext);
   const { id } = useParams();
   const { members } = useGetGovernmentBodyUsersInfo(true);
   const selectedBody = members.find((body) => body.bodiesData._id === id);

   const isAssembly = selectedGovernance.title === "Asamblea de accionistas"

   const [categoriesByCompany, setCategoriesByCompany] = useState(null);

   const fetchClarification = async () => {
      try {
         if (!companyInfo) return;
         const classData = await getClassificationById(companyInfo._id);
         return classData.categories;
      } catch (error) {
         console.error("Error fetching clarification:", error);
      }
   };

   const assignCompanyDetails = async () => {
      try {
         await assignCompanyDetailsToGoverningBodies(companySelected, {
            businessName: companyInfo.person_details.businessName,
            primaryColor: companyInfo.company_details.primaryColor,
            secondaryColor: companyInfo.company_details.secondaryColor,
         });
      } catch (error) {
         console.log(error);
      }
   };

   const assignUserDetails = async () => {
      if (!selectedGovernance) return;
      const GoverningusersWitNoEmail = await getAllUsersDetailsByGovernance(selectedGovernance._id);
      //PENDING INCLUDE COORDS
      if (GoverningusersWitNoEmail?.length === 0) return;
      const usersInfo = await getUsersBasicInfoByIds(GoverningusersWitNoEmail);
      if (usersInfo.length === 0) return;
      await addBasicInfoToUserDetails(usersInfo);
   };

   useEffect(() => {
      if (!companyInfo || !selectedGovernance) return;
      assignCompanyDetails();
      assignUserDetails();
      const fetchData = async () => {
         const categories = await fetchClarification();
         setCategoriesByCompany(categories);
      };

      fetchData();
   }, [companyInfo, selectedGovernance]);

   return (
      <Stack direction={"column"} spacing={3}>
         {selectedBody ? (
            <>
               <MembersListSection selectedBody={selectedBody} />
               {!isAssembly && <EvaluationResults />}
               <AgreementCommitmentSection />
               <SessionAttendanceSection selectedBody={selectedBody} categoriesByCompany={categoriesByCompany} />
               {/* <EffectivenessSection /> */}
               <SessionInfoSection />
               <Stack direction={"row"} spacing={2} width={"100%"} justifyContent={"space-between"}>
                  <TrimesterReportsSection />
                  <ReportGenerationSection selectedBody={selectedBody} />
               </Stack>
            </>
         ) : (
            <CircularProgress />
         )}
      </Stack>
   );
};

export default GoverningBodiesReportScreen;
