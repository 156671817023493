import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getComplaints } from "../lib/lecosyBackendClient";
import { UserContext } from "./userContext";
import { IUserContext } from "../types/BaseTypes";
import GetFilteredComplaints from "../hooks/complaints/getFilteredComplaints";
import { getUserBranches } from "../lib/usersBEClient";
import { AxiosError } from "axios";

interface ComplaintContextInferface {
   complaints: ComplaintsInterface[];
   NewComplaints: number;
   refetch: Function;
   loadingComplaints: boolean;
   branches: BranchesInterface[];
   loadingBranches: boolean;
   logout: Function;
}

type competenceInterface = {
   competent?: IUserContext;
   incompetent?: IUserContext;
};

export type BranchesInterface = {
   _id: any;
   name: string;
   disabled: boolean;
   company: any;
};

export interface ComplaintsInterface {
   reportNumber: string;
   companyBranch: any;
   category: string;
   status: string;
   sentDate: string;
   incoming: boolean;
   complaintType: any;
   complainerType: string;
   competence?: competenceInterface;
   updatedAt: Date;
}

export const ComplaintContext = createContext<ComplaintContextInferface>({
   complaints: null,
   NewComplaints: 0,
   refetch: () => {},
   loadingComplaints: true,
   branches: [],
   loadingBranches: true,
   logout: () => {},
});

export const ComplaintProvider = ({ children }) => {
   const { user, isCompanyAdmin, isloadingCompanies, companies } = useContext(UserContext);
   const [branches, setBranches] = useState<BranchesInterface[]>([]);
   const [complaints, setComplaints] = useState<any>(null);
   const [loadingBranches, setLoadingBranches] = useState(true);
   const [loadingComplaints, setLoadingComplaints] = useState(true);

   const refetch = async () => {
      try {
         const inboxComplaints = await getComplaints(branches, companies);
         if (!inboxComplaints) throw new AxiosError("Network Error");
         setComplaints(inboxComplaints);
         setLoadingComplaints(false);
         return null;
      } catch (error: any) {
         if (error.message === "Network Error") setLoadingComplaints(false);
         return error;
      }
   };

   const NewComplaints = useMemo(() => {
      let newComplaints = 0;
      if (complaints?.length > 0)
         for (const complaint of GetFilteredComplaints(complaints, "todos")) if (complaint.incoming) newComplaints++;
      return newComplaints;
   }, [complaints]);

   const logout = () => {
      setComplaints(null);
      setBranches([]);
      setLoadingBranches(true);
      setLoadingComplaints(true);
   };

   useEffect(() => {
      const fetchInformation = async () => {
         const companyDetails: string[] = []; //companyDetails where user is admin
         for (const company of companies) {
            companyDetails.push(company.company_details._id);
         }
         const branchesResponse = await getUserBranches(companyDetails, isCompanyAdmin, user);
         setBranches(branchesResponse);
         setLoadingBranches(false);
      };

      if (user.auth && !isloadingCompanies && companies.length > 0 && isCompanyAdmin !== null) fetchInformation();
      // eslint-disable-next-line
   }, [user.auth, isloadingCompanies, companies, isCompanyAdmin]);

   useEffect(() => {
      // const refetchComplaint = async () => {
      //    if (!loadingBranches) {
      //       const refetched = await refetch();
      //       if (refetched?.message === "Network Error") setComplaints(null);
      //    }
      // };
      if (!loadingBranches) refetch();
      // const interval = setInterval(refetchComplaint, 40000);
      // return () => clearInterval(interval);
      // eslint-disable-next-line
   }, [loadingBranches, branches.length, isCompanyAdmin, loadingComplaints]);

   return (
      <ComplaintContext.Provider
         value={{
            complaints,
            NewComplaints,
            refetch,
            loadingComplaints,
            branches,
            loadingBranches,
            logout,
         }}
      >
         {children}
      </ComplaintContext.Provider>
   );
};
