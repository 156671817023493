import { AttachFile } from "@mui/icons-material";
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, MenuItem, Modal, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../context/snackBarContext";
import { Form, Formik, FormikProps } from "formik";
import { corporateDataSchema } from "../../../lib/validations/inputSchemas";
import { InputTextField } from "../../Inputs/InputTextField";
import { SocietyKindsComponent } from "./SocietyKindsComponent";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../../lib/s3Client";
import { AddFileModal } from "../AddFileModal";
import { WatchmanOptionsComponent } from "./WatchmanOptionsComponent";
import { CountrySelectComponent } from "./CountrySelectComponent";
import TextEditor from "../../Inputs/TextEditor/TextEditor";
import { getUrlS3, postCorporateData, updateCorporateData } from "../../../lib/usersBEClient";
import { InputSelectField } from "../../Inputs/InputSelectField";
import dayjs from "dayjs";
import { ListOfTexFields } from "../../Gobierno corporativo/Inputs/ListOfTexFields";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createConsultiveGroup, getConsultiveGroupById, updateConsultiveGroup } from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { CorporateData } from "../../../context/governanceContext/corporateDataContext";
import { formatFileSize } from "../../../const/globalConst";

interface CorporateDataModalProps {
   state: boolean;
   setState: Function;
   companyId: string;
   companyNames: any;
   edition: boolean;
   data: CorporateData;
   constitutionalFile?: File;
   shareholderBodyId?: string;
   setBodiesSeed: Function;
}

type File = {
   name: string;
   size: number;
};
const administrationOptions = ["Consejo de administración", "Administrador único"];
const councilTypeOptions = ["Legal", "Consultivo"];
const recordTypes = ["Constitutiva", "Extraordinaria"];

export const CorporateDataModal = (props: CorporateDataModalProps) => {
   const [submitLoading, setSubmitLoading] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected, user } = useContext(UserContext);
   const [filePact, setFilePact] = useState<null | File>(null);
   const [currentPactsFileName, setCurrentPactsFileName] = useState("");
   const [beneficiaryFiel, setBeneficiaryFile] = useState<null | File>(null);
   const [currentBeneficiaryFile, setCurrentBeneficiaryFile] = useState("");
   const [openFilePactModal, setOpenFilePactModal] = useState(false);
   const [openBeneficiaryModal, setOpenBeneficiaryModal] = useState(false);
   const today = dayjs(new Date());
   const [incorporationDate, setIncorporationDate] = useState<dayjs.Dayjs>(
      typeof props.data?.incorporationYear === "string" && props.data?.incorporationYear.length < 6
         ? null
         : dayjs(props.data?.incorporationYear)
   );
   const [grantDate, setGrantDate] = useState<dayjs.Dayjs>(props.data ? dayjs(props.data.grantDate) : null);
   const [text, setText] = useState(props.data ? (props.edition ? props.data.administratorFaculties : "") : "");
   const hasBeneficiary = user && user?.modules?.includes("Beneficiario controlador");

   const fetchFile = async (fileName: string, setFile: Function) => {
      try {
         const fileUrl = await getUrlS3(
            "files-lecosy",
            { folder: `gc/companies/${props.companyId}/informacion-societaria` },
            fileName
         );
         const response = await fetch(fileUrl);
         const blob = await response.blob();
         const file = new File([blob], fileName, { type: "application/pdf" });
         setFile(file);
      } catch (error) {
         console.error("Error fetching file:", error);
      }
   };

   useEffect(() => {
      if (!props.data) return;
      const { filePact, beneficiaryManual } = props.data;
      const fetchFiles = [
         { fileData: filePact, setFile: setFilePact, setCurrentName: setCurrentPactsFileName },
         { fileData: beneficiaryManual, setFile: setBeneficiaryFile, setCurrentName: setCurrentBeneficiaryFile },
      ];
      fetchFiles.forEach(({ fileData, setFile, setCurrentName }) => {
         if (fileData) {
            setCurrentName(fileData.name);
            fetchFile(fileData.name, setFile);
         }
      });
   }, [props.data]);

   const handleUploadFile = async (fileName: string, file: File) => {
      const urlReceipt2 = await UseGetPresignedURLUpload({
         bucket: "files-lecosy",
         folder: `gc/companies/${props.companyId}/informacion-societaria`,
         name: fileName,
      });

      await uploadFileToS3(urlReceipt2.data, file);
   };

   const handleSubmit = async (values) => {
      try {
         setSubmitLoading(true);
         if (incorporationDate === null) {
            showSnackBar("Fecha de constitución/modificación sin seleccionar", true);
            setSubmitLoading(false);
            return;
         }
         if (grantDate === null) {
            showSnackBar("Fecha de otorgamiento sin seleccionar", true);
            setSubmitLoading(false);
            return;
         }
         if (filePact === null) {
            showSnackBar("Falta pacto de socios (PDF)", true);
            setSubmitLoading(false);
            return;
         }

         const data = {
            companyId: props.companyId,
            ...values,
            incorporationYear: incorporationDate,
            grantDate: grantDate,
            filePact: {
               name: filePact.name,
               size: Math.trunc(filePact.size / (1024 * 1024)),
            },
            beneficiaryManual: hasBeneficiary && {
               name: beneficiaryFiel.name,
               size: Math.trunc(beneficiaryFiel.size / (1024 * 1024)),
            },
         };
         if (props.edition) await updateCorporateData(props.data._id, data);
         else await postCorporateData(data);

         if (currentPactsFileName !== filePact.name) await handleUploadFile(filePact.name, filePact);
         if (hasBeneficiary && currentBeneficiaryFile !== beneficiaryFiel.name)
            await handleUploadFile(beneficiaryFiel.name, beneficiaryFiel);

         //GROUPS
         const groupId = (companySelected?.group as any)?._id;
         const groupFound = await getConsultiveGroupById(groupId);

         if (values.councilType === "Consultivo") {
            if (groupFound) {
               if (!groupFound.companies.includes(companySelected?._id)) {
                  groupFound.companies.push(companySelected?._id);
                  await updateConsultiveGroup(groupId, groupFound.companies);
               }
            } else await createConsultiveGroup({ _id: groupId, companies: [companySelected?._id] });
         } else if (groupFound && groupFound.companies.includes(companySelected?._id)) {
            groupFound.companies = groupFound.companies.filter((c) => c !== companySelected?._id);
            await updateConsultiveGroup(groupId, groupFound.companies);
         }

         showSnackBar(
            props.edition ? "Información actualizada correctamente" : "Información creada correctamente",
            false
         );
         props.setBodiesSeed(Math.random());
         props.setState(false);
      } catch (error) {
         console.log(error);
         showSnackBar(props.edition ? "Error al modificar la información" : "Error al agregar la información", true);
      }
      setSubmitLoading(false);
   };

   return (
      <Box sx={{ maxWidth: 700 }}>
         <Formik
            initialValues={
               props.edition
                  ? {
                       societyKind: props.data.societyKind,
                       legalConcept: props.data.legalConcept,
                       legalConceptOther: props.data.legalConceptOther,
                       physicalAddress: props.data.physicalAddress,
                       incorporationYear: props.data.incorporationYear,
                       taxRegime: props.data.taxRegime,
                       operatingCountries: props.data.operatingCountries,
                       watchman: typeof props.data.watchman === "string" ? [] : props.data.watchman,
                       socialObject: typeof props.data.socialObject === "string" ? [] : props.data.socialObject,
                       administration: props.data.administration,
                       legalRepresentative: props.data.legalRepresentative,
                       councilType: props.data.councilType,
                       administratorFaculties: props.data.administratorFaculties,
                       businessFolio: props.data.businessFolio,
                       notaryName: props.data.notaryName,
                       notaryNumber: props.data.notaryNumber,
                       recordType: props.data.recordType,
                       actNumber: props.data.actNumber,
                    }
                  : {
                       societyKind: "",
                       legalConcept: "",
                       legalConceptOther: "",
                       physicalAddress: "",
                       incorporationYear: "",
                       taxRegime: "",
                       operatingCountries: [],
                       watchman: [],
                       socialObject: "",
                       administration: "",
                       legalRepresentative: "",
                       councilType: "",
                       administratorFaculties: "",
                       businessFolio: "",
                       notaryName: "",
                       notaryNumber: "",
                       recordType: "",
                       actNumber: "",
                    }
            }
            onSubmit={handleSubmit}
            validationSchema={corporateDataSchema}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        maxHeight: 500,
                        px: 4,
                        py: 2,
                        rowGap: 2,
                        bgcolor: "white",
                        overflow: "auto",
                     }}
                  >
                     <Grid container sx={{ justifyContent: "space-between", rowGap: 2, columnGap: 1 }}>
                        <Grid item xs={12}>
                           <Typography>Denominación/Razón social</Typography>
                           <InputTextField
                              disabled
                              type="string"
                              id="a"
                              name="a"
                              placeholder={props.companyNames.businessName}
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography>Nombre comercial</Typography>
                           <InputTextField
                              disabled
                              type="string"
                              id="a"
                              name="a"
                              placeholder={props.companyNames.comercialName}
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <SocietyKindsComponent
                              currentSociety={formProps.values.societyKind}
                              currentLegalConcept={formProps.values.legalConcept}
                              legalConceptsId="legalConcept"
                              legalConceptsName="legalConcept"
                              societyKindsId="societyKind"
                              societyKindsName="societyKind"
                              legalConceptOtherId="legalConceptOther"
                              legalConceptOtherName="legalConceptOther"
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                              <Typography>Fecha de constitución/modificación</Typography>
                              <DatePicker
                                 maxDate={today}
                                 format="DD/MM/YYYY"
                                 value={incorporationDate || null}
                                 onChange={(event: any) => {
                                    if (event) setIncorporationDate(dayjs(event.$d));
                                    else setIncorporationDate(null);
                                 }}
                                 slotProps={{ textField: { variant: "outlined", size: "small", fullWidth: true } }}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={5.5}>
                           <WatchmanOptionsComponent
                              companyId={props.companyId}
                              id="watchman"
                              name="watchman"
                              currentWatchman={formProps.values.watchman}
                              setSelected={(option) => {
                                 formProps.setFieldValue(
                                    "watchman",
                                    typeof option === "string" ? option.split(",") : option
                                 );
                              }}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Tipo de acta</Typography>
                           <InputSelectField
                              id="recordType"
                              name="recordType"
                              label=""
                              labelId=""
                              variant="outlined"
                              size={"small"}
                              fullWidth
                           >
                              {recordTypes.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Folio mercantil</Typography>
                           <InputTextField
                              type="text"
                              id="businessFolio"
                              name="businessFolio"
                              placeholder="Folio mercantil"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Nombre del notario</Typography>
                           <InputTextField
                              type="text"
                              id="notaryName"
                              name="notaryName"
                              placeholder="Nombre del notario"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Número de notaria</Typography>
                           <InputTextField
                              type="text"
                              id="notaryNumber"
                              name="notaryNumber"
                              placeholder="Número de notaria"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Typography>Objeto social</Typography>
                           <ListOfTexFields
                              listOfData={formProps.values.socialObject || []}
                              setListOfData={formProps.setFieldValue}
                              inputProps={{
                                 id: "socialObject",
                                 name: "socialObject",
                              }}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Pactos de socios</Typography>
                           <Box
                              sx={{
                                 display: "flex",
                                 border: 1,
                                 borderColor: "#E0E0E0",
                                 alignItems: "center",
                                 px: 1,
                                 justifyContent: "space-between",
                                 borderRadius: 1.5,
                              }}
                           >
                              {filePact === null ? (
                                 <>
                                    <Typography fontSize={15}>Adjuntar archivo</Typography>
                                    <IconButton onClick={() => setOpenFilePactModal(true)}>
                                       <AttachFile />
                                    </IconButton>
                                 </>
                              ) : (
                                 <>
                                    <Typography fontSize={15}>{filePact.name.substring(0, 17)}</Typography>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <Typography fontSize={12}>{formatFileSize(filePact.size)}</Typography>
                                       <IconButton onClick={() => setOpenFilePactModal(true)}>
                                          <AttachFile />
                                       </IconButton>
                                    </Box>
                                 </>
                              )}
                           </Box>
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Régimen fiscal</Typography>
                           <InputTextField
                              type="text"
                              id="taxRegime"
                              name="taxRegime"
                              placeholder="Régimen fiscal"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <CountrySelectComponent
                              label="Países en los que opera"
                              id="operatingCountries"
                              name="operatingCountries"
                              countries={formProps.values.operatingCountries}
                              setCountries={(c) => {
                                 formProps.setFieldValue(
                                    "operatingCountries",
                                    typeof c === "string" ? c.split(",") : c
                                 );
                              }}
                           />
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Órgano de Administración</Typography>
                           <InputSelectField
                              id="administration"
                              name="administration"
                              label=""
                              labelId=""
                              variant="outlined"
                              size={"small"}
                              fullWidth
                           >
                              {administrationOptions.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={5.5}>
                           <Typography>Tipo de consejo</Typography>
                           <InputSelectField
                              id="councilType"
                              name="councilType"
                              label=""
                              labelId=""
                              variant="outlined"
                              size={"small"}
                              fullWidth
                           >
                              {councilTypeOptions.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>

                        <Grid item xs={5.5}>
                           <Typography>Dirección física</Typography>
                           <InputTextField
                              type="string"
                              id="physicalAddress"
                              name="physicalAddress"
                              placeholder="Dirección física"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={4}>
                           <Typography>Apoderado legal</Typography>
                           <InputTextField
                              type="text"
                              id="legalRepresentative"
                              name="legalRepresentative"
                              placeholder="Apoderado legal"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={2.5}>
                           <Typography>Número de acta</Typography>
                           <InputTextField
                              type="text"
                              id="actNumber"
                              name="actNumber"
                              placeholder="000"
                              size={"small"}
                              fullWidth
                           />
                        </Grid>
                        <Grid item xs={3.5}>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                              <Typography>Fecha de otorgamiento</Typography>
                              <DatePicker
                                 maxDate={today}
                                 format="DD/MM/YYYY"
                                 value={grantDate || null}
                                 onChange={(event: any) => {
                                    if (event) setGrantDate(dayjs(event.$d));
                                    else setGrantDate(null);
                                 }}
                                 slotProps={{ textField: { variant: "outlined", size: "small", fullWidth: true } }}
                              />
                           </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography>Poderes y facultades de la administración</Typography>
                           <TextEditor
                              text={text}
                              setText={(e) => {
                                 setText(e);
                                 formProps.setFieldValue("administratorFaculties", e);
                              }}
                           />
                        </Grid>
                        {hasBeneficiary && (
                           <Grid item xs={12}>
                              <Typography>Manual de identificación de beneficiario controlador</Typography>
                              <Box
                                 sx={{
                                    display: "flex",
                                    border: 1,
                                    borderColor: "#E0E0E0",
                                    alignItems: "center",
                                    px: 1,
                                    justifyContent: "space-between",
                                    borderRadius: 1.5,
                                 }}
                              >
                                 {beneficiaryFiel === null ? (
                                    <>
                                       <Typography fontSize={15}>Adjuntar archivo</Typography>
                                       <IconButton onClick={() => setOpenBeneficiaryModal(true)}>
                                          <AttachFile />
                                       </IconButton>
                                    </>
                                 ) : (
                                    <>
                                       <Typography fontSize={15}>{beneficiaryFiel.name.substring(0, 50)}</Typography>
                                       <Box sx={{ display: "flex", alignItems: "center" }}>
                                          <Typography fontSize={12}>{formatFileSize(beneficiaryFiel.size)}</Typography>
                                          <IconButton onClick={() => setOpenBeneficiaryModal(true)}>
                                             <AttachFile />
                                          </IconButton>
                                       </Box>
                                    </>
                                 )}
                              </Box>
                           </Grid>
                        )}
                     </Grid>
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        bgcolor: "#F3F3F3",
                        width: "100%",
                        p: 1,
                        height: 50,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                     }}
                  >
                     <Button
                        onClick={() => props.setState(false)}
                        sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                     >
                        Cancelar
                     </Button>
                     <Button
                        type="submit"
                        disabled={submitLoading}
                        sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                     >
                        {!submitLoading ? "Guardar" : <CircularProgress size={24} />}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>

         <AddFileModal
            state={openFilePactModal}
            setState={setOpenFilePactModal}
            setFile={setFilePact}
            file={filePact}
         />
         <AddFileModal
            state={openBeneficiaryModal}
            setState={setOpenBeneficiaryModal}
            setFile={setBeneficiaryFile}
            file={beneficiaryFiel}
         />
      </Box>
   );
};
