import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceSessionContext } from "../../../../../context/governanceContext/governanceSessionContext";
import ListComponent from "../../../../ListComponent";
import { IAffairs } from "../../../../../types/governance.types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { affairVotesSchema } from "../../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../Inputs/InputTextField";
import {
   CreateVotes,
   updateAffairVotation,
   UpdateVotes,
   updateVotesVotation,
} from "../../../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import VotationAnswerOptions from "./VotationAnswerOptions";
import _ from "lodash";
import CompanySelectorComponent from "./CompanySelectorComponent";

const AffairsVoteModal = ({ votationOnCourse, setVotationOnCourse, setOpenModal }) => {
   const [answersArray, setAnswersArray] = useState<string[]>([]);
   const [selectedCompany, setSelectedCompany] = useState<string>(null);
   const [orderSeed, setOrderSeed] = useState(false);
   const { state, dispatch, affairsArray, socket, session, membersWithCharge } = useContext(GovernanceSessionContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate } = CreateVotes(true);
   const { mutate: CancelVote } = UpdateVotes();

   const createVoteFunction = async (values) => {
      mutate(values, {
         onError: (error: any) => {
            console.error(error);
         },
         onSuccess: (data) => {
            dispatch({ type: "setActiveVote", activeVote: data });
            socket.emit("send-affair-votation", {
               sessionId: session._id,
               vote: data,
            });
         },
      });
   };

   // useEffect(() => {
   //    if (!state.vote) return;
   //    if (!state.vote.affair?.orderId) {
   //       const foundVoteIndex = state.additionalVotes.findIndex((v) => v._id === state.vote._id);
   //       dispatch({ type: "setActiveVote", activeVote: state.additionalVotes[foundVoteIndex] });
   //    }
   // }, [sessionSeed, additionalVotesSeed, dispatch, state.additionalVotes, state.vote]);

   const uniqueArray = {};

   for (const affair of affairsArray) {
      if (session.group && !affair.companies.includes(selectedCompany)) continue;
      if (!uniqueArray[affair._id]) uniqueArray[affair._id] = affair;
   }

   const handleActiveVotationState = useMemo(() => {
      if (state.vote === null) return null;
      const votationCounts = {
         nullVote: 0,
         pending: 0,
      };
      if (session.group) {
         const foundOrder = state.affairVotations.find(
            (affairOrder) => affairOrder.affair.orderId === state.vote.affair?.orderId || state.vote.orderId
         );
         const foundCompany = foundOrder.companies.find((company) =>
            company._id ? company._id : company.company === state.vote.company
         );
         if (state.vote.affair) {
            for (const user of foundCompany.users) {
               if (user.abstention) {
                  votationCounts["nullVote"] += 1;
                  continue;
               }
               if (!user.answer) {
                  votationCounts["pending"] += 1;
                  continue;
               }
               if (!votationCounts[user.answer]) votationCounts[user.answer] = 1;
               else votationCounts[user.answer] += 1;
            }
         } else {
            for (const user of state.vote.votes) {
               if (user.abstention) {
                  votationCounts["nullVote"] += 1;
                  continue;
               }
               if (!user.answer) {
                  votationCounts["pending"] += 1;
                  continue;
               }
               if (!votationCounts[user.answer]) votationCounts[user.answer] = 1;
               else votationCounts[user.answer] += 1;
            }
         }
      } else {
         if (Object.keys(state.vote).length === 0) return votationCounts;
         for (const user of state.vote.users || state.vote.votes) {
            if (user.abstention) {
               votationCounts["nullVote"] += 1;
               continue;
            }
            if (!user.answer) {
               votationCounts["pending"] += 1;
               continue;
            }
            if (!votationCounts[user.answer]) votationCounts[user.answer] = 1;
            else votationCounts[user.answer] += 1;
         }
      }
      return votationCounts;
   }, [state, session.group]);

   const handleSubmit = async (values) => {
      if (!state.vote) {
         if (answersArray.length < 2 || answersArray.filter((answer) => answer.trim().length !== 0).length < 2)
            return showSnackBar("La votación debe contener al menos dos respuestas", true);
         if (_.uniq(answersArray).length !== answersArray.length)
            return showSnackBar("Existen dos o más respuestas iguales", true);
         const membersWithVotes = membersWithCharge.filter((member) => {
            return (
               member.memberCharge.length > 1 ||
               (member.memberCharge.length === 1 &&
                  !member.memberCharge.some((charge) => charge.toLowerCase().includes("coordinador")))
            );
         });
         if (values.question !== "") {
            const foundAffair = state.affairVotations.find(
               (aVote) => aVote.affair.orderId === values.selectedOrder.orderId
            );
            const foundAffairCompany = foundAffair.companies?.find(
               (affairComany) => affairComany.company === selectedCompany
            );
            createVoteFunction({
               ...(selectedCompany && { company: selectedCompany }),
               orderId: foundAffair.affair.orderId,
               answers: answersArray,
               description: values.selectedOrder.description,
               title: values.question,
               session: session._id,
               votes: session.usersRegistry
                  .map((user) => {
                     const foundOnlineUser = membersWithVotes.find((voteUser) => voteUser._id === user.user);
                     if (foundOnlineUser) {
                        if (session.group) {
                           const foundUser = foundAffairCompany.users.find((u) => u.user === foundOnlineUser._id);
                           if (foundUser) {
                              return {
                                 userId: foundOnlineUser._id,
                                 userName: `${foundOnlineUser.firstName} ${foundOnlineUser.lastName}`,
                                 avaliableVotes: foundUser.avaliableVotes,
                              };
                           }
                           return null;
                        }
                        const foundAffair = state.affairVotations.find(
                           (v) => v.affair.orderId === values.selectedOrder.orderId
                        );
                        const foundUser = foundAffair.users.find((u) => u.user === foundOnlineUser._id);
                        if (foundUser) {
                           return {
                              userId: foundOnlineUser._id,
                              userName: `${foundOnlineUser.firstName} ${foundOnlineUser.lastName}`,
                              avaliableVotes: foundUser.avaliableVotes,
                           };
                        }
                     }
                     return null;
                  })
                  .filter(Boolean),
               totalVotes: foundAffairCompany
                  ? foundAffairCompany.totalVotes || foundAffairCompany.users.length
                  : foundAffair.totalVotes || foundAffairCompany.users.length,
               affairTotalVotes: foundAffairCompany
                  ? foundAffairCompany?.totalVotes || foundAffairCompany.users.length
                  : foundAffair.totalVotes || foundAffairCompany.users.length,
               totalVotesUsed: 0,
            });
         } else {
            const foundOrderVotation = state.affairVotations.find(
               (votation) => votation.affair.orderId === values.selectedOrder.orderId
            );
            let companyFound;
            if (session.group) {
               companyFound = foundOrderVotation.companies.find((company) => company.company === selectedCompany);
               if (companyFound) companyFound = { ...companyFound, affair: foundOrderVotation.affair };
            }
            const activeVote = companyFound ? companyFound : foundOrderVotation;
            dispatch({
               type: "setActiveVote",
               activeVote: { ...activeVote, answers: answersArray },
            });
            socket.emit("send-affair-votation", {
               sessionId: session._id,
               order: { ...activeVote, answers: answersArray },
               ...(selectedCompany && { company: selectedCompany }),
            });
            setVotationOnCourse(true);
         }
      } else {
         let endAffairVote;
         let endAddVote;
         if (state.vote.affair) endAffairVote = await updateAffairVotation(session._id, state.vote);
         else if (state.vote.orderId) {
            const foundVote = state.additionalVotes.find((addVote) => addVote._id === state.vote._id);
            endAddVote = await updateVotesVotation(foundVote);
         } else endAddVote = state.vote;
         setVotationOnCourse(false);
         socket.emit("end-vote", {
            sessionId: session._id,
            ...(endAffairVote && { order: endAffairVote }),
            ...(endAddVote && { addVote: endAddVote }),
            orderId: state.vote.affair?.orderId || state.vote.orderId,
         });
      }
   };
   const handleCancelVotation = () => {
      if (!state.vote) return setOpenModal(false);
      if (state.vote.affair)
         return socket.emit("cancel-vote", { sessionId: session._id, additionalVote: state.vote, isAffairVote: true });
      CancelVote(
         { votationId: state.vote._id, voteData: { canceled: true } },
         {
            onError: (error) => {
               console.error(error);
            },
            onSuccess: async (data) => {
               socket.emit("cancel-vote", { sessionId: session._id, additionalVote: data, isAffairVote: false });
            },
         }
      );
   };

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();
      useEffect(() => {
         if (!state.vote) return;
         const selectedOrder = affairsArray.find(
            (affair) => affair.orderId === (state.vote.affair ? state.vote.affair.orderId : state.vote.orderId)
         );
         setFieldValue("question", state.vote.affair ? state.vote.affair.description : state.vote.title);
         setFieldValue("selectedOrder", selectedOrder);
      }, [state.vote]);
      return null;
   };

   return (
      <Formik
         initialValues={{ question: "", selectedAffair: null, selectedOrder: null }}
         validationSchema={affairVotesSchema}
         onSubmit={handleSubmit}
      >
         {(formProps: FormikProps<any>) => (
            <Form>
               <FormObserver />
               {votationOnCourse && state.vote ? (
                  <Stack spacing={1.5}>
                     <Stack spacing={1}>
                        <Typography>Título de la pregunta</Typography>
                        <Typography>{state.vote.affair ? state.vote.affair.description : state.vote.title}</Typography>
                     </Stack>
                     <Stack spacing={1}>
                        <Typography>Respuestas:</Typography>
                        <Stack spacing={0.5}>
                           {session.group
                              ? state.vote.answers?.map((answer) => {
                                   return (
                                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }} key={answer}>
                                         <Typography>{`${answer}:`}</Typography>
                                         <Typography>{handleActiveVotationState?.[answer] || 0}</Typography>
                                      </Box>
                                   );
                                })
                              : state.vote.answers.map((answer) => {
                                   return (
                                      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }} key={answer}>
                                         <Typography>{`${answer}:`}</Typography>
                                         <Typography>{handleActiveVotationState?.[answer] || 0}</Typography>
                                      </Box>
                                   );
                                })}
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography>Se abstuvo de votar: </Typography>
                              <Typography>{handleActiveVotationState?.["nullVote"] || 0}</Typography>
                           </Box>
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography sx={{ fontWeight: 600 }}>Votos faltantes: </Typography>
                              <Typography sx={{ fontWeight: 600 }}>
                                 {handleActiveVotationState?.["pending"] || 0}
                              </Typography>
                           </Box>
                        </Stack>
                     </Stack>
                     <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Button
                           type="submit"
                           variant="contained"
                           sx={{ bgcolor: "#2D4357", color: "white", width: "33%" }}
                        >
                           Terminar
                        </Button>
                        <Button
                           type="button"
                           onClick={handleCancelVotation}
                           sx={{
                              bgcolor: "#E1E1E1",
                              border: 0.65,
                              borderColor: "#EFEFEF",
                              width: "33%",
                              color: "black",
                           }}
                        >
                           Cancelar
                        </Button>
                     </Box>
                  </Stack>
               ) : (
                  <Stack direction={"column"} spacing={2}>
                     {session.group && <CompanySelectorComponent setSelectedCompany={setSelectedCompany} />}
                     {((session.group && selectedCompany) || !session.group) && (
                        <ListComponent
                           disableEmptyList
                           headers={[
                              {
                                 headerTitle: "Selecciona un asunto",
                                 type: "text",
                                 bodyPosition: "left",
                                 headerPosition: "left",
                              },
                           ]}
                           rows={Object.keys(uniqueArray).map((affair) => {
                              return {
                                 title: uniqueArray[affair].title,
                                 orderId: uniqueArray[affair].orderId,
                              };
                           })}
                           rowEndAndornment={(row) => {
                              const foundAffair: any = Object.values(uniqueArray).find(
                                 (affair: IAffairs) => affair.orderId === row.orderId
                              );
                              const descriptionCount = affairsArray.filter((localAffair) => {
                                 return (
                                    localAffair._id === foundAffair._id &&
                                    (!session.group || localAffair.companies.includes(selectedCompany))
                                 );
                              }).length;
                              return (
                                 <Stack direction={"row"} spacing={1}>
                                    {descriptionCount > 1 && (
                                       <Typography sx={{ bgcolor: "#162c44", color: "white", borderRadius: 5, px: 1 }}>
                                          {
                                             affairsArray.filter((localAffair) => localAffair._id === foundAffair._id)
                                                .length
                                          }
                                       </Typography>
                                    )}
                                    {formProps.values.selectedAffair &&
                                       formProps.values.selectedAffair === foundAffair._id && (
                                          <IconButton
                                             onClick={(e) => {
                                                e.stopPropagation();
                                                setAnswersArray([]);
                                                formProps.values.selectedAffair = null;
                                                formProps.values.selectedOrder = null;
                                                setOrderSeed(false);
                                             }}
                                             sx={{ p: 0 }}
                                          >
                                             <DeleteForeverIcon color="error" />
                                          </IconButton>
                                       )}
                                 </Stack>
                              );
                           }}
                           headerProps={{
                              pb: 1,
                              px: 2,
                              borderRadius: 1,
                           }}
                           headerTextProps={{
                              fontSize: "16px",
                              fontWeight: 600,
                           }}
                           rowProps={function (row) {
                              const foundAffair: any = Object.values(uniqueArray).find(
                                 (affair: IAffairs) => affair.orderId === row.orderId
                              );
                              return {
                                 display:
                                    formProps.values.selectedAffair &&
                                    formProps.values.selectedAffair !== foundAffair._id
                                       ? "none"
                                       : "auto",
                                 bgcolor: formProps.values.selectedAffair === foundAffair._id ? "#D9D9D9" : "#EFEFEF",
                                 py: 1,
                                 px: 2,
                                 mb: 1,
                              };
                           }}
                           onClick={(_e, row) => {
                              formProps.values.selectedOrder = null;
                              setAnswersArray(["De acuerdo", "En desacauerdo"]);
                              const clickedAffair: any = Object.values(uniqueArray).find(
                                 (affair: IAffairs) => affair.orderId === row.orderId
                              );
                              const ordersArray = affairsArray.filter(
                                 (affair) =>
                                    affair._id === clickedAffair._id &&
                                    (!session.group || affair.companies.includes(selectedCompany))
                              );
                              if (ordersArray.length < 2) {
                                 formProps.values.selectedOrder = affairsArray.find(
                                    (affair) => affair.orderId === clickedAffair.orderId
                                 );
                                 setOrderSeed(true);
                              }
                              formProps.values.selectedAffair = clickedAffair._id;
                           }}
                        />
                     )}
                     {!orderSeed ? (
                        affairsArray.filter((affair) => affair._id === formProps.values.selectedAffair).length > 0 && (
                           <ListComponent
                              disableEmptyList
                              headers={[
                                 {
                                    headerTitle: "Selecciona una orden del día",
                                    type: "text",
                                    bodyPosition: "left",
                                    headerPosition: "left",
                                 },
                              ]}
                              rows={affairsArray
                                 .filter((affair) => {
                                    return (
                                       formProps.values.selectedAffair === affair._id &&
                                       (!session.group || affair.companies.includes(selectedCompany))
                                    );
                                 })
                                 .map((affair) => {
                                    return { description: affair.description };
                                 })}
                              headerProps={{
                                 pb: 1,
                                 px: 2,
                                 borderRadius: 1,
                              }}
                              headerTextProps={{
                                 fontSize: "16px",
                                 fontWeight: 600,
                              }}
                              rowProps={function () {
                                 return {
                                    bgcolor: "#F5F5F5",
                                    py: 1,
                                    px: 2,
                                    mb: 1,
                                 };
                              }}
                              onClick={(_e, row) => {
                                 const foundOrder = affairsArray.find(
                                    (array) =>
                                       array._id === formProps.values.selectedAffair &&
                                       array.description === row.description
                                 );
                                 formProps.values.selectedOrder = foundOrder;
                                 setOrderSeed(true);
                              }}
                           />
                        )
                     ) : (
                        <Box>
                           <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11} bgcolor={"#EFEFEF"} sx={{ px: 2, py: 1, alignContent: "center" }}>
                                 <Typography fontSize={"14px"}>
                                    {formProps.values.selectedOrder?.description}
                                 </Typography>
                              </Grid>
                              <Grid item xs={1}>
                                 <IconButton
                                    onClick={() => {
                                       formProps.values.selectedOrder = null;
                                       setOrderSeed(false);
                                       setAnswersArray(["De acuerdo", "En desacauerdo"]);
                                    }}
                                 >
                                    <DeleteForeverIcon color="error" />
                                 </IconButton>
                              </Grid>
                           </Grid>
                        </Box>
                     )}
                     {formProps.values.selectedOrder && (
                        <>
                           <InputTextField
                              id="question"
                              name="question"
                              type="text"
                              size={"small"}
                              placeholder="Agregar pregunta"
                              fullWidth
                              multiline
                              maxRows={3}
                           />
                           <VotationAnswerOptions
                              setVotationAnswers={setAnswersArray}
                              votationAnswers={answersArray}
                              maxHeight="120px"
                           />
                        </>
                     )}
                     <Box display={"flex"} justifyContent={"center"}>
                        <Button
                           sx={formProps.values.selectedOrder ? shareButton : undefined}
                           disabled={!formProps.values.selectedOrder}
                           type="submit"
                        >
                           <Typography>Compartir</Typography>
                        </Button>
                     </Box>
                  </Stack>
               )}
            </Form>
         )}
      </Formik>
   );
};

const shareButton = {
   bgcolor: "#162c44",
   ":hover": { bgcolor: "#162c44" },
   color: "white",
   px: 5,
};

export default AffairsVoteModal;
