const GetAdminRoles = (roleList: any[]) => {
   if (roleList?.length > 0) {
      const rolesFound = roleList.filter(
         (r) => r.name.includes("Administrador Único / Presidente del Consejo") || r.name.includes("Director General")
      );
      return rolesFound;
   }
   return [];
};

export default GetAdminRoles;
