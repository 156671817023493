import { Box, Checkbox, Collapse, Divider, ListItem, Stack } from "@mui/material";
import { useContext, useState } from "react";
import { AddUserContext } from "../../../context/addUserContext";
import { InputMap } from "./InputMap";

const moduleRoles = ["Contador"];

const BeneficiaryModule = ({ module }) => {
   const { removeModule } = useContext(AddUserContext);
   const [open, setOpen] = useState(false);

   const filteredRoles = module.service.roles.filter((r) => moduleRoles.includes(r.name));

   return (
      <>
         <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
               checked={open}
               onChange={() => {
                  if (open) removeModule(module.service.id);
                  setOpen(!open);
               }}
            />
            <ListItem>{module.service.serviceName}</ListItem>
         </Box>
         <Divider />
         <Collapse in={open}>
            <Stack spacing={2} sx={{ p: 2, border: 1, borderColor: "#F2F2F2", display: "flex" }} direction={"column"}>
               {open && <InputMap module={module} name={"rol"} roles={filteredRoles} service={module.service.id} />}
            </Stack>
         </Collapse>
      </>
   );
};

export default BeneficiaryModule;
