import { useCallback, useContext, useMemo, useState } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { UserContext } from "../../context/userContext";
import { useParams } from "react-router-dom";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { complaintUploadPdfEvidence, CreateFile } from "../../lib/usersBEClient";
import { useDropzone } from "react-dropzone";
import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CircularProgress,
   Collapse,
   IconButton,
   Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ConfirmationModal } from "../ConfirmationModal";
import { CustomModalComponent } from "../CustomModalComponent";
import { FilesContext } from "../../context/governanceContext/filesContext";

export const UploadFileBeneficiary = ({
   folder,
   setReload,
   reload,
   setSelectedFile = null,
   setCloseModal = null,
   openModalFile,
   setOpenModalFile,
   chat = false,
}) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { id } = useParams();
   const [evidenceBlob, setEvidenceBlob] = useState<File[]>([]);
   const [evidences, setEvidences] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [openModalCancel, setOpenModalCancel] = useState(false);
   const { mutate } = CreateFile();
   const { beneficiary } = useContext(FilesContext);

   const openEvidence = useMemo(() => {
      return evidenceBlob.length > 0;
   }, [evidenceBlob.length]);

   const onDrop = useCallback(
      (acceptedFiles) => {
         const files = evidenceBlob;
         const fileNames = evidences;
         if (acceptedFiles[0].size === 0) {
            showSnackBar("Archivo invalido", true);
            return;
         }
         if (acceptedFiles[0].type.includes("pdf") && acceptedFiles[0].size > 300000000) {
            showSnackBar("Archivo mayor a 300 MB", true);
            return;
         }
         files.push(acceptedFiles[0]);
         fileNames.push(acceptedFiles[0].name);
         setEvidenceBlob(files);
         setEvidences(fileNames);
      },
      [evidenceBlob, evidences, showSnackBar]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "application/pdf": [".pdf"],
      },
   });

   const handleUploadFiles = async (values) => {
      if (!chat) {
         if (evidenceBlob.length === 0) return showSnackBar("Favor de agregar el archivo", true);
         return setOpenModal(true);
      } else {
         setSelectedFile(evidenceBlob[0]);
         if (setCloseModal) setCloseModal(false);
      }
   };

   const uploadFile = async () => {
      setOpenModal(false);
      setIsLoading(true);
      const fileExtension = evidenceBlob[0].name.split(".");
      const fileName = fileExtension[0];
      mutate(
         {
            name: `${fileName}.${fileExtension[1].toLowerCase()}`,
            owner: null,
            metadata: [],
            size: evidenceBlob[0].size,
            type: evidenceBlob[0].type,
            folder: folder,
            fileDirection: `beneficiaries/${beneficiary.companyId[0]}/${folder}`,
            governingBody: id,
            beneficiary: { needUpdate: false },
         },
         {
            onError: (_error: any) => {
               showSnackBar("Error al agregar archivo.", true);
               setReload(!reload);
            },
            onSuccess: async (data) => {
               await complaintUploadPdfEvidence(data.urlToUpload, evidenceBlob[0]);
               showSnackBar("El archivo se subió correctamente.", false);
               setReload(!reload);
               setOpenModalFile(false);
            },
         }
      );
      setEvidences([]);
      setEvidenceBlob([]);
      setIsLoading(false);
   };

   const map = evidenceBlob.map((file, index) => (
      <Card
         key={file.name}
         sx={{
            display: "flex",
            bgcolor: "#F9F9F9",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 0.5,
            boxShadow: 0,
            border: 1,
            borderColor: "#E2E2E2",
         }}
      >
         <CardContent>
            <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{file.name}</Typography>
         </CardContent>
         <CardActions>
            <IconButton
               onClick={() => {
                  setEvidenceBlob((prev) => prev.filter((item, i) => i !== index));
                  setEvidences((prev) => prev.filter((item, i) => i !== index));
               }}
            >
               <Delete sx={{ color: "red" }} />
            </IconButton>
         </CardActions>
      </Card>
   ));

   const CloseModals = () => {
      setOpenModalCancel(false);
      if (setOpenModalFile) setOpenModalFile(false);
      if (setCloseModal) setCloseModal(false);
   };

   return (
      <CustomModalComponent title="Subir archivo" open={openModalFile} setOpen={setOpenModalFile} onClose={CloseModals}>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               gap: 1,
               flex: 1,
               p: 4,
            }}
         >
            <Box
               sx={{
                  flex: 1,
                  border: 1,
                  borderStyle: "dashed",
                  minHeight: 70,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 10,
                  borderRadius: 2,
               }}
               {...getRootProps()}
            >
               <Typography variant="body2" color={"grey"}>
                  Haga clic para navegar o arrastre y suelte su archivo
               </Typography>
               <input {...getInputProps()} />
            </Box>
            <Collapse in={openEvidence}>
               <Box sx={{ pb: 1, bx: 1, overflowY: "scroll", maxHeight: 130 }}>{map}</Box>
            </Collapse>
            <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
               <Button onClick={() => setOpenModalCancel(true)} variant="outlined">
                  Atras
               </Button>
               <Button onClick={handleUploadFiles} variant="outlined" disabled={isLoading}>
                  {isLoading ? <CircularProgress /> : reload ? "Guardar" : "Subir"}
               </Button>
            </Box>
         </Box>
         <ConfirmationModal
            open={openModal}
            setOpen={setOpenModal}
            onConfirm={uploadFile}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">¿Está seguro que desea guardar el archivo?</Typography>
               </Box>
            }
            title="Confirmar archivo"
            isLoading={isLoading}
         />
         <ConfirmationModal
            open={openModalCancel}
            setOpen={setOpenModalCancel}
            onConfirm={CloseModals}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">¿Está seguro que desea salir?</Typography>
               </Box>
            }
            title="Salir"
         />
      </CustomModalComponent>
   );
};
