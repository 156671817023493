import { useContext } from "react";
import { ClientCreationContext } from "../../../context/officeContexts/clientCreationContext";
import ClientCreationTabsContainer from "./Company/ClientCreationTabsContainer";
import GroupClienCreationTabsContainer from "./Group/GroupClienCreationTabsContainer";

const ClientCreationForms = () => {
   const { selectedClient } = useContext(ClientCreationContext);

   const ClientForm = () => {
      switch (selectedClient?.type) {
         case "natural":
            return <ClientCreationTabsContainer />;
         case "legal":
            return <ClientCreationTabsContainer />;
         case "group":
            return <GroupClienCreationTabsContainer />;
         default:
            <></>;
      }
   };

   return <ClientForm />;
};

export default ClientCreationForms;
