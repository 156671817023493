import { useContext, useEffect, useState } from "react";
import {
   addUserRoleOffice,
   GetAllUserRoles,
   getCompanyById,
   getImplementationUsers,
   removeRoleFromUser,
   removeUserFromCompany,
   updateCompanyAdminUser,
   updateUserEmail,
   useCreateUserOffice,
} from "../../../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../../../context/snackBarContext";
import { UserContext } from "../../../../../../context/userContext";
import { catchAxiosError } from "../../../../../../lib/lecosyBackendClient";
import { Form, Formik, useFormikContext } from "formik";
import {
   Box,
   Button,
   CircularProgress,
   IconButton,
   ListSubheader,
   MenuItem,
   Select,
   Stack,
   Typography,
} from "@mui/material";
import { Clear, Delete } from "@mui/icons-material";
import { createUserSchema } from "../../../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../../Inputs/InputTextField";
import { LoadingButton } from "../../../../../LoadingButton";
import { ConfirmationModal } from "../../../../../ConfirmationModal";
import { HeadCell, TableComponent } from "../../../../../TableComponent";
import _ from "lodash";

const CompanyCoordinatorUserComponent = ({ beneficiary = false }) => {
   const { mutate, isLoading: isLoadingCreate } = useCreateUserOffice();
   const { showSnackBar } = useContext(SnackBarContext);
   const { data: roles } = GetAllUserRoles();
   const { companySelected: clientInfo, refetch } = useContext(UserContext);
   const [selectedUser, setSelectedUser] = useState("");
   const [isLoadingAdd, setIsLoadingAdd] = useState(false);
   const [createMode, setCreateMode] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const [userToDelete, setUserToDelete] = useState("");
   const roleName = beneficiary ? "Coordinador de beneficiario controlador" : "Coordinador de gobierno corporativo";

   const UsefindCoordinatorsUser = () => {
      const [isLoadingUser, setIsLoadingUser] = useState(false);
      const [companyUsers, setCompanyUsers] = useState([]);
      const [users, setUsers] = useState([]);
      useEffect(() => {
         const getUsers = async () => {
            setIsLoadingUser(true);
            const allUsers: any[] = [
               ...(clientInfo?.company_details?.admin ? [clientInfo.company_details.admin] : []),
               ...(clientInfo?.company_details?.users || []),
            ];
            const officeFound = (await getCompanyById(clientInfo?.owner))?.data;
            const officeUsers: any[] = [
               ...(officeFound?.company_details?.admin ? [officeFound.company_details.admin] : []),
               ...(officeFound?.company_details?.users || []),
            ];
            const uniqueUsers = _.uniqBy([...allUsers, ...officeUsers], "_id");
            setCompanyUsers(uniqueUsers || []);
            const cordUsers = [];
            for (const user of uniqueUsers) {
               user.role.forEach((role) => {
                  if (role.company === clientInfo._id)
                     role.roles.forEach((r) => {
                        if (r.name === roleName) {
                           cordUsers.push({
                              uid: user.uid,
                              id: user._id,
                              firstName: user.firstName,
                              lastName: user.lastName,
                              phoneNumber: user.phoneNumber,
                              email: user.email,
                              delete: (
                                 <IconButton
                                    onClick={(e) => {
                                       e.stopPropagation();
                                       setUserToDelete(user._id);
                                       setOpenConfirmation(true);
                                    }}
                                 >
                                    <Delete sx={{ color: "#162c44" }} />
                                 </IconButton>
                              ),
                           });
                        }
                     });
               });
            }
            setUsers(cordUsers);
            setIsLoadingUser(false);
         };
         if (clientInfo?.company_details?.users) getUsers();
      }, [clientInfo]);
      return { users, companyUsers, isLoadingUser };
   };
   const { users, isLoadingUser, companyUsers } = UsefindCoordinatorsUser();

   const handleRemove = async () => {
      if (!userToDelete) return showSnackBar("Error al eliminar usuario.", true);
      setIsLoadingAdd(true);
      try {
         await removeRoleFromUser(userToDelete, clientInfo._id, roles.find((r) => r.name === roleName)._id);
         showSnackBar("El usuario fue eliminado correctamente.", false);
         setOpenConfirmation(false);
         await refetch();
      } catch (error) {
         showSnackBar("Error al eliminar usuario.", true);
      }
      setIsLoadingAdd(false);
   };

   const handleSubmitCreate = async (values) => {
      mutate(
         {
            userData: {
               firstName: values.firstName,
               lastName: values.lastName,
               email: values.email,
               phoneNumber: values.phone,
               role: [
                  {
                     company: clientInfo._id,
                     roles: roles.find((r) => r.name === roleName)._id,
                  },
               ],
            },
            additionalData: {
               admin: false,
               company: clientInfo._id,
               implementationUser: false,
            },
         },
         {
            onError: (error: any) => {
               error.response.data.message === "email already in use"
                  ? showSnackBar("Ya existe un usuario con ese correo", true)
                  : showSnackBar("Error al agregar usuario.", true);
            },
            onSuccess: async () => {
               showSnackBar("El usuario fue agregado correctamente.", false);
               await refetch();
               setCreateMode(false);
               setIsLoadingAdd(false);
            },
         }
      );
   };

   const handleSubmit = async () => {
      setIsLoadingAdd(true);
      try {
         if (selectedUser) {
            const data = {
               company: clientInfo._id,
               roles: [roles.find((r) => r.name === roleName)._id],
            };
            await addUserRoleOffice(data, selectedUser);
            await refetch();
            setCreateMode(false);
            showSnackBar("El usuario fue agregado correctamente", false);
         } else showSnackBar("Favor de seleccionar a un usuario existente", true);
      } catch (error) {
         showSnackBar("Error al agregar usuario.", true);
      }
      setIsLoadingAdd(false);
   };

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();
      useEffect(() => {
         if (selectedUser) {
            const allUsers = [...companyUsers];
            const userFound = allUsers.find((user) => user._id === selectedUser);
            setFieldValue("firstName", userFound.firstName);
            setFieldValue("lastName", userFound.lastName);
            setFieldValue("phone", userFound.phoneNumber);
            setFieldValue("email", userFound.email);
         } else if (!selectedUser) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
         }
         // eslint-disable-next-line
      }, [selectedUser]);
      return null;
   };

   const headCells: HeadCell[] = [
      { field: "firstName", headerName: "Nombre(s)", type: "string" },
      { field: "lastName", headerName: "Apellidos", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "phoneNumber", headerName: "Teléfono", type: "string" },
      { field: "delete", headerName: "Eliminar", type: "string" },
   ];

   return (
      <Box key={users.length || ""} sx={{ py: 2 }}>
         {!createMode ? (
            <>
               <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, pb: 1 }}>
                  <LoadingButton
                     isLoading={isLoadingUser}
                     label="Agregar"
                     sx={{ m: "0 !important" }}
                     onClick={() => setCreateMode(true)}
                  />
               </Box>
               <TableComponent
                  defaultColumnToOrder=""
                  defaultOrder="asc"
                  defaultRowsPerPage={3}
                  emptyDataText="No se han registrado coordinadores"
                  rowsPerPageOptions={[3]}
                  loader={isLoadingUser}
                  headers={headCells}
                  rows={users}
                  onClick={() => {}}
                  disableBorders
                  dense
               />
            </>
         ) : (
            !isLoadingUser && (
               <Stack spacing={2} sx={{ px: 2 }}>
                  {companyUsers?.length > 0 && (
                     <Stack spacing={2}>
                        <Typography>Seleccionar usuario existente:</Typography>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                           <Select size="small" sx={{ minWidth: 300 }} value={selectedUser}>
                              {companyUsers
                                 .filter((u) => !users.some((user) => user.id === u._id))
                                 .map((option, index) => (
                                    <MenuItem
                                       key={`user-${index}`}
                                       value={option._id}
                                       onClick={() => {
                                          setSelectedUser(option._id);
                                       }}
                                    >
                                       {`${option.firstName} ${option.lastName}`}
                                    </MenuItem>
                                 ))}
                           </Select>
                           {selectedUser && (
                              <IconButton
                                 onClick={() => {
                                    setSelectedUser("");
                                 }}
                              >
                                 <Clear sx={{ fontSize: 20 }} />
                              </IconButton>
                           )}
                        </Box>
                     </Stack>
                  )}
                  <Typography>{`Crear nuevo coordinador de ${
                     beneficiary ? "beneficiario controlador" : "gobierno corporativo"
                  }:`}</Typography>
                  <Formik
                     initialValues={{
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                     }}
                     validationSchema={createUserSchema}
                     onSubmit={selectedUser ? handleSubmit : handleSubmitCreate}
                  >
                     <Form>
                        <FormObserver />
                        <Box
                           sx={{
                              borderColor: "#E0E0E0",
                              display: "flex",
                              rowGap: 2,
                              flexDirection: "column",
                              maxHeight: "420px",
                              overflowY: "auto",
                           }}
                        >
                           <Box sx={{ display: "flex", columnGap: 2, my: 0.5 }}>
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="firstName"
                                 id="firstName"
                                 type="text"
                                 label="Nombre"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="lastName"
                                 id="lastName"
                                 type="text"
                                 label="Apellido"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="phone"
                                 id="phone"
                                 type="text"
                                 label="Teléfono"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                              <InputTextField
                                 variant="outlined"
                                 size="small"
                                 name="email"
                                 id="email"
                                 type="text"
                                 label="Correo electrónico"
                                 fullWidth={true}
                                 disabled={selectedUser ? true : false}
                              />
                           </Box>
                        </Box>
                        <Box
                           sx={{
                              mt: 2,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                           }}
                        >
                           <Button
                              variant={"outlined"}
                              onClick={() => {
                                 setCreateMode(false);
                                 setSelectedUser("");
                              }}
                           >
                              Cancelar
                           </Button>
                           <LoadingButton isLoading={isLoadingCreate || isLoadingAdd} label="Guardar" />
                        </Box>
                     </Form>
                  </Formik>
               </Stack>
            )
         )}
         <ConfirmationModal
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            generic
            onConfirm={() => {
               handleRemove();
            }}
            onCancel={() => setUserToDelete("")}
         />
      </Box>
   );
};

export default CompanyCoordinatorUserComponent;
