import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import ListComponent from "../../../../ListComponent";
import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getAffairsByIds } from "../../../../../lib/gobCorpBEClient";

const votesNull = (affair) => affair.votes === null || affair.votes === undefined || affair.votes.length === 0;

const sumPositiveNegativeVotes = (users) => {
   let positive = 0;
   let negative = 0;

   users?.forEach((user) => (user.vote ? (positive += user.numberOfVotes) : (negative += user.numberOfVotes)));

   return [positive, negative];
};

const isValidOldSesionQuorum = (totalVotes, quoromV, positiveVotes) => (positiveVotes / totalVotes) * 100 >= quoromV;

const isPositiveNumberHigher = (positive, negative) => positive > negative;

const getResolution = (selectedSession, totalVotes, quoromV, positiveVotes, negativesVotes) => {
   if (votesNull(selectedSession)) return "Votación inválida";
   if (!isValidOldSesionQuorum(totalVotes, quoromV, positiveVotes)) return "Votación inválida";
   return isPositiveNumberHigher(positiveVotes, negativesVotes) ? "Positiva" : "Negativa";
};

const getPercentage = (selectedSession, totalVotes, positiveVotes, quoromV, negativesVotes) => {
   if (votesNull(selectedSession)) return 0;
   if (!isValidOldSesionQuorum(totalVotes, quoromV, positiveVotes))
      return totalVotes === 0 ? 0 : ((positiveVotes / totalVotes) * 100).toFixed(0);
   return totalVotes === 0
      ? 0
      : isPositiveNumberHigher(positiveVotes, negativesVotes)
      ? ((positiveVotes / totalVotes) * 100).toFixed(0)
      : ((negativesVotes / totalVotes) * 100).toFixed(0);
};

const AssemblyList = ({ selectedSession, isFilteredAffairReady, setIsFilteredAffairReady }) => {
   const [filteredAffair, setFilteredAffair] = useState(null);

   const affairArray = [];

   const getAffairByArrayIds = async () => {
      const newValue = await getAffairsByIds(affairArray);
      return newValue;
   };

   const fetchAffairs = async () => {
      let filteredAffair = await getAffairByArrayIds();
      setFilteredAffair(filteredAffair);
      // setIsFilteredAffairReady(false);
   };

   useEffect(() => {
      fetchAffairs();
   }, [selectedSession]);

   // useEffect(() => {
   //    if (filteredAffair !== null) {
   //       setIsFilteredAffairReady(true);
   //    }
   // }, [filteredAffair]);

   let quoromV = 0;
   let quoromVIndex = 0;

   const filteredusers = selectedSession.votes?.filter((user) => user.numberOfVotes);

   selectedSession.proclamation !== "Primera" && (quoromVIndex = 1);

   selectedSession.categoriesByCompany?.forEach((category) => {
      category.title === selectedSession.categoryType && (quoromV = category.quorumV[quoromVIndex]);
   });

   const affairsList = {};
   if (selectedSession?.affairs) {
      selectedSession.affairs.forEach((affair, index) => {
         const affairVotes = selectedSession.votes?.filter((vote) => {
            return vote.affair.substring(2) === affair._id;
         });

         const involvedVotes = [];
         for (const involved of selectedSession.involved) {
            if (!involved) continue;
            affairVotes?.find((vote) => {
               return vote.user === involved._id;
            });

            involvedVotes.push({
               id: involved._id,
               name: involved.fullName,
               vote: affairVotes?.find((vote) => vote.user === involved._id)?.vote || null,
            });
         }

         const users = filteredusers?.filter((user) => user.affair[0] === `${index}`);

         const totalVotes = users?.reduce((acc, value) => acc + value.numberOfVotes, 0);

         let positiveVotes = sumPositiveNegativeVotes(users)[0];
         let negativesVotes = sumPositiveNegativeVotes(users)[1];

         affairsList[affair.title + " " + index] = {
            resolution: getResolution(selectedSession, totalVotes, quoromV, positiveVotes, negativesVotes),
            percentage: getPercentage(selectedSession, totalVotes, positiveVotes, quoromV, negativesVotes),
            involved: involvedVotes,
         };
      });
      setIsFilteredAffairReady(false);
   } else if (selectedSession?.newAffairs) {
      let newAffairsArray = [];
      for (let i = 0; i < selectedSession.newAffairs.length; i++) {
         const affair = selectedSession.newAffairs[i];
         affairArray.push(affair.affair.orderId.substring(2));
         const involvedVotes = [];
         for (const involved of selectedSession.involved) {
            if (!involved) continue;
            involvedVotes.push({
               id: involved._id,
               name: involved.fullName,
               vote: affair.users.find((user) => {
                  return user.user === involved._id && user?.answer;
               }),
            });
         }

         const answers: { [key: string]: number } = {};

         if (affair.answers) {
            for (let i = 0; i < affair.answers.length; i++) {
               const answer = affair.answers[i];
               answers[answer] = 0;
            }
         }

         if (affair.users) {
            for (let i = 0; i < affair.users.length; i++) {
               const user = affair.users[i];
               if (user.answer) {
                  answers[user.answer] += 1;
               }
            }
         }

         let mostVotedAnswer: string | null = null;
         let maxVotes = 0;
         let totalVotes = 0;

         if (answers) {
            totalVotes = Object.values(answers).reduce((sum, value) => sum + value, 0);
         }

         for (const [answer, count] of Object.entries(answers)) {
            if (count > maxVotes) {
               maxVotes = count;
               mostVotedAnswer = answer;
            }
         }

         const newTitle = filteredAffair?.find((e) => {
            let element1 = e._id;
            let orderId1 = affair.affair.orderId;

            if (element1[1] === "-") {
               element1 = element1.substring(2);
            }

            if (orderId1[1] === "-") {
               orderId1 = orderId1.substring(2);
            }

            return element1 === orderId1;
         });

         newAffairsArray.push(newTitle?.title);

         const resolutionPercentage = parseFloat(affair.resolutionPercentage);
         const isResolutionPercentageValid = !isNaN(resolutionPercentage);

         const isQuorumMet = isResolutionPercentageValid && resolutionPercentage >= quoromV;

         const maxVotesValid = !isNaN(maxVotes) && maxVotes !== 0;
         const totalVotesValid = !isNaN(totalVotes) && totalVotes !== 0;

         affairsList[newTitle?.title + " " + i] = {
            resolution:
               affair.resolutionPercentage === undefined
                  ? "Votación inválida"
                  : isQuorumMet
                  ? mostVotedAnswer
                  : "Votación inválida",
            percentage:
               affair.resolutionPercentage === undefined
                  ? 0
                  : isQuorumMet && maxVotesValid && totalVotesValid
                  ? ((maxVotes / totalVotes) * 100).toFixed(0)
                  : resolutionPercentage.toFixed(0),
            involved: involvedVotes,
         };
         selectedSession.newAffairs["newTitle"] = newAffairsArray;
      }
      setIsFilteredAffairReady(false);
   }

   const uniqueInvolvedIds = new Set<string>();

   const renderAffairs = (affairs, isNew = false) => {
      const affairsMap = affairs.map((affair, index) => {
         return {
            affair: isNew ? affairs?.newTitle[index] || "Sin título" : affair.title || "Sin título",
            description: isNew ? affair.affair.description : affair.description || "Sin descripción",
         };
      });
      return affairsMap;
   };
   // const renderAffairs = (affairs, isNew = false) =>
   //    affairs.map((affair, index) => (
   //       <AffairListItem
   //          key={
   //             affair.affair?.orderId + affair?.description + Math.random() ||
   //             affair.title + affair?.description + Math.random()
   //          }
   //          affair={affair}
   //          isNew={isNew}
   //          newTitle={(isNew && affairs?.newTitle[index]) || null}
   //       />
   //    ));

   const erraseIndex = (str) => {
      return str.slice(0, -2);
   };

   const [openedShareHolders, setOpenedShareHolders] = useState(false);
   const handleShareHolderList = () => {
      setOpenedShareHolders((s) => !s);
   };

   return isFilteredAffairReady ? (
      <Box width={"100%"} display={"flex"} justifyContent={"center"}>
         <CircularProgress size={24} />
      </Box>
   ) : (
      <Stack direction={"column"} spacing={2}>
         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ color: "#152c44", fontWeight: 700, fontSize: "18px", ml: "10px" }}>
               Detalles de la asamblea
            </Typography>
            <Typography sx={{ fontSize: "15px", mr: "20px" }}>No. escritura</Typography>
         </Box>
         <ListComponent
            headers={[
               { headerTitle: "Asunto tratado", type: "text", headerPosition: "left" },
               { headerTitle: "Descripción", type: "text", headerPosition: "left" },
            ]}
            rows={
               selectedSession?.affairs?.length > 0
                  ? renderAffairs(selectedSession.affairs)
                  : selectedSession?.newAffairs?.length > 0
                  ? renderAffairs(selectedSession.newAffairs, true)
                  : []
            }
            headerProps={() => {
               return {
                  bgcolor: "#e4e6e8",
                  mb: 0.25,
               };
            }}
            headerColumnProps={() => {
               return {
                  borderRight: 0.5,
                  borderColor: "lightGray",
               };
            }}
            headerTextProps={{
               fontSize: "13px",
               fontWeight: "bold",
               color: "#162c44",
               px: 1,
            }}
            rowProps={(_e, i) => {
               return {
                  borderLeft: 5,
                  borderBottomLeftRadius: 3,
                  borderBottom:
                     (selectedSession?.affairs?.length > 0
                        ? renderAffairs(selectedSession.affairs)
                        : selectedSession?.newAffairs?.length > 0
                        ? renderAffairs(selectedSession.newAffairs, true)
                        : []
                     ).length -
                        1 ===
                     i
                        ? 0.5
                        : 0,
                  borderTop: 0.5,
                  borderColor: "lightGray",
                  borderLeftColor: "#303b4b",
                  mb: 0.1,
               };
            }}
            rowsColumnProps={(_row) => {
               return {
                  borderRight: 0.5,
                  borderColor: "lightGray",
               };
            }}
            subComponentProps={() => {
               return {
                  p: 0.5,
                  fontSize: "13px",
               };
            }}
         />
         <Stack direction={"column"} display={"flex"}>
            <Stack bgcolor={"#D9D9D9"}>
               <Typography variant="caption" fontWeight={600} px={8} py={0.45} textAlign={"center"}>
                  Votaciones y deliberaciones
               </Typography>
            </Stack>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
               <Stack
                  display={"flex"}
                  borderRadius={1}
                  onClick={handleShareHolderList}
                  direction={"row"}
                  bgcolor={"#2d4357"}
                  color={"white"}
                  sx={{ ":hover": { cursor: "pointer" } }}
                  px={1}
                  mr={openedShareHolders ? 0 : "170px"}
                  width={openedShareHolders ? `${selectedSession.involved.length * 100}px` : "45px"}
                  maxWidth={"400px"}
                  marginY={-0.65}
                  zIndex={120}
               >
                  {openedShareHolders && (
                     <Typography fontSize={"14px"} width={"100%"} textAlign={"center"} my={0}>
                        Deliberación
                     </Typography>
                  )}
                  <Box
                     py={0.5}
                     sx={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: openedShareHolders ? "flex-end" : "center",
                        width: !openedShareHolders ? "100%" : "auto",
                     }}
                  >
                     <ArrowForwardIosIcon
                        sx={{
                           transform: `rotate(${openedShareHolders ? "180deg" : "0deg"})`,
                           fontSize: "14px",
                        }}
                     />
                  </Box>
               </Stack>
            </Box>
            <Box maxWidth={"705px"} sx={{ overflowx: "auto" }}>
               <ListComponent
                  maxColumns={6}
                  newStyles={{
                     bgcolorTitleRow: "#D9D9D9",
                     bgcolorTableRows: "white",
                     borderBottomTableRows: "2px solid #D9D9D9",
                     percentageDiv: {
                        bgcolor: "#868fa0",
                        pxPercentage: "8px",
                        borderRadiusPercentage: "6px",
                        colorPercentage: "white",
                        fontSizePercentage: "13px",
                     },
                  }}
                  headers={
                     openedShareHolders
                        ? [
                             { headerTitle: "Asunto", type: "text", bodyPosition: "center" },
                             ...Object.values(affairsList).flatMap((affair: any) => {
                                const involvedArray: any[] = [];
                                for (const involved of affair.involved) {
                                   if (!uniqueInvolvedIds.has(involved.id)) {
                                      uniqueInvolvedIds.add(involved.id);
                                      involvedArray.push({
                                         headerTitle: involved.name,
                                         headerPosition: "center",
                                         type: "text",
                                         bodyPosition: "center",
                                      });
                                   }
                                }
                                return involvedArray;
                             }),
                             {
                                headerTitle: "Porcentaje",
                                headerPosition: "center",
                                type: "percentage",
                                bodyPosition: "center",
                             },
                          ]
                        : [
                             { headerTitle: "Asunto", type: "text", bodyPosition: "center" },
                             { headerTitle: "Porcentaje", type: "percentage", bodyPosition: "center" },
                             { headerTitle: "Resolución", type: "positive", bodyPosition: "center" },
                          ]
                  }
                  headerTextProps={() => {
                     return {
                        textAlign: "center",
                        fontSize: "14px",
                     };
                  }}
                  rows={Object.keys(affairsList).map((affair) => {
                     if (!openedShareHolders)
                        return {
                           affairTitle: erraseIndex(affair),
                           percentage: affairsList[affair].percentage,
                           resolution: affairsList[affair].resolution,
                        };
                     const involvedList = {};
                     for (const involved of affairsList[affair].involved) {
                        if (involved.vote?.abstention) involvedList[involved._id] = "ABS";
                        involvedList[involved.id] = involved.vote?.answer || "PEN";
                     }
                     return {
                        affairTitle: erraseIndex(affair),
                        ...involvedList,
                        percentage: affairsList[affair].percentage,
                     };
                  })}
               />
            </Box>
         </Stack>
      </Stack>
   );
};

export default AssemblyList;
