import { GroupServiceUsers } from "../../../components/MiLecosy/ConsultiveGroup/GroupServiceUsers";
import { GroupServiceCompaniesTable } from "../../../components/MiLecosy/ConsultiveGroup/GroupServiceCompaniesTable";
import { ConsultiveGroupGoverningBodiesComponent } from "../../../components/MiLecosy/ConsultiveGroup/ConsultiveGroupGoverningBodiesComponent";

const governanceServiceId = "64e7851d978b71bd4f011ee4";

export const ConsultiveGroupScreen = () => {
   return (
      <>
         <ConsultiveGroupGoverningBodiesComponent />
         <GroupServiceUsers serviceCode={"GC"} />
         <GroupServiceCompaniesTable serviceId={governanceServiceId} />
      </>
   );
};
