import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from "@opentelemetry/core";
import { Resource } from "@opentelemetry/resources";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { BatchSpanProcessor } from "@opentelemetry/sdk-trace-base";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";

const environment =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "staging"
      : process.env.NODE_ENV === "production"
      ? "production"
      : "development";

const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx"
      : "http://localhost:8002";

const envBackends = {
   staging: [
      "https://test.server.lecosy.com.mx/",
      "https://test.server.lecosy.com.mx/gc",
      "https://test.server.lecosy.com.mx/cd",
   ],
   development: ["https://localhost:8002/", "https://localhost:8003/"],
   production: ["https://server.lecosy.com.mx/", "https://server.lecosy.com.mx/gc", "https://server.lecosy.com.mx/cd"],
};
const resourceSettings = new Resource({
   "service.name": "lecosy-externo-front-" + environment,
   "deployment.environment": environment,
});

const traceExporter = new OTLPTraceExporter({
   url: base_url + "/api/trace",
});

const provider = new WebTracerProvider({ resource: resourceSettings });

provider.addSpanProcessor(new BatchSpanProcessor(traceExporter));

provider.register({
   contextManager: new ZoneContextManager(),
   propagator: new CompositePropagator({
      propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
   }),
});

const startOtelInstrumentation = () => {
   registerInstrumentations({
      tracerProvider: provider,
      instrumentations: [
         getWebAutoInstrumentations({
            "@opentelemetry/instrumentation-xml-http-request": {
               enabled: true,
               clearTimingResources: true,
               propagateTraceHeaderCorsUrls: envBackends[environment].map((value) => new RegExp(value)),
            },
            "@opentelemetry/instrumentation-document-load": {
               enabled: true,
            },
            "@opentelemetry/instrumentation-user-interaction": {
               enabled: true,
            },
            "@opentelemetry/instrumentation-fetch": {
               propagateTraceHeaderCorsUrls: envBackends[environment].map((value) => new RegExp(value)),
               enabled: true,
            },
         }),
      ],
   });
};

export { startOtelInstrumentation };
