import { Box, Button, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { LocaleDateString, formatCurrency } from "../const/globalConst";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DisabledByDefault } from "@mui/icons-material";

export interface HeadersProps {
   headerPosition?: "left" | "right" | "center";
   bodyPosition?: "left" | "right" | "center";
   headerTitle: string;
   type:
      | "text"
      | "list"
      | "punctual"
      | "date"
      | "check"
      | "percentage"
      | "boolean"
      | "currency"
      | "number"
      | "checkIcon"
      | "positive";
}

interface ListComponentProps {
   title?: string;
   titleProps?: any;
   titleTextProps?: any;
   headers: HeadersProps[];
   headerProps?: any;
   headerColumnProps?: Function;
   headerTextProps?: any;
   rows: any[];
   rowsColumnProps?: Function;
   rowEndAndornment?: Function;
   footer?: (React.ReactNode | string)[];
   footerProps?: any;
   rowProps?: Function;
   subComponentProps?: Function;
   maxColumns?: number;
   spacing?: number;
   onClick?: (e, row, i) => void;
   disableEmptyList?: boolean;
   centerCheckIcon?: string;
   colorCheckIcon?: string;
   maxHeightList?: string;
   centerTypeText?: string;
   fontSizeTypeText?: string;
   newStyles?: any;
}
const ListComponent = (props: ListComponentProps) => {
   const {
      headers,
      rows,
      rowProps,
      headerProps,
      headerColumnProps,
      headerTextProps,
      subComponentProps,
      footer,
      footerProps,
      title,
      titleProps,
      titleTextProps,
      onClick,
      spacing,
      rowsColumnProps,
      disableEmptyList = false,
      centerCheckIcon,
      colorCheckIcon,
      maxHeightList,
      centerTypeText,
      newStyles,
   } = props;
   const keys = rows.length > 0 ? Object.keys(rows[0]) : [];

   const handleClick = (event: React.MouseEvent<unknown>, row: any, index: number) => {
      onClick && onClick(event, row, index);
   };

   return keys.length > 0 ? (
      <Stack direction={"column"} width={"100%"} height={maxHeightList} sx={{ overflowX: "auto", overflowY: "auto" }}>
         {title ? (
            <Box
               sx={[
                  titleProps,
                  {
                     minWidth:
                        headers.length > 10 || headers.length > props.maxColumns ? `${headers.length * 140}px` : "100%",
                  },
               ]}
            >
               <Typography sx={titleTextProps || { textAlign: "center" }}>{title}</Typography>
            </Box>
         ) : undefined}
         <Grid
            container
            spacing={spacing || 0}
            py={0.5}
            sx={[
               headerProps || defaultHeaderStyle,
               {
                  minWidth:
                     headers.length > 10 || headers.length > props.maxColumns ? `${headers.length * 140}px` : "100%",
               },
               {
                  bgcolor: newStyles && newStyles.bgcolorTitleRow,
               },
            ]}
         >
            {headers.length > 0 &&
               headers.map((header, i) => {
                  return (
                     <Grid
                        item
                        xs={12 / headers.length}
                        key={`${header.headerTitle} ${Math.random()}`}
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           color: newStyles && "#64748b",
                        }}
                     >
                        <Box
                           width={"100%"}
                           display={"flex"}
                           sx={[headerColumnProps?.(header, i), { justifyContent: header.headerPosition || "center" }]}
                        >
                           <Typography sx={headerTextProps || defaultHeaderTextProps}>{header.headerTitle}</Typography>
                        </Box>
                     </Grid>
                  );
               })}
         </Grid>
         <List
            sx={{
               maxHeight: maxHeightList ? maxHeightList : "300px",
               overflowY: "auto",
               // pt: 1, check if needs deletion
               minWidth:
                  headers.length > 10 || headers.length > props.maxColumns ? `${headers.length * 140}px` : "100%",
            }}
            disablePadding
            dense
         >
            {rows.map((row, i) => {
               const keys = Object.keys(row);
               return (
                  <ListItem
                     disablePadding
                     onClick={(e) => handleClick(e, row, i)}
                     sx={[
                        rowProps?.(row, i) || defaultRowStyle,
                        {
                           cursor: onClick ? "pointer" : "default",
                           "&:hover": onClick && { bgcolor: "whitesmoke" },
                           overflow: "auto",
                        },
                        {
                           bgcolor: newStyles && newStyles.bgcolorTableRows,
                           borderBottom: newStyles && newStyles.borderBottomTableRows,
                        },
                     ]}
                     key={`listC-row${i + 1}`}
                  >
                     <Grid container display={"flex"} spacing={spacing || 0}>
                        {headers.map((headerType, i) => {
                           const selectedKey = keys[i];
                           return (
                              <Grid
                                 item
                                 xs={12 / headers.length}
                                 display={"flex"}
                                 key={`${headerType.headerTitle} ${Math.random()}`}
                              >
                                 <Box
                                    width={"100%"}
                                    display={"flex"}
                                    sx={[
                                       rowsColumnProps?.(headerType, i),
                                       { justifyContent: headerType.bodyPosition || "left" },
                                    ]}
                                 >
                                    {componentSelector(
                                       row,
                                       selectedKey,
                                       headerType.type,
                                       subComponentProps,
                                       centerCheckIcon,
                                       colorCheckIcon,
                                       centerTypeText,
                                       newStyles
                                    )}
                                 </Box>
                              </Grid>
                           );
                        })}
                     </Grid>
                     {props.rowEndAndornment?.(row)}
                  </ListItem>
               );
            })}
         </List>
         {footer && (
            <Stack
               width={"100%"}
               px={2}
               py={1}
               sx={[
                  footerProps || defaultFooterStyle,
                  { minWidth: headers.length > 10 ? `${headers.length * 140}px` : "100%" },
               ]}
            >
               <Grid container>
                  {footer.map((foot, i) => {
                     return (
                        <Grid
                           item
                           xs={12 / headers.length}
                           display={"flex"}
                           justifyContent={"center"}
                           key={`footerC-${i + 1}`}
                        >
                           {foot}
                        </Grid>
                     );
                  })}
               </Grid>
            </Stack>
         )}
      </Stack>
   ) : (
      !disableEmptyList && (
         <Stack direction={"column"} spacing={1} display={"flex"} alignItems={"center"} color={"#C4C4C4"}>
            <InfoIcon sx={{ fontSize: "25px" }} />
            <Typography>No hay datos registrados</Typography>
         </Stack>
      )
   );
};

function componentSelector(
   row,
   rowKey,
   key,
   subComponentProps,
   centerCheckIcon = "",
   colorCheckIcon = "",
   centerTypeText = "",
   newStyles = {}
) {
   switch (key) {
      case "text":
         return (
            <PlainText
               text={row[rowKey]}
               subComponentProps={subComponentProps}
               centerTypeText={centerTypeText}
               stylesPlainText={newStyles}
            />
         );
      case "number":
         return <NumberComponent number={row[rowKey]} />;
      case "list":
         return <ItemList itemList={row[rowKey]} />;
      case "punctual":
         return <IsPunctual isPunctual={row[rowKey]} />;
      case "date":
         return <CompleteDate date={row[rowKey]} />;
      case "check":
         return (
            <CheckedBoxContent row={row[rowKey]} centerCheckIcon={centerCheckIcon} colorCheckIcon={colorCheckIcon} />
         );
      case "percentage":
         return <PercentageComponent percentage={row[rowKey]} stylesPercentage={newStyles} />;
      case "boolean":
         return <BooleanComponent boolean={row[rowKey]} />;
      case "currency":
         return <CurrencyComponent currency={row[rowKey]} />;
      case "checkIcon":
         return <CheckIconComponent boolean={row[rowKey]} />;
      case "positive":
         return <PositiveComponent boolean={row[rowKey]} />;
      default:
         return (
            <PlainText
               text={row[rowKey]}
               subComponentProps={subComponentProps}
               centerTypeText={centerTypeText}
               stylesPlainText={newStyles}
            />
         );
   }
}

//#region ComponentTypes

const PlainText = ({ text, subComponentProps, centerTypeText, stylesPlainText }) => {
   const defaultStyle = {
      variant: "subtitle1",
      lineHeight: 1.2,
      alignSelf: "center",
      wordWrap: "break-word",
      maxWidth: "100%",
      fontSize: "",
      textAlign: centerTypeText && "center",
      ml: stylesPlainText.mlAffairs,
      color: centerTypeText && "center",
   };

   return <Typography sx={subComponentProps || defaultStyle}>{text}</Typography>;
};

const NumberComponent = ({ number }) => (
   <Typography variant="subtitle1" lineHeight={1.2} alignSelf={"center"}>
      {formatCurrency(number)}
   </Typography>
);

const ItemList = ({ itemList }) => {
   const [showAllList, setShowAllList] = useState<boolean>(false);
   const handleShowList = () => setShowAllList((s) => !s);
   return (
      <Box display={"flex"} flexWrap={"wrap"} columnGap={1} rowGap={1}>
         {itemList.length > 0 &&
            itemList.map((item, i) => {
               if (i > 1 && !showAllList) return;
               if (Object.keys(item).length === 1) {
                  const key = Object.keys(item)[0];
                  return (
                     <Typography variant="button" bgcolor={"#D8D8D8"} px={0.5} alignSelf={"center"} key={item[key]}>
                        {item[key]}
                     </Typography>
                  );
               }
               return (
                  <Typography variant="button" bgcolor={"#D8D8D8"} px={0.5} alignSelf={"center"} key={item}>
                     {item}
                  </Typography>
               );
            })}
         {itemList.length > 2 ? (
            <Button onClick={handleShowList} sx={{ bgcolor: "#EFEFEF" }}>
               <Typography>{showAllList ? "Ver menos" : "Ver más"}</Typography>
            </Button>
         ) : undefined}
      </Box>
   );
};

const IsPunctual = ({ isPunctual }) => {
   return (
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
         <Typography bgcolor={isPunctual ? "#C8F2C4" : "#F08E8E"} px={1}>
            {isPunctual ? "Puntualmente" : "Impuntual"}
         </Typography>
      </Box>
   );
};

const CompleteDate = ({ date }) => <Typography alignSelf={"center"}>{LocaleDateString(date)}</Typography>;

const CheckedBoxContent = ({ row, centerCheckIcon, colorCheckIcon }) => {
   let booleanOption = false;
   let descriptionOption = "";
   if (row) {
      const keys = Object.keys(row);
      for (const option of keys) {
         if (typeof row[option] === "boolean") booleanOption = row[option];
         if (typeof row[option] === "string") descriptionOption = row[option];
      }
   }
   return (
      <Stack display={"flex"} width={"100%"} direction={"row"} spacing={0.75} justifyContent={centerCheckIcon}>
         {booleanOption ? (
            <CheckBoxIcon sx={{ color: colorCheckIcon !== "" ? colorCheckIcon : "#5E77F9" }} />
         ) : (
            <DisabledByDefault sx={{ color: colorCheckIcon !== "" ? colorCheckIcon : "#CECECE" }} />
         )}
         <Typography alignSelf={"center"}>{descriptionOption}</Typography>
      </Stack>
   );
};

interface PercentageComponentProps {
   percentage: number;
   stylesPercentage: any;
}

const PercentageComponent: React.FC<PercentageComponentProps> = ({ percentage, stylesPercentage }) => (
   <Typography
      fontWeight={600}
      alignSelf={"center"}
      bgcolor={stylesPercentage?.percentageDiv && stylesPercentage?.percentageDiv.bgcolor}
      px={stylesPercentage?.percentageDiv && stylesPercentage?.percentageDiv.pxPercentage}
      borderRadius={stylesPercentage?.percentageDiv && stylesPercentage?.percentageDiv.borderRadiusPercentage}
      color={stylesPercentage?.percentageDiv && stylesPercentage?.percentageDiv.colorPercentage}
      fontSize={stylesPercentage?.percentageDiv && stylesPercentage?.percentageDiv.fontSizePercentage}
   >{`${percentage}%`}</Typography>
);

const BooleanComponent = ({ boolean }) => (
   <Typography fontWeight={600} alignSelf={"center"}>
      {boolean ? "V" : "F"}
   </Typography>
);

const CurrencyComponent = ({ currency }) => (
   <Stack direction={"row"}>
      <Typography alignSelf={"center"}>{`$${formatCurrency(currency)}`}</Typography>
   </Stack>
);

const CheckIconComponent = ({ boolean }) => (boolean && <CheckIcon sx={{ alignSelf: "center" }} />) || undefined;

const PositiveComponent = ({ boolean }) => (
   <Typography
      fontWeight={600}
      alignSelf={"center"}
      sx={{ borderRadius: 1, bgcolor: "#7d859275" }}
      fontSize={"15px"}
      px={1}
   >
      {typeof boolean === "boolean" ? (boolean ? "Positiva" : "Negativa") : boolean}
   </Typography>
);

//#endregion

const defaultHeaderStyle = {
   px: 3,
   py: 0.5,
   // bgcolor: "#D9D9D9",
   borderRadius: 1,
};

const defaultHeaderTextProps = {
   fontSize: "14px",
   fontWeight: 500,
};

const defaultRowStyle = {
   bgcolor: "#F5F5F5",
   py: 1,
   my: 0.5,
};

const defaultFooterStyle = { borderBottomLeftRadius: 10, borderBottomRightRadius: 10, bgcolor: "#E8E8E8" };
export default ListComponent;
