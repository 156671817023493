import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { HeadCell, TableComponent } from "../../TableComponent";
import { getMemberChargesByCompany } from "../../../lib/gobCorpBEClient";
import rolesByService from "../../../const/rolesByService.json";
import { Box, Button, Stack, Typography } from "@mui/material";
import { getGroupUsers } from "../../../lib/usersBEClient";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { useNavigate } from "react-router-dom";
import { AddCircle } from "@mui/icons-material";
import AddGroupUserToService from "./AddGroupUser";

const usersColumns: HeadCell[] = [
   { field: "username", headerName: "Nombre", type: "string" },
   { field: "email", headerName: "Correo electrónico", type: "string" },
   { field: "phone", headerName: "Teléfono ", type: "string" },
   { field: "roles", headerName: "Rol", type: "popover" },
];

export const GroupServiceUsers = (props: { serviceCode: string; disableBorders?: boolean }) => {
   const { serviceCode, disableBorders } = props;
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const { user, groupSelected } = useContext(UserContext);
   const { seed } = useContext(CorporateDataContext);
   const navigate = useNavigate();

   const getGroupRoles = (roles: any[]) => {
      const rolesArray = roles.flatMap((role) =>
         role.roles.filter((r) => rolesByService[serviceCode].includes(r.name)).map((r) => r.name)
      );
      return Array.from(new Set(rolesArray));
   };

   const fetchUserData = useCallback(async () => {
      setIsLoading(true);
      const groupId = groupSelected._id;
      const groupUsers = await getGroupUsers(groupId);
      const filteredGroupUsers = groupUsers.filter((userG) => userG?.role?.some((role) => role?.group === groupId));
      const usersData = filteredGroupUsers.map((user) => ({
         _id: user._id,
         uid: user.uid,
         username: user.firstName + " " + user.lastName,
         firstName: user.firstName,
         lastName: user.lastName,
         phone: user.phoneNumber,
         email: user.email,
         roles: getGroupRoles(user.role),
      }));
      const gcUserIds = usersData
         .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
         .map((user) => user._id);

      if (gcUserIds.length > 0) {
         const chargesResponses = await getMemberChargesByCompany(gcUserIds, groupId);
         const GcChargesResponse = chargesResponses;
         for (const user of usersData) {
            const index = GcChargesResponse.findIndex((c) => c.user === user._id);
            if (index >= 0) {
               user.roles = user.roles.concat(GcChargesResponse[index].charges);
               user.roles = user.roles.filter((r) => !rolesByService.GC.includes(r));
            }
         }
      }
      const finalUserFilter = usersData.filter(
         (a) => a._id !== user.id && !a.roles.includes("Usuario de implementación") && a.roles.length > 0
      );
      setUserArray(finalUserFilter);
      setIsLoading(false);
   }, [seed, groupSelected]);

   useEffect(() => {
      fetchUserData();
   }, [fetchUserData]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: disableBorders ? 0 : 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
               Usuarios del módulo
            </Typography>
            {/* <Button variant="contained" sx={{ borderRadius: 1, px: 3 }} onClick={() => setOpenModal(true)}>
               <AddCircle sx={{ mr: 1 }} />
               Agregar usuario
            </Button> */}
         </Stack>
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={(_e, row) => {
               const userId = row.uid;
               const screen = "mi-lecosy/usuarios/0/" + userId;
               return navigate(`/${screen}`);
            }}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
         {openModal && <AddGroupUserToService state={openModal} setState={setOpenModal} />}
      </Box>
   );
};
