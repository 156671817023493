import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useContext, useMemo } from "react";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { getContrastYIQ } from "../../../../const/globalConst";
import { useFormikContext } from "formik";
import { UserContext } from "../../../../context/userContext";
import { ArrowDownward, Check, Clear } from "@mui/icons-material";

export const BillComment = () => {
   const { hasPermissions, colors, setReceivingChanges, receivingChanges, membersWithCharge, valuesFromBill } =
      useContext(GovernanceSessionContext);
   const { user } = useContext(UserContext);
   const { setFieldValue } = useFormikContext();

   const userCharge = useMemo(() => {
      if (!membersWithCharge) return [];
      if (membersWithCharge.length === 0) return [];
      const userCharges = membersWithCharge.find((member) => member._id === user.id);
      if (!userCharges?.memberCharge) return [];
      let charge = [];
      if (typeof userCharges?.memberCharge === "string") return [userCharges.memberCharge];
      for (const userCharge of userCharges.memberCharge) {
         if (userCharge.toLowerCase().includes("secretario")) charge.push("secretario");
         else if (userCharge.toLowerCase().includes("vicepresidente")) charge.push("vicepresidente");
         else if (userCharge.toLowerCase().includes("presidente")) charge.push("presidente");
         else if (userCharge.toLowerCase().includes("coordinador")) charge.push("coordinador");
         else charge.push(userCharge);
      }
      return charge;
   }, [membersWithCharge]);

   const handleSubmitComment = () => {
      const value: string = valuesFromBill["commentTemp"];
      if (!value.trim()) return;
      setFieldValue("comment", [...valuesFromBill["comment"], value]);
      setFieldValue("commentTemp", "");
   };

   const handleDeleteComment = (text: string, company?: string) => {
      const newArray = valuesFromBill["comment"].filter((string) => string !== text);
      setFieldValue("comment", newArray);
   };
   if (!colors) return;
   return (
      <>
         <Stack>
            <Typography
               sx={{
                  bgcolor: colors.primary,
                  textAlign: "center",
                  fontSize: 12,
                  color: getContrastYIQ(colors.primary),
               }}
            >
               COMENTARIOS CLAVE DEL PRESIDENTE / VICEPRESIDENTE
            </Typography>
            <Box display={"flex"} width={"100%"}>
               <InputTextField
                  id={"commentTemp"}
                  name={"commentTemp"}
                  type={"text"}
                  multiline
                  sx={{
                     ".Mui-disabled": { "-webkit-text-fill-color": "black !important" },
                     bgcolor: "#EFEFEF",
                     width: "100%",
                     fontSize: 12,
                     p: 2,
                  }}
                  unstyled
                  placeholder="Descripción..."
                  disabled={
                     !hasPermissions || !userCharge.some((charge) => charge.toLowerCase().includes("presidente"))
                  }
                  onChange={(e) => {
                     if (receivingChanges) setReceivingChanges(false);
                     setFieldValue("commentTemp", e.target.value);
                  }}
               />
               {userCharge.some((charge) => charge.toLowerCase().includes("presidente")) && (
                  <Stack sx={{ justifyContent: "center", bgcolor: "#EFEFEF" }}>
                     <IconButton onClick={handleSubmitComment}>
                        <Check sx={{ fontSize: 18 }} />
                     </IconButton>
                  </Stack>
               )}
            </Box>
         </Stack>
         <Stack spacing={0.5}>
            <Accordion disableGutters elevation={0}>
               <AccordionSummary
                  sx={{
                     bgcolor: colors.primary,
                     height: "22px",
                     minHeight: "22px !important",
                  }}
                  expandIcon={<ArrowDownward fontSize="small" sx={{ color: getContrastYIQ(colors.secondary) }} />}
               >
                  <Typography
                     sx={{
                        width: "100%",
                        color: getContrastYIQ(colors.primary),
                        textAlign: "center",
                        fontSize: 12,
                     }}
                  >
                     COMENTARIOS
                  </Typography>
               </AccordionSummary>
               <AccordionDetails sx={{ p: 0 }}>
                  <Stack spacing={0.5}>
                     <Stack
                        spacing={0.5}
                        sx={{
                           flex: 1,
                           justifyContent: "center",
                           borderLeft: `5px solid ${colors.primary}`,
                           bgcolor: "#EFEFEF",
                           p: 2,
                        }}
                     >
                        {valuesFromBill[`comment`]?.map((comment, i) => (
                           <Box
                              key={`general-comment-${i}`}
                              sx={{
                                 display: "flex",
                                 width: "100%",
                                 justifyContent: "space-between",
                                 "&:hover .iconButton": { display: "inherit" },
                              }}
                           >
                              <Typography sx={{ fontSize: 12 }}>{`${i + 1}. ${comment}`}</Typography>
                              {userCharge.some((charge) => charge.toLowerCase().includes("presidente")) && (
                                 <Stack sx={{ justifyContent: "center", bgcolor: "#EFEFEF" }}>
                                    <IconButton
                                       onClick={() => handleDeleteComment(comment)}
                                       sx={{
                                          p: 0,
                                          display: "none",
                                       }}
                                       className="iconButton"
                                    >
                                       <Clear sx={{ fontSize: 14 }} />
                                    </IconButton>
                                 </Stack>
                              )}
                           </Box>
                        ))}
                     </Stack>
                  </Stack>
               </AccordionDetails>
            </Accordion>
         </Stack>
      </>
   );
};
