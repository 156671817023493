import { useContext, useState } from "react";
import { Box, Grid, Typography, Divider, Popover, Button, CircularProgress } from "@mui/material";
import { UserContext } from "../../context/userContext";
import { getDateFromISODate } from "../../const/globalConst";
import { SupportContext } from "../../context/supportContext";
import { Info } from "@mui/icons-material";

export const InvoiceClientInfoComponent = ({ invoiceData }) => {
   const { groupSelected } = useContext(UserContext);
   const { setSupportOpen, setUpdateData, supportOpen } = useContext(SupportContext);
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const open = Boolean(anchorEl);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };

   return (
      <>
         <Box
            display="flex"
            justifyContent={"center"}
            p={1}
            alignItems={"center"}
            sx={{ bgcolor: "white", boxShadow: 2 }}
         >
            {invoiceData ? (
               <>
                  <Box m={1} display={"flex"} flexDirection={"column"}>
                     <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>RFC:</Typography>
                           <Typography>{invoiceData?.rfc ?? "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Denominación o razón social:</Typography>
                           <Typography>{invoiceData?.denomination ?? "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Avenida o calle y número:</Typography>
                           <Typography>{invoiceData?.address ?? "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Colonia:</Typography>
                           <Typography>{invoiceData.suburb ?? "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Correo electrónico:</Typography>
                           {invoiceData.email ? (
                              invoiceData.email.length > 25 ? (
                                 <>
                                    <Typography
                                       noWrap
                                       aria-owns={open ? "mouse-over-popover" : undefined}
                                       aria-haspopup="true"
                                       onMouseEnter={handlePopoverOpen}
                                       onMouseLeave={handlePopoverClose}
                                    >
                                       {invoiceData.email ?? "Sin datos"}
                                    </Typography>
                                    <Popover
                                       id="mouse-over-popover"
                                       sx={{
                                          pointerEvents: "none",
                                       }}
                                       open={open}
                                       anchorEl={anchorEl}
                                       anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                       }}
                                       transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                       }}
                                       onClose={handlePopoverClose}
                                       disableRestoreFocus
                                    >
                                       <Typography sx={{ p: 1 }}>{invoiceData.email}</Typography>
                                    </Popover>
                                 </>
                              ) : (
                                 <Typography>{invoiceData?.email}</Typography>
                              )
                           ) : (
                              <Typography noWrap>No cuenta con correo</Typography>
                           )}
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Teléfono:</Typography>
                           <Typography>{invoiceData?.phone ?? "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Código postal:</Typography>
                           <Typography>{invoiceData.zipCode ?? "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Régimen fiscal:</Typography>
                           <Typography>{invoiceData.regime ?? "No contiene datos"}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                           <Typography fontWeight={600}>Monto a facturar:</Typography>
                           <Typography>
                              {invoiceData.amount ? `$${invoiceData.amount} antes de iva` : "Sin datos"}
                           </Typography>
                        </Grid>
                     </Grid>
                     <Divider sx={{ my: 2 }} />
                     <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontSize={14}>
                           Última modificación:{" "}
                           {invoiceData.updatedAt ? getDateFromISODate(invoiceData.updatedAt) : null}
                        </Typography>
                        <Button
                           variant={"contained"}
                           onClick={() => {
                              setSupportOpen(!supportOpen);
                              setUpdateData(true);
                           }}
                        >
                           Actualizar información
                        </Button>
                     </Box>
                  </Box>
               </>
            ) : !invoiceData && !groupSelected.invoiceCompany ? (
               <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                  <Info sx={{ fontSize: 60, color: "gray" }} />
                  <Typography sx={{ color: "gray" }}>Facturación generada por empresa</Typography>
               </Box>
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 2 }}>
                  <CircularProgress />
               </Box>
            )}
         </Box>
      </>
   );
};
