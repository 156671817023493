import { Box, CircularProgress, Stack } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { BillCommittee } from "./SubComponent/BillCommittee";
import { BillHeader } from "./SubComponent/BillHeader";
import { BillCommitments } from "./SubComponent/BillCommitments";
import { BillSigns } from "./SubComponent/BillSigns";
import { BillOtherSubjects } from "./SubComponent/BillOtherSubjects";
import { BillNotes } from "./SubComponent/BillNotes";
import { BillComment } from "./SubComponent/BillComment";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { ShareholderCommittee } from "./SubComponentShareholder/ShareholderCommittee";
import { ShareholderDeliberations } from "./SubComponentShareholder/ShareholderDeliberations";
import { ShareholderUrlRecording } from "./SubComponentShareholder/ShareholderUrlRecording";
import { UserContext } from "../../../context/userContext";
import _ from "lodash";
import { BillOrderDay } from "./SubComponent/BillOrderDay";
import { UpdateUserAttendance } from "../../../lib/gobCorpBEClient";
import { ShareholderResolutions } from "./SubComponentShareholder/ShareholderResolutions";
import { BillGroupNotes } from "./SubComponent/BillGroupNotes";
import { BillGroupComment } from "./SubComponent/BillGroupComment";

const GetBillData = () => {
   const {
      socket,
      state,
      dispatch,
      session,
      setValuesFromBill,
      membersWithCharge,
      companyLogo,
      valuesFromBill,
      affairsArray,
      colors,
      fileArray,
      affairsStatus,
      externalMemberUsers,
      setInitialValues,
      initialValues: initialValuesToExternal,
      governingBody,
      quorum,
      receivingChanges,
      setReceivingChanges,
      setAdditionalVotesSeed,
      groupCompaniesInSession,
      userOnlineSigns,
      isPendingVote,
   } = useContext(GovernanceSessionContext);
   const { user } = useContext(UserContext);
   const [firstJoin, setFirstJoin] = useState(true);

   const FormObserver: React.FC = () => {
      const { values, setFieldValue, setValues } = useFormikContext();
      const [userTyping, setUserTyping] = useState("");

      useEffect(() => {
         if (socket === null && user) return;
         else if (user.id.length === 0) return;
         else if (firstJoin) {
            setFirstJoin(false);
            setValuesFromBill(valuesFromBill);
            return;
         } else if (userTyping !== user.id && userTyping !== "") return setUserTyping(user.id);
         else if (receivingChanges) return;
         else if (values[`officialID${user.id}`])
            socket.emit("send-changes", { values, sessionId: session._id, userTypingSocket: user.id });
         setValuesFromBill(values);
      }, [values]);

      useEffect(() => {
         if (socket === null) return;
         const handler = async ({ values: valuesFromSocket, userTypingSocket }) => {
            setReceivingChanges(true);
            setUserTyping(userTypingSocket);
            const valuesFromSocketKeys = Object.keys(valuesFromSocket);
            valuesFromSocketKeys.forEach((key) => {
               if (Array.isArray(values[key]) && Array.isArray(valuesFromSocket[key])) {
                  if (values[key].length !== valuesFromSocket[key].length) {
                     setFieldValue(key, valuesFromSocket[key]);
                  }
               } else if (valuesFromSocket[key] !== values[key] || values[key] === "") {
                  if (!key.includes("sign")) {
                     setFieldValue(key, valuesFromSocket[key]);
                     setValuesFromBill(valuesFromSocket);
                  }
               }
            });
            if (!_.isEqual(valuesFromSocket, values)) {
               const valuesChanged = _.reduce(
                  valuesFromSocket,
                  function (result, value, key) {
                     return _.isEqual(value, values[key]) ? result : result.concat(key);
                  },
                  []
               );
               if (valuesChanged[0]?.includes("officialID")) {
                  setFieldValue(valuesChanged[0], true);
                  setValuesFromBill(valuesFromSocket);
               } else {
                  setValues(valuesFromSocket);
                  setValuesFromBill(valuesFromSocket);
               }
            }
         };

         const handlerSignForm = (valuesFromSocket) => {
            if (valuesFromSocket.access) {
               if (!values["sign" + valuesFromSocket.userId]) {
                  setFieldValue("sign" + valuesFromSocket.userId, true);
               }
            }
         };

         const handleAlreadyJoinExternal = async (values) => {
            socket.emit("send-values-to-external", {
               ...values,
               membersWithCharge: membersWithCharge,
               companyLogo: companyLogo,
               valuesFromBill: valuesFromBill,
               affairsArray: affairsArray,
               colors: colors,
               fileArray: fileArray,
               affairsStatus: affairsStatus,
               additionalVotes: state.additionalVotes,
               affairVotations: state.affairVotations,
               externalMemberUsers: externalMemberUsers,
               initialValues: initialValuesToExternal,
               groupCompaniesInSession: groupCompaniesInSession,
               userOnlineSigns: userOnlineSigns,
               governingBody: {
                  series: governingBody.series,
                  title: governingBody.title,
                  structure: governingBody.structure,
                  totalCompanyCapital: governingBody.totalCompanyCapital,
                  totalCompanyCapitalBySerie: governingBody.totalCompanyCapitalBySerie,
                  users: governingBody.users,
               },
               quorum: quorum,
            });
            setFieldValue("officialID" + values.userId, true);
            await UpdateUserAttendance(session._id, values.userId);
         };
         const handleSetChanges = (valuesFromSocket) => {
            let { values, users } = valuesFromSocket;
            const userFromSocket = Object.values(users).find((userId) => userId === user.id);
            const chargeName = membersWithCharge?.find((member) => member._id === userFromSocket)?.memberCharge;
            if (values) {
               values["officialID" + userFromSocket] = true;
               setValues(values);
            } else {
               if (
                  chargeName?.some(
                     (charge) =>
                        charge.toLowerCase().includes("secretario") ||
                        charge.toLowerCase().includes("presidente") ||
                        charge.toLowerCase().includes("coordinador")
                  )
               ) {
                  setFieldValue("officialID" + userFromSocket, true);
               }
            }
         };

         const handleRequestVerified = async (valuesFromSocket) => {
            if (!valuesFromSocket.access) return;
            setFieldValue("officialID" + valuesFromSocket.userId, true);
            await UpdateUserAttendance(session._id, valuesFromSocket.userId);
         };

         const handleUpdateAdditionalVotes = (valuesFromSocket) => {
            dispatch({
               type: "updateSpecificVote",
               votationId: valuesFromSocket.votationId,
               voteInfo: valuesFromSocket.voteInfo,
            });
            setAdditionalVotesSeed((s) => s + 1);
            if (isPendingVote) dispatch({ type: "deleteActiveVote" });
         };

         const handleUpdateAffariVotes = (valuesFromSocket) => {
            dispatch({
               type: "voteUserInAffairVotation",
               orderInfo: valuesFromSocket.orderInfo,
               ...(valuesFromSocket.company && { company: valuesFromSocket.company }),
            });
         };

         const handleAddSigners = (valuesFormSocket) => {
            const tempBill = valuesFromBill;
            setFieldValue("document_id", valuesFormSocket.data.document_id);
            tempBill["document_id"] = valuesFormSocket.data.document_id;
            for (const signer of valuesFormSocket.data.signers) {
               setFieldValue(`${signer.userId}-signerId`, signer.signerId);
               tempBill[`${signer.userId}-signerId`] = signer.signerId;
            }
            setValuesFromBill(tempBill);
         };

         socket.on("receive-changes", handler);

         socket.on("add-signers", handleAddSigners);

         socket.on("before-verify-sign", handlerSignForm);

         socket.on("user-connected", handleSetChanges);

         socket.on("already-join-external", handleAlreadyJoinExternal);

         socket.on("request-verified", handleRequestVerified);

         socket.on("receive-additional-votes-changes", handleUpdateAdditionalVotes);

         socket.on("receive-affair-votes-changes", handleUpdateAffariVotes);

         return () => {
            socket.off("receive-changes", handler);

            socket.off("receive-additional-votes-changes", handleUpdateAdditionalVotes);

            socket.off("receive-affair-votes-changes", handleUpdateAffariVotes);

            socket.off("before-verify-sign", handlerSignForm);

            socket.off("already-join-external", handleAlreadyJoinExternal);

            socket.off("request-verified", handleRequestVerified);

            socket.off("user-connected", handleSetChanges);
         };
      }, [values, socket, valuesFromBill]);

      return null;
   };

   useEffect(() => {
      const usersThatNeedToSign =
         membersWithCharge?.filter(
            (user) => user.firstName && !user.memberCharge.includes("Usuario de implementacion")
         ) || [];

      let initialValues = {
         activity: "",
         limitDate: "",
         responsableName: [],
         commitmentCompanies: [],
         meetingUrl: false,
         notes: "",
         comment: [],
         commitmentsArray: [],
      };

      if (session.group) {
         groupCompaniesInSession?.forEach((company: any) => {
            initialValues = { ...initialValues, ["comment" + company._id]: [], ["notes" + company._id]: [] };
         });
      }
      usersThatNeedToSign.forEach((user: any) => {
         if (session.assembly) initialValues = { ...initialValues, ["officialID" + user._id]: false };
         initialValues = { ...initialValues, ["sign" + user._id]: false };
      });

      session.externs.forEach((user: any) => {
         if (session.assembly) initialValues = { ...initialValues, ["officialID" + user.user]: false };
         initialValues = { ...initialValues, ["sign" + user.user]: false };
      });

      const orderList = {};
      session.order.forEach((order) => {
         if (!orderList[order.affair.replaceAll(" ", "-").replaceAll(".", "_")])
            orderList[order.affair.replaceAll(" ", "-").replaceAll(".", "_")] = [];
         initialValues = {
            ...initialValues,
            ["orderDay-" +
            orderList[order.affair.replaceAll(" ", "-").replaceAll(".", "_")].length +
            "-" +
            order.affair.replaceAll(" ", "-").replaceAll(".", "_")]: false,
         };
         orderList[order.affair.replaceAll(" ", "-").replaceAll(".", "_")].push(order);
      });
      setValuesFromBill(initialValues);
      setInitialValues(initialValues);
   }, []);

   return { FormObserver, initialValuesToExternal };
};

export const Bill = () => {
   const { session } = useContext(GovernanceSessionContext);
   const { FormObserver } = GetBillData();
   const { isLoadingBill, initialValues, colors } = useContext(GovernanceSessionContext);

   if (isLoadingBill || !initialValues || !colors) return <CircularProgress />;
   return (
      <Box
         sx={{
            width: "50%",
            border: 1,
            borderColor: "#F0f0f0",
            bgcolor: "white",
            height: 880,
            overflowY: session.completed === false ? "hidden" : "auto",
         }}
      >
         <Formik initialValues={initialValues} onSubmit={() => {}}>
            <Form>
               <Stack sx={{ py: 2, px: 4, gap: 2, overflowY: session.completed === false ? "hidden" : "auto" }}>
                  <FormObserver />
                  <BillHeader />
                  {session.assembly ? (
                     <>
                        <ShareholderCommittee />
                        <BillOrderDay />
                        <ShareholderDeliberations />
                        <ShareholderResolutions />
                        <BillCommitments />
                        <BillOtherSubjects />
                        <BillSigns />
                        <BillNotes />
                        <BillComment />
                        <ShareholderUrlRecording />
                     </>
                  ) : (
                     <>
                        <BillCommittee />
                        <BillOrderDay />
                        <ShareholderDeliberations />
                        {!session.group && <ShareholderResolutions />}
                        <BillCommitments />
                        <BillSigns />
                        <BillOtherSubjects />
                        {session.group ? <BillGroupNotes /> : <BillNotes />}
                        {session.group ? <BillGroupComment /> : <BillComment />}
                        <ShareholderUrlRecording />
                     </>
                  )}
               </Stack>
            </Form>
         </Formik>
      </Box>
   );
};
