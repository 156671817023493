import React from "react";
import { CustomModalComponent } from "../CustomModalComponent";
import { Box } from "@mui/material";
import BeneficiaryForm from "../Gobierno corporativo/CreateProfile/SubComponent/BeneficiaryForm";

const BeneficiaryRequestsModal = (props: { open: boolean; setOpen: Function }) => {
   const { open, setOpen } = props;

   return (
      <CustomModalComponent
         title="Actualización de información de Beneficiario controlador"
         setOpen={setOpen}
         open={open}
      >
         <Box sx={{ width: 700 }}>
            <BeneficiaryForm />
         </Box>
      </CustomModalComponent>
   );
};

export default BeneficiaryRequestsModal;
