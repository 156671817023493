import {
   Button,
   CircularProgress,
   FormControl,
   FormControlLabel,
   Grid,
   ListItemText,
   MenuItem,
   Radio,
   RadioGroup,
   Stack,
   Tab,
   Tabs,
   TextField,
   Typography,
} from "@mui/material";
import React, { forwardRef, useContext, useState } from "react";
import { InputTextField } from "../Inputs/InputTextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { AttachFile } from "@mui/icons-material";
import { FileSelectedCollapse } from "../MiLecosy/ModalsGovernanceModule/ControllingBeneficiaryModal";
import { InputSelectField } from "../Inputs/InputSelectField";
import { Form, Formik } from "formik";
import { HeaderBlue } from "../MiLecosy/ModalsGovernanceModule/headersComponent";
import { SnackBarContext } from "../../context/snackBarContext";

enum FieldTypes {
   TEXT = "text",
   DATE = "date",
   SELECT = "select",
   CHECK = "check",
   FILE = "file",
}

interface FormStructureComponentProps {
   tabs?: boolean;
   data?: any; //Object of values from db
   dataSecond?: any; //Another object of values from db
   fieldsFormat: {
      section?: string; //Section name
      fields: FieldProps[];
      subSection?: {
         subSectionDepends: string; //Only shows when this field is not empty
         if: string[]; //Only shows when the value of the above field is one of the values in the array
         title: string; //Title to show in the section
         subFields: FieldProps[];
      };
   }[];
   maxWidth?: number | string;
   minHeight?: number | string;
   minWidth?: number | string;
   maxHeight?: number | string;
   onSubmit: (values) => void;
   isLoadingSubmit: boolean;
   disableEdit?: boolean;
   ref?: any;
}

interface FieldProps {
   depends?: string; //Only shows when this field is not empty
   if?: string[]; //Only shows when the value of the above field is one of the values in the array
   name: string; //Field name
   title?: string | any; //Title to show with the field
   type: string; //Field type
   size: number; // Field size
   titleSize?: number; // Title size
   options?: string[]; //Select options
   disabled?: boolean; //Disable field
}

export const FormStructureComponent = forwardRef(
   (
      {
         maxWidth,
         maxHeight,
         data,
         dataSecond,
         tabs,
         fieldsFormat,
         onSubmit,
         isLoadingSubmit,
         disableEdit,
         minHeight,
         minWidth,
      }: FormStructureComponentProps,
      ref: any
   ) => {
      const { showSnackBar } = useContext(SnackBarContext);
      const [value, setValue] = useState(0);

      const handleInitialValues = (fields, valuesFromBd, valuesFormBd2?) => {
         let initialValues = {};

         fields.forEach((element, index) => {
            if (!fields[index]["disabled"]) {
               fields[index]["disabled"] = disableEdit;
            }
            if (element.name.includes(".")) {
               const split = element.name.split(".");
               if (!initialValues[split[0]]) {
                  initialValues[split[0]] = {};
               }
               initialValues[split[0]][split[1]] = valuesFromBd?.[split[0]]?.[split[1]];
            } else {
               let value = null;
               if (!value) {
                  value = valuesFormBd2?.[element.name];
               }
               initialValues[element.name] = value;
            }
         });
         return initialValues;
      };
      const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
         if (!disableEdit) {
            showSnackBar("Favor de guardar los cambios, o descartarlos.", true);
            return;
         }
         setValue(newValue);
      };

      return (
         <Stack sx={{ rowGap: 2, maxWidth: maxWidth ?? 800 }}>
            {tabs && (
               <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                     flex: 1,
                     borderBottom: 1,
                     borderColor: "#CACDD8",
                     justifyContent: "space-between",
                  }}
               >
                  {fieldsFormat.map((label, index) => (
                     <Tab key={label.section} label={label.section} sx={index === value ? { fontWeight: 600 } : {}} />
                  ))}
               </Tabs>
            )}

            {fieldsFormat.map((section, index) => {
               if (index === value || !tabs) {
                  return (
                     <Stack sx={{ minHeight: minHeight ?? "auto" }}>
                        <Formik
                           innerRef={ref}
                           initialValues={handleInitialValues(
                              section.subSection
                                 ? [...section.fields, ...section.subSection.subFields]
                                 : section.fields,
                              data,
                              dataSecond
                           )}
                           onSubmit={onSubmit}
                        >
                           {({ setFieldValue, values }) => {
                              let validation = false;

                              if (section.subSection && section.subSection.subSectionDepends) {
                                 let split = null;
                                 if (section.subSection.subSectionDepends.includes(".")) {
                                    split = section.subSection.subSectionDepends.split(".");
                                 }
                                 //If subSectionDepends on not object properties
                                 if (
                                    values[section.subSection.subSectionDepends] &&
                                    section.subSection.if.includes(values[section.subSection.subSectionDepends])
                                 ) {
                                    validation = true;
                                    //If subSectionDepends object properties
                                 } else if (
                                    split &&
                                    values[split[0]][split[1]] &&
                                    section.subSection.if.includes(values[split[0]][split[1]])
                                 ) {
                                    validation = true;
                                 }
                              }

                              return (
                                 <Form>
                                    <Grid
                                       container
                                       spacing={2}
                                       sx={{
                                          maxHeight: maxHeight ?? 500,
                                          minWidth: minWidth ?? 600,
                                          overflowY: "auto",
                                          px: 4,
                                       }}
                                    >
                                       {section.section === "DOMICILIO" ? (
                                          <>
                                             <Grid item xs={12}>
                                                <HeaderBlue colorSmall="#152C44" colorLarge="#64748B">
                                                   Domicilio particular
                                                </HeaderBlue>
                                             </Grid>
                                             {section.fields.map((field) => FieldDepends(field, values, setFieldValue))}
                                          </>
                                       ) : (
                                          section.fields.map((field) => FieldDepends(field, values, setFieldValue))
                                       )}
                                       {validation ? (
                                          <>
                                             <Grid item xs={12}>
                                                <HeaderBlue colorSmall="#152C44" colorLarge="#64748B">
                                                   {section.subSection.title}
                                                </HeaderBlue>
                                             </Grid>
                                             {section.subSection.subFields.map((field) =>
                                                FieldDepends(field, values, setFieldValue)
                                             )}
                                          </>
                                       ) : null}
                                       {!ref && (
                                          <Grid item xs={12} sx={{ justifyContent: "flex-end", display: "flex" }}>
                                             <Button variant="contained" type="submit" disabled={disableEdit}>
                                                {isLoadingSubmit ? (
                                                   <CircularProgress size={25} sx={{ color: "white" }} />
                                                ) : (
                                                   "Guardar"
                                                )}
                                             </Button>
                                          </Grid>
                                       )}
                                    </Grid>
                                 </Form>
                              );
                           }}
                        </Formik>
                     </Stack>
                  );
               }
            })}
         </Stack>
      );
   }
);

const FieldComponentMap = (field, value?, setFieldValue?) => {
   switch (field.type) {
      case "text":
         return (
            <InputTextField
               id={field.name}
               name={field.name}
               type="text"
               fullWidth
               size={"small"}
               disabled={field.disabled}
            />
         );
      case "date":
         return (
            <LocalizationProvider
               dateAdapter={AdapterDayjs}
               // adapterLocale="es-mx"
            >
               <DatePicker
                  name={field.name}
                  value={value ? dayjs(value) : null}
                  format="DD/MM/YYYY"
                  onChange={(date) => {
                     setFieldValue(field.name, date);
                  }}
                  sx={{
                     width: "100%",
                     borderColor: "#787486",
                  }}
                  slotProps={{
                     textField: {
                        size: "small",
                        error: false,
                     },
                  }}
                  disabled={field.disabled}
               />
            </LocalizationProvider>
         );
      case "file":
         return (
            <Stack sx={{ width: "100%" }}>
               <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  disabled={field.disabled}
                  endIcon={<AttachFile sx={{ color: "#B0B0B0" }} />}
                  sx={{
                     display: "flex",
                     height: "40px",
                     justifyContent: "space-between",
                     alignItems: "center",
                     color: "#B0B0B0",
                     borderColor: "#B0B0B0",
                     "&:hover": {
                        borderColor: "#B0B0B0",
                     },
                  }}
               >
                  <Typography sx={{ flex: 1, textAlign: "left" }}>Subir archivo</Typography>
                  <input
                     type="file"
                     hidden
                     onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file) {
                           if (file.type !== "application/pdf") {
                              alert("Solo se permiten archivos PDF.");
                              return;
                           }
                           if (file.size > 5 * 1024 * 1024) {
                              alert("El archivo debe ser menor de 5 MB.");
                              return;
                           }
                           setFieldValue(field.name, event.currentTarget.files[0]);
                        }
                     }}
                  />
               </Button>
               <FileSelectedCollapse
                  file={value}
                  onClick={() => {
                     setFieldValue(field.name, null);
                  }}
               />
            </Stack>
         );
      case "select":
         return (
            <InputSelectField
               fullWidth={true}
               id={field.name}
               name={field.name}
               labelId=""
               size={"small"}
               disabled={field.disabled}
            >
               {field.options.map((option) => (
                  <MenuItem key={option} value={option}>
                     <ListItemText primary={option} />
                  </MenuItem>
               ))}
            </InputSelectField>
         );
      case "check":
         return (
            <Stack direction={"row"} sx={{ columnGap: 2 }}>
               <FormControl disabled={field.disabled}>
                  <RadioGroup
                     name={field.name}
                     id={field.name}
                     value={value}
                     onChange={(_ev, valueon) => {
                        setFieldValue(field.name, valueon);
                     }}
                     row
                  >
                     <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
                     <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
               </FormControl>
            </Stack>
         );
      case "special":
         if (value) {
            return (
               <Stack>
                  {value.map((action, index) => (
                     <React.Fragment>
                        <Grid container xs={12}>
                           <Grid
                              item
                              xs={2}
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                                 justifyContent: "center",
                                 bgcolor: "whitesmoke",
                              }}
                           >
                              <Typography fontSize={13} sx={{ color: "#787486", fontWeight: "bold" }} align="center">
                                 {action.title}
                              </Typography>
                           </Grid>
                           <Grid item xs={10} container rowSpacing={2} sx={{ display: "flex", alignItems: "center" }}>
                              <Grid item xs={3}>
                                 <Typography fontSize={13} sx={{ color: "#787486" }} align="center">
                                    Votos
                                 </Typography>
                              </Grid>
                              <Grid item xs={3} container>
                                 <TextField
                                    disabled
                                    fullWidth
                                    id={`relationMoralPerson.actions[${index}].nominalValue`}
                                    name={`relationMoralPerson.actions[${index}].nominalValue`}
                                    value={action?.nominalValue || ""}
                                    type="string"
                                    size="small"
                                 />
                              </Grid>
                              <Grid item xs={3}>
                                 <Typography fontSize={13} sx={{ color: "#787486" }} align="center">
                                    Total de acciones
                                 </Typography>
                              </Grid>
                              <Grid item xs={3} container>
                                 <TextField
                                    disabled
                                    fullWidth
                                    id={`relationMoralPerson.actions[${index}].totalActions`}
                                    name={`relationMoralPerson.actions[${index}].totalActions`}
                                    value={action?.totalActions || ""}
                                    type="string"
                                    size="small"
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </React.Fragment>
                  ))}
               </Stack>
            );
         } else {
            return <Typography>Sin datos</Typography>;
         }
      default:
         break;
   }
};

const FieldDepends = (field, values, setFieldValue) => {
   let titleSize = field.titleSize ? field.titleSize : 3.5;
   let value = values[field.name];
   if (field.name.includes(".")) {
      const split = field.name.split(".");
      value = values[split[0]][split[1]];
   }
   if (field.depends) {
      let value2 = "";
      if (field.depends.includes(".")) {
         const split = field.depends.split(".");
         value2 = values[split[0]][split[1]];
      }
      if ((field.if && field.if.includes(value2)) || (value2 && !field.if)) {
         return Field(
            field.size,
            titleSize,
            { field, value, setFieldValue },
            field.titles ? field.titles[value2] : field.title
         );
      }
   } else {
      return Field(field.size, titleSize, { field, value, setFieldValue }, field.title);
   }
};

const Field = (size, titleSize, fieldProps, title) => {
   return (
      <>
         <Grid item xs={titleSize} sx={{ alignItems: "center", display: "flex" }}>
            <Typography>{title}</Typography>
         </Grid>
         <Grid item xs={size - titleSize} sx={{ alignItems: "center", display: "flex" }}>
            {FieldComponentMap(fieldProps.field, fieldProps.value, fieldProps.setFieldValue)}
         </Grid>
      </>
   );
};
