import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Box, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import { getManyCorporateData, getUrlS3 } from "../../../lib/usersBEClient";
import { FilterComponent } from "../../../components/FilterComponent";
import { HeadCell, TableComponent } from "../../../components/TableComponent";
import _ from "lodash";
import { Error } from "@mui/icons-material";

let columns: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "name", headerName: "Nombre", type: "string" },
   { field: "type", headerName: "Tipo", type: "string" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "status", headerName: "Estatus", type: "boolean" },
];

export const CompaniesScreen = () => {
   const { companies, groups } = useContext(UserContext);
   const [clientsRow, setClientsRow] = useState([]);
   const [filteredClients, setFilteredClients] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();

   const fetchCompaniesInfo = useCallback(async () => {
      setIsLoading(true);
      const companyData: any[] = [];
      const groupsData: any[] = [];
      try {
         for (const group of groups) {
            const logo = await getDataS3(`group/${group._id}`);
            groupsData.push({
               _id: group._id,
               logo: logo,
               name: group.name,
               status: group.disabled,
               type: "Grupo empresarial",
               group: true,
               councilType: "---",
            });
         }

         const companyCorporateDatas = await getManyCorporateData(companies.map((c) => c._id));
         for (const company of companies) {
            const logo = await getDataS3(company._id);
            companyData.push({
               _id: company._id,
               logo: logo,
               name: company.person_details.comercialName,
               status: company.disabled,
               type: company?.company_details?.office ? "Despacho" : "Empresa",
               councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
            });
         }
         setClientsRow([...groupsData, ...companyData]);
         setFilteredClients([...groupsData, ...companyData]);
      } catch (error) {
         console.error(error);
      } finally {
         setIsLoading(false);
      }
   }, [companies, groups]);

   useEffect(() => {
      fetchCompaniesInfo();
   }, [fetchCompaniesInfo]);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return (
         <img
            defaultValue={logoUrl}
            src={logoUrl}
            alt="logo"
            style={{ maxWidth: "140px", maxHeight: "50px" }}
            loading="lazy"
         />
      );
   };

   const filteredData = useMemo(
      () => ({
         companies: filteredClients.filter((c) => c.type === "Empresa"),
         groups: filteredClients.filter((c) => c.type === "Grupo empresarial"),
         offices: filteredClients.filter((c) => c.type === "Despacho"),
      }),
      [filteredClients]
   );

   return (
      <Box sx={{ width: "100%" }}>
         {!isLoading ? (
            <Stack spacing={2}>
               <FilterComponent
                  originaltableOneRows={clientsRow}
                  tableOneFilteredRows={filteredClients}
                  setTableOneFilteredRows={setFilteredClients}
                  filters={[]}
                  fullWidth
                  searchBar={<></>}
                  disableRefresh
               />
               {filteredData.companies.length > 0 && (
                  <Box sx={{ boxShadow: 2, borderRadius: 2, bgcolor: "white" }}>
                     <Typography
                        fontSize={20}
                        p={2}
                        sx={{ color: "white", bgcolor: "#162c44", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                     >
                        {"Empresas"}
                     </Typography>
                     <Divider />
                     <TableComponent
                        defaultColumnToOrder=""
                        defaultOrder="asc"
                        defaultRowsPerPage={5}
                        emptyDataText={
                           filteredData.companies.length > 0
                              ? "No se encontró una empresa con ese nombre"
                              : "No existen empresas registradas"
                        }
                        disableBorders
                        headers={columns}
                        loader={isLoading}
                        rows={filteredData.companies}
                        rowsPerPageOptions={[5, 10, 20]}
                        onClick={(_e, row) => navigate(`/mi-lecosy/${row._id}/inicio`)}
                     />
                  </Box>
               )}

               {filteredData.groups.length > 0 && (
                  <Box sx={{ boxShadow: 2, borderRadius: 2, bgcolor: "white" }}>
                     <Typography
                        fontSize={20}
                        p={2}
                        sx={{ color: "white", bgcolor: "#162c44", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                     >
                        {"Grupos empresariales"}
                     </Typography>
                     <Divider />
                     <TableComponent
                        defaultColumnToOrder=""
                        defaultOrder="asc"
                        defaultRowsPerPage={5}
                        headers={columns}
                        loader={isLoading}
                        disableBorders
                        rows={filteredData.groups}
                        rowsPerPageOptions={[5, 10, 20]}
                        onClick={(_e, row) => navigate(`/mi-lecosy/grupo/${row._id}/inicio`)}
                        emptyDataText={
                           filteredData.groups.length > 0
                              ? "No se encontró un grupo empresarial con ese nombre"
                              : "No existen grupos empresariales registrados"
                        }
                     />
                  </Box>
               )}

               {filteredData.offices.length > 0 && (
                  <Box sx={{ boxShadow: 2, borderRadius: 2, bgcolor: "white" }}>
                     <Typography
                        fontSize={20}
                        p={2}
                        sx={{ color: "white", bgcolor: "#162c44", borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                     >
                        {"Despachos"}
                     </Typography>
                     <Divider />
                     <TableComponent
                        defaultColumnToOrder=""
                        defaultOrder="asc"
                        defaultRowsPerPage={5}
                        emptyDataText={
                           filteredData.offices.length > 0
                              ? "No se encontró un despacho con ese nombre"
                              : "No existen despachos registradas"
                        }
                        disableBorders
                        headers={columns}
                        loader={isLoading}
                        rows={filteredData.offices}
                        rowsPerPageOptions={[5, 10, 20]}
                        onClick={(_e, row) => navigate(`/mi-lecosy/${row._id}/inicio`)}
                     />
                  </Box>
               )}
               {!filteredData.companies.length && !filteredData.groups.length && !filteredData.offices.length && (
                  <Box
                     display="flex"
                     sx={{ p: 4, justifyContent: "center", alignItems: "center", columnGap: 2, opacity: 0.3 }}
                  >
                     <Typography>No se encontró una empresa con ese nombre</Typography>
                     <Error sx={{ width: "35px", height: "35px", color: "#162c44" }} />
                  </Box>
               )}
            </Stack>
         ) : (
            <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
               <CircularProgress sx={{ alignSelf: "center" }} />
            </Box>
         )}
      </Box>
   );
};
