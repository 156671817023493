import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { ArrowDownward } from "@mui/icons-material";
import { DeliberationVoteBox, LinkedVotesComponent } from "../../Bill/SubComponentShareholder/ShareholderDeliberations";

export const ShareholderDeliberationsExternal = () => {
   const { colors, affairVotations, additionalVotes, session } = useContext(ExternalGovernanceSessionContext);

   const deliberationVotes = useMemo(
      () => affairVotations?.concat(additionalVotes),
      [additionalVotes, affairVotations, session]
   );

   if (!deliberationVotes || deliberationVotes.length === 0) return;
   return (
      <Stack border={1} borderColor={"#EFEFEF"}>
         <Accordion disableGutters elevation={0}>
            <AccordionSummary
               sx={{
                  bgcolor: colors.secondary,
                  height: "22px",
                  minHeight: "22px !important",
               }}
               expandIcon={<ArrowDownward fontSize="small" sx={{ color: getContrastYIQ(colors.secondary) }} />}
            >
               <Typography
                  sx={{
                     width: "100%",
                     color: getContrastYIQ(colors.secondary),
                     textAlign: "center",
                     fontSize: 12,
                  }}
               >
                  {session?.group ? "DELIBERACIONES Y RESOLUCIONES" : "DELIBERACIONES"}
               </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "10px 0 0 0" }}>
               <Stack sx={{ display: "flex" }} direction={"column"}>
                  {session.group ? (
                     <GroupDeliberations deliberationVotes={deliberationVotes} />
                  ) : (
                     <DeliberationsMapping deliberationVotes={deliberationVotes} />
                  )}
               </Stack>
            </AccordionDetails>
         </Accordion>
      </Stack>
   );
};

const GroupDeliberations = ({ deliberationVotes }) => {
   const { membersWithCharge, additionalVotes, affairVotations, groupCompaniesInSession, sessionResolutions } =
      useContext(ExternalGovernanceSessionContext);

   const shareholdersArray =
      membersWithCharge?.filter(
         (member) => member.firstName && !member.memberCharge.includes("Usuario de implementacion")
      ) || [];

   return (
      <Stack direction={"column"} spacing={1}>
         {groupCompaniesInSession.map((company) => {
            const companyAdditionalDeliberations = additionalVotes.filter(
               (vote) => vote.company && vote.company === company._id
            );
            const companyResolutions = sessionResolutions.filter((resolution) => resolution.company === company._id);
            const orderVotations = affairVotations.reduce((array, order) => {
               const foundCompany = order.companies.find((orderCompany) => orderCompany.company === company._id);
               if (!foundCompany) return [...array];
               return [
                  ...array,
                  {
                     affair: order.affair,
                     company: foundCompany,
                  },
               ];
            }, []);

            return (
               <Accordion
                  disableGutters
                  elevation={0}
                  key={`companyVotation-${company._id}`}
                  sx={{ p: "0px !important" }}
               >
                  <AccordionSummary
                     sx={{
                        bgcolor: "#162c44",
                        height: "25px",
                        minHeight: "25px !important",
                     }}
                     expandIcon={<ArrowDownward fontSize="small" sx={{ color: "white" }} />}
                  >
                     <Typography width={"100%"} color={"white"} textAlign={"center"} fontSize={12}>
                        {company.person_details.comercialName.toUpperCase()}
                     </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                     sx={{
                        padding: 0,
                        borderLeft: 5,
                        borderColor: company.company_details.secondaryColor,
                     }}
                  >
                     <Stack direction={"column"}>
                        {orderVotations.map((order) => {
                           const orderAdditionalDeliberations = companyAdditionalDeliberations.filter(
                              (addVote) => addVote.orderId === order.affair.orderId
                           );
                           const orderResolutions = companyResolutions.filter(
                              (resolution) => resolution.orderId === order.affair.orderId
                           );
                           return (
                              <Stack
                                 key={`deliberation-company-${company._id}`}
                                 display={"flex"}
                                 direction={"column"}
                                 maxWidth={"100%"}
                                 sx={{ borderBottom: 3, borderColor: `${company.company_details.secondaryColor}40` }}
                              >
                                 <Box p={1} borderBottom={1} borderColor={"#C9C9C9"}>
                                    <Typography fontWeight={600} fontSize={14}>
                                       {order.affair.description.toUpperCase()}
                                    </Typography>
                                 </Box>
                                 {order.company.totalVotesUsed > 0 && (
                                    <>
                                       <Stack
                                          direction={"row"}
                                          maxWidth={"100%"}
                                          display={"flex"}
                                          flexWrap={"wrap"}
                                          rowGap={2}
                                          columnGap={1}
                                       >
                                          {shareholdersArray.map((shareholder) => {
                                             const shareholderFound = order.company.users.find(
                                                (user) => user.user === shareholder._id
                                             );
                                             if (!shareholderFound) return;
                                             return (
                                                <DeliberationVoteBox
                                                   userName={shareholder.firstName + " " + shareholder.lastName}
                                                   vote={shareholderFound.answer}
                                                   abstention={shareholderFound.abstention}
                                                   key={`deliberation-${
                                                      shareholder.firstName + " " + shareholder.lastName
                                                   }`}
                                                />
                                             );
                                          })}
                                       </Stack>
                                       <Typography fontWeight={600} fontSize={12} sx={{ py: 0.5 }} textAlign={"center"}>
                                          {`Resolución: ${orderResolutions.find((res) => !res.votationId).resolution}`}
                                       </Typography>
                                    </>
                                 )}
                                 {orderAdditionalDeliberations.length > 0 && (
                                    <LinkedVotesComponent
                                       linkedVotes={orderAdditionalDeliberations}
                                       linkedResolutions={orderResolutions.filter(
                                          (resolution) => resolution.votationId
                                       )}
                                    />
                                 )}
                              </Stack>
                           );
                        })}
                     </Stack>
                  </AccordionDetails>
               </Accordion>
            );
         })}
         {deliberationVotes.filter((order) => order?.session && !order.company) && (
            <Accordion disableGutters elevation={0}>
               <AccordionSummary
                  expandIcon={<ArrowDownward fontSize="small" />}
                  sx={{ height: "25px", minHeight: "25px !important", bgcolor: "#D9D9D9" }}
               >
                  <Typography textAlign={"center"} fontSize={12} width={"100%"}>
                     PREGUNTAS NO ASOCIADAS
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <DeliberationsMapping
                     deliberationVotes={deliberationVotes.filter((order) => order?.session && !order.company)}
                     deliberationsResolutions={sessionResolutions.filter((resolution) => !resolution.company)}
                  />
               </AccordionDetails>
            </Accordion>
         )}
      </Stack>
   );
};

const DeliberationsMapping = ({ deliberationVotes, deliberationsResolutions = [] }) => {
   const { membersWithCharge, colors, additionalVotes, session } = useContext(ExternalGovernanceSessionContext);

   const shareholdersArray =
      membersWithCharge?.filter(
         (member) => member.firstName && !member.memberCharge.includes("Usuario de implementacion")
      ) || [];

   return deliberationVotes
      .map((order) => {
         if (!order) return;
         const orderResolution = deliberationsResolutions?.find((resolution) => resolution.votationId === order._id);
         let linkedVotes = [];
         if (order.affair && additionalVotes?.length > 0)
            linkedVotes = additionalVotes.filter((addVote) => addVote.orderId === order.affair?.orderId);

         if (
            (order.affair && (!order.totalVotesUsed || order.totalVotesUsed === 0) && linkedVotes.length === 0) ||
            order.orderId
         )
            return;
         return (
            <Stack borderBottom={2} borderColor={colors?.secondary || "#162c44"} key={order._id}>
               <Typography
                  px={2}
                  py={1}
                  fontWeight={600}
                  color={"#162c44"}
                  width={"100%"}
                  border={1}
                  borderColor={"#EFEFEF"}
                  fontSize={"13px"}
               >
                  {order.affair?.description || order.title}
               </Typography>
               <Stack direction={"row"} display={"flex"} overflow={"auto"} spacing={1} rowGap={2}>
                  {order.canceled ? (
                     <Typography textAlign={"center"} py={0.5} width={"100%"} fontWeight={600} fontSize={"14px"}>
                        Votación cancelada
                     </Typography>
                  ) : order?.session ? (
                     order.votes?.map((addVote) => {
                        return (
                           <DeliberationVoteBox
                              userName={`${addVote.userName}`}
                              vote={addVote.answer}
                              abstention={addVote.abstention}
                           />
                        );
                     })
                  ) : (
                     (order.totalVotesUsed &&
                        order.totalVotesUsed > 0 &&
                        shareholdersArray.map((shareholder) => {
                           const shareholderFound = order.users.find((user) => user.user === shareholder._id);
                           if (!shareholderFound) return;
                           return (
                              <DeliberationVoteBox
                                 userName={shareholder.firstName + " " + shareholder.lastName}
                                 vote={shareholderFound.answer}
                                 abstention={shareholderFound.abstention}
                              />
                           );
                        })) || <></>
                  )}
               </Stack>
               {session?.group && !order.canceled && (
                  <Typography fontWeight={600} fontSize={12} sx={{ py: 0.5 }} textAlign={"center"}>
                     {`Resolución: ${orderResolution.resolution}`}
                  </Typography>
               )}
               {linkedVotes.length > 0 && <LinkedVotesComponent linkedVotes={linkedVotes} />}
            </Stack>
         );
      })
      ?.filter(Boolean);
};
