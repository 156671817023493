import { Box, Stack, Switch, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { ClientCreationContext } from "../../../../../context/officeContexts/clientCreationContext";
import { Companies } from "../../../../../types/BaseTypes";
import { ConfirmationModal } from "../../../../ConfirmationModal";
import LoginModal from "../../../../LoginModal";
import { BeneficiaryRequiredDocs } from "../../../../../const/globalConst";
import {
   createBeneficiaryApplicationConfig,
   getBeneficiaryApplicationConfig,
   updateServiceDisable,
} from "../../../../../lib/usersBEClient";
import { UserContext } from "../../../../../context/userContext";

interface ModuleCheckProps {
   service: { _id: string; name: string };
   checked: boolean;
   setChecked: Function;
}

const ModuleCheck = (props: ModuleCheckProps) => {
   const { service, checked, setChecked } = props;
   const { showSnackBar } = useContext(SnackBarContext);
   const { selectedClient, refetch } = useContext(ClientCreationContext);
   const { companySelected } = useContext(UserContext);
   const clientInfo = (selectedClient?.data as Companies) || (companySelected as Companies);

   const [openDisableServiceModal, setOpenDisableServiceModal] = useState(false);
   const [openLoginModal, setOpenLoginModal] = useState(false);

   const serviceUpdate = async () => {
      try {
         const updateServiceObject = {
            service_id: props.service._id,
            companyDetails_id: clientInfo.company_details._id,
            disable: checked,
         };

         await updateServiceDisable(updateServiceObject);
         showSnackBar(`Servicio ${!checked ? "habilitado" : "inhabilitado"} correctamente`, false);
         if (!checked && service.name === "Beneficiario controlador") {
            const configFound = getBeneficiaryApplicationConfig(clientInfo._id);
            if (!configFound) {
               const data = {
                  company: clientInfo._id,
                  files: BeneficiaryRequiredDocs.map((file) => {
                     return {
                        fileName: file,
                        fileType: "PDF",
                        maxFileSize: 10 * (1024 * 1024),
                        periodType: "monthly",
                        periodFrequency: 3,
                     };
                  }),
               };
               await createBeneficiaryApplicationConfig(data);
            }
         }
         setChecked(!checked);
         if (refetch) refetch();
      } catch (error) {
         console.log(error);
         showSnackBar(`Error al ${!checked ? "habilitar" : "inhabilitar"}`, true);
      }
   };

   return (
      <>
         <Box
            sx={{
               alignItems: "center",
               display: "flex",
               justifyContent: "space-between",
            }}
         >
            <Typography fontWeight={600} variant="subtitle1">
               {service.name}
            </Typography>
            <Stack direction={"row"} columnGap={2}>
               <Switch
                  checked={checked}
                  onChange={() => {
                     setOpenDisableServiceModal(true);
                  }}
                  sx={{ display: "flex" }}
               />
            </Stack>
         </Box>
         <ConfirmationModal
            open={openDisableServiceModal}
            setOpen={setOpenDisableServiceModal}
            title={!checked ? `Habilitar servicio` : `Inhabilitar servicio`}
            body={`¿Está seguro de ${!checked ? "habilitar" : "inhabilitar"} este servicio?`}
            onConfirm={() => {}}
            setOpenLogin={setOpenLoginModal}
         />
         <LoginModal
            open={openLoginModal}
            setOpen={setOpenLoginModal}
            onConfirm={() => {
               serviceUpdate();
               setChecked(!checked);
            }}
         />
      </>
   );
};

export default ModuleCheck;
