import { Box, Button, Stack, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SessionNote } from "./SubComponents/SessionNote";
import { NoteModal } from "../NotesScreen/NoteModal";
import { useContext, useEffect, useState } from "react";
import { getNotesAndCommentsBySession } from "../../../lib/gobCorpBEClient";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { UserContext } from "../../../context/userContext";
import { CustomModalComponent } from "../../CustomModalComponent";

const GetSessionNotesData = (sessionId) => {
   const [notes, setNotes] = useState([]);
   const [notesWithOwner, setNotesWithOwner] = useState([]);
   const [seed, setSeed] = useState(0);
   const { socket, membersWithCharge } = useContext(GovernanceSessionContext);
   const { user } = useContext(UserContext);

   const fetchNotes = async () => {
      const notesData = await getNotesAndCommentsBySession(sessionId);
      const filteredNotes = notesData?.filter(
         (notes) =>
            notes.sharedInSession || notes.users.some((userNote) => userNote === user.id) || notes.owner === user.id
      );
      setNotes(filteredNotes);
   };

   useEffect(() => {
      fetchNotes();
      socket.emit("created-note", sessionId);
   }, [seed, sessionId]);

   const handleSocketFetchNotes = () => {
      fetchNotes();
   };

   useEffect(() => {
      socket.on("fetch-notes", handleSocketFetchNotes);

      return () => {
         socket.off("fetch-notes", handleSocketFetchNotes);
      };
   }, [socket]);

   const fetchUsers = async () => {
      if (notes.length === 0) return;
      const ownerMap = {};
      membersWithCharge.forEach((owner) => {
         ownerMap[owner._id] = `${owner.firstName} ${owner.lastName}`;
      });
      const notesWithNames = notes.map((note) => {
         const ownerName = ownerMap[note.owner];
         if (ownerName) {
            return { ...note, ownerName };
         }
      });
      setNotesWithOwner(notesWithNames);
   };

   useEffect(() => {
      fetchUsers();
   }, [notes]);

   return { notesWithOwner, setSeed };
};

export const SessionNotes = () => {
   const { session, membersWithCharge } = useContext(GovernanceSessionContext);
   const [openModal, setOpenModal] = useState<number>(0);
   const { notesWithOwner, setSeed } = GetSessionNotesData(session._id);
   const [data, setData] = useState(null);

   return (
      <>
         <Box>
            <Box sx={{ bgcolor: "#F8F8F9", border: 1, borderColor: "#E0E0E0", p: 2 }}>
               <Typography sx={{ fontWeight: 600 }}>Notas</Typography>
               <Typography sx={{ fontSize: 10, color: "#64748B" }}>
                  Lista de notas generadas por los miembros durante la sesión.
               </Typography>
            </Box>
            <Stack
               sx={{
                  bgcolor: "#FFFFFF",
                  border: 1,
                  borderTop: 0,
                  borderColor: "#E0E0E0",
                  py: 2,
                  alignItems: "center",
               }}
            >
               <Button
                  onClick={() => {
                     setData(null);
                     setOpenModal(2);
                  }}
                  sx={{ display: "flex", gap: 1, alignContent: "center", ml: -1, width: "55%" }}
               >
                  <AddCircleIcon sx={{ color: "#64748B", fontSize: 16 }} />
                  <Typography sx={{ color: "#64748B", fontSize: 12, fontWeight: 600 }}>Agregar nota</Typography>
               </Button>
               <Typography sx={{ fontSize: 10, color: "#D9D9D9", textAlign: "center" }}>
                  La nota será compartida en la sesión actual.
               </Typography>
            </Stack>
            <Box
               sx={{
                  bgcolor: "white",
                  border: 0.65,
                  borderTop: 0,
                  borderColor: "#CBD5E0",
                  height: 325,
                  overflowY: "auto",
               }}
            >
               {notesWithOwner.map((note, index) => (
                  <Box
                     key={`note-${index}`}
                     onClick={() => {
                        setOpenModal(1);
                        setData(note);
                     }}
                     sx={{ cursor: "pointer", ":hover": { bgcolor: "#F4F9FD" } }}
                  >
                     <SessionNote key={index} noteData={note} />
                  </Box>
               ))}
            </Box>
         </Box>

         <CustomModalComponent
            title={openModal === 1 ? "Detalles de nota" : data ? "Modificar nota" : "Crear nota"}
            open={openModal === 1 || openModal === 2}
            setOpen={setOpenModal}
            timeStamp={openModal === 2}
         >
            <NoteModal
               state={openModal}
               setState={setOpenModal}
               session={session && session}
               data={data}
               setSeed={setSeed}
               users={membersWithCharge.map((user) => {
                  return {
                     user: `${user.firstName} ${user.lastName}`,
                     id: user._id,
                     profilePicture: user.profilePic,
                  };
               })}
            />
         </CustomModalComponent>
      </>
   );
};
