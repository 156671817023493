import { Box, IconButton, TextField, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState, useContext } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import { getTodayDateFormat } from "../const/globalConst";
import { SnackBarContext } from "../context/snackBarContext";

enum Filters {
   "daterange",
}

const filterTypes = Object.keys(Filters) as Array<keyof typeof Filters>;

interface FilterComponentProps {
   originaltableOneRows: object[];
   originaltableTwoRows?: object[];
   tableOneFilteredRows: object[];
   tableTwoFilteredRows?: object[];
   setTableOneFilteredRows: Function;
   setTableTwoFilteredRows?: Function;
   filters: typeof filterTypes;
   searchBar: React.ReactNode;
   opened?: string;
   setOpened?: Function;
   period?: boolean;
   disableRefresh?: boolean;
   fullWidth?: boolean;
}

export const FilterComponent = (props: FilterComponentProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const [startDate, setStartDate] = useState("");
   const [endDate, setEndDate] = useState("");
   const [searchValue, setSearchValue] = useState("");
   const [provisionalValues, setProvisionalValues] = useState<any[]>(props.tableOneFilteredRows);

   const filterCompanies = (props: { companies: any[]; value: any; date?: boolean }) => {
      try {
         const provisionalValues = [];
         for (const item of props.companies) {
            for (const key in item) {
               if (
                  item[key] &&
                  item[key].toString().includes(props.value) &&
                  !provisionalValues.includes(item) &&
                  (!Array.isArray(item[key]) || (Array.isArray(item[key]) && item[key].length > 0))
               ) {
                  provisionalValues.push(item);
               }
            }
         }
         return provisionalValues;
      } catch (error) {
         showSnackBar("Ha ocurrido un error, inténtelo de nuevo mas tarde", true);
      }
   };

   const dateComparator = (table: any[], name: string, value: any) => {
      return table.filter((row: any) => {
         if (props.period) {
            return (
               new Date(name === "startDate" ? value : startDate).getTime() <= row.unformatedDate &&
               row.unformatedDate <
                  new Date(name === "endDate" ? value : endDate ? endDate : getTodayDateFormat()).getTime() &&
               new Date(name === "startDate" ? value : startDate).getTime() < row.unformatedDate2 &&
               row.unformatedDate2 <=
                  new Date(name === "endDate" ? value : endDate ? endDate : getTodayDateFormat()).getTime()
            );
         } else {
            return (
               new Date(name === "startDate" ? value : startDate).getTime() < row.unformatedDate &&
               row.unformatedDate <
                  new Date(name === "endDate" ? value : endDate ? endDate : getTodayDateFormat()).getTime()
            );
         }
      });
   };

   const filterSecondTable = (table: any[], value: any, isDate = false, name?: string) => {
      const provisionalValuesTable2 = [];
      for (const item of table) {
         for (const key in item) {
            if (item[key] instanceof Array) {
               if (item[key].length > 0) {
                  let filteredCompanies = [];
                  if (isDate) {
                     filteredCompanies = dateComparator(item[key], name, value);
                  } else {
                     filteredCompanies = filterCompanies({
                        companies: item[key],
                        value: value,
                     });
                  }
                  if (filteredCompanies.length > 0 && !provisionalValuesTable2.includes(item)) {
                     provisionalValuesTable2.push(item);
                  }
               }
            } else if (
               item[key].toString().includes(value) &&
               !provisionalValuesTable2.includes(item) &&
               value !== ""
            ) {
               provisionalValuesTable2.push(item);
            }
         }
      }
      return provisionalValuesTable2;
   };

   const filteredRows = (event?) => {
      if (event) {
         if (props.opened) {
            props.setOpened("");
         }
         const { name, value } = event.target;
         if (name === "startDate" || name === "endDate") {
            if (name === "startDate" && endDate === "") {
               setStartDate(value);
               setEndDate(getTodayDateFormat());
            } else if (name === "startDate") {
               setStartDate(value);
            } else {
               setEndDate(value);
            }
            props.setTableOneFilteredRows(
               searchValue === ""
                  ? dateComparator(props.originaltableOneRows, name, value)
                  : dateComparator(props.tableOneFilteredRows, name, value).length > 0
                  ? dateComparator(props.tableOneFilteredRows, name, value)
                  : dateComparator(provisionalValues, name, value)
            );
            if (props.originaltableTwoRows) {
               props.setTableTwoFilteredRows(filterSecondTable(props.originaltableTwoRows, value, true, name));
            }
         } else {
            setSearchValue(value);
            //Tabla 1
            if (value !== "") {
               const newValues = filterCompanies({ companies: props.originaltableOneRows, value: value });
               if (startDate !== "" && endDate !== "") {
                  props.setTableOneFilteredRows(dateComparator(newValues, name, value));
               } else {
                  props.setTableOneFilteredRows(newValues);
               }
               setProvisionalValues(newValues);
            } else {
               if (startDate !== "" && endDate !== "") {
                  props.setTableOneFilteredRows(dateComparator(props.originaltableOneRows, name, value));
               } else {
                  props.setTableOneFilteredRows(props.originaltableOneRows);
                  setProvisionalValues(props.originaltableOneRows);
               }
            }
            if (props.originaltableTwoRows) {
               if (value !== "" && (startDate !== "" || endDate !== "")) {
                  props.setTableTwoFilteredRows(filterSecondTable(props.tableTwoFilteredRows, value));
               } else if (startDate !== "" || (endDate !== "" && value === "")) {
                  props.setTableTwoFilteredRows(filterSecondTable(props.originaltableTwoRows, value, true, name));
               } else {
                  props.setTableTwoFilteredRows(filterSecondTable(props.originaltableTwoRows, value));
               }
            }
         }
      } else {
         setSearchValue("");
         if (props.originaltableTwoRows) props.setTableTwoFilteredRows(props.originaltableTwoRows);
         props.setTableOneFilteredRows(props.originaltableOneRows);
      }
   };

   return (
      <Box sx={{ display: "flex", flex: 1 }}>
         <Box
            sx={{
               my: 1,
               display: "flex",
               flexDirection: "row",
               justifyContent: "center",
               alignItems: "center",
               columnGap: 2,
               flex: props.fullWidth ? 1 : "",
            }}
         >
            {props.filters.includes("daterange") ? (
               <Box sx={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", columnGap: 1 }}>
                  <TextField
                     fullWidth
                     type="date"
                     value={startDate}
                     name={"startDate"}
                     label={props.period ? "Inicio" : ""}
                     onChange={(e) => filteredRows(e)}
                     size="small"
                     InputLabelProps={{ shrink: true }}
                  />
                  <Typography>{">"}</Typography>
                  <TextField
                     fullWidth
                     type="date"
                     value={endDate}
                     name={"endDate"}
                     label={props.period ? "Cierre" : ""}
                     onChange={(e) => filteredRows(e)}
                     InputLabelProps={{ shrink: true }}
                     size="small"
                  />
               </Box>
            ) : null}
            {!props.disableRefresh ? (
               <IconButton
                  onClick={() => {
                     setEndDate("");
                     setStartDate("");
                     filteredRows();
                  }}
               >
                  <RefreshIcon />
               </IconButton>
            ) : null}
            <Box
               sx={{
                  flex: 2,
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 2,
                  maxHeight: 40,
               }}
            >
               <Box sx={{ flex: 3 }}>
                  <TextField
                     type="text"
                     onChange={(e) => filteredRows(e)}
                     value={searchValue}
                     size="small"
                     fullWidth
                     sx={{ bgcolor: "white" }}
                     label={
                        <Box sx={{ display: "flex", flexDirection: "row", columnGap: 1 }}>
                           <SearchIcon />
                           <Typography>Buscar</Typography>
                        </Box>
                     }
                  />
               </Box>
               {props.searchBar}
            </Box>
         </Box>
      </Box>
   );
};
