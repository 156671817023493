import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Form, Formik, FormikProps } from "formik";
import React, { useContext, useState } from "react";
import { ConfirmationModal } from "../../ConfirmationModal";
import { SnackBarContext } from "../../../context/snackBarContext";
import { useParams } from "react-router-dom";
import { addBeneficiaryDocument } from "../../../lib/usersBEClient";
import { BeneficiaryDocument } from "./BeneficiaryConfiguration";

const AddBeneficiaryDocument = (props: {
   document: BeneficiaryDocument;
   setState: Function;
   refreshTable: Function;
}) => {
   const { setState, refreshTable } = props;
   const [isLoading, setIsLoading] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companyId } = useParams();

   const handleSubmit = async (values) => {
      if (!values.docName) return showSnackBar("Favor de agregar el nombre del documento", true);
      setIsLoading(true);
      try {
         const data = {
            updateData: {
               docName: values.docName,
               docRequest: values.docRequest,
               docType: "PDF",
               docSize: values.docSize * (1024 * 1024),
               docDate: values.docDate.$d,
               docUpdateType: values.docUpdate === "custom" ? values.customConfig.type : values.docUpdate,
               docUpdate: values.docUpdate === "custom" ? values.customConfig.quantity : null,
               endOn: values.customConfig.withOutEndDate ? null : values.customConfig.endOn.$d,
            },
         };

         await addBeneficiaryDocument(companyId, data);
         showSnackBar("Nuevo documento guardado correctamente", false);
         refreshTable();
         setState(false);
      } catch (error) {
         if (error.response.data.message === "file already exists")
            showSnackBar("Ya existe un documento con el mismo nombre", true);
         else showSnackBar("Error al agregar archivo", true);
         console.log(error);
      }
      setIsLoading(false);
      setOpenConfirmation(false);
   };

   return (
      <Formik
         initialValues={{
            docSize: 10,
            docName: "",
            docRequest: "any",
            docType: "PDF",
            docDate: dayjs(new Date()),
            docUpdate: "none",
            customConfig: {
               quantity: 1,
               type: "daily",
               endOn: dayjs(new Date()),
               withOutEndDate: true,
            },
         }}
         onSubmit={handleSubmit}
      >
         {(formProps: FormikProps<any>) => (
            <Form>
               <Stack spacing={3} sx={{ p: 4, width: 700, height: 500 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <Typography sx={{ flex: 1 }}>Nombre del documento:</Typography>
                     <TextField
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={formProps.values.docName}
                        sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", flex: 2 }}
                        onChange={(event) => {
                           formProps.setFieldValue("docName", event.target.value);
                        }}
                     />
                  </Box>

                  <Box sx={{ display: "flex", columnGap: 5, alignItems: "center" }}>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Solicitud del documento:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                           <Select
                              variant="outlined"
                              size="small"
                              value={formProps.values.docRequest}
                              onChange={(event) => formProps.setFieldValue("docRequest", event.target.value)}
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 200 }}
                           >
                              <MenuItem value="any">Todos</MenuItem>
                              <MenuItem value="shareholder">Accionistas</MenuItem>
                              <MenuItem value="effective">Control Efectivo</MenuItem>
                              <MenuItem value="beneficiary">Beneficiario controlador</MenuItem>
                           </Select>
                        </LocalizationProvider>
                     </Box>
                  </Box>

                  <Box sx={{ display: "flex", columnGap: 5 }}>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Tipo de archivo:</Typography>
                        <Typography
                           sx={{ p: 1, bgcolor: "#F5F5F5", borderRadius: 2, border: 1, borderColor: "#E0E0E0" }}
                        >
                           PDF
                        </Typography>
                     </Box>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Tamaño:</Typography>
                        <TextField
                           variant="outlined"
                           size="small"
                           value={formProps.values.docSize}
                           onChange={(event) => {
                              if (/^\d{0,3}$/.test(event.target.value))
                                 formProps.setFieldValue("docSize", event.target.value);
                           }}
                           inputProps={{ maxLength: 3 }}
                           sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 60 }}
                        />
                        MB
                     </Box>
                  </Box>

                  <Box sx={{ display: "flex", columnGap: 5, alignItems: "center" }}>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Fecha de solicitud:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                           <DatePicker
                              name="docDate"
                              value={formProps.values.docDate}
                              onChange={(newValue) => formProps.setFieldValue("docDate", newValue)}
                              format="DD / MMMM / YYYY"
                              views={["year", "month", "day"]}
                              slotProps={{
                                 textField: {
                                    variant: "outlined",
                                    size: "small",
                                    sx: { bgcolor: "#F5F5F5", borderColor: "#E0E0E0" },
                                 },
                              }}
                           />
                        </LocalizationProvider>
                     </Box>

                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Select
                           variant="outlined"
                           size="small"
                           value={formProps.values.docUpdate}
                           onChange={(event) => formProps.setFieldValue("docUpdate", event.target.value)}
                           sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 200 }}
                        >
                           <MenuItem value="none">No repetir</MenuItem>
                           <MenuItem value="weekly">Semanal, cada {formProps.values.docDate.format("dddd")}</MenuItem>
                           <MenuItem value="monthly">Mensual, {formProps.values.docDate.format("DD")} del mes</MenuItem>
                           <MenuItem value="annually">Anual, {formProps.values.docDate.format("DD MMMM")}</MenuItem>
                           <MenuItem value="custom">Personalizar</MenuItem>
                        </Select>
                     </Box>
                  </Box>
                  {formProps.values.docUpdate === "custom" && (
                     <>
                        <Typography sx={{ fontWeight: 600 }}>Personalizar configuración</Typography>
                        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                           <Typography>Repetir cada</Typography>
                           <TextField
                              variant="outlined"
                              size="small"
                              value={formProps.values.customConfig.quantity}
                              onChange={(event) => {
                                 if (/^\d{0,3}$/.test(event.target.value))
                                    formProps.setFieldValue("customConfig.quantity", event.target.value);
                              }}
                              inputProps={{ maxLength: 3 }}
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 60 }}
                           />
                           <Select
                              variant="outlined"
                              size="small"
                              value={formProps.values.customConfig.type}
                              onChange={(event: any) =>
                                 formProps.setFieldValue("customConfig.type", event.target.value)
                              }
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 100 }}
                           >
                              <MenuItem value="daily">Día (s)</MenuItem>
                              <MenuItem value="weekly">Semana (s)</MenuItem>
                              <MenuItem value="monthly">Mes (es)</MenuItem>
                              <MenuItem value="annually">Año (s)</MenuItem>
                           </Select>
                           {formProps.values.customConfig.type !== "daily" && (
                              <Select
                                 disabled
                                 variant="outlined"
                                 size="small"
                                 value={formProps.values.customConfig.type}
                                 onChange={(event: any) =>
                                    formProps.setFieldValue("customConfig.type", event.target.value)
                                 }
                                 sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 100 }}
                              >
                                 <MenuItem value="weekly">Cada {formProps.values.docDate.format("dddd")}</MenuItem>
                                 <MenuItem value="monthly">
                                    Cada {formProps.values.docDate.format("DD")} del mes
                                 </MenuItem>
                                 <MenuItem value="annually">{formProps.values.docDate.format("DD MMMM")}</MenuItem>
                              </Select>
                           )}
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                           <Typography>Finalizar en</Typography>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                              <DatePicker
                                 minDate={formProps.values.docDate}
                                 disabled={formProps.values.customConfig.withOutEndDate}
                                 value={formProps.values.customConfig.endOn}
                                 onChange={(event: any) =>
                                    formProps.setFieldValue("customConfig.endOn", dayjs(event.$d))
                                 }
                                 format="DD / MMMM / YYYY"
                                 views={["year", "month", "day"]}
                                 slotProps={{
                                    textField: {
                                       variant: "outlined",
                                       size: "small",
                                    },
                                 }}
                                 sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 240 }}
                              />
                           </LocalizationProvider>
                           <FormControlLabel
                              sx={{ ml: "auto" }}
                              control={
                                 <Checkbox
                                    checked={formProps.values.customConfig.withOutEndDate}
                                    size="small"
                                    onChange={() =>
                                       formProps.setFieldValue(
                                          "customConfig.withOutEndDate",
                                          !formProps.values.customConfig.withOutEndDate
                                       )
                                    }
                                 />
                              }
                              label={<Typography>No establecer fecha final</Typography>}
                           />
                        </Box>
                     </>
                  )}
               </Stack>
               <Box sx={{ p: 1, bgcolor: "#F5F5F5", display: "flex", justifyContent: "flex-end", columnGap: 2 }}>
                  <Button variant="text" onClick={() => setState(false)} sx={{ color: "#ABAFB1" }}>
                     Cancelar
                  </Button>
                  <Button variant="text" onClick={() => setOpenConfirmation(true)} sx={{ color: "#162c44" }}>
                     Guardar
                  </Button>
               </Box>

               <ConfirmationModal
                  open={openConfirmation}
                  setOpen={setOpenConfirmation}
                  onConfirm={() => formProps.submitForm()}
                  title={"Confirmar configuración"}
                  isLoading={isLoading}
                  body={
                     <Stack spacing={1} sx={{ textAlign: "center" }}>
                        <Typography>¿Está seguro que desea guardar la configuración establecida?</Typography>
                        <Typography
                           sx={{ display: "flex", justifyContent: "left" }}
                           fontStyle={"italic"}
                           color={"red"}
                           fontSize={13}
                        >
                           Una vez concluido, se establecerá la configuración en la solicitud de los documentos.
                        </Typography>
                     </Stack>
                  }
               />
            </Form>
         )}
      </Formik>
   );
};

export default AddBeneficiaryDocument;
