import {
   Avatar,
   Box,
   Button,
   CircularProgress,
   Divider,
   Grid,
   List,
   ListItem,
   Modal,
   Stack,
   Tab,
   Tabs,
   Typography,
} from "@mui/material";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import LastAddedMemberModal from "./SubComponents/LastAddedMembersModal";
import LastChangeModal from "./SubComponents/LastChangeModal";
import { useContext, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getDateFromISODate, quickSort } from "../../../const/globalConst";
import { UserContext } from "../../../context/userContext";
import { MemberModal } from "../MembersScreen/MemberModal";
import { CustomModalComponent } from "../../CustomModalComponent";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getAllResources, getLogsByGovernanceAndResource, getUserByUserId } from "../../../lib/usersBEClient";
import { getChargeById } from "../../../lib/gobCorpBEClient";
import _ from "lodash";

interface MembersListComponentProps {
   selectedBody: any;
}

const MembersListSection = (props: MembersListComponentProps) => {
   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Lista de miembros" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack direction={"row"} spacing={5} p={3}>
               <MembersList {...props} />
               <MemberListInfo {...props} />
            </Stack>
         </TabPanel>
      </Box>
   );
};

const ShowMoreButton = ({ showMore }) => {
   return (
      <Button sx={{ color: "white", columnGap: 1 }} onClick={showMore}>
         <Typography sx={{ fontSize: "14px" }}>Ver</Typography>
         <VisibilityIcon fontSize="small" />
      </Button>
   );
};

export const MembersList = (props: MembersListComponentProps) => {
   const { selectedBody } = props;
   const [selectedMember, setSelectedMember] = useState(selectedBody.usersData[0]);
   const [openMemberModal, setOpenMemberModal] = useState(false);

   return (
      <Box flex={4} p={2}>
         <Grid container>
            <Grid item xs={5} display={"flex"} justifyContent={"left"}>
               Nombre
            </Grid>
            <Grid item xs={4} display={"flex"} justifyContent={"center"}>
               Cargo(s)
            </Grid>
            <Grid item xs={3} display={"flex"} justifyContent={"center"}>
               Carácter consejero
            </Grid>
         </Grid>
         <List>
            {selectedBody.usersData
               .filter(
                  (u) =>
                     u.chargeName !== "Usuario de implementacion" &&
                     u.chargeName !== "Coordinador de gobierno corporativo"
               )
               .map((user) => {
                  if (!user.firstName || !user.lastName) {
                     return null;
                  }
                  return (
                     <ListItem
                        onClick={() => {
                           setSelectedMember(user);
                           setOpenMemberModal(true);
                        }}
                        sx={{
                           bgcolor: "#F5F5F5",
                           my: 1,
                           ":hover": {
                              bgcolor: "#D9D9D9",
                              cursor: "pointer",
                           },
                        }}
                        key={user.email}
                     >
                        <Grid container>
                           <Grid item xs={5}>
                              <Stack direction={"row"} spacing={1}>
                                 <Avatar sx={{ width: "30px", height: "30px", alignSelf: "center" }}>
                                    <Typography>
                                       {user.firstName.charAt(0)}
                                       {user.lastName.charAt(0)}
                                    </Typography>
                                 </Avatar>
                                 <Stack direction={"column"} justifyContent={"space-between"}>
                                    <Typography variant="button" fontWeight={600}>
                                       {user.firstName} {user.lastName}
                                    </Typography>
                                    <Typography variant="caption">{user.email}</Typography>
                                 </Stack>
                              </Stack>
                           </Grid>
                           <Grid item xs={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                              <Typography variant="button">{user.chargeName}</Typography>
                           </Grid>
                           <Grid item xs={3} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                              <Stack direction={"row"} spacing={1} justifyContent={"space-evenly"}>
                                 {(user.characters.length > 0 &&
                                    user.characters.map((character) => {
                                       return (
                                          <Typography
                                             key={character}
                                             variant="caption"
                                             fontWeight={600}
                                             sx={{
                                                color: "#162c44",
                                                bgcolor: "white",
                                                p: 0.5,
                                                borderRadius: 2,
                                             }}
                                          >
                                             {character}
                                          </Typography>
                                       );
                                    })) || <Typography>Ninguno</Typography>}
                              </Stack>
                           </Grid>
                        </Grid>
                     </ListItem>
                  );
               })}
         </List>

         <CustomModalComponent title="Ficha de usuario" open={openMemberModal} setOpen={setOpenMemberModal}>
            <MemberModal
               state={openMemberModal}
               setState={setOpenMemberModal}
               dataId={selectedMember?.user}
               memberData={selectedMember}
            />
         </CustomModalComponent>
      </Box>
   );
};

const useGetLogs = () => {
   const { user, selectedResources, isLoadingUser } = useContext(UserContext);
   const { refetch, companySelected, selectedGovernance } = useContext(GovernanceContext);
   const [newUsers, setNewUsers] = useState([]);
   const [updatedUsers, setUpdatedUsers] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchLogs = async () => {
         setIsLoading(true);
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         const logs = await getLogsByGovernanceAndResource(selectedGovernance._id, resourceId, companySelected);
         const usersId = logs
            .filter((l) => l.details.action !== "creación")
            .slice(0, 5)
            .map((log) => {
               return { userId: log.details.involvedUsers[0], createdAt: log.createdAt, title: log.title };
            });
         const uniqueUsers = usersId.filter((value, index) => usersId.indexOf(value) === index);

         const addedUsers = [];
         for (const member of uniqueUsers) {
            const memberData = await getUserByUserId(member.userId);
            addedUsers.push({
               title: member.title,
               name: `${memberData.user.firstName} ${memberData.user.lastName}`,
               createdAt: member.createdAt,
            });
         }
         setUpdatedUsers(addedUsers);

         const newUsersId = logs
            .filter((l) => l.details.action === "creación")
            .slice(0, 5)
            .map((log) => {
               return { userId: log.details.involvedUsers[0], createdAt: log.createdAt };
            });
         const newuniqueUsers = newUsersId.filter((value, index) => newUsersId.indexOf(value) === index);

         const currentMembers = [];
         for (const gobUser of selectedGovernance.users as any)
            for (const newUser of newuniqueUsers)
               if (gobUser.user === newUser.userId)
                  currentMembers.push({ _id: gobUser.user, charge: gobUser.charge, createdAt: newUser.createdAt });
         const formattedusers = [];
         for (const member of currentMembers) {
            const memberData = await getUserByUserId(member._id);
            const charge = await getChargeById(member.charge);
            formattedusers.push({
               name: `${memberData.user.firstName} ${memberData.user.lastName}`,
               charge: charge.chargeName,
               createdAt: member.createdAt,
            });
         }
         setNewUsers(formattedusers);
         setIsLoading(false);
      };
      if (!isLoadingUser && selectedResources && companySelected) fetchLogs();
   }, [refetch, selectedResources, companySelected, isLoadingUser, user.id]);

   return { newUsers, updatedUsers, isLoading };
};

export const MemberListInfo = (props: MembersListComponentProps) => {
   const { selectedBody } = props;
   const [lastAddedMembersOpen, setLastAddedMembersOpen] = useState<boolean>(false);
   const [lastChangedOpen, setLastChangedOpen] = useState<boolean>(false);
   const updatedAtDates = selectedBody.usersData.map((user) => user.updatedAt);
   const { newUsers, updatedUsers, isLoading } = useGetLogs();

   return (
      <Box flex={2} alignSelf={"center"}>
         <Typography fontWeight={600} color={"#162c44"}>
            Información general
         </Typography>
         <Stack py={2} direction={"column"} spacing={1}>
            <Box display={"flex"} columnGap={2}>
               <Typography sx={{ fontSize: "14px" }} alignSelf={"center"}>
                  <strong>Número total de miembros:</strong>
               </Typography>
               <Box bgcolor={"#162c44"} color={"white"} px={2.5} borderRadius={10}>
                  <Typography>
                     {selectedBody.usersData.length -
                        _.filter(selectedBody.usersData, {
                           chargeName: "Usuario de implementacion" || "Coordinador de gobierno corporativo",
                        }).length}
                  </Typography>
               </Box>
            </Box>
            <Box display={"flex"} columnGap={1}>
               <Typography sx={{ fontSize: "14px" }} alignSelf={"center"}>
                  <strong>Últimos miembros registrados:</strong>
               </Typography>
               <Box bgcolor={"#162c44"} borderRadius={10}>
                  <ShowMoreButton showMore={setLastAddedMembersOpen} />
               </Box>
            </Box>
            <Box display={"flex"} columnGap={1}>
               <Typography sx={{ fontSize: "14px" }} alignSelf={"center"}>
                  <strong>Último cambios efectuados:</strong>
               </Typography>
               <Box bgcolor={"#162c44"} borderRadius={10}>
                  <ShowMoreButton showMore={setLastChangedOpen} />
               </Box>
            </Box>
         </Stack>
         <Stack py={2} direction={"column"} spacing={1}>
            <Box display={"flex"} columnGap={1} alignContent={"center"}>
               <Typography sx={{ fontSize: "14px" }}>
                  <strong>Última actualización: </strong>
               </Typography>
               {!isLoading && (
                  <Typography sx={{ opacity: "50%", fontSize: 14 }} fontWeight={600}>
                     {[...newUsers, ...updatedUsers].length > 0
                        ? getDateFromISODate(
                             new Date(quickSort([...newUsers, ...updatedUsers].map((log) => log.createdAt))[0])
                          )
                        : "Sin datos"}
                  </Typography>
               )}
            </Box>
            {/* <Box>
               <Typography sx={{ fontSize: "14px" }}>
                  <strong>Fecha de inicio:</strong>
               </Typography>
            </Box>
            <Box>
               <Typography sx={{ fontSize: "14px" }}>
                  <strong>% cumplimiento:</strong>
               </Typography>
            </Box> */}
         </Stack>
         <LastAddedMemberModal
            open={lastAddedMembersOpen}
            setOpen={setLastAddedMembersOpen}
            users={newUsers}
            isLoading={isLoading}
         />
         <LastChangeModal
            open={lastChangedOpen}
            setOpen={setLastChangedOpen}
            users={updatedUsers}
            isLoading={isLoading}
         />
      </Box>
   );
};

export default MembersListSection;
