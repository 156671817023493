import { useCallback, useContext, useEffect, useState } from "react";
import { ServiceInfoScreen } from "../ServiceInfoScreen";
import { UserContext } from "../../../context/userContext";
import { IServiceDetails } from "../../../types/BaseTypes";
import { getCompanyById } from "../../../lib/usersBEClient";
import { Box, CircularProgress } from "@mui/material";

export const ServicesScreen = () => {
   const { companySelected, isOfficeAdmin } = useContext(UserContext);
   const [availableServices, setAvailableServices] = useState<IServiceDetails[]>();
   const [isLoading, setIsLoading] = useState(false);

   const getServices = useCallback(async () => {
      if (isOfficeAdmin) {
         setIsLoading(true);
         const ownerCompany = await getCompanyById(companySelected.owner);
         setAvailableServices(ownerCompany.data?.company_details?.servicesDetails);
         setIsLoading(false);
      } else setAvailableServices(companySelected?.company_details?.servicesDetails);
   }, [companySelected, isOfficeAdmin]);

   useEffect(() => {
      getServices();
   }, [getServices]);

   return (
      <>
         {isLoading ? (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         ) : (
            <ServiceInfoScreen services={availableServices} company={companySelected ? companySelected : null} />
         )}
      </>
   );
};
