import {
   Accordion,
   AccordionDetails,
   AccordionSummary,
   Box,
   Card,
   CircularProgress,
   Collapse,
   Divider,
   Grid,
   IconButton,
   Stack,
   Tab,
   Tabs,
   Typography,
} from "@mui/material";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useContext, useEffect, useState } from "react";
import { LoadingButton } from "../../LoadingButton";
import { getGroupColors, getUrlForDocumentsListGC, getUrlS3 } from "../../../lib/usersBEClient";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { createChartPdf } from "../../../lib/gobCorpBEClient";
import { getTrimesterDates } from "../../../const/globalConst";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { UserContext } from "../../../context/userContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SnackBarContext } from "../../../context/snackBarContext";
import ReplayIcon from "@mui/icons-material/Replay";
import ListComponent from "../../ListComponent";

interface ReportGenerationSectionProps {
   selectedBody: any;
}

export const ReportGenerationSection = (props: ReportGenerationSectionProps) => {
   const { selectedGovernance, consultiveGroupCompanies } = useContext(GovernanceContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const today = dayjs(new Date());
   const [startDate, setStartDate] = useState<dayjs.Dayjs>(today);
   const [finishDate, setFinishDate] = useState<dayjs.Dayjs>(today);
   const [monthValue, setMonthValue] = useState<dayjs.Dayjs>(undefined);
   const [isLoadingReport, setIsLoadingReport] = useState(false);
   const [openReport, setOpenReport] = useState(false);
   const isShareHolder = selectedGovernance.title.includes("Asamblea");

   const isConsultiveGroup = consultiveGroupCompanies.some((company) => {
      const isMatchByGroup = company.group === selectedGovernance?.company;

      return isMatchByGroup;
   });

   useEffect(() => {
      const handleOpenReport = () => {
         setTimeout(async () => {
            const reportUrl = await getUrlS3(
               "files-lecosy",
               { folder: `gc/${selectedGovernance.company}/governance/${selectedGovernance._id}/reports/temp` },
               `temp-report.pdf`
            );
            setIsLoadingReport(false);
            setOpenReport(false);
            window.open(reportUrl, "_blank", "noopener,noreferrer");
         }, 2000);
      };
      if (openReport) handleOpenReport();
   }, [openReport]);

   const createPDF = async () => {
      try {
         setIsLoadingReport(true);

         let groupDetailsObj;

         if (isConsultiveGroup) {
            // Intentar obtener los colores y el nombre del grupo
            try {
               const { groupName, primaryColor, secondaryColor } = await getGroupColors(selectedGovernance.company);

               // Crear el objeto groupDetails
               groupDetailsObj = {
                  groupName,
                  primaryColor,
                  secondaryColor,
               };
            } catch (error) {
               console.error("Error al obtener detalles del grupo, generando PDF sin información adicional:", error);
            }
         }

         // Intentar crear el PDF con o sin groupDetails
         const response = await createChartPdf(
            isShareHolder,
            selectedGovernance._id,
            {
               startDate: startDate?.toDate() ? new Date(startDate.toDate()) : null,
               finishDate: finishDate?.toDate() ? new Date(finishDate.toDate()) : null,
               monthValue: monthValue?.toDate() ? new Date(monthValue.toDate()) : null,
            },
            groupDetailsObj // Parámetro opcional groupDetails, será undefined si no es consultivo o hubo un error
         );

         if (response?.message === "OK") {
            setOpenReport(true);
         } else {
            throw new Error("Error al generar el PDF");
         }
      } catch (error) {
         showSnackBar("No es posible crear el pdf", true);
         setIsLoadingReport(false);
      }
   };

   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3} width={"100%"}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Generar reportes" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack direction={"row"}>
               <Box flex={5} alignSelf={"center"}>
                  <Stack width={"100%"} py={2} px={3} spacing={3}>
                     <Typography>
                        <strong>Personalizado:</strong>
                     </Typography>
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                           <Typography>Desde</Typography>
                           <DatePicker
                              maxDate={finishDate}
                              label={"Fecha de inicio"}
                              format="DD/MM/YYYY"
                              value={startDate}
                              onChange={(event: any) => {
                                 setStartDate(dayjs(event.$d));
                                 setMonthValue(null);
                              }}
                              slotProps={{ textField: { variant: "outlined", size: "small" } }}
                           />
                           <Typography>Hasta</Typography>
                           <DatePicker
                              minDate={startDate}
                              maxDate={today}
                              label={"Fecha de fin"}
                              format="DD/MM/YYYY"
                              value={finishDate}
                              onChange={(event: any) => {
                                 setFinishDate(dayjs(event.$d));
                                 setMonthValue(null);
                              }}
                              slotProps={{ textField: { variant: "outlined", size: "small" } }}
                           />
                        </Stack>
                     </LocalizationProvider>
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <Stack direction={"row"} display={"flex"} spacing={1} justifyContent={"space-between"} pr={2}>
                           <Stack direction={"row"} spacing={1} alignItems={"center"}>
                              <Typography>Mensual</Typography>
                              <DatePicker
                                 views={["year", "month"]}
                                 label="Mes y año"
                                 value={monthValue}
                                 onChange={(e) => {
                                    setStartDate(null);
                                    setFinishDate(null);
                                    setMonthValue(e);
                                 }}
                                 slotProps={{ textField: { variant: "outlined", size: "small" } }}
                              />
                           </Stack>
                           <LoadingButton
                              disabled={!props.selectedBody}
                              onClick={() => {
                                 createPDF();
                              }}
                              isLoading={isLoadingReport}
                              label="Generar reporte en pdf"
                           />
                        </Stack>
                     </LocalizationProvider>
                  </Stack>
               </Box>
            </Stack>
         </TabPanel>
      </Box>
   );
};

export const TrimesterReportsSection = () => {
   const { selectedGovernance } = useContext(GovernanceContext);
   const { companySelected } = useContext(UserContext);
   const [trimesterReports, setTrimesterReports] = useState<any>(null);
   const [loadingReports, setLoadingReports] = useState(true);
   const [selectedTrimester, setSelectedTrimester] = useState("");
   const [errorLoadingDocs, setErrorLoadingDocs] = useState(false);
   const trimesters = getTrimesterDates();

   const getTrimesterReports = async () => {
      try {
         let reportList: any = {};
         await Promise.all(
            trimesters.map(async (_, index) => {
               const trimester = `T${index + 1}`;
               const autoReports = await getUrlForDocumentsListGC(
                  "files-lecosy",
                  `gc/${companySelected._id}/governance/${selectedGovernance._id}/reports/${trimester}`
               );
               reportList[trimester] = autoReports.Contents;
            })
         );
         setTrimesterReports(reportList);
         setLoadingReports(false);
      } catch (error) {
         setLoadingReports(false);
         setErrorLoadingDocs(true);
      }
   };

   useEffect(() => {
      getTrimesterReports();
   }, [selectedGovernance]);

   const handleOpenDocument = async (trimester, fileKey) => {
      window.open(
         await getUrlS3(
            "files-lecosy",
            { folder: `gc/${companySelected._id}/governance/${selectedGovernance._id}/reports/${trimester}` },
            fileKey
         ),
         "_blank"
      );
   };

   const handleChange = (panelName) => {
      if (selectedTrimester === panelName) return setSelectedTrimester("");
      return setSelectedTrimester(panelName);
   };

   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3} width={"100%"}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Reportes automáticos (trimestre)" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Grid container px={2} py={1} rowGap={1}>
               <Grid item xs={9}>
                  <Typography fontSize={14}>Folio:</Typography>
               </Grid>
               <Grid item xs={3} display={"flex"} justifyContent={"center"}>
                  <Typography fontSize={14}>Ver documento:</Typography>
               </Grid>
               <Grid item xs={12}>
                  <Divider sx={{ borderBottomWidth: 2 }} />
               </Grid>
            </Grid>
            <Box maxHeight={"200px"} sx={{ overflowY: "auto" }}>
               {(!loadingReports &&
                  trimesterReports !== null &&
                  (Object.keys(trimesterReports).some((trimester) => trimesterReports[trimester] !== undefined) ? (
                     Object.keys(trimesterReports).map((trimester) => {
                        return (
                           trimesterReports[trimester]?.length > 0 && (
                              <Grid container key={trimester.toLowerCase()} px={2} rowGap={1}>
                                 <Grid
                                    item
                                    display={"flex"}
                                    xs={12}
                                    sx={{
                                       bgcolor: "#D8D8D8",
                                       borderRadius: 1,
                                       px: 2,
                                       py: 0.3,
                                       ":hover": { cursor: "pointer" },
                                       justifyContent: "space-between",
                                    }}
                                    onClick={() => handleChange(trimester)}
                                 >
                                    <Typography fontSize={"14px"}>{trimester.replace("T", "Trimestre ")}</Typography>
                                    <ExpandMoreIcon
                                       fontSize="small"
                                       sx={{
                                          transform: `rotate(${selectedTrimester === trimester ? "180deg" : "0deg"})`,
                                       }}
                                    />
                                 </Grid>
                                 <Collapse in={selectedTrimester === trimester} sx={{ width: "100%", display: "flex" }}>
                                    {trimesterReports[trimester].map((document) => {
                                       const documentIndex = document.Key.indexOf(`${trimester}/`);
                                       const documentName = document.Key.substring(documentIndex + 3, document.length);
                                       return (
                                          trimesterReports[trimester]?.length > 0 && (
                                             <Grid container key={documentName}>
                                                <Grid item xs={9} display={"flex"} alignItems={"center"} sx={{ pl: 2 }}>
                                                   <Typography fontSize={12}>{documentName}</Typography>
                                                </Grid>
                                                <Grid item xs={3} display={"flex"} justifyContent={"center"}>
                                                   <IconButton
                                                      sx={{ color: "#162c44" }}
                                                      onClick={() => handleOpenDocument(trimester, documentName)}
                                                   >
                                                      <PictureAsPdfIcon fontSize="small" />
                                                   </IconButton>
                                                </Grid>
                                                <Grid item xs={12}>
                                                   <Divider sx={{ borderBottomWidth: 1 }} />
                                                </Grid>
                                             </Grid>
                                          )
                                       );
                                    })}
                                 </Collapse>
                              </Grid>
                           )
                        );
                     })
                  ) : (
                     <Stack
                        direction={"column"}
                        display={"flex"}
                        justifySelf={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        width={"100%"}
                        sx={{ opacity: 0.5 }}
                     >
                        <InfoOutlinedIcon sx={{ fontSize: "30px" }} />
                        {errorLoadingDocs ? (
                           <Stack
                              sx={{ opacity: 0.5, ":hover": { cursor: "pointer" } }}
                              direction={"column"}
                              spacing={1}
                              onClick={() => {
                                 setLoadingReports(true);
                                 getTrimesterReports();
                              }}
                           >
                              <Typography fontSize={"12px"}>Recargar</Typography>
                              <ReplayIcon fontSize="medium" />
                           </Stack>
                        ) : (
                           <Typography fontWeight={600} fontSize={"16px"}>
                              No existen reportes automáticos
                           </Typography>
                        )}
                     </Stack>
                  ))) || (
                  <Box
                     width={"100%"}
                     height={"100%"}
                     display={"flex"}
                     alignContent={"center"}
                     justifyContent={"center"}
                  >
                     <CircularProgress size={24} />
                  </Box>
               )}
            </Box>
         </TabPanel>
      </Box>
   );
};

//PANEL REPORTS SECTION

export const PanelReportGenerationSection = () => {
   const [trimesterReports, setTrimesterReports] = useState<any>(null);
   const [loadingReports, setLoadingReports] = useState(true);
   const [errorLoadingDocs, setErrorLoadingDocs] = useState(false);

   const trimesters = getTrimesterDates();
   const { companySelectedUserP, user } = useContext(UserContext);

   const getTrimesterReports = async () => {
      try {
         let reportList: any = {};
         await Promise.all(
            trimesters.map(async (_, index) => {
               const trimester = `T${index + 1}`;
               const autoReports = await getUrlForDocumentsListGC(
                  "files-lecosy",
                  `gc/${companySelectedUserP._id}/panel/${user.id}/reports/${trimester}`
               );
               reportList[trimester] = autoReports.Contents;
            })
         );
         setTrimesterReports(reportList);
         setLoadingReports(false);
      } catch (error) {
         setLoadingReports(false);
         setErrorLoadingDocs(true);
      }
   };

   useEffect(() => {
      getTrimesterReports();
   }, [companySelectedUserP]);

   const handleOpenDocument = async (trimester, fileKey) => {
      window.open(
         await getUrlS3(
            "files-lecosy",
            { folder: `gc/${companySelectedUserP._id}/panel/${user.id}/reports/${trimester}` },
            fileKey
         ),
         "_blank"
      );
   };


   return (
      <Card sx={{ flex: 1 }}>
         <Tabs value={"1"} textColor="primary">
            <Tab label={"Reportes automáticos (trimestre)"} value={"1"} />
         </Tabs>
         <Box width={"100%"} px={2} py={1}>
            {!loadingReports &&
            trimesterReports !== null &&
            Object.keys(trimesterReports).some((trimester) => trimesterReports[trimester] !== undefined) ? (
               Object.keys(trimesterReports).map((trimester) => {
                  return (
                     trimesterReports[trimester]?.length > 0 && (
                        <Accordion disableGutters elevation={0}>
                           <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              sx={{ bgcolor: "#D8D8D8", height: "30px", minHeight: "30px !important" }}
                           >
                              <Typography>{trimester.replace("T", "Trimestre ")}</Typography>
                           </AccordionSummary>
                           <AccordionDetails sx={{ p: 0 }}>
                              <ListComponent
                                 headers={[{ headerTitle: "", type: "text", bodyPosition: "left" }]}
                                 rows={trimesterReports[trimester].map((report) => {
                                    return { title: report.Key.split(`${trimester}/`)[1] };
                                 })}
                                 rowProps={() => {
                                    return {
                                       borderBottom: 1,
                                       borderColor: "#CECECE",
                                       px: 5,
                                    };
                                 }}
                                 rowEndAndornment={(e) => {
                                    return (
                                       <IconButton onClick={() => handleOpenDocument(trimester, e.title)}>
                                          <PictureAsPdfIcon color="primary" fontSize="small" />
                                       </IconButton>
                                    );
                                 }}
                              />
                           </AccordionDetails>
                        </Accordion>
                     )
                  );
               }) || (
                  <Box
                     width={"100%"}
                     height={"100%"}
                     display={"flex"}
                     alignContent={"center"}
                     justifyContent={"center"}
                  >
                     <CircularProgress size={24} />
                  </Box>
               )
            ) : (
               <Stack
                  direction={"column"}
                  display={"flex"}
                  justifySelf={"center"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  sx={{ opacity: 0.5 }}
               >
                  <InfoOutlinedIcon sx={{ fontSize: "30px" }} />
                  {errorLoadingDocs ? (
                     <Stack
                        sx={{ opacity: 0.5, ":hover": { cursor: "pointer" } }}
                        direction={"column"}
                        spacing={1}
                        onClick={() => {
                           setLoadingReports(true);
                           getTrimesterReports();
                        }}
                     >
                        <Typography fontSize={"12px"}>Recargar</Typography>
                        <ReplayIcon fontSize="medium" />
                     </Stack>
                  ) : (
                     <Typography fontWeight={600} fontSize={"16px"}>
                        No existen reportes automáticos
                     </Typography>
                  )}
               </Stack>
            )}
         </Box>
      </Card>
   );
};
