import {
   Box,
   Button,
   ClickAwayListener,
   Divider,
   Grow,
   MenuItem,
   MenuList,
   Paper,
   Popper,
   Typography,
} from "@mui/material";
import { useState, useContext, useEffect, useRef } from "react";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ClientCreationContext } from "../../../../context/officeContexts/clientCreationContext";
import {
   getCompanyById,
   getInCompletedCompanies,
   getManyCompaniesById,
   useUpdateGroupCompanies,
} from "../../../../lib/usersBEClient";
import { Group } from "../../../../types/BaseTypes";
import { getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { UserContext } from "../../../../context/userContext";
import { LoadingButton } from "../../../LoadingButton";

const detailsHeaders: HeadCell[] = [
   { field: "check", headerName: "", type: "check" },
   { field: "comercialName", headerName: "Nombre comercial", type: "string" },
   { field: "businessName", headerName: "Razón social", type: "string" },
   { field: "createdAt", headerName: "Fecha de creación", type: "string" },
];

export const CompaniesSelectorComponet = ({ onComplete }) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(UserContext);
   const { selectedClient, setSelectedClient, refetch } = useContext(ClientCreationContext);
   const group = selectedClient.data as Group;

   const [selected, setSelected] = useState<readonly string[]>([]);
   const [isLoadingInfo, setIsLoadingInfo] = useState(true);
   // const { group, getGroupInfo, setClientInfo, refreshGroupInfo, setGroup } = useContext(ClientConfigContext);

   const [openModal, setOpenModal] = useState<boolean>(false);
   const [company, setCompany] = useState([]);
   const [company2, setCompany2] = useState([]);
   const { mutate: updateGroup } = useUpdateGroupCompanies();
   const [open, setOpen] = useState(false);
   const anchorRef = useRef<HTMLButtonElement>(null);

   const handleCancel = () => setSelectedClient(null);

   useEffect(() => {
      const fetchCompanies = async () => {
         const office = await getCompanyById(group.owner);
         const officeCompanies = office.data?.company_details?.office?.companies;
         const companyResponseObtained = await getManyCompaniesById(officeCompanies);
         const companyInfo = companyResponseObtained.map((comp) => ({
            id: comp._id,
            comercialName: comp.person_details.comercialName,
            businessName: comp.person_details.businessName,
            createdAt: getDateAndTimeFromISODate(comp.createdAt),
         }));
         const companyInfo2 =
            group?.companies.length > 0
               ? group.companies.map((comp) => ({
                    id: comp._id,
                    comercialName: comp.person_details.comercialName,
                    businessName: comp.person_details.businessName,
                    createdAt: getDateAndTimeFromISODate(comp.createdAt),
                 }))
               : [];
         const filteredCompanies = companyInfo.filter((company) => !company.group);
         setCompany(filteredCompanies);
         setCompany2(companyInfo2);
         setIsLoadingInfo(false);
      };
      fetchCompanies();
      // eslint-disable-next-line
   }, [group, companySelected]);

   const handleSubmit = async () => {
      if (selected.length < 1 && group?.companies.length > 0) {
         // props.setValue(2);
      } else {
         try {
            updateGroup(
               { companies: selected, groupId: group._id },
               {
                  onSuccess: async () => {
                     setSelected([]);
                     refetch();
                     showSnackBar("Las empresas se agregaron correctamente", false);
                     // props.setValue(2);
                  },
                  onError: () => {
                     showSnackBar("Error al agregar empresas", true);
                  },
               }
            );
         } catch (error) {
            console.log({ error });
            return;
         }
      }
   };

   // const handleToggle = () => {F
   //    setOpen((prevOpen) => !prevOpen);
   // };

   // const handleClose = (event: Event | React.SyntheticEvent) => {
   //    if (anchorRef.current?.contains(event.target as HTMLElement)) {
   //       return;
   //    }

   //    setOpen(false);
   // };

   // function handleListKeyDown(event: React.KeyboardEvent) {
   //    if (event.key === "Tab") {
   //       event.preventDefault();
   //       setOpen(false);
   //    } else if (event.key === "Escape") {
   //       setOpen(false);
   //    }
   // }

   const prevOpen = useRef(open);
   useEffect(() => {
      if (prevOpen.current === true && open === false) {
         anchorRef.current.focus();
      }

      prevOpen.current = open;
   }, [open]);

   return (
      <Box sx={{ minWidth: 800 }}>
         <Box
            sx={{
               border: 1,
               borderColor: "#E0E0E0",
               borderRadius: 2,
            }}
         >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
               <Typography fontWeight={600} variant="h6" sx={{ px: 2, py: 2 }}>
                  Empresas del cliente
               </Typography>
               {/* <Button
                  variant="contained"
                  sx={{ minHeight: "100%", mx: 2 }}
                  size="small"
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
               >
                  <AddCircleIcon sx={{ marginRight: 1 }} />
                  <Typography ref={anchorRef} variant="button" sx={{ mr: 1 }} onClick={() => handleToggle}>
                     Agregar Empresa
                  </Typography>
               </Button> */}
               {/* <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  style={{ zIndex: 20 }}
               >
                  {({ TransitionProps, placement }) => (
                     <Grow
                        {...TransitionProps}
                        style={{
                           transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                        }}
                     >
                        <Paper>
                           <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                 autoFocusItem={open}
                                 id="composition-menu"
                                 aria-labelledby="composition-button"
                                 onKeyDown={handleListKeyDown}
                              >
                                 <MenuItem
                                    onClick={(event) => {
                                       // setClientInfo(undefined);
                                       setOpenModal(!openModal);
                                       // props.setTypeClient(1);
                                       // props.handleCreate();
                                       handleToggle();
                                       handleClose(event);
                                    }}
                                 >
                                    Persona física
                                 </MenuItem>
                                 <MenuItem
                                    onClick={(event) => {
                                       // setClientInfo(undefined);
                                       setOpenModal(!openModal);
                                       // props.setTypeClient(2);
                                       // props.handleCreate();
                                       handleToggle();
                                       handleClose(event);
                                    }}
                                 >
                                    Persona Moral
                                 </MenuItem>
                              </MenuList>
                           </ClickAwayListener>
                        </Paper>
                     </Grow>
                  )}
               </Popper> */}
            </Box>
            <Divider />
            <TableComponent
               disableBorders
               defaultColumnToOrder="name"
               defaultOrder="asc"
               defaultRowsPerPage={3}
               rowsPerPageOptions={[]}
               emptyDataText="Este cliente no tiene empresas por el momento"
               headers={detailsHeaders}
               //upRows={props.itsOnClientconfiguration ? undefined : company2}
               rows={company}
               onClick={() => {}}
               loader={isLoadingInfo}
               checkboxSelection={true}
               setSelected={setSelected}
               selectedRows={selected}
               multiselect={true}
               filters={[{ allOption: "", data: [], id: "comercialName", label: "Nombre Empresa", type: "text" }]}
               filtersInitialState={{ comercialName: "" }}
            />
         </Box>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", mt: 2, mx: 2 }}>
            <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
               Cancelar
            </Button>
            <LoadingButton label={false ? "Guardar cambios" : "Continuar"} isLoading={false} onClick={handleSubmit} />
         </Box>
      </Box>
   );
};
