import { Stack } from "@mui/material";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { SnackBarContext } from "../../context/snackBarContext";
import { CreateFolder } from "../../lib/usersBEClient";
import { CustomModalComponent } from "../CustomModalComponent";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "../LoadingButton";
import { FilesContext } from "../../context/governanceContext/filesContext";

interface CreateFolderModalProps {
   openModal: boolean;
   setOpenModal: Function;
   selected: string;
   value?: string;
   setName: Function;
   reloadFiles: boolean;
   setReloadFiles: Function;
   main?: boolean;
   setOpenMenu?: Function;
}

export const CreateBeneficiaryFolderModal = ({
   openModal,
   setOpenModal,
   selected,
   value,
   setName,
   reloadFiles,
   setReloadFiles,
   setOpenMenu,
}: CreateFolderModalProps) => {
   const { id, companyId } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate } = CreateFolder();
   const [isLoadingCreate, setIsLoadingCreate] = useState(false);
   const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
   const { documentSelected } = useContext(FilesContext);

   const handleChange = (newValue: Dayjs | null) => {
      setSelectedDate(newValue);
      if (newValue) setName(newValue.format("YYYY"));
   };

   const handleCreateFolder = () => {
      if (!selectedDate) return showSnackBar("Favor de seleccionar un año.", true);
      setIsLoadingCreate(true);
      mutate(
         {
            name: value,
            parent: selected ? selected : undefined,
            governance: id,
            companyId,
            owner: null,
         },
         {
            onError: () => {
               showSnackBar("Error al agregar carpeta.", true);
               setIsLoadingCreate(false);
            },
            onSuccess: async () => {
               showSnackBar("La carpeta se creo correctamente.", false);
               setIsLoadingCreate(false);
               if (setOpenMenu) setOpenMenu();
               else setReloadFiles(!reloadFiles);
               handleClose();
            },
         }
      );
   };

   const handleClose = () => {
      setOpenModal(false);
      setName("");
      setSelectedDate(null);
   };

   const shouldDisableYear = (year: Dayjs) => {
      if (!documentSelected || !documentSelected.children) return false;
      const disabledYears = documentSelected.children.map((c) => Number(c.name)).filter((n) => !isNaN(n));
      return disabledYears.includes(year.year());
   };

   return (
      <CustomModalComponent open={openModal} setOpen={setOpenModal} title={"Crear carpeta"} onClose={handleClose}>
         <Stack sx={{ p: 2, minWidth: 300 }} rowGap={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
               <DatePicker
                  views={["year"]}
                  label="Fecha de la carpeta"
                  value={selectedDate}
                  onChange={handleChange}
                  maxDate={dayjs()}
                  shouldDisableYear={shouldDisableYear}
               />
            </LocalizationProvider>
            <LoadingButton isLoading={isLoadingCreate} label={"Crear"} onClick={handleCreateFolder} fullWidth />
         </Stack>
      </CustomModalComponent>
   );
};
