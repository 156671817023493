import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { CouncilStepperPercentage } from "./SubComponents/CouncilStepperPercentage";
import { EvaluationQuestionary } from "./SubComponents/EvaluationQuestionary";
import { useContext, useEffect, useMemo, useState } from "react";
import { EvaluationContext } from "../../../context/governanceContext/evaluationContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { AddEvaluationResponses, modifyUserEvaluationProgress } from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { ConfirmationModal } from "../../ConfirmationModal";
import { getUserDataGB } from "../../../lib/usersBEClient";
import { CustomModalComponent } from "../../CustomModalComponent";
import { UserContext } from "../../../context/userContext";

export const EvaluationModal = ({ setOpenModalEv, open }) => {
   const {
      fetchQuestions,
      setActiveCouncil,
      verifyStepsToContinue,
      activePersonEvaluation,
      activeCouncil,
      dotMembersByCouncil,
      setActivePersonEvaluation,
      progressMembers,
      total,
      responsesByCouncil,
      refreshInfo,
      setUserData,
      evaluation,
      setResponsesByCouncil,
      setFinished,
   } = useContext(EvaluationContext);
   const { gobernanceBody } = useContext(GovernanceContext);
   const { companySelectedUserP } = useContext(UserContext);
   const { mutate } = AddEvaluationResponses();
   const { showSnackBar } = useContext(SnackBarContext);
   const [openModal, setOpenModal] = useState(false);
   const [openModalVerify, setOpenModalVerify] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [isLoadingSave, setIsLoadingSave] = useState(false);

   useEffect(() => {
      fetchQuestions();
   }, []);

   const filteredGoverning = useMemo(() => {
      if (gobernanceBody.length > 0) {
         const filtered = gobernanceBody.filter(
            (governance) =>
               governance.title !== "PANEL DE USUARIO" &&
               governance.title.toLocaleLowerCase() !== "asamblea de accionistas" &&
               governance.company === companySelectedUserP._id
         );
         return filtered;
      } else {
         return [];
      }
   }, [gobernanceBody, companySelectedUserP._id]);

   useEffect(() => {
      const fetchUsers = async () => {
         let users = [];
         for (const governance of filteredGoverning) {
            users.push(...governance.users);
         }
         const unique = users.filter((obj, index) => users.findIndex((item) => item.user === obj.user) === index);
         const response = await getUserDataGB(unique.map((user) => user.user));
         setUserData(response);
         setIsLoading(false);
      };
      fetchUsers();
   }, [filteredGoverning]);

   const saveProgress = () => {
      const responsesNotSaved = responsesByCouncil.filter((res) => !res._id);
      mutate(
         { responses: responsesNotSaved },
         {
            onSuccess: async () => {
               showSnackBar("Progreso guardado con éxito.", false);
            },
            onError: (error: any) => {
               showSnackBar("Error al completar la petición", true);
               return null;
            },
         }
      );
      setResponsesByCouncil([
         ...responsesByCouncil.filter((res) => res._id),
         ...responsesNotSaved.map((res) => {
            return {
               _id: "notNull",
               ...res,
            };
         }),
      ]);
   };

   const verifySteps = async () => {
      const percentage = [];
      for (const iterator of Object.keys(progressMembers)) {
         percentage.push(progressMembers[iterator]);
      }
      const fullCouncil = percentage.some((value) => value < 1);
      const verify = verifyStepsToContinue();
      if (!verify) {
         showSnackBar("Faltan datos por llenar.", true);
         return;
      }
      setIsLoadingSave(true);
      saveProgress();
      if (!fullCouncil) {
         if (total(filteredGoverning) === responsesByCouncil.length) {
            await modifyUserEvaluationProgress(
               evaluation._id,
               filteredGoverning[filteredGoverning.length - 1]._id,
               filteredGoverning[filteredGoverning.length - 1]._id,
               true
            );
            setFinished(true);
            setOpenModalEv(false);
            setOpenModal(false);
            setOpenModalVerify(true);
         } else {
            for (let i = 0; i < filteredGoverning.length; i++) {
               const element = filteredGoverning[i];
               if (element._id === activeCouncil._id) {
                  setActiveCouncil(filteredGoverning[i + 1]);
                  setActivePersonEvaluation(null);
                  setOpenModalVerify(false);
                  await modifyUserEvaluationProgress(
                     evaluation._id,
                     filteredGoverning[i + 1]._id,
                     filteredGoverning[i + 1]._id
                  );
               }
            }
         }
      } else {
         if (activePersonEvaluation) {
            for (let i = 0; i < dotMembersByCouncil.length; i++) {
               if (dotMembersByCouncil[i].user === activePersonEvaluation.user) {
                  setActivePersonEvaluation(dotMembersByCouncil[i + 1]);
                  setOpenModalVerify(false);
                  await modifyUserEvaluationProgress(evaluation._id, dotMembersByCouncil[i + 1]._id, activeCouncil._id);
               }
            }
         } else {
            for (let i = 0; i < dotMembersByCouncil.length; i++) {
               if (dotMembersByCouncil[i]._id === activeCouncil._id) {
                  setActivePersonEvaluation(dotMembersByCouncil[i + 1]);
                  setOpenModalVerify(false);
                  await modifyUserEvaluationProgress(evaluation._id, dotMembersByCouncil[i + 1]._id, activeCouncil._id);
               }
            }
         }
      }
      setIsLoadingSave(false);
   };

   const handleClose = () => {
      setResponsesByCouncil([...responsesByCouncil.filter((res) => res._id)]);
      setOpenModalEv(false);
   };

   return (
      <CustomModalComponent
         title={"Evaluación de desempeño"}
         open={open}
         setOpen={setOpenModalEv}
         timeStamp
         onClose={handleClose}
      >
         <Stack>
            <Box sx={{ display: "flex" }}>
               {isLoading ? (
                  <Box
                     sx={{
                        minHeight: 300,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                     }}
                  >
                     <CircularProgress />
                  </Box>
               ) : (
                  <>
                     <CouncilStepperPercentage steps={filteredGoverning} />
                     <EvaluationQuestionary steps={filteredGoverning} />
                  </>
               )}
            </Box>
            <Stack
               direction={"row"}
               sx={{
                  p: 1,
                  bgcolor: "#F3F3F3",
                  justifyContent: "flex-end",
                  borderBottomRightRadius: 8,
                  borderBottomLeftRadius: 8,
               }}
            >
               <Button onClick={() => setOpenModal(true)}>Cancelar</Button>
               <Button onClick={() => setOpenModalVerify(true)}>Continuar</Button>
            </Stack>
            <ConfirmationModal
               open={openModal}
               setOpen={setOpenModal}
               onConfirm={handleClose}
               title="Cancelar evaluación"
               isLoading={isLoadingSave}
               body={
                  <Stack>
                     <Typography align="center">¿Está seguro de cancelar la evaluación?</Typography>
                     <Typography align="center" color={"red"} fontSize={12}>
                        Al confirmar, no será posible guardar su progreso.
                     </Typography>
                  </Stack>
               }
            />
            <ConfirmationModal
               open={openModalVerify}
               setOpen={setOpenModalVerify}
               onConfirm={() => verifySteps()}
               title="Confirmar registro de avance"
               isLoading={isLoadingSave}
               body={
                  <Stack>
                     <Typography align="center">
                        ¿Está seguro de continuar a la siguiente sección para evaluar?
                     </Typography>
                     <Typography align="center" color={"red"} fontSize={12}>
                        Al confirmar, se guardara el progreso y no podrá realizar modificaciones.
                     </Typography>
                  </Stack>
               }
            />
         </Stack>
      </CustomModalComponent>
   );
};
