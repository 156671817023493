import { Box, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { LocaleDateString } from "../../../../const/globalConst";
import { useGetSessionsAttendanceInfo } from "../../../../hooks/gob-corp/governmentBody/useGetSessionsAttendanceInfo";
import { useState } from "react";
import ListComponent from "../../../ListComponent";

const SessionAttendanceListByTrimester = (props) => {
   const { selectedSession, setSelectedSession, setIsFilteredAffairReady } = props;
   const [selectedQuarter, setSelectedQuarter] = useState("");
   const [selectedSessionInfo, setSelectedSessionInfo] = useState(false);
   const [sessionInfo, setSessionInfo] = useState({ index: 0, title: "", sessionDate: "" });

   const trimesterSessionsInfo = useGetSessionsAttendanceInfo({ ...props });

   return (
      <Stack direction={"column"} color={"#152C44"} sx={{ maxHeight: "420px", overflowY: "auto" }}>
         {selectedSessionInfo ? (
            <Box pb={0}>
               <Typography sx={{ opacity: "70%" }}>{selectedQuarter.replace("trimester", "Trimestre ")}</Typography>
               <Stack direction={"column"}>
                  <ListItem
                     sx={{ bgcolor: "#F5F5F5", mt: 1, ":hover": { bgcolor: "#D8D8D8", cursor: "pointer" } }}
                     dense
                     onClick={() => {
                        setIsFilteredAffairReady(true);
                        setSelectedSessionInfo((value) => !value);
                     }}
                  >
                     <Grid container>
                        <Grid item xs={6}>
                           <Stack direction={"column"}>
                              <Typography fontWeight={600} variant="button">
                                 {`Sesión ${sessionInfo?.index + 1}.`}
                              </Typography>
                              <Typography variant="caption">{sessionInfo.title}</Typography>
                           </Stack>
                        </Grid>
                        <Grid item xs={6} display={"flex"} alignContent={"center"}>
                           <Typography alignSelf={"center"} variant="button">
                              {LocaleDateString(new Date(sessionInfo.sessionDate))}
                           </Typography>
                        </Grid>
                     </Grid>
                  </ListItem>
                  <Box sx={{ width: "100%", height: "335px", border: "1px solid #e4e4e4", px: 2 }}>
                     <ListComponent
                        headers={[
                           { headerTitle: "Invitado", type: "text", bodyPosition: "center" },
                           { headerTitle: "Asistencia", type: "check", bodyPosition: "center" },
                        ]}
                        rows={
                           (selectedSession.involved.length > 0 &&
                              selectedSession.involved
                                 .map((user) => {
                                    if (user === undefined) return;
                                    return {
                                       user: user.fullName,
                                       attended: { attended: user.attended },
                                    };
                                 })
                                 .filter((user) => user)) ||
                           []
                        }
                        rowsColumnProps={() => {
                           return {
                              px: 2,
                           };
                        }}
                        rowProps={(row, i) => {
                           return {
                              bgcolor: "white",
                              border: "1px solid #ededed",
                              borderLeft: 7,
                              borderBottomLeftRadius: 4,
                              borderLeftColor: "#46566a",
                              py: 1,
                              my: 0.5,
                           };
                        }}
                        centerCheckIcon="center"
                        colorCheckIcon="#46566a"
                        fontSizeTypeText="30px"
                        centerTypeText="center"
                     />
                  </Box>
               </Stack>
            </Box>
         ) : (
            Object.keys(trimesterSessionsInfo).map((trimester) => {
               return (
                  <List key={`session-${trimester}`}>
                     <Typography sx={{ opacity: "70%" }}>{trimester.replace("trimester", "Trimestre ")}</Typography>
                     <SessionInfo
                        selectedSession={selectedSession}
                        setSelectedSession={setSelectedSession}
                        sessionList={trimesterSessionsInfo[trimester]}
                        trimester={trimester}
                        setSelectedQuarter={setSelectedQuarter}
                        setSelectedSesionInfo={setSelectedSessionInfo}
                        setSessionInfo={setSessionInfo}
                        categoriesByCompany={props.categoriesByCompany}
                     />
                  </List>
               );
            })
         )}
      </Stack>
   );
};

const SessionInfo = (props) => {
   const {
      // selectedSession,
      setSelectedSession,
      sessionList,
      trimester,
      setSelectedQuarter,
      setSelectedSesionInfo,
      setSessionInfo,
      categoriesByCompany,
   } = props;

   // const { selectedGovernance } = useContext(GovernanceContext);

   return (
      (sessionList.length > 0 &&
         sessionList.map((session, i) => {
            return (
               <Stack direction={"column"} key={`${session.title}-${i}`}>
                  <ListItem
                     sx={{
                        bgcolor: "white",
                        my: 1,
                        borderBottom: "1px solid #D9D9D9",
                        ":hover": { bgcolor: "#D8D8D8", cursor: "pointer" },
                     }}
                     dense
                     onClick={() => {
                        setSelectedSession({
                           _id: session._id,
                           involved: session.involved,
                           affairs: session.affairs,
                           newAffairs: session.newAffairs,
                           votes: session.votes,
                           categoryType: session.categoryType,
                           categoriesByCompany: categoriesByCompany,
                           proclamation: session.proclamation,
                        });
                        setSelectedQuarter(trimester);
                        setSessionInfo({ index: i, title: session.title, sessionDate: session.sessionDate });
                        setSelectedSesionInfo((value) => !value);
                     }}
                  >
                     <Grid container>
                        <Grid item xs={6}>
                           <Stack direction={"column"}>
                              <Typography fontWeight={600} variant="button">
                                 {`Sesión ${i + 1}.`}
                              </Typography>
                              <Typography variant="caption">{session.title}</Typography>
                           </Stack>
                        </Grid>
                        <Grid item xs={6} display={"flex"} alignContent={"center"}>
                           <Typography alignSelf={"center"} variant="button">
                              {LocaleDateString(new Date(session.sessionDate))}
                           </Typography>
                        </Grid>
                     </Grid>
                  </ListItem>
                  {/* {selectedGovernance.title === "Asamblea de accionistas" && (
                     <Collapse in={selectedSession?._id === session._id}>
                        <AffairsList sessionAffairs={session.affairs} />
                     </Collapse>
                  )} */}
               </Stack>
            );
         })) || <Typography>Sin datos</Typography>
   );
};

export default SessionAttendanceListByTrimester;
