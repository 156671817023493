import { Box, Card, Divider, Typography } from "@mui/material";
import CarouselComponent from "../../CarouselComponent";
import InsightsComponent from "../../Gobierno corporativo/DashboardScreen/SubComponent/InsightsComponent";

export const GuidesBeneficiary = () => {
   //const { insightsData } = useContext(BeneficiaryControllerContext);

   const manual = {
      title: "Manual de Beneficiario Controlador",
      date: "",
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/manualBeneficiaro.png",
      link: "https://images-lecosy.s3.us-west-2.amazonaws.com/manuals/Manual+de+usuario+Beneficiario+Controlador.pdf",
      category: ["Guías y herramientas"],
   };

   return (
      <Card sx={{ borderRadius: 2, pt: 2, pb: 1, flex: 1 }}>
         <Box sx={{ px: 3 }}>
            <Typography color={"#162c44"} fontSize={20} fontWeight={700}>
               Recomendaciones clave
            </Typography>
            <Divider sx={{ my: 1 }} />
         </Box>
         <Box sx={{ px: 4 }}>
            <CarouselComponent maxElements={1} hideSlide={true} rows={1}>
               <InsightsComponent insight={manual} disableFavorite />
            </CarouselComponent>
         </Box>
      </Card>
   );
};
