import { Box, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useState } from "react";
import ShareHolderRegistry from "../../components/Gobierno corporativo/CorporateDataScreen/ShareHolderRegistry";
import SocietaryAnalysis from "../../components/Gobierno corporativo/CorporateDataScreen/SocietaryAnalysis";
import { AddShareholderModal } from "../../components/MiLecosy/ModalsGovernanceModule/AddShareholderModal";
import SeriesRegistry from "../../components/Gobierno corporativo/CorporateDataScreen/SeriesRegistry";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { UserContext } from "../../context/userContext";
import { Navigate } from "react-router-dom";
import { SnackBarContext } from "../../context/snackBarContext";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}
function CustomTabPanel(props: Readonly<TabPanelProps>) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box>{children}</Box>}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}
const CorporateDataScreen = () => {
   const [value, setValue] = useState(0);
   const [openModal, setOpenModal] = useState(false);
   const { series } = useContext(CorporateDataContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <Stack sx={{ maxWidth: 1380 }}>
         <Stack direction={"row"}>
            <Tabs value={value} onChange={handleChange} sx={{ flex: 1 }}>
               <Tab label={"Registro de accionistas"} {...a11yProps(0)} />
               <Tab label={"Análisis societario"} {...a11yProps(1)} />
               <Tab label={"Clasificación y series"} {...a11yProps(2)} />
            </Tabs>
            <Stack
               flex={1}
               direction={"row"}
               justifyContent={"flex-end"}
               spacing={2}
               display={value === 0 ? "auto" : "none"}
            >
               <Box alignSelf={"center"}>
                  <Button
                     sx={{ bgcolor: "#162c44", color: "white", ":hover": { bgcolor: "#162c44" }, px: 2 }}
                     onClick={() => {
                        if (series.length > 0) setOpenModal(!openModal);
                        else showSnackBar("Favor de crear series antes de agregar accionistas", true);
                     }}
                  >
                     <Typography>Agregar nuevo</Typography>
                  </Button>
               </Box>
            </Stack>
         </Stack>
         <CustomTabPanel value={value} index={0}>
            <ShareHolderRegistry />
         </CustomTabPanel>
         <CustomTabPanel value={value} index={1}>
            <SocietaryAnalysis />
         </CustomTabPanel>
         <CustomTabPanel value={value} index={2}>
            <SeriesRegistry />
         </CustomTabPanel>

         {series.length > 0 && <AddShareholderModal state={openModal} setState={setOpenModal} />}
      </Stack>
   );
};

export default CorporateDataScreen;
