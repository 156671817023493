import {
   Box,
   Typography,
   Button,
   CircularProgress,
   Autocomplete,
   TextField,
   IconButton,
   Tooltip,
   Divider,
} from "@mui/material";
import { ClientCreationContext } from "../../../../context/officeContexts/clientCreationContext";
import { Companies, Group, Users } from "../../../../types/BaseTypes";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { getMemberChargesByCompany, getMemberChargesByManyCompanies } from "../../../../lib/gobCorpBEClient";
import { useCallback, useContext, useEffect, useState } from "react";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { catchAxiosError } from "../../../../lib/lecosyBackendClient";
import {
   addUserRoleOffice,
   GetAllUserRoles,
   GetCompanyUsers,
   GetGroupUsers,
   getUserByEmail,
   removeRoleFromUser,
   updateClientUserRoles,
   updateCompanyAdminUser,
   useCreateUserOffice,
} from "../../../../lib/usersBEClient";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { InputTextField } from "../../../Inputs/InputTextField";
import { Info } from "@mui/icons-material";
import { LoadingButton } from "../../../LoadingButton";
import { createUserSchema } from "../../../../lib/validations/inputSchemas";
import GetAdminRoles from "../../../../hooks/companies-branches/getAdminRoles";
import { UserContext } from "../../../../context/userContext";

interface UserDetailsComponentProps {
   group?: Group;
   onComplete: Function;
   value: number;
}

const userColumns: HeadCell[] = [
   { field: "name", headerName: "Nombre", type: "string" },
   { field: "roles", headerName: "Rol", type: "popover", width: 200 },
   { field: "email", headerName: "Correo electrónico ", type: "string" },
];

interface RoleInterface {
   _id: string;
   name: string;
   description: string;
}

export const UserDetailsComponent = (props: UserDetailsComponentProps) => {
   const { companySelected } = useContext(UserContext);
   const { selectedClient, setSelectedClient, refetch, isLoading } = useContext(ClientCreationContext);
   const selectedCompany = selectedClient.data as Companies;

   const getUsers = () => {
      if (selectedCompany?.group) {
         return GetCompanyUsers(selectedCompany, { groupId: (selectedCompany.group as Group)._id });
      }
      if (!selectedCompany?.group && !props.group) {
         if (props.value === 1 || props.value === 3) {
            return GetCompanyUsers(selectedCompany);
         }
      }
      return GetGroupUsers(props.group);
   };

   const { data, isLoading: isLoadingUsers = false } = getUsers();
   const { data: roleList, isLoading: isLoadingRoles } = GetAllUserRoles();
   const [selectedRoles, setSelectedRoles] = useState([]);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate, isLoading: isLoadingCreate } = useCreateUserOffice();
   const [rolesSelectedError, setRolesSelectedError] = useState(false);
   const [presidentExists, setPresidentExists] = useState(false);
   const [directorExists, setDirectorExists] = useState(false);
   const [userSelected, setUserSelected] = useState<Users | null>(null);
   const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
   const [userfromGroup, setUserfromGroup] = useState(false);
   const [usedRoles, setUsedRoles] = useState([]);
   const [userSelectedFlag, setUserSelectedFlag] = useState(false);
   const [companyUsers, setCompanyUsers] = useState([]);
   const [foundByEmail, setFoundByEmail] = useState(false);
   const GCRoles = ["N1", "N2", "N3", "N4", "N5", "N6", "N7", "N8", "N9"];

   const handleCancel = () => setSelectedClient(null);

   const fetchCompanyUsers = useCallback(async () => {
      try {
         if (!isLoadingUsers) {
            if (props.group) {
               const usersData = (props.group.users as any[]).map((user) => ({
                  _id: user._id,
                  name: user.firstName + " " + user.lastName,
                  email: user.email,
                  roles: getRoles(user.role).length > 0 ? getRoles(user.role) : ["sin datos"],
               }));

               const gcUserIds = usersData
                  .filter((user) => user.roles.some((r) => GCRoles.includes(r)))
                  .map((user) => user._id);

               if (gcUserIds.length > 0) {
                  const companyIds = props.group.companies.map((company) => company._id);
                  const chargesResponses = await getMemberChargesByManyCompanies(gcUserIds, companyIds);
                  const GcChargesResponse = chargesResponses;
                  for (const user of usersData) {
                     const index = GcChargesResponse.findIndex((c) => c.user === user._id);
                     if (index >= 0) {
                        user.roles = user.roles.concat(GcChargesResponse[index].charges);
                        user.roles = user.roles.filter((r) => !GCRoles.includes(r));
                     }
                  }
               }
               setCompanyUsers(usersData);
            } else if (selectedCompany) {
               const rolesSet: string[] = [];
               selectedCompany.company_details?.users.forEach((user: any) => {
                  if (user.role) {
                     user.role.some((userRole) => {
                        if (userRole.company === selectedCompany._id) {
                           userRole.roles.forEach((uniqueRole) => {
                              const { name } = uniqueRole;
                              if (
                                 name === "Administrador Único / Presidente del Consejo" ||
                                 name === "Director General"
                              )
                                 rolesSet.push(name);
                           });
                           return true;
                        }
                        return false;
                     });
                  }
               });

               const rolesFromCompanyArray = new Set(rolesSet);
               if (Array.from(rolesFromCompanyArray).includes("Administrador Único / Presidente del Consejo")) {
                  setPresidentExists(true);
               }
               if (Array.from(rolesFromCompanyArray).includes("Director General")) {
                  setDirectorExists(true);
               }
               setUsedRoles(Array.from(rolesFromCompanyArray));
               const usersData = (selectedCompany.company_details?.users as any[]).map((user) => ({
                  _id: user._id,
                  uid: user.uid,
                  name: user.firstName + " " + user.lastName,
                  email: user.email,
                  roles: getRoles(user.role).length > 0 ? getRoles(user.role) : ["sin datos"],
               }));

               const gcUserIds = usersData
                  .filter((user) => user.roles.some((r) => GCRoles.includes(r)))
                  .map((user) => user._id);

               if (gcUserIds.length > 0) {
                  const GcChargesResponse = await getMemberChargesByCompany(gcUserIds, selectedCompany?._id);
                  for (const user of usersData) {
                     const index = GcChargesResponse.findIndex((c) => c.user === user._id);
                     if (index >= 0) {
                        user.roles = user.roles.concat(GcChargesResponse[index].charges);
                        user.roles = user.roles.filter((r) => !GCRoles.includes(r));
                     }
                  }
               }
               setCompanyUsers(usersData);
            }
         }
      } catch (error) {
         console.error("Error fetching roles:", error);
      }
   }, [data, selectedCompany, props.group?.users, isLoadingUsers]);

   useEffect(() => {
      fetchCompanyUsers();
   }, [fetchCompanyUsers]);

   useEffect(() => {
      if (selectedRoles.length > 0 || props.value === 1) setRolesSelectedError(false);
      // eslint-disable-next-line
   }, [selectedRoles]);

   const handleSubmit = async (values, actions) => {
      try {
         if (!userfromGroup) {
            if (props.value === 1 && selectedCompany && selectedCompany.company_details.admin) {
               try {
                  const response = await updateCompanyAdminUser(
                     selectedCompany.company_details.admin.uid,
                     values.firstName,
                     values.lastName,
                     values.email,
                     values.phoneNumber
                  );
                  if (response.data) {
                     refetch();
                     showSnackBar("Usuario modificado exitosamente", false);
                     setIsLoadingUpdate(false);
                  }
               } catch (error) {
                  const errorMessage = catchAxiosError(error);
                  if (errorMessage.message.includes("no user found")) showSnackBar("Error, intente de nuevo", true);
                  if (errorMessage.message.includes("email-already-exists"))
                     showSnackBar("Correo electrónico en uso", true);
                  setIsLoadingUpdate(false);
               }
               return;
            } else if (
               selectedRoles.length > 0 ||
               props.value === 1 /*&& values.email !== clientInfo.company_details?.admin.email*/
            ) {
               const roles = selectedRoles.map((role) => role._id);
               const rolesFormat = [{ company: selectedCompany._id, roles: roles }];
               mutate(
                  {
                     userData: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phone,
                        role: props.value === 1 ? null : rolesFormat,
                     },
                     additionalData: {
                        admin: props.value === 1,
                        company: selectedCompany ? selectedCompany._id : null,
                        group: props.group ? props.group._id : selectedCompany.group ? selectedCompany.group : null,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar administrador.", true);
                     },
                     onSuccess: async () => {
                        actions.resetForm();
                        setSelectedRoles([]);
                        props.value === 1
                           ? showSnackBar("El administrador fue agregado correctamente.", false)
                           : showSnackBar("El usuario fue agregado correctamente.", false);
                        if (props.value === 1) props.onComplete();
                        refetch();
                        if (props.value === 3 && usedRoles.length + selectedRoles.length === 2) {
                           props.onComplete();
                        }
                     },
                  }
               );
               return;
            } else if (props.value === 3 && props.group) {
               mutate(
                  {
                     userData: {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phone,
                        role: [],
                     },
                     additionalData: {
                        admin: false,
                        group: props.group._id,
                     },
                  },
                  {
                     onError: (error: any) => {
                        error.response.data.message === "email already in use"
                           ? showSnackBar("Ya existe un usuario con ese correo", true)
                           : showSnackBar("Error al agregar administrador.", true);
                     },
                     onSuccess: async () => {
                        refetch();
                        actions.resetForm();
                        setSelectedRoles([]);
                        props.value === 1
                           ? showSnackBar("El administrador fue agregado correctamente.", false)
                           : showSnackBar("El usuario fue agregado correctamente.", false);
                     },
                  }
               );
            } else {
               setRolesSelectedError(true);
               return;
            }
         } else if (props.value === 1 && selectedCompany.company_details /* && clientInfo.company_details.admin*/) {
            const roles = selectedRoles.map((role) => role._id);
            const rolesFormat = [{ company: selectedCompany._id, roles: roles }];
            const response = await updateClientUserRoles(
               userSelected._id,
               userfromGroup,
               true,
               selectedCompany ? selectedCompany._id : props.group._id,
               rolesFormat
            );
            if (response.data) {
               refetch();
               showSnackBar("Usuario modificado exitosamente", false);
               actions.resetForm();
               setIsLoadingUpdate(false);
            }
         } else if (props.value === 3 && selectedCompany.company_details /* && clientInfo.company_details.admin*/) {
            const roles = selectedRoles.map((role) => role._id);
            const rolesFormat = [{ company: selectedCompany._id, roles: roles }];
            const response = await updateClientUserRoles(
               userSelected._id,
               userfromGroup,
               false,
               selectedCompany ? selectedCompany._id : props.group._id,
               rolesFormat
            );
            if (response.data) {
               refetch();
               setSelectedRoles([]);
               showSnackBar("Usuario modificado exitosamente", false);
               actions.resetForm();
               setIsLoadingUpdate(false);
               if (props.value === 3 && usedRoles.length + selectedRoles.length === 2) {
                  props.onComplete();
               }
            }
         }
      } catch (error) {
         console.log({ error });
         return;
      }
   };

   function getRoles(roles: any[]) {
      const roleNames = roles ? roles.map((role) => role) : [];
      let rolesArray = [];
      for (const role of roleNames) rolesArray.push(role.roles.map((role) => role.name));
      rolesArray = rolesArray.flat();
      return rolesArray.filter((item, index) => rolesArray.indexOf(item) === index);
   }

   const usersGroupList = (() => {
      if (props.group && props.value === 1 && data) return data;
      if (props.group && props.value === 3 && selectedCompany) return props.group.users;
      if (selectedCompany?.group && !props.group && data) return data;
      return [];
   })();

   const searchUserByEmail = async (email: string, setFieldValue) => {
      if (!email || props.value !== 1 || email === selectedCompany?.company_details?.admin?.email) {
         return setFoundByEmail(null);
      }
      const userFound = await getUserByEmail(email);
      if (!userFound) return setFoundByEmail(null);
      setFoundByEmail(userFound._id);

      setFieldValue("firstName", userFound.firstName);
      setFieldValue("lastName", userFound.lastName);
      setFieldValue("email", userFound.email);
      setFieldValue("phone", userFound.phoneNumber);
   };

   const handleSubmitExisting = async () => {
      setIsLoadingUpdate(true);
      try {
         const data = {
            company: selectedCompany._id,
            roles: ["64f6261e2eb21c6d24e252c9"],
         };
         const roleAdded = await addUserRoleOffice(data, foundByEmail);
         if (!roleAdded) return showSnackBar("Error al agregar rol a usuario.", true);
         const currentAdminId = selectedCompany?.company_details?.admin?._id;
         if (currentAdminId) {
            await removeRoleFromUser(currentAdminId, selectedCompany._id, "64f6261e2eb21c6d24e252c9");
            showSnackBar("El administrador fue reemplazado correctamente", false);
         } else {
            showSnackBar("El usuario fue agregado correctamente", false);
         }
         refetch();
      } catch (error) {
         showSnackBar("Error al agregar usuario.", true);
      }
      setIsLoadingUpdate(false);
   };

   const FormObserver: React.FC = () => {
      const { setFieldValue } = useFormikContext();

      useEffect(() => {
         if (userSelected) {
            setUserSelectedFlag(true);
         } else {
            setUserSelectedFlag(false);
         }
         if (userSelected === null && userSelectedFlag) {
            setFieldValue("firstName", "");
            setFieldValue("lastName", "");
            setFieldValue("phone", "");
            setFieldValue("email", "");
            setUserfromGroup(false);
         } else if (userSelected) {
            setFieldValue("firstName", userSelected?.firstName);
            setFieldValue("lastName", userSelected?.lastName);
            setFieldValue("phone", userSelected?.phoneNumber);
            setFieldValue("email", userSelected?.email);
            setUserfromGroup(true);
         }
         // eslint-disable-next-line
      }, [userSelected]);

      return null;
   };

   const adminDetails = !isLoading && props.value === 1 && selectedCompany?.company_details?.admin;
   return (
      <Box flex={1}>
         <Formik
            initialValues={{
               firstName: adminDetails?.firstName ?? "",
               lastName: adminDetails?.lastName ?? "",
               phone: adminDetails?.phoneNumber ?? "",
               email: adminDetails?.email ?? "",
               role: [],
            }}
            validationSchema={createUserSchema}
            onSubmit={foundByEmail ? handleSubmitExisting : handleSubmit}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <FormObserver />
                  <Box
                     sx={{
                        border: 1,
                        borderColor: "#E0E0E0",
                        borderRadius: 2,
                        flex: 1,
                        maxHeight: "420px",
                        overflowY: "auto",
                     }}
                  >
                     {selectedCompany?.group &&
                     (props.value === 1 || props.value === 3) &&
                     data &&
                     !isLoading &&
                     !isLoadingUsers &&
                     data.length > 0 ? (
                        <>
                           <Box sx={{ p: 2 }}>
                              <Typography fontWeight={600} variant="h6">
                                 {props.value === 1
                                    ? `Administrador de sistema existente`
                                    : `Usuarios del grupo empresarial`}
                              </Typography>
                              <Autocomplete
                                 limitTags={2}
                                 id="user"
                                 options={
                                    props.group && (props.value === 1 || props.value === 3) ? usersGroupList : data
                                 }
                                 getOptionLabel={(option: Users) => `${option.firstName} ${option.lastName}`}
                                 onChange={(_event, newValue: Users | null) => setUserSelected(newValue as Users)}
                                 renderInput={(params) => (
                                    <TextField {...params} label="" placeholder="Selecciona un usuario" name="user" />
                                 )}
                                 sx={{ width: "auto", py: 1 }}
                              />
                           </Box>
                           <Divider />
                        </>
                     ) : (
                        selectedCompany?.group &&
                        props.value === 1 &&
                        (isLoading || isLoadingUsers) && (
                           <>
                              <Box
                                 sx={{
                                    justifyContent: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    p: 2,
                                 }}
                              >
                                 <CircularProgress size={24} />
                              </Box>
                              <Divider />
                           </>
                        )
                     )}
                     <Box
                        sx={{
                           display: "flex",
                           rowGap: 2,
                           flexDirection: "column",
                        }}
                     >
                        <Box
                           sx={{
                              px: 3,
                              pt: 2,
                              pb: props.value === 1 ? 3 : 0,
                              display: "flex",
                              rowGap: 2,
                              flexDirection: "column",
                           }}
                        >
                           <Typography fontWeight={600} variant="h6">
                              {props.value === 1 ? "Registro de administrador de sistema" : "Registro de usuarios"}
                           </Typography>
                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="firstName"
                                 id="firstName"
                                 type="text"
                                 label="Nombre"
                                 fullWidth={true}
                                 disabled={userfromGroup || foundByEmail}
                              />
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="lastName"
                                 id="lastName"
                                 type="text"
                                 label="Apellido"
                                 fullWidth={true}
                                 disabled={userfromGroup || foundByEmail}
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 2 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="phone"
                                 id="phone"
                                 type="text"
                                 label="Teléfono"
                                 fullWidth={true}
                                 disabled={userfromGroup || foundByEmail}
                              />
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 name="email"
                                 id="email"
                                 type="text"
                                 label="Correo electrónico"
                                 fullWidth={true}
                                 disabled={userfromGroup}
                                 onBlur={() => searchUserByEmail(formProps.values.email, formProps.setFieldValue)}
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 1 }}>
                              <InputTextField
                                 sx={{ my: 0.5 }}
                                 variant="outlined"
                                 size="small"
                                 value="DefaultPassword"
                                 name="comercialName"
                                 id="comercialName"
                                 type="password"
                                 label="Contraseña"
                                 disabled
                                 fullWidth={true}
                              />
                              <Tooltip
                                 title={"La contraseña se genera automáticamente y se enviara al usuario registrado"}
                                 placement="right-start"
                              >
                                 <IconButton>
                                    <Info />
                                 </IconButton>
                              </Tooltip>
                           </Box>
                           {foundByEmail && selectedCompany?.company_details?.admin && (
                              <Typography sx={{ textAlign: "center", color: "red", fontSize: 11 }}>
                                 El administrador actual se reemplazará por el encontrado por correo electrónico
                              </Typography>
                           )}
                           {props.value === 1 ||
                           (!selectedCompany?.company_details?.users && props.value !== 3) ||
                           (props.value === 3 && props.group) ? (
                              <></>
                           ) : (
                              <Autocomplete
                                 sx={{ width: "auto" }}
                                 multiple
                                 limitTags={2}
                                 id="role"
                                 value={selectedRoles}
                                 options={
                                    !isLoadingRoles && data
                                       ? GetAdminRoles(roleList).filter((r) => {
                                            return !usedRoles.some((role) => role === r.name);
                                         })
                                       : []
                                 }
                                 getOptionLabel={(option) => (option as RoleInterface).name}
                                 onChange={(_e, newValue) => {
                                    setSelectedRoles(newValue);
                                 }}
                                 renderInput={(params) => (
                                    <TextField
                                       {...params}
                                       label="Roles"
                                       placeholder="Seleccionar roles"
                                       name="role"
                                       error={rolesSelectedError && props.value === 3}
                                       helperText={
                                          rolesSelectedError && props.value === 3
                                             ? "Seleccionar como minimo un rol."
                                             : null
                                       }
                                       onClick={() => setRolesSelectedError(false)}
                                    />
                                 )}
                              />
                           )}
                           {props.value === 1 ? (
                              <></>
                           ) : (
                              <Box sx={{ display: "flex", justifyContent: "right" }}>
                                 <LoadingButton
                                    disabled={directorExists && presidentExists}
                                    label={"Agregar usuario"}
                                    isLoading={isLoadingCreate}
                                 />
                              </Box>
                           )}
                        </Box>
                        {props.value === 1 || (!selectedCompany?.company_details?.users && !props.group) ? (
                           <></>
                        ) : selectedCompany?.company_details?.users && !isLoadingUsers ? (
                           <>
                              <Typography
                                 sx={{ borderTop: 1, borderColor: "#E0E0E0", pt: 3, px: 2 }}
                                 fontWeight={600}
                                 variant="h6"
                              >
                                 Usuarios registrados
                              </Typography>

                              <TableComponent
                                 defaultColumnToOrder=""
                                 defaultOrder="asc"
                                 defaultRowsPerPage={2}
                                 emptyDataText="No se han agregado usuarios"
                                 rowsPerPageOptions={[]}
                                 loader={isLoadingUsers}
                                 headers={userColumns}
                                 rows={companyUsers}
                                 onClick={() => {}}
                                 disableBorders
                              />
                           </>
                        ) : props.group && !isLoadingUsers ? (
                           <>
                              <Typography
                                 sx={{ borderTop: 1, borderColor: "#E0E0E0", pt: 3, px: 2 }}
                                 fontWeight={600}
                                 variant="h6"
                              >
                                 Usuarios registrados
                              </Typography>

                              <TableComponent
                                 defaultColumnToOrder=""
                                 defaultOrder="asc"
                                 defaultRowsPerPage={2}
                                 emptyDataText="No se han agregado usuarios"
                                 rowsPerPageOptions={[5]}
                                 loader={false}
                                 headers={userColumns}
                                 rows={companyUsers || []}
                                 onClick={() => {}}
                                 disableBorders
                              />
                           </>
                        ) : (
                           <Box sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}>
                              <CircularProgress size={24} />
                           </Box>
                        )}
                     </Box>
                  </Box>

                  <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
                     <Button sx={{ height: "100%" }} variant="outlined" onClick={handleCancel}>
                        Cancelar
                     </Button>
                     {props.value === 1 ? (
                        <LoadingButton label={"Continuar"} isLoading={isLoadingCreate && !isLoadingUpdate} />
                     ) : (
                        <Button
                           sx={{
                              height: "100%",
                              bgcolor: "#142c44",
                              color: "white",
                              mb: 2,
                              "&:hover": {
                                 bgcolor: "#142c4490",
                              },
                           }}
                           variant="outlined"
                           onClick={() => props.onComplete()}
                           disabled={isLoadingUpdate}
                        >
                           Continuar
                        </Button>
                     )}
                  </Box>
               </Form>
            )}
         </Formik>
      </Box>
   );
};
