import {
   Box,
   Button,
   Card,
   Divider,
   FormControl,
   InputLabel,
   MenuItem,
   Select,
   Stack,
   Tooltip,
   Typography,
} from "@mui/material";
import { useState } from "react";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { getPercentage } from "../../const/globalConst";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface SubjectComparativeProps {
   filteredSubjects: any;
   mainTitle: string;
   minWidthValue?: string;
   branches: any;
   selectedBranch: any;
   handleBranchChange: any;
   heightValue?: string;
}

export const CategoriesComparative: React.FC<SubjectComparativeProps> = ({
   filteredSubjects,
   mainTitle,
   minWidthValue,
   heightValue,
   branches,
   selectedBranch,
   handleBranchChange,
}) => {
   return (
      <Box
         sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 2,
            flex: 1,
            width: "100%",
            minWidth: minWidthValue,
         }}
      >
         <Card>
            <Stack sx={{ p: 2 }}>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     columnGap: 3,
                     minWidth: minWidthValue,
                  }}
               >
                  <Typography fontWeight={600} sx={{ flex: 2.5 }}>
                     {mainTitle}
                  </Typography>

                  <FormControl
                     variant="outlined"
                     sx={{
                        minWidth: 90,
                        marginRight: "80px",
                        padding: 0,
                     }}
                  >
                     <InputLabel>Sucursal</InputLabel>
                     <Select
                        value={selectedBranch ?? ""}
                        onChange={handleBranchChange}
                        label="Branch"
                        sx={{ borderRadius: "50px", maxHeight: 40, padding: 0 }}
                     >
                        <MenuItem value="Todas las categorias">Todas las categorías</MenuItem>
                        {branches.map((branch) => (
                           <MenuItem key={branch.branchId} value={branch.branchId}>
                              {branch.branchDetails.name}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </Box>
            </Stack>
            <Divider />
            <Box sx={{ p: 2, height: heightValue, overflowY: "auto" }}>
               {filteredSubjects.length === 0 ? (
                  <Typography textAlign="center">{`No se encuentran categorías disponibles`}</Typography>
               ) : (
                  filteredSubjects?.map((c, i) => (
                     <Box key={i} sx={{ mb: 1.5 }}>
                        <Typography fontWeight={600}>{c.name}</Typography>
                        <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 1 }}>
                           <Box width={"95%"}>
                              <LinearProgress
                                 variant="determinate"
                                 value={c?.data * 100 || 0}
                                 sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    [`&.${linearProgressClasses.colorPrimary}`]: {
                                       backgroundColor: "#EEEEEE",
                                    },
                                    [`& .${linearProgressClasses.bar}`]: {
                                       borderRadius: 5,
                                       backgroundColor: "#273d52",
                                    },
                                 }}
                              />
                           </Box>
                           <Box width={"5%"} textAlign={"right"}>
                              <Typography>
                                 {(c?.data ?? 0) * 100 === 100 ? "100%" : ((c?.data ?? 0) * 100).toFixed(1) + "%"}
                              </Typography>
                           </Box>
                        </Stack>
                     </Box>
                  ))
               )}
            </Box>
         </Card>
      </Box>
   );
};
