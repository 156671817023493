import React, { createContext, useContext, useEffect, useMemo, useReducer, useState, Dispatch } from "react";
import {
   IAffairs,
   IMembersToVerify,
   IMembersWithCharges,
   ISession,
   ISign,
   IAffairVotation,
   IAdditionalVotes,
   IResolutions,
} from "../../types/governance.types";
import { io } from "socket.io-client";
import { getManyCompaniesById, getUserProfilePics, getUsersByIds } from "../../lib/usersBEClient";
import {
   getAffairsByIdsSession,
   getGoverningBodyById,
   getSessionByDate,
   getSessionByIdNotPopulate,
   getUsersByGovernBody,
   getVotesBySession,
} from "../../lib/gobCorpBEClient";
import { UserContext } from "../userContext";
import { useParams } from "react-router-dom";
import { GovernanceContext } from "./governanceContext";
import { GovernanceTheSequelContext } from "./governanceTheSequelContext";
import { Companies } from "../../types/BaseTypes";
import { SnackBarContext } from "../snackBarContext";

interface ISessionContext {
   state: any;
   dispatch: Dispatch<any>;
   session: ISession;
   setSession: Function;
   sessionSeed: number;
   setSessionSeed: Function;
   signsArray: ISign[];
   setSignsArray: Function;
   hideSecondTopMenu: boolean;
   setHideSecondTopMenu: Function;
   membersToVerify: IMembersToVerify[];
   setMembersToVerify: Function;
   socket: any;
   setSocket: Function;
   membersWithCharge: IMembersWithCharges[];
   hasPermissions: boolean;
   isLoadingSession: boolean;
   setIsLoadingSession: Function;
   finishHour: Date;
   setFinishHour: Function;
   isLoadingBill: boolean;
   setIsLoadingBill: Function;
   sessionFound: ISession;
   setSessionFound: Function;
   inSession: boolean;
   setInSession: Function;
   fileArray: { file: string; fileName: string }[];
   setFileArray: Function;
   companyLogo: string;
   setCompanyLogo: Function;
   alreadySign: boolean;
   setAlreadySign: Function;
   isShareholderSession: boolean;
   affairsArray: IAffairs[];
   setAffairsArray: Function;
   governingBody: any;
   setGoverningBody: Function;
   valuesFromBill: any;
   setValuesFromBill: Function;
   isLoadingGoverningBody: boolean;
   setIsLoadingGoverningBody: Function;
   isShareholder: boolean;
   documentUrl: string;
   setDocumentUrl: Function;
   openSecurityQuestionModal: boolean;
   setOpenSecurityQuestionModal: Function;
   affairsStatus: any[];
   setAffairsStatus: Function;
   colors: { primary: string; secondary: string };
   usersOnline: any[];
   setUsersOnline: Function;
   attendedPercentage: number;
   setAttendedPercentage: Function;
   additionalVotesSeed: number;
   setAdditionalVotesSeed: Function;
   sessionFoundArray: ISession[];
   setSessionFoundArray: Function;
   hideNotification: boolean;
   setHideNotification: Function;
   externalMemberUsers: { user: string; email: string; name?: string; status: string; attended: boolean }[];
   initialValues: any;
   setInitialValues: Function;
   quorum: { attendance: number; vote: number };
   setQuorum: Function;
   userOnlineSigns: { firstName: string; lastName: string; _id: string }[];
   receivingChanges: boolean;
   setReceivingChanges: Function;
   isSocketConnected: boolean;
   setIsSocketConnected: Function;
   pendingVotes: IAdditionalVotes[];
   groupCompaniesInSession: Companies[];
   isPendingVote: boolean;
   sessionResolutions: IResolutions[];
}

export const GovernanceSessionContext = createContext<ISessionContext>({
   state: null,
   dispatch: null,
   session: null,
   setSession: () => {},
   sessionSeed: 0,
   setSessionSeed: () => {},
   signsArray: [],
   setSignsArray: () => {},
   hideSecondTopMenu: true,
   setHideSecondTopMenu: () => {},
   membersToVerify: [],
   setMembersToVerify: () => {},
   socket: null,
   setSocket: () => {},
   membersWithCharge: [],
   hasPermissions: false,
   isLoadingSession: true,
   setIsLoadingSession: () => {},
   isLoadingBill: true,
   setIsLoadingBill: () => {},
   finishHour: null,
   setFinishHour: () => {},
   sessionFound: null,
   setSessionFound: () => {},
   inSession: false,
   setInSession: () => {},
   fileArray: [],
   setFileArray: () => {},
   companyLogo: null,
   setCompanyLogo: () => {},
   alreadySign: false,
   setAlreadySign: () => {},
   isShareholderSession: true,
   affairsArray: [],
   setAffairsArray: () => {},
   governingBody: null,
   setGoverningBody: () => {},
   valuesFromBill: {},
   setValuesFromBill: () => {},
   isLoadingGoverningBody: true,
   setIsLoadingGoverningBody: () => {},
   isShareholder: false,
   documentUrl: "",
   setDocumentUrl: () => {},
   openSecurityQuestionModal: false,
   setOpenSecurityQuestionModal: () => {},
   affairsStatus: [],
   setAffairsStatus: () => {},
   colors: { primary: "#2D4357", secondary: "#8A95A0" },
   usersOnline: [],
   setUsersOnline: () => {},
   attendedPercentage: 0,
   setAttendedPercentage: () => {},
   additionalVotesSeed: 0,
   setAdditionalVotesSeed: () => {},
   sessionFoundArray: [],
   setSessionFoundArray: () => {},
   hideNotification: false,
   setHideNotification: () => {},
   externalMemberUsers: [],
   initialValues: {},
   setInitialValues: () => {},
   quorum: null,
   setQuorum: () => {},
   userOnlineSigns: [],
   receivingChanges: false,
   setReceivingChanges: () => {},
   isSocketConnected: false,
   setIsSocketConnected: () => {},
   pendingVotes: [],
   groupCompaniesInSession: [],
   isPendingVote: false,
   sessionResolutions: [],
});

export const GovernanceSessionProvider = ({ children }) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const [isPendingVote, setIsPendingVote] = useState(false);
   function reducer(state, action) {
      switch (action.type) {
         //#region affairVotationState
         case "affairsInitialState":
            return { ...state, affairVotations: action.affairVotations };

         case "voteUserInAffairVotation": {
            return voteUserInAffairVotation(state, action.orderInfo, action.company);
         }

         case "updateAffairVoteInVoteStatus": {
            const tempVote = state.affairVotations;
            if (session.group) {
               const foundOrderIndex = tempVote.findIndex(
                  (order) => order.affair.orderId === state.vote.affair.orderId
               );
               const foundCompanyIndex = tempVote[foundOrderIndex].companies.findIndex(
                  (company) => action.orderInfo.company === company.company
               );
               tempVote[foundOrderIndex].companies[foundCompanyIndex] = action.orderInfo;
            } else {
               const foundIndexByOrder = tempVote.findIndex(
                  (vote) => vote.affair.orderId === action.orderInfo.affair.orderId
               );
               tempVote[foundIndexByOrder] = action.orderInfo;
            }
            return {
               ...state,
               affairsVotation: tempVote,
            };
         }

         //#endregion
         //#region additionalVotesState

         case "additionalVotesUpdateAll":
            return { ...state, additionalVotes: action.additionalVotes };

         case "addNewAdditionalVote":
            return {
               ...state,
               additionalVotes: [...state.additionalVotes, action.newAdditionalVote],
            };
         case "updateSpecificVote": {
            setIsPendingVote(false);
            return updateSpecificVote(state, action);
         }
         case "overWriteAdditionalVote": {
            const tempAdditionalVotes = state.additionalVotes;
            const foundIndex = tempAdditionalVotes.findIndex((vote) => vote._id === action.additionalVote._id);
            if (foundIndex === -1) tempAdditionalVotes.push(action.additionalVote);
            else tempAdditionalVotes[foundIndex] = action.additionalVote;
            return { ...state, additionalVotes: tempAdditionalVotes };
         }
         //#endregion
         //#region activeVote

         case "setActiveVote":
            if (action.pendingVote) setIsPendingVote(true);
            return { ...state, vote: { ...action.activeVote } };

         case "deleteActiveVote":
            return { ...state, vote: null };
         //#endregion
      }
   }
   const [session, setSession] = useState(null);
   const [sessionSeed, setSessionSeed] = useState(0);
   const [hideSecondTopMenu, setHideSecondTopMenu] = useState(true);
   const [membersToVerify, setMembersToVerify] = useState([]);
   const [signsArray, setSignsArray] = useState([]);
   const [socket, setSocket] = useState(null);
   const [isSocketConnected, setIsSocketConnected] = useState(false);
   const [members, setMembers] = useState([]);
   const [isLoadingSession, setIsLoadingSession] = useState(true);
   const [isLoadingBill, setIsLoadingBill] = useState(true);
   const [usersInGB, setUsersInGB] = useState([]);
   const { user, companySelected, getCompanyDetails } = useContext(UserContext);
   const { companySelected: companySelectedId, gobernanceBody } = useContext(GovernanceContext);
   const { governanceCoordinators } = useContext(GovernanceTheSequelContext);
   const { sessionId } = useParams();
   const [finishHour, setFinishHour] = useState(null);
   const [sessionFound, setSessionFound] = useState(null);
   const [inSession, setInSession] = useState(null);
   const [fileArray, setFileArray] = useState([]);
   const [companyLogo, setCompanyLogo] = useState(null);
   const [alreadySign, setAlreadySign] = useState(false);
   const [isShareholderSession, setIsShareholderSession] = useState(false);
   const [affairsArray, setAffairsArray] = useState([]);
   const [governingBody, setGoverningBody] = useState(null);
   const [valuesFromBill, setValuesFromBill] = useState({});
   const [isLoadingGoverningBody, setIsLoadingGoverningBody] = useState(true);
   const [isShareholder, setIsShareholder] = useState(false);
   const [documentUrl, setDocumentUrl] = useState("");
   const [openSecurityQuestionModal, setOpenSecurityQuestionModal] = useState(false);
   const [affairsStatus, setAffairsStatus] = useState([]);
   const [colors, setColors] = useState({ primary: "#2D4357", secondary: "#8A95A0" });
   const [usersOnline, setUsersOnline] = useState([]);
   const [attendedPercentage, setAttendedPercentage] = useState(0);
   const [additionalVotesSeed, setAdditionalVotesSeed] = useState(0);
   const [sessionFoundArray, setSessionFoundArray] = useState([]);
   const [hideNotification, setHideNotification] = useState(false);
   const [initialValues, setInitialValues] = useState(null);
   const [quorum, setQuorum] = useState(null);
   const [receivingChanges, setReceivingChanges] = useState(false);
   const [groupCompaniesInSession, setGroupCompaniesInSession] = useState([]);
   const [state, dispatch] = useReducer(reducer, { additionalVotes: [], affairVotations: [], vote: null });

   const pendingVotes = useMemo(() => {
      if (!state.additionalVotes || state.additionalVotes.length === 0) return [];
      return state.additionalVotes.filter((vote) => {
         if (vote.canceled) return false;
         return vote.votes.some((user) => !user.answer);
      });
   }, [state.additionalVotes, state.vote, additionalVotesSeed]);

   const sessionResolutions = useMemo(() => {
      const rows = [];
      const deliberationVotes = [];
      if (!affairsArray || affairsArray.length === 0) return [];
      if (session?.group) {
         for (const order of state.affairVotations) {
            const foundAffair = affairsArray.find((affair) => affair.orderId === order.affair?.orderId);
            for (const company of order.companies) {
               if (!company.answers) continue;
               const votationObject = {
                  title: foundAffair.title,
                  description: order.affair.description,
                  company: company.company,
                  orderId: order.affair.orderId,
               };
               const votationTotalVotes = Object.values(company.answers).reduce((obj: any, keyName: any) => {
                  if (Object.keys(obj).length === 0) return { [keyName]: 0 };
                  return { ...obj, [keyName]: 0 };
               }, {});

               for (const user of company.users || company.votes) {
                  if (!user.answer || user.abstention) continue;
                  votationTotalVotes[user.answer] += user.avaliableVotes;
               }

               let maxedObject;
               for (const key of Object.keys(votationTotalVotes)) {
                  if (!maxedObject) {
                     maxedObject = key;
                     continue;
                  }
                  if (votationTotalVotes[maxedObject] < votationTotalVotes[key]) maxedObject = key;
               }
               votationObject["resolution"] =
                  votationTotalVotes[maxedObject] > 0
                     ? `${maxedObject} con ${((votationTotalVotes[maxedObject] * 100) / company.totalVotes).toFixed(
                          2
                       )}% de votos`
                     : "Votación inválida";
               rows.push(votationObject);
            }
         }
      } else if (state.affairVotations?.length > 0) deliberationVotes.push(...state.affairVotations);
      if (state.additionalVotes?.length > 0) deliberationVotes.push(...state.additionalVotes);
      if (deliberationVotes?.length === 0) return rows;
      for (const votation of deliberationVotes) {
         if (!votation.answers) continue;
         const foundAffair = affairsArray.find((affair) => affair.orderId === votation.affair?.orderId);
         const proclamation = !quorum ? 100 : session.proclamation === "Primera" ? quorum.vote[0] : quorum.vote[1];
         const title = votation.affair ? foundAffair?.title || votation.affair.description : votation.title;
         const votationObject = {
            title: title,
            description: votation.affair ? votation.affair.description : "N/A",
            ...(votation.company && { company: votation.company }),
            ...(session.group && { orderId: votation.orderId, votationId: votation._id }),
         };

         if ((votation.affair || votation.orderId) && Number(votation.resolutionPercentage || 0) < proclamation) {
            votationObject["resolution"] = "Votación inválida";
         } else {
            const votationTotalVotes = Object.values(votation.answers).reduce((obj: any, keyName: any) => {
               if (Object.keys(obj).length === 0) return { [keyName]: 0 };
               return { ...obj, [keyName]: 0 };
            }, {});
            for (const user of votation.users || votation.votes) {
               if (!user.answer || user.abstention) continue;
               votationTotalVotes[user.answer] += user.avaliableVotes;
            }

            let maxedObject;
            for (const key of Object.keys(votationTotalVotes)) {
               if (!maxedObject) {
                  maxedObject = key;
                  continue;
               }
               if (votationTotalVotes[maxedObject] < votationTotalVotes[key]) maxedObject = key;
            }
            votationObject["resolution"] =
               votationTotalVotes[maxedObject] > 0
                  ? `${maxedObject} con ${((votationTotalVotes[maxedObject] * 100) / votation.totalVotes).toFixed(
                       2
                    )}% de votos`
                  : "Votación inválida";
         }
         rows.push(votationObject);
      }
      return rows;
   }, [
      state,
      state?.additionalVotes,
      state?.affairVotations,
      quorum,
      additionalVotesSeed,
      sessionSeed,
      session?.group,
      affairsArray,
   ]);

   const base_url =
      window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
         ? "https://test.server.lecosy.com.mx"
         : process.env.NODE_ENV === "production"
         ? "https://server.lecosy.com.mx"
         : "http://localhost:8003";

   useEffect(() => {
      try {
         const socketServer = io(base_url, {
            path:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? "/gc/socket.io"
                  : "/socket.io",
            port:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? 80
                  : 8003,
            withCredentials: true,
         });
         setSocket(socketServer);
         setIsSocketConnected(true);
         return () => {
            socketServer.disconnect();
         };
      } catch (e) {
         setSocket(null);
      }
   }, [base_url, isSocketConnected]);

   useEffect(() => {
      if (!session || !user || user.id.length === 0 || !isSocketConnected) return;
      if (inSession) socket.emit("join", { sessionId: session._id, userId: user.id });
   }, [inSession, session, user, socket, isSocketConnected]);

   const memberUsers = useMemo(() => {
      if (session === null) return;
      const membersArray = session.usersRegistry.map((member) => member.user);
      return membersArray;
   }, [session]);

   const externalMemberUsers = useMemo(() => {
      if (session === null) return;
      const membersArray = session.externs.map((member) => member);
      return membersArray;
   }, [session]);

   useEffect(() => {
      const fetchMembers = async () => {
         const membersData = await getUsersByIds(memberUsers);
         if (membersData) {
            const profilePicsResponse = await getUserProfilePics(membersData.map((m) => m._id));
            for (const member of membersData)
               member.profilePic = profilePicsResponse.find((pic) => pic.user === member._id)?.url ?? "";
            setMembers(membersData);
         }
      };

      const fetchUsersInGB = async () => {
         const usersInGBData = (await getUsersByGovernBody(session.governance)).users;
         setUsersInGB(usersInGBData);
      };

      const fetchAffairs = async () => {
         const affairsFromSession = session.order;
         if (session.assembly) {
            const affairsData: any = await getAffairsByIdsSession(affairsFromSession.map((order) => order.affair));
            let affairMap = {};
            session.order.forEach((order, index) => {
               affairMap[index] = {
                  ...order,
                  _id: order.affair,
               };
            });
            const affairsDataFormated = affairsData.flatMap((affair) => {
               const foundRepeatedAffairs = Object.values(affairMap).filter(
                  (affairInMap: any) => affairInMap.affair === affair._id
               );
               return foundRepeatedAffairs.map((affairInMap: any, index) => {
                  return {
                     ...affair,
                     description: affairInMap.description,
                     orderId: `${index}-${affair._id}`,
                     ...(affairInMap.vote && { vote: affairInMap.vote }),
                  };
               });
            });
            setAffairsArray(affairsDataFormated);
         } else {
            let affairMap = {};
            session.affairVotations.forEach((order, index) => {
               const foundOrder = session.order.find((affair) => {
                  return (
                     affair.affair.replaceAll(" ", "-").replaceAll(".", "_") ===
                     order.affair.orderId.substring(2, order.affair.orderId.length).replaceAll(".", "_")
                  );
               });
               affairMap[index] = {
                  title: foundOrder.affair,
                  description: order.affair.description,
                  orderId: order.affair.orderId,
                  _id: foundOrder.affair,
               };
               if (session.group) {
                  const companiesArray = [];
                  for (const company of order.companies) {
                     companiesArray.push(company.company);
                  }
                  affairMap[index].companies = companiesArray;
               }
            });
            setAffairsArray(Object.values(affairMap));
         }
      };

      const fetchFiles = () => {
         setFileArray(
            session.files.map((file) => {
               return { ...file, sessionId: session._id };
            })
         );
      };

      if (session === null) return;
      fetchMembers();
      fetchUsersInGB();
      fetchAffairs();
      setIsShareholderSession(session.assembly);
      fetchFiles();
   }, [session, memberUsers]);

   useEffect(() => {
      const fetchSession = async () => {
         setIsLoadingSession(true);
         const sessionData = await getSessionByIdNotPopulate(sessionId);
         if (!sessionData) return;
         setSession(sessionData);
         dispatch({ type: "affairsInitialState", affairVotations: sessionData.affairVotations });
      };

      if (!sessionId) return;
      fetchSession();
      if (user.id.length > 0) setHideSecondTopMenu(false);
   }, [user, sessionId]);

   useEffect(() => {
      const fetchAdditionalVotes = async () => {
         const additionalVotes = await getVotesBySession(sessionId);
         dispatch({ type: "additionalVotesUpdateAll", additionalVotes: additionalVotes });
      };
      if (!user || user.id.length === 0 || !isSocketConnected || !sessionId) return;
      if (inSession) fetchAdditionalVotes();
   }, [sessionId, inSession, user, isSocketConnected]);

   const membersWithCharge = useMemo<IMembersWithCharges[]>(() => {
      if (members.length > 0 && usersInGB.length > 0) {
         const membersIds = members.map((member) => member._id);
         const usersInGBFiltered = usersInGB.filter((userInGB) => membersIds.includes(userInGB.user));
         const usersMap = {};
         usersInGBFiltered.forEach((user) => {
            let totalCapital = 0;
            if (governingBody) {
               totalCapital = governingBody.series.reduce((sum, serie) => {
                  const action = user.actions.find((action) => action.title === serie.title);
                  if (action) {
                     return sum + serie.nominalValue * action.sharesAmount;
                  }
                  return sum;
               }, 0);
            }
            usersMap[user.user] = {
               memberCharge: usersMap[user.user]
                  ? [...usersMap[user.user].memberCharge, user.charge.chargeName]
                  : [user.charge.chargeName],
               series: usersMap[user.user] ? [...usersMap[user.user].series, ...user.actions] : user.actions,
               totalCapital: usersMap[user.user] ? usersMap[user.user].totalCapital + totalCapital : totalCapital,
            };
         });
         const membersWithChargeArray = [];
         members.forEach((member) => {
            const memberCharge = usersMap[member._id];
            let membersWithCharge = {};
            if (memberCharge) membersWithCharge = { ...member, ...memberCharge };

            if (session.assembly) {
               let president;
               let vicepresident;
               let secretary;

               const councilGovernance =
                  gobernanceBody.find(
                     (g) => g.title === "Consejo de administración" && g.company === session.company
                  ) || gobernanceBody.find((g) => g.title === "Consejo de administración");

               if (councilGovernance) {
                  president = councilGovernance.users.find((user: any) => user.charge === "659f11b1fb579847f88877cd");
                  secretary = councilGovernance.users.find((user: any) => user.charge === "659f11befb579847f88877ce");
                  vicepresident = councilGovernance.users.find(
                     (user: any) => user.charge === "66300f211fc117a6154a3f7a"
                  );
               }

               const roles = [
                  { user: president?.user, charge: "Presidente del consejo de administración" },
                  { user: secretary?.user, charge: "Secretario del consejo de administración" },
                  { user: vicepresident?.user, charge: "Vicepresidente del consejo de administración" },
               ];

               roles.forEach(({ user, charge }) => {
                  if (member._id === user) {
                     if (membersWithCharge["memberCharge"]?.length > 0) {
                        membersWithCharge["memberCharge"].push(charge);
                     } else {
                        membersWithCharge = {
                           ...member,
                           series: [],
                           totalCapital: 0,
                           memberCharge: [charge],
                        };
                     }
                  }
               });
            }

            const isCoordinator = governanceCoordinators.some((user) => user.user._id === member._id);
            if (isCoordinator) {
               if (membersWithCharge["memberCharge"]?.length > 0)
                  membersWithCharge["memberCharge"].push("Coordinador del Gobierno Corporativo");
               else
                  membersWithCharge = {
                     ...member,
                     series: [],
                     totalCapital: 0,
                     memberCharge: ["Coordinador del Gobierno Corporativo"],
                  };
            }

            const membersWithChargeUnique = {
               ...membersWithCharge,
               memberCharge: Array.from(new Set(membersWithCharge["memberCharge"])),
            };
            if (Object.keys(membersWithCharge).length > 0) membersWithChargeArray.push(membersWithChargeUnique);
         });

         setIsLoadingSession(false);
         return membersWithChargeArray;
      }
   }, [members, usersInGB, governingBody, session?.externs, governanceCoordinators]);

   const hasPermissions = useMemo(() => {
      if (!membersWithCharge || membersWithCharge.length === 0) return;
      let userCharges = membersWithCharge.find((member) => member._id === user.id);
      if (!userCharges) return;
      if (userCharges.memberCharge.some((charge) => charge.includes("Accionista"))) setIsShareholder(true);
      return userCharges.memberCharge.some(
         (charge) =>
            charge.toLowerCase().includes("secretario") ||
            charge.toLowerCase().includes("presidente") ||
            charge.toLowerCase().includes("coordinador") ||
            charge.includes("Usuario de implementacion")
      );
   }, [membersWithCharge, user?.id]);

   useEffect(() => {
      const fetchGoverningBody = async () => {
         setIsLoadingGoverningBody(true);
         const governingBodyData = await getGoverningBodyById(session.governance);
         let totalCompanyCapital = 0;
         const relevantActions = governingBodyData.users
            .flatMap((member) => member.actions.filter((action) => action.title && action.sharesAmount > 0))
            .map((action) => ({
               ...action,
               serie: governingBodyData.series.find((serie) => serie.title === action.title),
            }));
         relevantActions.forEach((action) => {
            if (action.serie) {
               totalCompanyCapital += action.serie.nominalValue * action.sharesAmount;
            }
         });
         governingBodyData["totalCompanyCapital"] = totalCompanyCapital;
         setGoverningBody(governingBodyData);
         setIsLoadingGoverningBody(false);
      };
      if (!session || !membersWithCharge || membersWithCharge.length === 0 || governingBody) return;
      fetchGoverningBody();
   }, [session, membersWithCharge, governingBody, groupCompaniesInSession]);

   useEffect(() => {
      const refetchSession = async () => {
         if (user.id.length > 0) {
            if (sessionFound || sessionFoundArray.length > 0) return;
            const sessionToday = await getSessionByDate(user.id);
            if (Array.isArray(sessionToday)) setSessionFoundArray(sessionToday);
            else setSessionFound(sessionToday);
         }
         if (sessionFound) clearInterval(interval);
      };
      const interval = setInterval(refetchSession, 5000);
      return () => clearInterval(interval);
   }, [sessionFound, sessionFoundArray, user?.id]);

   useEffect(() => {
      if (isLoadingSession) return;
      if (!companySelected && session.company && !session.group) {
         getCompanyDetails(session.company);
         return;
      }
      setColors({
         primary: session.group ? "#162c44" : companySelected.company_details?.primaryColor,
         secondary: session.group ? "#162c44" : companySelected.company_details?.secondaryColor,
      });
      setIsLoadingBill(false);
   }, [companySelected, companySelectedId, isLoadingSession, session]);

   const userThatAlreadySigns = useMemo(() => {
      const signsArray = Object.keys(valuesFromBill)?.filter((key) => key.includes("sign"));
      const userToReturn = [];
      signsArray.forEach((sign) => {
         if (valuesFromBill[sign]) {
            userToReturn.push(sign.split("sign")[1]);
         }
      });
      return userToReturn;
   }, [valuesFromBill]);

   const userOnlineSigns = useMemo(() => {
      let userMap = {};
      if (!membersWithCharge || usersOnline.length === 0) return [];
      membersWithCharge.concat(externalMemberUsers)?.forEach((member) => {
         userMap[member._id || member.user] = {
            firstName: (member?.firstName || member.name) ?? member?.email,
            lastName: member?.lastName || "",
            _id: member?._id || member.user,
         };
      });
      const usersOnlineSignsArray = usersOnline.map((userOnline) => userMap[userOnline]);
      const userThatAlreadySignsArray =
         userThatAlreadySigns.length > 0 ? userThatAlreadySigns.map((userOnlineSign) => userMap[userOnlineSign]) : [];
      return [...new Set(usersOnlineSignsArray.concat(userThatAlreadySignsArray))];
   }, [usersOnline, membersWithCharge, userThatAlreadySigns, externalMemberUsers]);

   useEffect(() => {
      const fetchGroupCompanies = async () => {
         const companiesIds = affairsArray
            ?.flatMap((affair) => affair?.companies)
            .filter((value, index, self) => self.indexOf(value) === index);
         const companiesRespones = await getManyCompaniesById(companiesIds);
         setGroupCompaniesInSession(companiesRespones);
      };
      if (session?.group) fetchGroupCompanies();
   }, [affairsArray, session]);

   //#region votationComplexFunctions

   function voteUserInAffairVotation(state, orderInfo, orderCompany = undefined) {
      const tempVotations: IAffairVotation[] = state.affairVotations;
      const orderIndex = tempVotations.findIndex((vote) => vote.affair.orderId === orderInfo.orderId);
      if (orderIndex < 0) {
         showSnackBar("Error en votación, intente de nuevo más tarde", true);
         return { ...state };
      }
      if (session.group) {
         const companyIndex = tempVotations[orderIndex].companies.findIndex(
            (company) => company.company === orderCompany
         );
         if (companyIndex < 0) {
            showSnackBar("Error en votación, intente de nuevo más tarde", true);
            return { ...state };
         }
         const foundUserIndex = tempVotations[orderIndex].companies[companyIndex].users.findIndex(
            (user) => user.user === orderInfo.user
         );
         if (foundUserIndex < 0) {
            showSnackBar("Error en votación, intente de nuevo más tarde", true);
            return { ...state };
         }
         tempVotations[orderIndex].companies[companyIndex].answers = state.vote.answers;

         if (orderInfo.abstention) {
            tempVotations[orderIndex].companies[companyIndex].users[foundUserIndex].abstention = true;
         } else {
            tempVotations[orderIndex].companies[companyIndex].users[foundUserIndex].answer = orderInfo.answer;
         }

         let tempVote = state.vote;
         if (tempVote !== null)
            tempVote = {
               ...tempVotations[orderIndex].companies[companyIndex],
               affair: tempVotations[orderIndex].affair,
            };
         return { ...state, affairVotations: tempVotations, vote: tempVote || state.vote };
      } else {
         const foundUserIndex = tempVotations[orderIndex].users.findIndex((user) => user.user === orderInfo.user);
         if (foundUserIndex < 0) {
            showSnackBar("Error en votación, intente de nuevo más tarde", true);
            return { ...state };
         }
         tempVotations[orderIndex].answers = state.vote.answers;

         if (orderInfo.abstention) {
            tempVotations[orderIndex].users[foundUserIndex].abstention = true;
         } else {
            tempVotations[orderIndex].users[foundUserIndex].answer = orderInfo.answer;
         }

         let tempVote = state.vote;
         if (tempVote !== null) tempVote = tempVotations[orderIndex];
         return { ...state, affairVotations: tempVotations, vote: tempVote || state.vote };
      }
   }

   function updateSpecificVote(state, action) {
      const tempVotes = state.additionalVotes;
      const foundVoteIndex = tempVotes.findIndex((vindex: any) => vindex._id === action.votationId);
      if (foundVoteIndex === -1) return { ...state };
      const foundUserVoteIndex = tempVotes[foundVoteIndex].votes.findIndex(
         (user) => user.userId === action.voteInfo.userId
      );
      if (foundUserVoteIndex === -1) tempVotes[foundVoteIndex].votes.push(action.voteInfo);
      else tempVotes[foundVoteIndex].votes[foundUserVoteIndex] = action.voteInfo;
      let tempActiveVote = state.vote;
      if (tempActiveVote !== null) tempActiveVote = tempVotes[foundVoteIndex];
      return {
         ...state,
         additionalVotes: tempVotes,
         vote: tempActiveVote !== null ? tempActiveVote : state.vote,
      };
   }
   //#endregion

   return (
      <GovernanceSessionContext.Provider
         value={{
            state,
            dispatch,
            hideSecondTopMenu,
            setHideSecondTopMenu,
            membersToVerify,
            setMembersToVerify,
            session,
            setSession,
            sessionSeed,
            setSessionSeed,
            signsArray,
            setSignsArray,
            socket,
            setSocket,
            membersWithCharge,
            hasPermissions,
            isLoadingSession,
            setIsLoadingSession,
            finishHour,
            setFinishHour,
            isLoadingBill,
            setIsLoadingBill,
            sessionFound,
            setSessionFound,
            inSession,
            setInSession,
            fileArray,
            setFileArray,
            companyLogo,
            setCompanyLogo,
            alreadySign,
            setAlreadySign,
            isShareholderSession,
            affairsArray,
            setAffairsArray,
            governingBody,
            setGoverningBody,
            valuesFromBill,
            setValuesFromBill,
            isLoadingGoverningBody,
            setIsLoadingGoverningBody,
            isShareholder,
            documentUrl,
            setDocumentUrl,
            openSecurityQuestionModal,
            setOpenSecurityQuestionModal,
            affairsStatus,
            setAffairsStatus,
            colors,
            usersOnline,
            setUsersOnline,
            attendedPercentage,
            setAttendedPercentage,
            additionalVotesSeed,
            setAdditionalVotesSeed,
            sessionFoundArray,
            setSessionFoundArray,
            hideNotification,
            setHideNotification,
            externalMemberUsers,
            initialValues,
            setInitialValues,
            quorum,
            setQuorum,
            userOnlineSigns,
            receivingChanges,
            setReceivingChanges,
            isSocketConnected,
            setIsSocketConnected,
            pendingVotes,
            groupCompaniesInSession,
            isPendingVote,
            sessionResolutions,
         }}
      >
         {children}
      </GovernanceSessionContext.Provider>
   );
};
