import { AddCard } from "@mui/icons-material";
import { Box, Button, Divider, FormGroup, Typography, Stack, ButtonBase } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { CheckBoxComponent } from "../Inputs/CheckBoxComponent";
import { TagsComponent } from "./SubComponents/TagsComponent";
import { RecentMovements } from "./SubComponents/RecentMovements";
import { useParams } from "react-router-dom";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";
import { UserContext } from "../../../context/userContext";

interface IPropsLeftSideMenu {
   handleOpenModal?: (event) => void;
   notes?: boolean;
   buttonTitle?: string;
   permission?: number;
}

export const LeftSideMenu = (props: IPropsLeftSideMenu) => {
   const {
      gobernanceBody,
      selectedGovernance,
      getSpecificCalendar,
      allCalendars,
      consultiveGroup,
      consultiveGroupCompanies,
   } = useContext(GovernanceContext);
   const { id, companyId } = useParams();
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const { user } = useContext(UserContext);
   const [checkedCompany, setCheckedCompany] = useState({ state: false, company: "" });

   const isConsultiveGroup = consultiveGroup?._id === companyId;

   const availableFilters = useMemo(() => {
      const newArray = gobernanceBody
         .filter((gb, index) => {
            const govIndex = myGovBody.findIndex((body) => body.bodiesData.title === gb.title);
            return index === 0 || govIndex >= 0;
         })
         .map((body) => {
            let companyName = "";
            if (consultiveGroup?._id === companyId && consultiveGroupCompanies.length > 0) {
               companyName = consultiveGroupCompanies.find((company) => company._id === body.company)?.person_details
                  ?.comercialName;
            }
            return { ...body, title: body.title, company: companyName };
         });
      return newArray;
   }, [gobernanceBody, myGovBody, consultiveGroupCompanies, selectedGovernance]);

   const groupedByCompany = useMemo(() => {
      return availableFilters.reduce((acc, item) => {
         const companyKey = item.company || consultiveGroup?.name;
         if (!acc[companyKey]) acc[companyKey] = [];
         if (item.title === "PANEL DE USUARIO") return acc;
         acc[companyKey].push(item);
         return acc;
      }, {});
   }, [availableFilters, user]);

   useEffect(() => {
      if (!(selectedGovernance.title === "PANEL DE USUARIO")) {
         getSpecificCalendar(selectedGovernance.calendar, false, false, true);
      }
   }, [allCalendars, selectedGovernance]);

   // Verificar si los roles del usuario contienen las palabras clave específicas

   const rolesToCheck = ["coordinador", "n2", "n5", "n7", "presidente", "usuario de implementación"];
   const hasRequiredRole = useMemo(() => {
      if (user.role.every((item) => typeof item === "string")) {
         return user.role.some((role) => {
            const lowerCaseRole = role.toLowerCase();
            return rolesToCheck.some((keyword) => lowerCaseRole.includes(keyword));
         });
      }
      return false;
   }, [user.role, rolesToCheck]);

   return (
      <Stack spacing={2} sx={{ width: 250, maxHeight: "100vh" }}>
         {!props.notes && (!id || hasRequiredRole || selectedGovernance._id === "0") && (
            <Stack
               sx={{
                  bgcolor: "white",
                  boxShadow: 1,
                  borderRadius: 1,
                  maxHeight: 250,
               }}
            >
               {(hasRequiredRole || selectedGovernance._id === "0") && (
                  <Button
                     variant="contained"
                     sx={{
                        bgcolor: "#64748B",
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                        boxShadow: 0,
                     }}
                     onClick={() => props.handleOpenModal(null)}
                  >
                     <AddCard />
                     <Typography sx={{ pl: 1 }}>{props.buttonTitle}</Typography>
                  </Button>
               )}
               {!id && (
                  <FormGroup sx={{ overflow: "auto" }}>
                     {isConsultiveGroup ? (
                        <Stack>
                           <Box sx={{ pl: 2 }}>
                              <CheckBoxComponent data={availableFilters[0].title} id={availableFilters[0].calendar} />
                           </Box>
                           {Object.keys(groupedByCompany).map((company) => (
                              <>
                                 <ButtonBase
                                    key={`filter-box-${company}`}
                                    onClick={() =>
                                       setCheckedCompany({
                                          state: checkedCompany.company === company ? !checkedCompany.state : true,
                                          company: company,
                                       })
                                    }
                                    sx={{
                                       display: "flex",
                                       alignItems: "baseline",
                                       p: 1,
                                       justifyContent: "flex-start",
                                       textAlign: "left",
                                       "&:hover": {
                                          backgroundColor: "#D9D9D980",
                                       },
                                    }}
                                 >
                                    <Typography variant="subtitle2" sx={{ pl: 1 }}>
                                       {company}
                                    </Typography>
                                 </ButtonBase>

                                 {checkedCompany.state &&
                                    checkedCompany.company === company &&
                                    groupedByCompany[company].map((governingBody, i) => (
                                       <Box sx={{ pl: 2 }}>
                                          <CheckBoxComponent
                                             data={governingBody.title}
                                             id={governingBody.calendar}
                                             key={i}
                                          />
                                       </Box>
                                    ))}
                              </>
                           ))}
                        </Stack>
                     ) : (
                        <Stack sx={{ pl: 2 }}>
                           {availableFilters.map((governingBody, i) => (
                              <CheckBoxComponent data={governingBody.title} id={governingBody.calendar} key={i} />
                           ))}
                        </Stack>
                     )}
                  </FormGroup>
               )}
            </Stack>
         )}
         {selectedGovernance._id === "0" && (
            <Stack
               sx={{
                  bgcolor: "white",
                  boxShadow: 1,
                  borderRadius: 1,
                  overflow: "auto",
                  maxHeight: 150,
               }}
            >
               <TagsComponent notes={props.notes} />
            </Stack>
         )}
         <Stack
            sx={{
               bgcolor: "white",
               boxShadow: 1,
               borderRadius: 1,
               flex: 1,
               overflow: "hidden",
            }}
         >
            <Typography sx={{ px: 2, py: 1 }}>Actividades recientes</Typography>
            <Divider />
            <RecentMovements />
         </Stack>
      </Stack>
   );
};
