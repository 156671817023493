import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/userContext";
import CarouselComponent, { CarouselContentType } from "./CarouselComponent";
import { getUrlS3 } from "../lib/usersBEClient";
import { Avatar, Box, Typography } from "@mui/material";

type CompaniesStatisticsTableProps = {
   selectedCompany: (company: any) => void;
   filterName: string;
   orientation: boolean;
   maxComponentNumber?: number;
};

export const CompaniesCarousel = ({
   selectedCompany,
   filterName,
   orientation,
   maxComponentNumber = 3,
}: CompaniesStatisticsTableProps) => {
   const { user } = useContext(UserContext);
   const [companiesData, setCompaniesData] = useState<CarouselContentType[]>([]);
   const [currentSelectedCompany, setCurrentSelectedCompany] = useState<any>(null);

   const fetchedUrl = useCallback(async (company: any) => {
      return await getUrlS3("images-lecosy", { folder: `${company._id}` }, "logo.png");
   }, []);

   const getCompaniesByFilter = useCallback(
      async (companies: any[]) => {
         if (companies?.length > 0) {
            const serviceIdToCheck = "64e784dd978b71bd4f011ee3"; // ID del servicio que queremos verificar

            const companiesFound = await Promise.all(
               companies
                  .filter((company) => {
                     // Filtrar por nombre comercial o si filterName está vacío
                     const matchesFilter =
                        company.person_details.comercialName.includes(filterName) || filterName === "";

                     // Verificar si el servicio está habilitado
                     const hasServiceEnabled = company.company_details.servicesDetails.some(
                        (service: any) => service.serviceId === serviceIdToCheck && !service.disable
                     );

                     return matchesFilter && hasServiceEnabled;
                  })
                  .map(async (company) => {
                     const logo = await fetchedUrl(company);
                     return {
                        imageSrc: logo,
                        title: company.person_details.comercialName,
                        object: company,
                     };
                  })
            );
            setCompaniesData(companiesFound);
         }
      },
      [filterName, fetchedUrl]
   );

   useEffect(() => {
      if (user?.companies) {
         getCompaniesByFilter(user.companies);
      }
   }, [user.companies, getCompaniesByFilter]);

   const handleCompanySelect = (company: any) => {
      setCurrentSelectedCompany(company);
      selectedCompany(company);
   };

   return (
      <CarouselComponent
         maxElements={maxComponentNumber}
         vertical={orientation}
         hideSlide={companiesData.length < maxComponentNumber}
      >
         {companiesData.map((company, i) => {
            const isSelected = currentSelectedCompany?._id === company.object._id;
            const primaryColor = company.object?.company_details?.primaryColor || "#1976d2";

            return (
               <div key={`cc-${i}`}>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: orientation ? "row-reverse" : "column",
                        py: orientation ? (companiesData.length > 3 ? 0.2 : 1) : 0,
                        px: orientation ? 3 : 0,
                        width: "100%",
                     }}
                  >
                     <Typography
                        textAlign={orientation ? "end" : "center"}
                        variant={"subtitle2"}
                        sx={{ px: 0.5, fontWeight: "bold" }}
                        flex={1}
                     >
                        {company.title}
                     </Typography>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           mt: orientation ? 0 : 1,
                           mb: orientation ? 0 : 2,
                        }}
                     >
                        <div
                           onClick={() => {
                              if (!orientation) {
                                 handleCompanySelect(company.object);
                              }
                           }}
                        >
                           <Avatar
                              src={company.imageSrc}
                              sx={{
                                 width: orientation ? 75 : 120,
                                 height: orientation ? 75 : 120,
                                 transition: "0.3s ease-in-out",
                                 "&:hover": {
                                    transform: "scale(1.05)",
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                                    cursor:"pointer"
                                 },
                                 border: isSelected ? `2px solid ${primaryColor}` : "1px solid #E3E2E2",
                                 boxShadow: isSelected ? "0px 4px 15px rgba(0, 0, 0, 0.3)" : "none",
                                 overflow: "hidden",
                              }}
                              imgProps={{ sx: { objectFit: "contain", width: "80%", height: "80%" } }}
                           />
                        </div>
                     </Box>
                  </Box>
               </div>
            );
         })}
      </CarouselComponent>
   );
};
