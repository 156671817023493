import { useContext, useEffect, useState } from "react";
import {
   Box,
   Button,
   CircularProgress,
   Divider,
   FormHelperText,
   List,
   Stack,
   Tooltip,
   Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { ArrowBack, Warning } from "@mui/icons-material";
import {
   addGovernBodyRole,
   getAccessByServiceId,
   getBranchesByCompanyId,
   getRoleByCharge,
   useCreateUser,
   useGetResourceSelected,
} from "../../lib/usersBEClient";
import { userCreateSchemaNew } from "../../lib/validations/inputSchemas";
import { SnackBarContext } from "../../context/snackBarContext";
import { UserContext } from "../../context/userContext";
import UseModules from "./Modules/useModules";
import { AddUserContext } from "../../context/addUserContext";
import { addGovernBodyUser, addUserGovernBody } from "../../lib/gobCorpBEClient";
import { CustomModalComponent } from "../CustomModalComponent";

interface PropsAddUserModal {
   open: boolean;
   setOpen: Function;
   company: any;
   module?: boolean;
   usersByModule?: any[];
   service?: any;
}

export const AddUserModal = (props: PropsAddUserModal) => {
   const { user, getCompanyDetails, companySelected } = useContext(UserContext);
   const resourceId = useGetResourceSelected();
   const [initialValues, setInitialValues] = useState({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
   });
   const [firstSreen, setFirstSreen] = useState(true);
   const [validationError, setValidationError] = useState(false);
   const [modulesAndRoles, setModulesAndRoles] = useState([]);
   const [companyNumber, setCompanyNumber] = useState(0);
   const [errorRoles, setErrorRoles] = useState(false);
   const [errorModules, setErrorModules] = useState(false);
   const [userInformation, setUserInformation] = useState(null);
   const [isLoadingModules, setIsLoadingModules] = useState(false);
   const { mutate, isLoading: isLoadingCreate } = useCreateUser();
   const { showSnackBar } = useContext(SnackBarContext);
   let validation = false;
   const { modules } = useContext(AddUserContext);

   const handleOnCloseModal = () => {
      setFirstSreen(true);
      props.setOpen(false);
      setUserInformation(null);
      setValidationError(false);
      setModulesAndRoles([]);
      setCompanyNumber(0);
      setErrorRoles(false);
      // setRolesSelected({});
      setInitialValues({ firstName: "", lastName: "", phoneNumber: "", email: "" });
   };

   const validationCreationUsers = () => {
      if (props.module) {
         if (
            props.service.serviceId.service === "Canal de denuncias" &&
            props.service.userLimit <= props.usersByModule.length
         ) {
            validation = true;
         }
      } else {
         const userLimit = props.company.company_details.servicesDetails;
         const userPerModule = getUserCountByModule(props.company);
         for (const module of userLimit) {
            if (module.serviceId.service === "Canal de denuncias" && module.userLimit <= userPerModule?.cd) {
               validation = true;
            }
         }
      }

      return (
         <>
            {!validation ? null : (
               <Tooltip
                  key={Math.random()}
                  placement="right-start"
                  title={"No cuentas con suficientes licencias en los módulos."}
               >
                  <Warning sx={{ color: "orange" }} />
               </Tooltip>
            )}
            <Divider />
         </>
      );
   };

   const getUserCountByModule = (company) => {
      if (props.usersByModule.length === 0) return;
      const processedData = [];
      for (const users of props.usersByModule) {
         if (users._id !== user.id) {
            for (const role of users.role) {
               if (role.roles.length !== 0) processedData.push(role);
            }
         }
      }
      const filteredRoles = processedData.filter((rol) => rol.company === company._id);
      const processedData2 = [];
      for (const users of filteredRoles) {
         for (const role of users.roles) {
            processedData2.push(role);
         }
      }
      const rolesByCD = processedData2.filter(
         (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
      );
      return {
         cd: Array.isArray(rolesByCD) ? rolesByCD.length : 0,
      };
   };

   const submitInformation = (values, action) => {
      const companyArray = [props.company];
      setInitialValues(values);
      setUserInformation({
         firstName: values.firstName,
         lastName: values.lastName,
         phoneNumber: values.phoneNumber,
         email: values.email,
         companies: companyArray,
      });
      setFirstSreen(false);
   };

   const handleContinue = (index) => {
      if (userInformation.companies.length > 0) {
         setErrorRoles(false);
         const modulesByCompany = modules.filter((module) => module.company === index);
         if (modulesByCompany.length === 0) {
            setErrorRoles(true);
            return;
         }

         //validation CD
         const modulesCD = modules.find(
            (module) => module.module === "64e784dd978b71bd4f011ee3" && module.company === index
         );
         //validation GC
         const modulesGC = modules.find(
            (module) => module.module === "64e7851d978b71bd4f011ee4" && module.company === index
         );
         if (modulesGC) {
         }

         if (userInformation.companies.length === companyNumber + 1) {
            setErrorRoles(false);
            let formatedRoles = [];
            let branches = [];

            for (const company of userInformation.companies) {
               let roles = [];
               const modulesByC = modules.filter((module) => module.company === company._id);
               for (const module of modulesByC) {
                  if (module.module !== "64e7851d978b71bd4f011ee4" && module.company === company._id) {
                     if (module.branches && module.branches.length > 0) {
                        branches.push(...module.branches.map((branches) => branches._id));
                     }
                     roles.push(module.role);
                  }
               }
               formatedRoles.push({
                  company: company._id,
                  roles: roles,
               });
            }

            mutate(
               {
                  userData: {
                     firstName: userInformation.firstName,
                     lastName: userInformation.lastName,
                     email: userInformation.email,
                     phoneNumber: userInformation.phoneNumber,
                     role: formatedRoles,
                  },
                  additionalData: {
                     ...(branches && { branches: branches }),
                     admin: false,
                     // group: user.group._id,
                     company: props.company._id,
                  },
               },
               {
                  onError: (error: any) => {
                     error.response.data.message === "email already in use"
                        ? showSnackBar("Ya existe un usuario con ese correo", true)
                        : showSnackBar("Error al agregar usuario.", true);
                  },
                  onSuccess: async (data) => {
                     const moduleGC = modules.filter((module) => module.module === "64e7851d978b71bd4f011ee4");
                     if (moduleGC.length > 0) {
                        for (const iterator of moduleGC) {
                           const userData = {
                              user: data.user._id,
                              charge: iterator.role,
                              characters: [],
                              addedDate: new Date(),
                           };
                           if (iterator.role === "659f11befb579847f88877ce") {
                              await addGovernBodyUser(iterator.company, data.user._id, "659f11befb579847f88877ce");
                           }
                           await addUserGovernBody(iterator.governance, userData, iterator.company, resourceId);
                           const roleId = await getRoleByCharge(iterator.role);
                           const roleData = {
                              role: roleId._id,
                              company: iterator.company,
                              user: data.user._id,
                           };
                           await addGovernBodyRole(roleData, iterator.governance);
                        }
                     }
                     showSnackBar("El usuario fue agregado correctamente.", false);
                     handleOnCloseModal();
                     getCompanyDetails(companySelected._id);
                     // window.location.reload();
                  },
               }
            );
         } else {
            setFirstSreen(false);
            setCompanyNumber(companyNumber + 1);
            setErrorRoles(false);
         }
      } else {
         return;
         //save
      }
   };
   useEffect(() => {
      const rolesPerModule = async () => {
         setIsLoadingModules(true);
         const services = [];
         for (const iterator of userInformation.companies) {
            if (
               Array.isArray(iterator.company_details.servicesDetails) &&
               iterator.company_details.servicesDetails.length > 0
            ) {
               if (props.module) {
                  const { data } = await getAccessByServiceId(props.service.serviceId._id);
                  let branches = [];
                  if (data.serviceName === "Canal de denuncias") {
                     const branchesResponse = await getBranchesByCompanyId(iterator._id);
                     branches = branchesResponse.data;
                  }
                  const provisionalData = {
                     company: iterator._id,
                     service: { ...data, id: props.service.serviceId._id },
                     userLimit: props.service.userLimit,
                     branches: branches,
                  };
                  services.push(provisionalData);
               } else {
                  for (const service of iterator.company_details.servicesDetails) {
                     const { data } = await getAccessByServiceId(service.serviceId._id);
                     let branches = [];
                     if (data.serviceName === "Canal de denuncias") {
                        const branchesResponse = await getBranchesByCompanyId(iterator._id);
                        branches = branchesResponse.data;
                     }
                     const provisionalData = {
                        company: iterator._id,
                        service: { ...data, id: service.serviceId._id },
                        userLimit: service.userLimit,
                        branches: branches,
                     };
                     services.push(provisionalData);
                  }
               }
            } else {
               const provisionalData = {
                  company: iterator._id,
                  service: null,
               };
               services.push(provisionalData);
            }
         }
         // if (rolesFiltered !== rolesSelected)
         //    setRolesSelected(Object.keys(rolesFiltered).length === 0 ? rolesSelected : rolesFiltered);
         const validation = [];
         for (const iterator of userInformation.companies) {
            const prov = modulesAndRoles.filter((serv) => serv.company === iterator._id);
            validation.push(...prov);
         }
         if (services.length > 0 || modulesAndRoles.length === 0) {
            setModulesAndRoles([...services]);
         } else if (validation.length > 0) {
            setModulesAndRoles([...validation]);
         } else {
            setModulesAndRoles([...modulesAndRoles]);
         }
         setIsLoadingModules(false);
      };

      if (userInformation && !firstSreen) {
         rolesPerModule();
      }
      // eslint-disable-next-line
   }, [userInformation, firstSreen]);

   return (
      <CustomModalComponent open={props.open} setOpen={props.setOpen} title="Agregar nuevo usuario" timeStamp>
         <Box sx={{ p: 4, width: 900 }}>
            {!firstSreen ? (
               <>
                  {userInformation.companies.map((company, index) => (
                     <>
                        {companyNumber === index ? (
                           <Stack spacing={2}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                 }}
                              >
                                 <Box flex={1}>
                                    <Button
                                       onClick={() =>
                                          companyNumber === 0
                                             ? setFirstSreen(true)
                                             : setCompanyNumber(companyNumber - 1)
                                       }
                                    >
                                       <ArrowBack />
                                       <Typography ml={1}>Regresar</Typography>
                                    </Button>
                                 </Box>

                                 <Box flex={4}>
                                    <Divider />
                                 </Box>
                              </Box>
                              <Stack sx={{ px: 1 }} spacing={2}>
                                 <Box>
                                    <Typography variant="h6" fontWeight={600}>
                                       {company.person_details.comercialName}
                                    </Typography>
                                    <Typography fontWeight={550}>Asignación de módulos</Typography>
                                 </Box>
                                 <Box sx={{ maxHeight: 350, overflowY: "auto" }}>
                                    {isLoadingModules ? (
                                       <Box sx={{ display: "flex", justifyContent: "center" }}>
                                          <CircularProgress />
                                       </Box>
                                    ) : (
                                       <>
                                          {Array.isArray(company.company_details.servicesDetails) &&
                                          company.company_details.servicesDetails.length > 0 ? (
                                             <Box>
                                                {modulesAndRoles.map((module) => {
                                                   if (module.company === company._id && module.service) {
                                                      const moduleComponent = UseModules(module);
                                                      return moduleComponent;
                                                   } else {
                                                      return <></>;
                                                   }
                                                })}
                                             </Box>
                                          ) : (
                                             <Typography>Esta empresa no tiene ningún módulo contratado</Typography>
                                          )}
                                       </>
                                    )}
                                 </Box>
                                 <FormHelperText error={errorRoles || errorModules}>
                                    {errorRoles
                                       ? "Favor de seleccionar almenos 1 rol."
                                       : errorModules
                                       ? "Favor de seleccionar almenos 1 módulo."
                                       : null}
                                 </FormHelperText>
                              </Stack>

                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    pt: 1,
                                 }}
                              >
                                 <Button variant="contained" sx={{ opacity: 0.5 }} onClick={handleOnCloseModal}>
                                    Cancelar
                                 </Button>
                                 <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                    <Typography fontSize={14}>Completar información:</Typography>
                                    <Typography fontSize={14} fontWeight={600}>
                                       empresa {index + 1} de {userInformation.companies.length}
                                    </Typography>
                                 </Box>
                                 {/* {modulesAndRoles.some(
                                       (module) =>
                                          module.branches.length === 0 &&
                                          module.service.serviceName === "Canal de denuncias"
                                    ) ? (
                                       <Box
                                          sx={{
                                             height: 30,
                                             width: 90,
                                          }}
                                       >
                                          <Tooltip
                                             placement="top"
                                             title={
                                                "Esta empresa no cuenta con sucursales, favor de agregar sucursales antes de agregar usuarios."
                                             }
                                          >
                                             <Warning sx={{ color: "orange" }} />
                                          </Tooltip>
                                       </Box>
                                    ) : ( */}
                                 <>
                                    {!isLoadingModules ? (
                                       <Button
                                          variant="contained"
                                          color="primary"
                                          disabled={
                                             Array.isArray(company.company_details.servicesDetails) &&
                                             company.company_details.servicesDetails.length <= 0
                                          }
                                          onClick={() => handleContinue(company._id)}
                                          fullWidth
                                          sx={{
                                             maxHeight: 50,
                                             maxWidth: 90,
                                          }}
                                       >
                                          {companyNumber + 1 === userInformation.companies.length ? (
                                             isLoadingCreate ? (
                                                <CircularProgress
                                                   size={20}
                                                   sx={{
                                                      color: "white",
                                                   }}
                                                />
                                             ) : (
                                                "Guardar"
                                             )
                                          ) : (
                                             "Continuar"
                                          )}
                                       </Button>
                                    ) : (
                                       <></>
                                    )}
                                 </>
                                 {/* )} */}
                              </Box>
                           </Stack>
                        ) : null}
                     </>
                  ))}
               </>
            ) : (
               <>
                  {props.open ? (
                     <>
                        <Typography fontWeight={600}>Información del usuario</Typography>
                        <Formik
                           initialValues={initialValues}
                           onSubmit={submitInformation}
                           validationSchema={userCreateSchemaNew}
                        >
                           <Form>
                              <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                                 <InputTextField
                                    label="Nombre(s)"
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                 />
                                 <InputTextField
                                    label="Apellido(s)"
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                 />
                              </Box>
                              <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                                 <InputTextField
                                    label="Teléfono"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="text"
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                 />
                                 <InputTextField
                                    label="Correo electrónico"
                                    id="email"
                                    name="email"
                                    type="text"
                                    fullWidth={true}
                                    size="small"
                                    variant="outlined"
                                 />
                              </Box>
                              <Stack spacing={1} sx={{ pt: 2 }}>
                                 <List>{props.company ? validationCreationUsers() : null}</List>
                                 <FormHelperText error={validationError}>
                                    {validationError ? "Favor de seleccionar 1 empresa." : null}
                                 </FormHelperText>
                              </Stack>
                              <Box sx={{ display: "flex", justifyContent: "space-between", pt: 3 }}>
                                 <Button variant="contained" sx={{ opacity: 0.5 }} onClick={handleOnCloseModal}>
                                    Cancelar
                                 </Button>
                                 <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={validation ? true : false}
                                 >
                                    Continuar
                                 </Button>
                              </Box>
                           </Form>
                        </Formik>
                     </>
                  ) : null}
               </>
            )}
         </Box>
      </CustomModalComponent>
   );
};
