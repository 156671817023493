import Modal from "@mui/material/Modal";
import { Button, Divider, Stack, Box, CircularProgress, Typography } from "@mui/material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { useEffect, useState } from "react";

import { getDateFromISODate } from "../../const/globalConst";
import { getInvoiceById, getReceiptById } from "../../lib/usersBEClient";

interface IfilesModalDownload {
   open: boolean;
   setOpen: Function;
   file1Name: string;
   file2Name: string;
   file1URL: string;
   file2URL: string;
   id: string;
   name: string;
   invoice?: boolean;
   receipt?: boolean;
}

const style = {
   position: "absolute" as "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 500,
   bgcolor: "background.paper",
   border: "1px",
   boxShadow: 24,
   p: 4,
   borderRadius: 1,
   alignItems: "center",
};

export const FilesModalDownload = (props: IfilesModalDownload) => {
   const [updatedAt, setUpdatedAt] = useState<string>(null);
   const [isLoading, setIsLoading] = useState<boolean>(true);

   function downloadDataUrlFromJavascript() {
      fetch(props.file2URL)
         .then((response) => response.blob())
         .then((blob) => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = props.file2Name;
            link.click();
         });
   }

   useEffect(() => {
      setIsLoading(true);
      if (!props.id) {
         return;
      }
      const fetchData = async () => {
         if (props.receipt) {
            const data = await getReceiptById(props.id);
            setUpdatedAt(getDateFromISODate(data.updatedAt));
         } else if (props.invoice) {
            const data = await getInvoiceById(props.id);
            setUpdatedAt(getDateFromISODate(data.updatedAt));
         }
      };
      fetchData();
      setTimeout(() => {
         setIsLoading(false);
      }, 500);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.id]);

   return (
      <>
         <Box>
            <Modal open={props.open} onClose={() => props.setOpen(false)}>
               {!isLoading && props.file1Name && props.name && props.file2Name ? (
                  <Box sx={style}>
                     <Typography id="modal-modal-title" variant="h6" component="h2" fontWeight={600}>
                        {props.invoice ? "Factura" : "Comprobante de pago"} {props.name}
                     </Typography>
                     <Typography fontSize={"14px"} fontWeight={600} my={1}>
                        2 elementos
                     </Typography>
                     <Divider />
                     <Stack mt={1} direction={"row"} justifyContent={"space-between"} p={1}>
                        <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                           <PictureAsPdfOutlinedIcon sx={{ mr: 2, color: "#162c44" }} />
                           <Typography id="modal-modal-description" variant="body2">
                              {props.file1Name}
                           </Typography>
                        </Box>
                        <Button
                           sx={{ borderRadius: 10 }}
                           onClick={() => window.open(props.file1URL, "_blank", "noopener,noreferrer")}
                        >
                           <FileDownloadIcon sx={{ color: "#162c44" }} />
                        </Button>
                     </Stack>
                     <Stack mt={1} direction={"row"} justifyContent={"space-between"} p={1}>
                        <Box sx={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                           <FilePresentOutlinedIcon sx={{ mr: 2, color: "#162c44" }} />
                           <Typography id="modal-modal-description" variant="body2">
                              {props.file2Name}
                           </Typography>
                        </Box>
                        <Button
                           sx={{ borderRadius: 10 }}
                           onClick={() => {
                              downloadDataUrlFromJavascript();
                           }}
                        >
                           <FileDownloadIcon sx={{ color: "#162c44" }} />
                        </Button>
                     </Stack>
                     <Divider sx={{ my: 2 }} />
                     <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontSize={"12px"}>Última actualización: {updatedAt}</Typography>
                        <Button sx={{ borderRadius: 1 }} onClick={() => props.setOpen(false)}>
                           <Typography fontWeight={600}>Cerrar</Typography>
                        </Button>
                     </Stack>
                  </Box>
               ) : (
                  <Box sx={style}>
                     <Box display={"flex"} justifyContent={"center"}>
                        <CircularProgress sx={{ alignSelf: "center" }} />
                     </Box>
                  </Box>
               )}
            </Modal>
         </Box>
      </>
   );
};
