import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TabPanel } from "../ClientInfoScreenNew";
import { ClientCompanyAdminInformationComponent } from "../../../components/ClientInfo/ClientAdminInformationComponent";
import { InvoiceClientInfoComponent } from "../../../components/MiLecosy/InvoiceClientInfoComponent";
import { UserContext } from "../../../context/userContext";
import { getCompanyById, getInvoiceDetailsById } from "../../../lib/usersBEClient";
import ClientStatusAccount from "../../../components/MiLecosy/ClientStatusAccount";
import { GroupInformationComponent } from "../../../components/MiLecosy/Group/GroupInformationComponent";
import { Companies } from "../../../types/BaseTypes";

export const GroupMainScreen = () => {
   const { groupSelected } = useContext(UserContext);
   const [invoiceData, setInvoiceData] = useState(null);
   const [value, setValue] = useState(0);
   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   useEffect(() => {
      const getInvoiceData = async () => {
         if (!groupSelected.invoiceCompany) return;
         const companyFound: Companies = (await getCompanyById(groupSelected.invoiceCompany._id)).data;
         if (!companyFound) return;
         const data = await getInvoiceDetailsById(companyFound.invoice_details._id);
         setInvoiceData(data);
      };
      if (groupSelected) getInvoiceData();
   }, [groupSelected]);

   return (
      <Box
         sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
         }}
      >
         <Box
            display="flex"
            sx={{
               borderBottom: 1,
               borderColor: "divider",
               boxShadow: 2,
            }}
            bgcolor="white"
         >
            <Tabs value={value} onChange={handleChange}>
               <Tab sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} label="Información general" />
               <Tab sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} label="Facturas" />
            </Tabs>
         </Box>
         <Box sx={{ flex: 1 }}>
            {value === 0 ? (
               <TabPanel value={value} index={0}>
                  <GroupInformationComponent />
                  <Box sx={{ bgcolor: "white", mt: 2 }}>
                     <Tabs value={0} onChange={handleChange} sx={{ boxShadow: 2 }}>
                        <Tab
                           sx={{ ml: 2, fontSize: 14, fontWeight: 600 }}
                           label="Información de administrador de sistema"
                        />
                     </Tabs>
                     <ClientCompanyAdminInformationComponent companyData={null} group={true} />
                  </Box>
               </TabPanel>
            ) : (
               <TabPanel value={value} index={1}>
                  <InvoiceClientInfoComponent invoiceData={invoiceData} />
                  <ClientStatusAccount />
               </TabPanel>
            )}
         </Box>
      </Box>
   );
};
