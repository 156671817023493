import { getCompanyById, GetGroupById, getInCompletedCompanies, getInCompletedGroups } from "../../lib/usersBEClient";
import { Companies, Group } from "../../types/BaseTypes";
import { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { UserContext } from "../userContext";

interface ClientCreationContextType {
   incompletedCompanies: Companies[];
   incompletedGroups: Group[];
   refetch: Function;
   isLoading: boolean;
   selectedClient: { type: "natural" | "legal" | "group" | "office"; data: Companies | Group };
   setSelectedClient: Function;
}

interface ClientCreationState {
   incompletedCompanies: Companies[];
   incompletedGroups: Group[];
   selectedClient: { type: "natural" | "legal" | "group" | "office"; data: Companies | Group };
}

const initialState: ClientCreationState = {
   incompletedCompanies: null,
   incompletedGroups: null,
   selectedClient: null,
};

function userReducer(state: ClientCreationState, action) {
   switch (action.type) {
      case "SET_INCOMPLETED_COMPANIES":
         return { ...state, incompletedCompanies: action.payload };
      case "SET_INCOMPLETED_GROUPS":
         return { ...state, incompletedGroups: action.payload };

      case "SET_SELECTED_CLIENT":
         return { ...state, selectedClient: action.payload };
      default:
         return state;
   }
}

export const ClientCreationContext = createContext<ClientCreationContextType | undefined>(undefined);

export const ClientCreationProvider = ({ children }) => {
   const [state, dispatch] = useReducer(userReducer, initialState);

   //#region Dispatch
   const setIncompletedCompanies = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_INCOMPLETED_COMPANIES", payload: state });
   }, []);

   const setIncompletedGroups = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_INCOMPLETED_GROUPS", payload: state });
   }, []);

   const setSelectedClient = useCallback((state: ClientCreationState) => {
      dispatch({ type: "SET_SELECTED_CLIENT", payload: state });
   }, []);
   //#end region

   const { companySelected } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [fetchKey, setFetchKey] = useState(0);

   const refetch = async () => {
      setFetchKey((prevKey) => prevKey + 1);
      const { selectedClient } = state;
      if (selectedClient?.data) {
         const client =
            selectedClient.type === "group"
               ? await GetGroupById(selectedClient.data._id)
               : (await getCompanyById(selectedClient.data._id)).data;

         const clientObject: any = { type: state.selectedClient.type, data: client };
         console.log(clientObject);
         setSelectedClient(clientObject);
      }
   };

   const fetchIncompletedData = useCallback(async () => {
      if (!companySelected || !companySelected?.company_details?.office) return;
      setIsLoading(true);
      const [companies, groups] = await Promise.all([
         getInCompletedCompanies(companySelected._id),
         getInCompletedGroups(companySelected._id),
      ]);
      setIncompletedCompanies(companies);
      setIncompletedGroups(groups);
      setIsLoading(false);
   }, [fetchKey, companySelected]);

   useEffect(() => {
      fetchIncompletedData();
   }, [fetchIncompletedData]);

   return (
      <ClientCreationContext.Provider
         value={{
            ...state,
            refetch,
            isLoading,
            setSelectedClient,
         }}
      >
         {children}
      </ClientCreationContext.Provider>
   );
};
