import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { GovernanceSessionContext } from "../../../../../context/governanceContext/governanceSessionContext";

const CompanySelectorComponent = ({ setSelectedCompany }) => {
   const { groupCompaniesInSession } = useContext(GovernanceSessionContext);

   const handleChangeSelected = (e) => setSelectedCompany(e.target.value);
   return (
      <FormControl>
         <InputLabel size="small">Selecciona una empresa</InputLabel>
         <Select size="small" label={"Selecciona una empresa"} onChange={handleChangeSelected} defaultValue={""}>
            {groupCompaniesInSession?.map((company) => {
               return <MenuItem value={company._id}>{company.person_details.comercialName}</MenuItem>;
            })}
         </Select>
      </FormControl>
   );
};

export default CompanySelectorComponent;
