import {
   Avatar,
   Badge,
   Box,
   Button,
   CircularProgress,
   CssBaseline,
   Divider,
   Grid,
   Stack,
   Typography,
} from "@mui/material";
import { useParams } from "react-router";
import { useContext, useEffect, useState } from "react";
import { UptateStatusUserByIdPublic, getSessionByIdVerify } from "../../lib/gobCorpBEClient";
import { SystemThemeConfig } from "../../config/systemTheme.config";
import { SnackBarComponent } from "../../components/SnackBarComponent";
import { Container } from "@mui/system";
import { Check, Close, Dangerous, Groups, Launch, ListAlt, MeetingRoom } from "@mui/icons-material";
import { getManyCompaniesByIdPublic, getUrlS3Token, getUsersByIdPublic } from "../../lib/usersBEClient";
import { SnackBarContext } from "../../context/snackBarContext";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import Link from "@mui/icons-material/Link";

const useGetEventInfo = () => {
   const { userId, eventId } = useParams();
   const [session, setSession] = useState(null);
   const [isLoading, setIsLoading] = useState(true);
   const [isError, setIsError] = useState(false);
   const [token, setToken] = useState("");
   const [refetch, setRefetch] = useState(false);
   const [companyName, setCompanyName] = useState("");
   const [affairsFound, setAffairsFound] = useState([]);
   const [companiesFound, setCompaniesFound] = useState([]);
   const [logo, setLogo] = useState("");

   useEffect(() => {
      const fetchSession = async () => {
         setIsLoading(true);
         const response = await getSessionByIdVerify(eventId, userId);
         if (!response) {
            setIsError(true);
            setIsLoading(false);
            return;
         }

         if (response.session.group) {
            const companies = response.session.order.flatMap((order) => order.companies);
            const uniqueCompanies: string[] = [...new Set(companies)] as string[];
            const companiesResponse = await getManyCompaniesByIdPublic(uniqueCompanies, response.token);
            setCompaniesFound(companiesResponse);
         }

         const users = response.session.usersRegistry.map((user) => user.user);
         const usersF = await getUsersByIdPublic(
            users,
            response.token,
            response.session.company,
            response.session.group
         );
         if (!usersF) {
            setIsError(true);
            setIsLoading(false);
            return;
         }
         for (let i = 0; i < response.session.usersRegistry.length; i++) {
            for (const iterator2 of usersF.users) {
               if (iterator2._id === response.session.usersRegistry[i].user) {
                  response.session.usersRegistry[i] = { ...iterator2, ...response.session.usersRegistry[i] };
               }
            }
         }
         const logoUrl = await getUrlS3Token(
            "images-lecosy",
            {
               folder: response.session.group ? `group/${response.session.company}` : response.session.company,
               token: response.token,
            },
            "logo.png"
         );
         setLogo(logoUrl);
         setAffairsFound(response.affairs);
         setCompanyName(usersF.companyName);
         setToken(response.tokenC);
         setSession(response.session);
         setIsLoading(false);
      };
      fetchSession();
   }, [refetch]);

   return { session, isLoading, isError, token, setRefetch, refetch, companyName, affairsFound, logo, companiesFound };
};

export const ScheduleEvent = () => {
   const { session, isLoading, isError, token, setRefetch, refetch, companyName, affairsFound, logo, companiesFound } =
      useGetEventInfo();
   const { showSnackBar } = useContext(SnackBarContext);
   const { userId, eventId } = useParams();
   const { mutate: modifyStatus } = UptateStatusUserByIdPublic(userId, eventId);
   //const { mutate: modifySuggest } = UptateSuggestUserByIdPublic(userId, eventId);
   const [openModal, setOpenModal] = useState(false);
   const [values, setValues] = useState("");
   const [invitation, setinvitation] = useState("");

   const handleModifyStatus = () => {
      modifyStatus(
         {
            status: values,
            token: token,
         },
         {
            onSuccess: async () => {
               showSnackBar("Su respuesta ha sido registrada", false);
               setinvitation(values);
               setRefetch(!refetch);
               setOpenModal(false);
            },
            onError: (error: any) => {
               showSnackBar("Error al registrar respuesta", true);
               return null;
            },
         }
      );
   };

   const handleOpenModal = (value) => {
      setValues(value);
      setOpenModal(true);
   };

   // const handleSuggest = (value) => {
   //    modifySuggest(
   //       {
   //          date: value.date,
   //          finishTime: value.end,
   //          startTime: value.start,
   //          token: token,
   //       },

   //       {
   //          onSuccess: async () => {
   //             showSnackBar("Su petición fue completada correctamente", false);
   //             setRefetch(!refetch);
   //             setOpenModal(false);
   //          },
   //          onError: (error: any) => {
   //             showSnackBar("Error al completar la petición", true);
   //             return null;
   //          },
   //       }
   //    );
   // };

   return (
      <SystemThemeConfig>
         <SnackBarComponent />
         <Box bgcolor="#12181B" sx={{ width: "100%", height: "100%" }}>
            <Container maxWidth="lg">
               <CssBaseline />
               <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
                  <Box
                     bgcolor="whitesmoke"
                     display="flex"
                     alignItems="center"
                     flexDirection="column"
                     borderRadius={2}
                     py={2}
                     sx={{ minWidth: { xs: 0, lg: 600 }, maxWidth: { xs: "100vw", lg: "auto" } }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           width: "100%",
                           alignItems: "center",
                           px: { xs: 1, lg: 4 },
                        }}
                     >
                        <Box>
                           {!isLoading && <Box component={"img"} src={logo} sx={{ maxHeight: { xs: 60, lg: 80 } }} />}
                        </Box>
                        <Box>
                           <Box
                              component={"img"}
                              src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"
                              alt="Logo Lecosy"
                              sx={{ maxWidth: { xs: 130, lg: 150 } }}
                           />
                        </Box>
                     </Box>
                     <Stack spacing={2} sx={{}}>
                        <Divider />
                        {isLoading ? (
                           <Box sx={{ width: "100%", height: "100%" }}>
                              <CircularProgress />
                           </Box>
                        ) : isError ? (
                           <Box textAlign={"center"} sx={{ mx: 2 }}>
                              <Typography>Dias para confirmar asistencia finalizados</Typography>
                              <Dangerous sx={{ fontSize: 100, color: "red" }} />
                           </Box>
                        ) : (
                           <Stack spacing={2}>
                              <Box>
                                 <Typography align="center">{companyName}</Typography>
                                 <Typography fontWeight={600} align="center">
                                    {session.governance.title}
                                 </Typography>
                              </Box>
                              <Stack
                                 spacing={2}
                                 maxWidth={600}
                                 sx={{
                                    maxHeight: { xs: "60vh", lg: 400 },
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                    px: 4,
                                 }}
                              >
                                 <Typography fontWeight={600}>Detalles de la sesión</Typography>
                                 <Box>
                                    <Typography>{session.title}</Typography>
                                    <Box sx={{ display: "flex", columnGap: 1 }}>
                                       <Typography>
                                          {new Date(session.date).toLocaleDateString("es-MX", {
                                             month: "long",
                                             day: "numeric",
                                             year: "numeric",
                                          })}
                                       </Typography>
                                       -
                                       <Typography>
                                          {new Date(session.startTime).toLocaleTimeString("es-MX", {
                                             timeStyle: "short",
                                             hour12: true,
                                          })}
                                       </Typography>
                                       -
                                       <Typography>
                                          {new Date(session.finishTime).toLocaleTimeString("es-MX", {
                                             timeStyle: "short",
                                             hour12: true,
                                          })}
                                       </Typography>
                                    </Box>
                                 </Box>
                                 {session.provider && (
                                    <Box
                                       component="a"
                                       href={session.provider}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       sx={{
                                          display: "flex",
                                          columnGap: 1,
                                          color: "inherit",
                                          alignItems: "center",
                                       }}
                                    >
                                       <Link />
                                       <Typography>{session.provider}</Typography>
                                    </Box>
                                 )}
                                 {session.meetingURL && (
                                    <Box sx={{ display: "flex", columnGap: 1 }}>
                                       <Launch />
                                       <Typography>{session.meetingURL}</Typography>
                                    </Box>
                                 )}
                                 {session.location && (
                                    <Box sx={{ display: "flex", columnGap: 1 }}>
                                       <MeetingRoom />
                                       <Typography>{session.location}</Typography>
                                    </Box>
                                 )}
                                 <Box sx={{ display: "flex", columnGap: 1 }}>
                                    <ListAlt />
                                    <Typography>Orden del día</Typography>
                                 </Box>

                                 <Grid container spacing={0.2}>
                                    <Grid item xs={session.group ? 4 : 5}>
                                       <Box
                                          sx={{
                                             bgcolor: "#f0f0f0",
                                             display: "flex",
                                             justifyContent: "center",
                                             p: 0.5,
                                             borderRadius: 1,
                                          }}
                                       >
                                          Asunto
                                       </Box>
                                    </Grid>
                                    <Grid item xs={session.group ? 4 : 7}>
                                       <Box
                                          sx={{
                                             bgcolor: "#f0f0f0",
                                             display: "flex",
                                             justifyContent: "center",
                                             p: 0.5,
                                             borderRadius: 1,
                                          }}
                                       >
                                          Descripción
                                       </Box>
                                    </Grid>
                                    {session.group && (
                                       <Grid item xs={4}>
                                          <Box
                                             sx={{
                                                bgcolor: "#f0f0f0",
                                                display: "flex",
                                                justifyContent: "center",
                                                p: 0.5,
                                                borderRadius: 1,
                                             }}
                                          >
                                             Empresa(s)
                                          </Box>
                                       </Grid>
                                    )}
                                    {session.order.length > 0 && !isLoading && session.assembly
                                       ? session.order.map((affair: any, i) => (
                                            <>
                                               <Grid item xs={5}>
                                                  <Box sx={{ p: 1 }}>
                                                     {
                                                        affairsFound.find((affairS) => affairS._id === affair.affair)[
                                                           "title"
                                                        ]
                                                     }
                                                  </Box>
                                               </Grid>
                                               <Grid item xs={7}>
                                                  <Box sx={{ p: 1 }}>{affair.description}</Box>
                                               </Grid>
                                            </>
                                         ))
                                       : session.order.map((affair: any, i) => (
                                            <>
                                               <Grid item xs={session.group ? 4 : 5}>
                                                  <Box sx={{ p: 1 }}>{affair.affair}</Box>
                                               </Grid>
                                               <Grid item xs={session.group ? 4 : 7}>
                                                  <Box sx={{ p: 1 }}>{affair.description}</Box>
                                               </Grid>
                                               {session.group && (
                                                  <Grid item xs={4}>
                                                     <Stack
                                                        spacing={1}
                                                        sx={{ alignItems: "center", justifyContent: "center", p: 1 }}
                                                     >
                                                        {affair.companies.map((c) => {
                                                           const companyName =
                                                              companiesFound.find((com) => com._id === c)
                                                                 ?.person_details?.comercialName || "";
                                                           return (
                                                              <Typography
                                                                 key={`selectedC-${c?._id}`}
                                                                 sx={{
                                                                    bgcolor: "#f0f0f0",
                                                                    borderRadius: 2,
                                                                    textAlign: "center",
                                                                    fontSize: 14,
                                                                    px: 2,
                                                                    alignSelf: "center",
                                                                 }}
                                                              >
                                                                 {companyName.length > 10
                                                                    ? `${companyName.slice(0, 10)}...`
                                                                    : companyName || ""}
                                                              </Typography>
                                                           );
                                                        })}
                                                     </Stack>
                                                  </Grid>
                                               )}
                                            </>
                                         ))}
                                 </Grid>
                                 <Box sx={{ display: "flex", columnGap: 1 }}>
                                    <Groups />
                                    <Stack>
                                       {session.usersRegistry.length} invitados
                                       <Box>
                                          <Typography variant="caption">
                                             {session.usersRegistry.filter((user) => user.status === "APPROVED")
                                                .length +
                                                session.externs.filter((user) => user.status === "APPROVED")
                                                   .length}{" "}
                                             Sí
                                          </Typography>
                                       </Box>
                                       <Box>
                                          <Typography variant="caption">
                                             {session.usersRegistry.filter((user) => user.status === "PENDING").length +
                                                session.externs.filter((user) => user.status === "PENDING").length}{" "}
                                             pendiente(s)
                                          </Typography>
                                       </Box>
                                       <Stack spacing={1} sx={{ pt: 1 }}>
                                          {session.usersRegistry.map((user, i) => (
                                             <UserTag
                                                i={i}
                                                name={user.firstName + " " + user.lastName}
                                                status={user.status}
                                                profilePicture={user.picture}
                                             />
                                          ))}
                                       </Stack>
                                       <Stack spacing={1} sx={{ pt: 1 }}>
                                          <Typography variant="body2" sx={{ pb: 1 }}>
                                             Invitados externos
                                          </Typography>
                                          {session.externs.map((user, i) => (
                                             <UserTag
                                                i={i}
                                                name={user.email}
                                                status={user.status}
                                                profilePicture={user.picture}
                                             />
                                          ))}
                                       </Stack>
                                    </Stack>
                                 </Box>
                              </Stack>
                              <Divider />
                              <Box
                                 sx={{
                                    bgcolor: "whitesmoke",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    columnGap: 2,
                                 }}
                              >
                                 <Typography>
                                    {invitation !== ""
                                       ? `Esta invitación fue ${invitation === "APPROVED" ? "aceptada" : "rechazada"}`
                                       : "¿Asistirás?"}
                                 </Typography>

                                 {invitation === "" && (
                                    <Box
                                       sx={{
                                          display: "flex",
                                          columnGap: 2,
                                       }}
                                    >
                                       <Button
                                          variant="outlined"
                                          onClick={() => {
                                             handleOpenModal("APPROVED");
                                          }}
                                       >
                                          Sí
                                       </Button>
                                       <Button
                                          variant="outlined"
                                          onClick={() => {
                                             handleOpenModal("CANCELLED");
                                          }}
                                       >
                                          No
                                       </Button>
                                       {/* <Button
                                       variant="outlined"
                                       onClick={() => {
                                          handleOpenModal("SUGGEST");
                                       }}
                                    >
                                       Sugerir cambio de fecha
                                    </Button> */}
                                    </Box>
                                 )}
                              </Box>
                           </Stack>
                        )}
                     </Stack>
                     <ConfirmationModal
                        open={openModal}
                        onConfirm={handleModifyStatus}
                        setOpen={setOpenModal}
                        title={"Confirmación de asistencia"}
                        buttonText={"Aceptar"}
                        body={
                           <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <Typography align="center">{`Al aceptar, se enviara la confirmación de ${
                                 values === "APPROVED" ? "asistencia" : "inasistencia"
                              } a la ${session?.assembly ? "asamblea" : "sesión"} `}</Typography>
                           </Box>
                        }
                     />
                  </Box>
               </Box>
            </Container>
         </Box>

         {/* OLD CONFIRMATION
         
         <ConfirmationModal
            open={openModal}
            onConfirm={handleModifyStatus}
            setOpen={setOpenModal}
            generic={values !== "SUGGEST"}
            title={values === "SUGGEST" ? "Sugerir cambio de fecha" : ""}
            hideButtons={values === "SUGGEST"}
            body={
               values === "SUGGEST" ? (
                  <>
                     <Formik
                        initialValues={{
                           date: null,
                           start: null,
                           end: null,
                        }}
                        onSubmit={handleSuggest}
                        validationSchema={datesSchema}
                     >
                        {(formProps: FormikProps<any>) => (
                           <Form style={{ minWidth: "100%" }}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    pt: 2,
                                 }}
                              >
                                 <CustomInputStyle
                                    icon={CalendarMonth}
                                    inputProps={{
                                       id: "date",
                                       name: "date",
                                       fullWidth: true,
                                       unstyled: true,
                                       format: "DD/MM/YYYY",
                                       minDate: dayjs(new Date()),
                                       disablePast: true,
                                       onChange: (e) => {
                                          formProps.setFieldValue("date", dayjs(e.$d));
                                       },
                                    }}
                                    type="date"
                                    label="Fecha"
                                 />
                                 <CustomInputStyle
                                    icon={HourglassTop}
                                    inputProps={{
                                       id: "start",
                                       name: "start",
                                       fullWidth: true,
                                       unstyled: true,
                                       minDate: formProps.values.date,
                                       disableIgnoringDatePartForTimeValidation: true,
                                       onChange: (e) => {
                                          formProps.setFieldValue("start", dayjs(e.$d));
                                       },
                                    }}
                                    type="time"
                                    label="Hora inicio"
                                 />
                                 <CustomInputStyle
                                    icon={HourglassBottom}
                                    inputProps={{
                                       id: "end",
                                       name: "end",
                                       type: "time",
                                       fullWidth: true,
                                       unstyled: true,
                                       minDate: formProps.values.date,
                                       disableIgnoringDatePartForTimeValidation: true,
                                       onChange: (e) => {
                                          formProps.setFieldValue("end", dayjs(e.$d));
                                       },
                                    }}
                                    type="time"
                                    label="Hora fin"
                                 />
                              </Box>
                              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                                 <Button variant="outlined">Cancel</Button>
                                 <Button variant="contained" color="primary" type="submit">
                                    Confirmar
                                 </Button>
                              </Box>
                           </Form>
                        )}
                     </Formik>
                  </>
               ) : (
                  <></>
               )
            }
         /> */}
      </SystemThemeConfig>
   );
};

const UserTag = ({ status, name, profilePicture, i }) => {
   return (
      <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }} key={i}>
         <Badge
            badgeContent={
               status === "APPROVED" ? (
                  <Box sx={{ bgcolor: "green", borderRadius: 5 }}>
                     <Check sx={{ fontSize: 14, color: "white" }} />
                  </Box>
               ) : status === "CANCELLED" ? (
                  <Box sx={{ bgcolor: "red", borderRadius: 5 }}>
                     <Close sx={{ fontSize: 14, color: "white" }} />
                  </Box>
               ) : null
            }
         >
            <Avatar
               src={profilePicture}
               sx={{
                  width: 28,
                  height: 28,
                  color: "#162C44",
                  fontSize: 16,
                  bgcolor: profilePicture ? "transparent" : "#BDBDBD",
               }}
            >
               {name.charAt(0).toUpperCase()}
            </Avatar>
         </Badge>
         <Typography>{name}</Typography>
      </Box>
   );
};
