import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { getCommitmentsByUserIdAndGovernance } from "../../../lib/gobCorpBEClient";

export const useGetGraphInformationByGovernance = (id) => {
   const { user } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(true);
   const [commitments, setCommitments] = useState([]);
   const [sessions, setSessions] = useState([]);

   useEffect(() => {
      const fetchCommitmentsByUserId = async () => {
         setIsLoading(true);
         const response = await getCommitmentsByUserIdAndGovernance(id, user.id);
         if (response?.length > 0) {
            let commitments = [];
            for (const iterator of response) {
               commitments.push(...iterator.commitments);
            }
            setCommitments(commitments);
           
         }
         setIsLoading(false);
         setSessions(response);
      };
      fetchCommitmentsByUserId();
   }, []);
   
   return { sessions, commitments, isLoading };
}   