import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { EvaluationModal } from "./EvaluationModal";
import { EvaluationContext } from "../../../context/governanceContext/evaluationContext";
import { getDateFromISODate } from "../../../const/globalConst";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { CustomModalComponent } from "../../CustomModalComponent";
import { useNavigate } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import { UserContext } from "../../../context/userContext";
import { getNumberOfEvaluations } from "../../../lib/gobCorpBEClient";

export const PerformanceEvaluationBar = () => {
   const [openModal, setOpenModal] = useState(false);

   const { evaluation, isLoadingEvaluation, finished } =
      useContext(EvaluationContext);

   const { gobernanceBody, setSelectedSection,  companySelected: governanceContext} = useContext(GovernanceContext);
   const { user } = useContext(UserContext);
   const navigate = useNavigate();
   const [number, setNumber] = useState(null);
   const [permission, setPermission] = useState(false);

   const fetchPermissions = useCallback(() => {
      if (Array.isArray(user.role) && user.role.every((item) => typeof item !== "string")) return;
      const rolesToCheck = ["coordinador", "n2", "n5", "n7", "presidente", "usuario de implementación"];
      const hasRequiredRole = rolesToCheck.some((keyword) =>
         user.role.some((role) => role.toLowerCase().includes(keyword))
      );
      setPermission(hasRequiredRole);
   }, [user.role]);

   useEffect(() => {
      fetchPermissions();
   }, [fetchPermissions]);

   const fetchNumberOfEvaluations = useCallback(async () => {
      if (number || !evaluation) return;
      const response = await getNumberOfEvaluations(governanceContext);
      setNumber(response);
   }, [number, evaluation]);


   useEffect(() => {
      fetchNumberOfEvaluations();
   }, [fetchNumberOfEvaluations]);

   return (
      <Stack
         sx={{
            p: 2,
            bgcolor: "white",
            borderRadius: 1,
            border: 1,
            borderColor: "#D8D8D8",
            height: "100%",
         }}
      >
         <Box sx={{ maxWidth: 200, mx: 2 }}>
            <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
               Evaluación de desempeño
            </Typography>
         </Box>
         <Stack direction={"column"} sx={{ p: 2, justifyContent: "center" }}>
            {!isLoadingEvaluation && evaluation ? (
               <Grid container>
                  <Grid item container xs={8} spacing={2}>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Fecha de inicio:
                           </Typography>
                           <Typography variant="body2">
                              {evaluation && evaluation.createdAt
                                 ? getDateFromISODate(evaluation.createdAt)
                                 : "Sin datos"}
                           </Typography>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Número de evaluaciones realizadas:
                           </Typography>
                           <Typography
                              sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                              variant="body2"
                           >
                              {number ? number.progress : 0}
                           </Typography>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Fecha de última evaluación realizada:
                           </Typography>
                           <Typography variant="body2">
                              {number && number.progressLastFinished
                                 ? getDateFromISODate(number.progressLastFinished.updatedAt)
                                 : "Sin datos"}
                           </Typography>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Fecha de próxima evaluación:
                           </Typography>
                           <Typography
                              sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                              variant="body2"
                           >
                              {getDateFromISODate(
                                 `${parseInt(evaluation.createdAt.split("-")[0]) + 1}-${
                                    evaluation.createdAt.split("-")[1]
                                 }-${evaluation.createdAt.split("-")[2]}`
                              )}
                           </Typography>
                        </Stack>
                     </Grid>
                  </Grid>
                  <Grid item xs={4} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                     <Stack sx={{ rowGap: 2 }}>
                        <Button
                           variant="contained"
                           fullWidth
                           sx={{ maxWidth: 250 }}
                           onClick={() => setOpenModal(true)}
                           disabled={finished || gobernanceBody.length === 1}
                        >
                           Iniciar evaluación
                        </Button>
                        {permission ? (
                           <Button
                              onClick={() => {
                                 navigate("evaluation");
                                 setSelectedSection("Configuración de la evaluación");
                              }}
                              sx={{ columnGap: 1 }}
                           >
                              Configurar evaluación <Settings />
                           </Button>
                        ) : null}
                     </Stack>
                  </Grid>
               </Grid>
            ) : isLoadingEvaluation ? (
               <CircularProgress />
            ) : (
               <>
                  <Typography>Sin evaluación pendiente</Typography>
                  {permission ? (
                     <Button
                        onClick={() => {
                           navigate("evaluation");
                           setSelectedSection("Configuración de la evaluación");
                        }}
                        sx={{ columnGap: 1 }}
                     >
                        Configurar evaluación <Settings />
                     </Button>
                  ) : null}
               </>
            )}
         </Stack>
         <CustomModalComponent title={"Evaluación de desempeño"} open={openModal} setOpen={setOpenModal} timeStamp>
            <EvaluationModal setOpenModalEv={setOpenModal} open={openModal} />
         </CustomModalComponent>
         <Divider />
     
        
      </Stack>
   );
};
