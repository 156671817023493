import { TextField, FormControl, InputLabel, FormHelperText, Input, InputBase, Box } from "@mui/material";
import { useField } from "formik";

interface TextFieldInterfaceProps {
   label?: string;
   fullWidth?: boolean;
   rows?: string | number;
   minRows?: number;
   maxRows?: number;
   multiline?: boolean;
   variant?: any;
   size?: any;
   id: string;
   name: string;
   placeholder?: string;
   type: string;
   value?: any;
   endAdornment?: React.ReactNode;
   startAdornment?: React.ReactNode;
   disabled?: boolean;
   sx?: object;
   InputProps?: any;
   InputLabelProps?: any;
   maxLength?: number;
   mindate?: string;
   readonly?: boolean;
   unstyled?: boolean;
   noError?: boolean;
   gray?: boolean;
   centeredText?: boolean;
   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
   onBlur?: (event) => void;
}

export const InputTextField = (props: TextFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <>
         {props.endAdornment ? (
            <FormControl fullWidth={props.fullWidth} sx={props.sx} variant={props.variant} size={props.size}>
               <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
               <Input
                  {...field}
                  // {...props} // With this spread operator, input tag props become invalid.
                  id={props.id}
                  rows={props.rows}
                  type={props.type}
                  multiline={props.multiline}
                  endAdornment={props.endAdornment}
                  error={meta.error && meta.touched ? true : false}
               />
               <FormHelperText error={meta.error && meta.touched ? true : false}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            </FormControl>
         ) : props.startAdornment ? (
            <FormControl fullWidth={props.fullWidth} variant={props.variant} size={props.size}>
               <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
               <Input
                  {...field}
                  // {...props} // With this spread operator, input tag props become invalid.
                  id={props.id}
                  rows={props.rows}
                  type={props.type}
                  multiline={props.multiline}
                  startAdornment={props.startAdornment}
                  error={meta.error && meta.touched ? true : false}
               />
               <FormHelperText error={meta.error && meta.touched ? true : false}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            </FormControl>
         ) : props.unstyled ? (
            props.gray ? (
               <FormControl fullWidth={props.fullWidth} sx={props.sx} variant={props.variant} size={props.size}>
                  <Box
                     sx={{
                        bgcolor: "#F6F7FB",
                        p: 0.5,
                        borderRadius: 2,
                        border: meta.error && meta.touched ? 1 : 0,
                        borderColor: meta.error && meta.touched ? "red" : "transparent",
                     }}
                  >
                     <InputBase
                        {...field}
                        {...props}
                        placeholder={props.placeholder}
                        // helperText={meta.error && meta.touched ? meta.error : null}
                        inputProps={{ maxLength: props.maxLength, min: props.mindate }}
                        sx={{ ml: 1 }}
                     />
                  </Box>
                  <FormHelperText error={meta.error && meta.touched ? true : false}>
                     {meta.error && meta.touched ? meta.error : null}
                  </FormHelperText>
               </FormControl>
            ) : (
               <>
                  <InputBase
                     {...field}
                     {...props}
                     placeholder={props.placeholder}
                     error={meta.error && meta.touched ? true : false}
                     // helperText={meta.error && meta.touched ? meta.error : null}
                     inputProps={{ maxLength: props.maxLength, min: props.mindate }}
                  />
                  {props.noError ? null : (
                     <FormHelperText error={meta.error && meta.touched ? true : false}>
                        {meta.error && meta.touched ? meta.error : null}
                     </FormHelperText>
                  )}
               </>
            )
         ) : (
            <TextField
               {...field}
               {...props}
               placeholder={props.placeholder}
               error={meta.error && meta.touched ? true : false}
               helperText={meta.error && meta.touched ? meta.error : null}
               inputProps={{
                  maxLength: props.maxLength,
                  min: props.mindate,
                  style: { textAlign: props.centeredText ? "center" : "left" },
               }}
            />
         )}
      </>
   );
};
