import { Box, ButtonBase, Stack, Typography, Tab, Tabs, Divider, Card, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { GovernanceTheSequelContext } from "../../../../context/governanceContext/governanceTheSequelContext";
import { useParams } from "react-router-dom";
import { EvaluationContext } from "../../../../context/governanceContext/evaluationContext";
import { getCompanyColors, getGroupColors } from "../../../../lib/usersBEClient";

export const FiltersTabsCompanyComponent = ({ setFilterValue }) => {
  const { selectedGovernance, consultiveGroup, consultiveGroupCompanies } = useContext(GovernanceContext);
  const { myGovBody } = useContext(GovernanceTheSequelContext);
  const { setFinished } = useContext(EvaluationContext);
  const { companyId } = useParams();
  const [filter, setFilter] = useState(null);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(true);

  const fetchColors = useCallback(async (ids, type) => {
    const fetchFunction = type === "company" ? getCompanyColors : getGroupColors;
    return Promise.all(ids.map(fetchFunction));
  }, []);

  const getFilters = useCallback(async () => {
    setLoadingFilters(true);
    const accessibleCompanyIds = myGovBody.map(body => body.bodiesData?.company).filter(Boolean);

    const { companiesIds, groupsIds } = accessibleCompanyIds.reduce(
      (acc, id) => {
        consultiveGroup && consultiveGroup._id === id ? acc.groupsIds.push(id) : acc.companiesIds.push(id);
        return acc;
      },
      { companiesIds: [], groupsIds: [] }
    );

    try {
      const [companyInfo, groupNames] = await Promise.all([
        fetchColors(companiesIds, "company"),
        fetchColors(groupsIds, "group")
      ]);

      const idToNameMap = companiesIds.reduce((acc, id, index) => {
        acc[id] = companyInfo[index]?.businessName || "Sin compañía";
        return acc;
      }, {});

      groupsIds.forEach((id, index) => {
        const groupName = groupNames[index]?.groupName;
        if (groupName) {
          idToNameMap[id] = groupName;
        }
      });

      const items = [
        ...companiesIds.map(companyId => ({ title: idToNameMap[companyId], _id: companyId })),
        ...consultiveGroupCompanies
          .filter(company => accessibleCompanyIds.includes(company._id))
          .map(company => ({ title: company.person_details?.comercialName || "Sin compañía", _id: company._id })),
        ...(consultiveGroupCompanies.some(company => accessibleCompanyIds.includes(company.group))
          ? [{ title: consultiveGroup?.name || "Grupo Consultivo Sin Nombre", _id: consultiveGroup?._id }]
          : [])
      ];

      const uniqueItems = Array.from(new Map(items.map(item => [item._id, item])).values());
      setAvailableFilters(uniqueItems);
    } catch (error) {
      console.error("Error fetching filters:", error);
    } finally {
      setLoadingFilters(false);
    }
  }, [consultiveGroup, consultiveGroupCompanies, myGovBody, fetchColors]);

  useEffect(() => {
    getFilters();
  }, [getFilters]);

  useEffect(() => {
    const initialFilter = filter || availableFilters[0];
    setFilter(initialFilter);
    setFilterValue(initialFilter);
    setFinished(false);
  }, [filter, availableFilters, setFilterValue, setFinished]);

  const renderFilters = useMemo(() => (
    <Stack spacing={0.5}>
      {availableFilters.map((item) => (
        <ButtonBase
          key={`item-box-${item._id}`}
          onClick={() => setFilter(item)}
          sx={styles(filter, item)}
          aria-pressed={filter?._id === item._id}
        >
          <Typography>{item.title}</Typography>
        </ButtonBase>
      ))}
    </Stack>
  ), [availableFilters, filter]);

  return (
    <Card>
      <Tabs value="1" textColor="primary">
        <Tab label="Empresas" value="1" sx={{ px: 3, py: 2 }} />
      </Tabs>
      <Divider sx={{ borderBottomWidth: 2 }} />
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", minHeight: 250, p: 2 }}>
        {loadingFilters ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <CircularProgress />
          </Box>
        ) : (
          renderFilters
        )}
      </Box>
    </Card>
  );
};

const styles = (filter, item) => ({
  display: "flex",
  alignItems: "center",
  py: 1,
  px: filter?._id === item._id ? 1 : 0,
  width: 250,
  justifyContent: "flex-start",
  borderLeft: filter?._id === item._id ? "6px solid #3F5063" : "6px solid transparent",
  backgroundColor: filter?._id === item._id ? "#D9D9D980" : "transparent",
  fontWeight: filter?._id === item._id ? 700 : 500,
  textAlign: "left",
  borderRadius: 1,
  transition: "background-color 0.3s, border-left 0.3s",
  "&:hover": {
    backgroundColor: "#D9D9D980",
    cursor: "pointer",
  },
});
