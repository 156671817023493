import { Scheduler } from "@aldabil/react-scheduler";
import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import { es } from "date-fns/locale";
import { useContext, useEffect, useMemo, useState, useRef } from "react";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { Users } from "../../../types/BaseTypes";
import { EventViewer } from "./SubComponents/EventViewer";
import { IComment, ITags, IUserRegistry } from "../../../types/governance.types";
import { LeftSideMenu } from "./LeftSideMenu";
import { CreateEvent } from "./CreateEvent";
import dayjs from "dayjs";
import { SchedulerRef } from "@aldabil/react-scheduler/types";
import { getFilteredUsers } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { SessionAssembly } from "./Sessions/SessionAssembly";
import { SessionCouncil } from "./Sessions/SessionCouncil";
import { CustomModalComponent } from "../../CustomModalComponent";
import { RightSideMenu } from "./RightSideMenu";
import { getContrastYIQ } from "../../../const/globalConst";
import { UserContext } from "../../../context/userContext";

export interface IEventsS {
   start: Date;
   end: Date;
   date?: Date;
   order?: any[];
   title: string;
   billCode?: string;
   description?: string;
   location?: string;
   users?: Users[];
   files?: { fileName: string }[];
   color: string;
   textColor: string;
   type: string;
   event_id: string;
   tags?: ITags[];
   meetingURL?: string;
   comments?: IComment[];
   usersRegistry?: IUserRegistry[];
   externs?: string[];
   governance?: string;
   governanceUsers?: string[];
   governanceTitle?: string;
   provider?: string;
   assembly?: boolean;
   group?: boolean;
   completed?: boolean;
}

const handleEvents = (data: any, companySelected) => {
   let events: IEventsS[] = [];
   if (data?.event) {
      for (const event of data.event) {
         const date = event.date.split("T")[0];
         events.push({
            start: new Date(date + "T" + event.startTime.split("T")[1]),
            end: new Date(date + "T" + event.finishTime.split("T")[1]),
            date: new Date(event.date),
            description: event.description,
            title: event.title,
            location: event.location,
            files: event.files,
            users: event.users,
            color: event.tags?.length > 0 ? event.tags[0].color : "green",
            textColor: getContrastYIQ(event.tags?.length > 0 ? event.tags[0].color : "green"),
            type: "event",
            event_id: event.id,
            tags: event.tags,
            meetingURL: event.meetingURL,
            externs: event.externs,
         });
      }
   }
   if (data?.task) {
      for (const task of data.task) {
         const date = task.date.split("T")[0];
         events.push({
            start: new Date(date + "T" + task.startTime.split("T")[1]),
            end: new Date(date + "T" + task.finishTime.split("T")[1]),
            description: task.description,
            title: task.title,
            users: task.users,
            color: "red",
            textColor: getContrastYIQ("#ff0000"),
            type: "task",
            event_id: task.id,
            tags: [],
            files: [],
         });
      }
   }
   if (data?.session) {
      for (const session of data.session) {
         const date = session.date.split("T")[0];
         events.push({
            start: new Date(date + "T" + session.startTime.split("T")[1]),
            end: new Date(date + "T" + session.finishTime.split("T")[1]),
            date: new Date(session.date),
            billCode: session.billCode,
            order: session.order,
            title: session.title,
            color: session.group ? "#162c44" : companySelected?.company_details?.primaryColor || "#162c44",
            textColor: session.group
               ? "#FFF"
               : getContrastYIQ(companySelected?.company_details?.primaryColor || "#162c44"),
            type: session.type,
            event_id: session._id,
            tags: session.tags,
            files: session.files,
            location: session.location,
            meetingURL: session.meetingURL,
            comments: session.comments,
            usersRegistry: session.usersRegistry,
            externs: session.externs,
            governance: session.governance?._id,
            governanceUsers: session.governance?.users,
            governanceTitle: session.governance?.title,
            provider: session.provider,
            assembly: session.assembly,
            group: session.group,
            completed: session?.completed,
         });
      }
   }
   return events;
};

const useFetchEvents = () => {
   const { allCalendars, specificCalendar, tagsFilter, selectedId, selectedGovernance } = useContext(GovernanceContext);
   const { companySelected } = useContext(UserContext);
   let events: IEventsS[] = [];
   const [recentEvents, setRecentEvents] = useState({});
   const [isLoading, setIsLoading] = useState(true);
   const [users, setUsers] = useState([]);

   function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
   }

   const eventsG = useMemo(() => {
      //GOVERNANCE BODY CALENDAR
      if (selectedGovernance && selectedGovernance._id !== "0" && selectedGovernance.calendar) {
         const calendar = allCalendars.find((calendar) => calendar._id === selectedGovernance.calendar);
         events.push(...handleEvents(calendar ?? { event: [], task: [], session: [] }, companySelected));
      } else if (
         specificCalendar &&
         specificCalendar.length > 0 &&
         specificCalendar.every((item) => item !== undefined)
      ) {
         for (const iterator of specificCalendar) {
            events.push(...handleEvents(iterator, companySelected));
         }
      } else if (selectedId.length > 0) {
         events = [];
      } else {
         if (allCalendars.length > 0) {
            for (const iterator of allCalendars) {
               events.push(...handleEvents(iterator, companySelected));
            }
         }
      }
      return events;
   }, [allCalendars, specificCalendar, selectedGovernance]);

   const filteredEvents = useMemo(() => {
      if (eventsG && eventsG.length > 0) {
         if (tagsFilter.length > 0) {
            const eventsF = eventsG.filter((ev) => ev.tags?.some((tag) => tagsFilter.includes(tag._id)));
            return eventsF;
         } else return eventsG;
      }
      return eventsG;
   }, [eventsG, tagsFilter]);

   useEffect(() => {
      setIsLoading(true);
      let orderedEvents = [];
      let eventsMonthAfter = [];
      let eventsMonthAfter2 = [];

      if (filteredEvents.length > 0) {
         const now = dayjs();
         for (const iterator of filteredEvents) {
            const date = dayjs(iterator.start);
            if (date.get("month") === now.get("month")) orderedEvents.push(iterator);
            else if (date.get("month") === now.add(1, "months").get("month")) eventsMonthAfter.push(iterator);
            else if (date.get("month") === now.add(2, "months").get("month")) eventsMonthAfter2.push(iterator);
         }
      }

      const users = [];
      for (const event of events) {
         if (event.usersRegistry) users.push(...event.usersRegistry.map((user) => user.user));
         else users.push(...event.users);
      }
      const unique = users.filter(onlyUnique);

      const data = {
         thisMonth: orderedEvents,
         afterMonth: eventsMonthAfter,
         other: eventsMonthAfter2,
      };
      setRecentEvents(data);
      setIsLoading(false);

      const fetchUsers = async () => {
         const usersF = await getFilteredUsers(unique);
         setUsers(usersF);
      };

      fetchUsers();
   }, [filteredEvents]);

   return { recentEvents, isLoading, filteredEvents, users };
};

export const SchedulerComponent = () => {
   const { recentEvents, isLoading, filteredEvents, users } = useFetchEvents();

   const {
      setTagsFilter,
      gobernanceBody,
      isLoading: isLoadingGovernance,
      setSpecificCalendar,
      resourceAccessGob,
      setSelectedId,
   } = useContext(GovernanceContext);
   const { id } = useParams();
   const [open, setOpen] = useState(false);
   const [selectedEvent, setSelectedEvent] = useState<IEventsS | null>(null);
   const calendarRef = useRef<SchedulerRef>(null);
   const [permission, setPermission] = useState(0);
   const { user } = useContext(UserContext);
   const governance = useMemo(() => {
      if (id) {
         return gobernanceBody.find((gov) => gov._id === id);
      }
   }, [gobernanceBody, isLoadingGovernance, id]);

   useEffect(() => {
      if (user.resources && user.resources.length > 0) {
         const resource = user.resources.find((r) => r.name === "Calendar");
         const p = resource.permission;
         setPermission(p);
      } else {
         setPermission(3); // O cualquier valor predeterminado que desees si el usuario no tiene recursos
      }
   }, [resourceAccessGob]);

   useEffect(() => {
      setTagsFilter([]);
      setSelectedId([]);

      if (!id) {
         setSpecificCalendar(null);
      }
   }, []);

   const handleOpen = (event) => {
      setOpen(true);
      setSelectedEvent(event);
   };
   const handleClose = () => {
      setOpen(false);
      setSelectedEvent(null);
   };

   if (isLoadingGovernance) {
      return (
         <Box
            sx={{
               display: "flex",
               justifyContent: "center",
               height: "100%",
               alignItems: "center",
               width: "100%",
            }}
         >
            <CircularProgress />
         </Box>
      );
   }

   return (
      <>
         <LeftSideMenu
            permission={permission}
            handleOpenModal={handleOpen}
            buttonTitle={
               !id
                  ? "Crear nuevo"
                  : governance?.title === "Asamblea de accionistas"
                  ? "Convocar asamblea"
                  : "Crear nuevo"
            }
         />
         <Box
            sx={{
               flex: 3,
               boxShadow: 2,
               maxHeight: 700,
               overflowY: "auto",
               bgcolor: "white",
            }}
         >
            {!isLoading && filteredEvents ? (
               <Box>
                  <Scheduler
                     ref={calendarRef}
                     view="month"
                     deletable={false}
                     editable={false}
                     locale={es}
                     agenda={false}
                     week={{
                        weekDays: [0, 1, 2, 3, 4, 5, 6],
                        startHour: 0,
                        endHour: 24,
                        weekStartOn: 1,
                        step: 60,
                     }}
                     day={{
                        startHour: 0,
                        endHour: 24,
                        step: 60,
                     }}
                     translations={{
                        navigation: {
                           agenda: "Agenda",
                           today: "Hoy",
                           month: "Mes",
                           week: "Semana",
                           day: "Día",
                        },
                        form: {
                           addTitle: "Agregar evento",
                           editTitle: "Editar evento",
                           delete: "Borrar evento",
                           cancel: "Cancelar",
                           confirm: "Confirmar",
                        },
                        event: {
                           title: "Título",
                           start: "Inicio",
                           end: "Fin",
                           allDay: "Todo el día",
                        },

                        moreEvents: "Más...",
                        noDataToDisplay: "Sin datos",
                        loading: "Cargando...",
                     }}
                     fields={[
                        {
                           name: "users",
                           type: "select",
                        },
                     ]}
                     events={filteredEvents}
                     eventRenderer={({ event, ...props }) => {
                        return (
                           <Box
                              {...props}
                              sx={{
                                 display: "flex",
                                 bgcolor: event.color,
                                 flexDirection: "column",
                                 height: "100%",
                                 border: 1,
                                 borderColor: "white",
                                 borderRadius: 2,
                                 p: 0.5,
                              }}
                              color={getContrastYIQ(event.color)}
                           >
                              <Typography variant="caption" fontWeight={600}>
                                 {event.title}
                              </Typography>
                              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                 <Box sx={{ display: "flex" }}>
                                    <Typography variant="caption">{getDayFromDate(event.start)}</Typography>
                                    {getDayFromDate(event.start) === getDayFromDate(event.end) ? null : (
                                       <Typography variant="caption">{getDayFromDate(event.end)}</Typography>
                                    )}
                                 </Box>
                                 <Typography variant="caption">
                                    {event.start.toLocaleTimeString("es-MX", {
                                       timeStyle: "short",
                                       hour12: true,
                                    })}
                                 </Typography>
                              </Box>
                           </Box>
                        );
                     }}
                     customViewer={(event: IEventsS, close) => {
                        return <EventViewer event={{ ...event }} handleOpenModal={handleOpen} users={users} />;
                     }}
                     customEditor={(scheduler) => (
                        <Box sx={{ width: 700 }}>
                           {!id ? (
                              <CreateEvent scheduler={scheduler} closeScheduler />
                           ) : governance?.title === "Asamblea de accionistas" ? (
                              <SessionAssembly setOpen={setOpen} scheduler={scheduler} closeScheduler />
                           ) : (
                              <SessionCouncil setOpen={setOpen} scheduler={scheduler} closeScheduler />
                           )}
                        </Box>
                     )}
                  />

                  {open && selectedEvent ? (
                     <Modal open={open} onClose={handleClose} sx={{ display: "flex" }} tabIndex={0}>
                        <Box sx={{ m: "auto", maxWidth: 700 }}>
                           <EventViewer
                              event={{ ...selectedEvent }}
                              users={users}
                              handleOpenModal={handleOpen}
                              handleClose={handleClose}
                              modal
                           />
                        </Box>
                     </Modal>
                  ) : (
                     <Box>
                        {!id ? (
                           <CustomModalComponent open={open} setOpen={setOpen} title="Crear evento" timeStamp>
                              <CreateEvent setOpen={setOpen} />
                           </CustomModalComponent>
                        ) : governance?.title === "Asamblea de accionistas" ? (
                           <SessionAssembly setOpen={setOpen} open={open} />
                        ) : (
                           <SessionCouncil setOpen={setOpen} open={open} />
                        )}
                     </Box>
                  )}
               </Box>
            ) : (
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     height: "100%",
                     alignItems: "center",
                     width: "100%",
                  }}
               >
                  <CircularProgress />
               </Box>
            )}
         </Box>
         <RightSideMenu recentEvents={recentEvents} schedulerRef={calendarRef} />
      </>
   );
};

const getDayFromDate = (date: Date) => {
   const day = date
      .toLocaleTimeString("es-MX", {
         weekday: "long",
      })
      .split(" ")[0]
      .slice(0, 3);
   return day.charAt(0).toUpperCase() + day.slice(1);
};
