import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { externalTooltipHandler } from "../../hooks/useCreateCharts";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export const LineChart = (props) => {
   return (
      <Line
         ref={props.chartRef}
         data={{
            datasets: props.scores.map((e: [], index) => {
               return {
                  label: props.labelNames[index],
                  data: e,
                  tension: props.tension || 0,
                  borderColor: props.colors[index],
                  pointRadius: props.pointColor ? 5 : 3, // Tamaño de puntos
                  pointBackgroundColor: props.pointColors ? props.pointColors[index] : props.colors[index], // Color de puntos
                  normalized: true,
               };
            }),
            labels: props.labels,
         }}
         options={{
            interaction: props.intersection ? { intersect: false, mode: "index" } : undefined,
            plugins: {
               datalabels: { display: false },
               legend: { display: false, labels: { boxWidth: 3 }, position: "right", align: "start" },
               tooltip: {
                  enabled: !props.customTooltip,
                  position: props.customTooltip ? "nearest" : undefined,
                  external: props.customTooltip
                     ? (context) => externalTooltipHandler(context, props.tooltipColorBox)
                     : undefined,
               },
            },
            scales: {
               x: {
                  title: {
                     display: true,
                     align: "center",
                     color: "#A3A3A3",
                     text: props.xTitle,
                     font: {
                        family: "Open Sans",
                        size: 16,
                        style: "normal",
                     },
                  },
                  ticks: {
                     autoSkip: false, // No saltar etiquetas automáticamente
                     maxRotation: 0, // Rotación máxima de las etiquetas
                     minRotation: 0, // Rotación mínima de las etiquetas
                  },
               },
               y: {
                  title: {
                     display: true,
                     align: "center",
                     color: "#A3A3A3",
                     text: props.yTitle,
                     font: {
                        family: "Open Sans",
                        size: 16,
                        style: "normal",
                     },
                  },
                  min: 0,
                  max: props.maxY || undefined,
                  ticks: props.percentage ? { format: { style: "percent", maximumSignificantDigits: 2 } } : undefined,
               },
            },

            responsive: true,
            maintainAspectRatio: false,
            onClick: props.onClick,
         }}
      />
   );
};

export const SingleLineChart = ({ scores, labels, labelNames, pointColor }) => {
   return (
      <Line
         data={{
            datasets: [
               {
                  label: labelNames[2],
                  data: scores,
                  tension: 0.4,
                  borderWidth: 0.7,
                  borderColor: "colors[index]",
                  pointRadius: 0,
                  pointBackgroundColor: pointColor || "#162c44", // Color de puntos
               },
            ],
            labels,
         }}
         options={{
            //   line: { tension: 0.2 },
            plugins: {
               datalabels: { display: false },
               legend: { display: false, labels: { boxWidth: 3 }, position: "right", align: "start" },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
               x: {
                  min: 0,
                  grid: { color: "transparent" },
                  ticks: {
                     autoSkip: false, // No saltar etiquetas automáticamente
                     maxRotation: 0, // Rotación máxima de las etiquetas
                     minRotation: 0, // Rotación mínima de las etiquetas
                  },
               },
               y: { min: 0, grid: { color: "transparent" } },
            },
         }}
      />
   );
};
