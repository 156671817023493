import {
   Avatar,
   Box,
   Typography,
   CircularProgress,
   Drawer,
   Switch,
   Tooltip,
   Divider,
   Grid,
   Button,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUrlS3, getUserByUserUid, ReplaceUser, updateUserDetailsDisabled } from "../../../lib/usersBEClient";
import { TableUserComponent } from "../../../components/MiLecosy/Users/TableUserComponent";
import { SnackBarContext } from "../../../context/snackBarContext";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { ModifyUserInfoDrawer } from "../../../components/Drawers/ModifyUserInfoDrawer";
import { getDateFromISODate } from "../../../const/globalConst";
import { UserContext } from "../../../context/userContext";
import { ClientTypeModal } from "../../../components/ClientTypeModal";
import { Form, Formik, FormikProps } from "formik";
import { userCreateSchemaNew } from "../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../components/Inputs/InputTextField";

type UserDataValuesType = {
   name: string;
   lastName: string;
   email: string;
   phoneNumber: string;
   uid: string;
   validPwd?: boolean;
};

export const UserInfoScreen = () => {
   const { userId, group } = useParams();
   const { user } = useContext(UserContext);
   const [userData, setUserData] = useState(null);
   const [selectedCompany, setSelectedCompany] = useState();
   const [isLoadingCompany, setIsLoadingCompany] = useState(false);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [confirmModalReplace, setConfirmModalReplace] = useState(false);
   const { mutate } = ReplaceUser();
   const [openDrawer, setOpenDrawer] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [confirmModal, setConfirmModal] = useState(false);
   const [userDataValues, setuserDataValues] = useState<UserDataValuesType | undefined>();
   const [userInfoSeed, setUserInfoSeed] = useState(0);
   const handleModifyUserInfo = () => {
      setuserDataValues({
         uid: userData.user.uid,
         name: userData.user.firstName,
         lastName: userData.user.lastName,
         email: userData.user.email,
         phoneNumber: userData.user.phoneNumber,
         validPwd: userData.userDetails.validPwd,
      });
      setOpenDrawer(true);
   };
   const [initialValues, setInitialValues] = useState({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
   });

   const disabledUser = async (_id, disabled) => {
      try {
         const response = await updateUserDetailsDisabled(_id, disabled);
         if (response.status === 200) {
            showSnackBar("Usuario modificado", false);
            setConfirmModal(!confirmModal);
            fetchData();
         }
      } catch (error: any) {
         if (error.response.status === 400) {
            showSnackBar("Ocurrió un error.", true);
            return null;
         }
      }
   };

   const submitInformation = (values) => {
      mutate(
         {
            newUser: {
               firstName: values.firstName,
               lastName: values.lastName,
               phoneNumber: values.phoneNumber,
               email: values.email,
            },
            oldUser: userId,
         },
         {
            onError: (error: any) => {
               error.response.data.message === "email already in use"
                  ? showSnackBar("Ya existe un usuario con ese correo", true)
                  : showSnackBar("Error al agregar usuario.", true);
            },
            onSuccess: async () => {
               showSnackBar("El usuario fue reemplazado correctamente.", false);
               handleOnCloseModal();
               setConfirmModalReplace(false);
               setInitialValues({ firstName: "", lastName: "", phoneNumber: "", email: "" });
               fetchData();
            },
         }
      );
   };

   const fetchData = async () => {
      setLoading(true);
      const data = await getUserByUserUid(userId);
      setUserData(data);
      setLoading(false);
   };

   useEffect(() => {
      fetchData();
      setIsLoadingCompany(true);
      if (!user.group) setSelectedCompany(user.companies[0]);
      setIsLoadingCompany(false);
      // eslint-disable-next-line
   }, [userInfoSeed]);

   const handleOnCloseModal = () => {
      setOpenModal(false);
      setInitialValues({ firstName: "", lastName: "", phoneNumber: "", email: "" });
   };

   const [profilePicture, setProfilePicture] = useState("");
   useEffect(() => {
      const getProfilePicture = async () => {
         const url = await getUrlS3("files-lecosy", { folder: `users/${userData.user._id}` }, "profile.png");
         setProfilePicture(url);
      };
      if (userData) getProfilePicture();
      // eslint-disable-next-line
   }, [userData]);

   return (
      <>
         {userData && !isLoadingCompany && !loading ? (
            <>
               <Box width={"100%"} sx={{ display: "flex", columnGap: 2, flexDirection: "row", mt: 2 }}>
                  <Box flex={1}>
                     <Box bgcolor={"white"} boxShadow={2}>
                        <Box
                           display={"flex"}
                           flexDirection={"column"}
                           alignItems={"center"}
                           justifyContent={"center"}
                           p={4.5}
                        >
                           <Avatar
                              src={profilePicture}
                              sx={{
                                 width: 160,
                                 height: 160,
                                 mb: 4,
                                 bgcolor: profilePicture ? "white" : "#BDBDBD",
                              }}
                              imgProps={{ sx: { objectFit: "cover" } }}
                           >
                              <Typography fontSize={60}>{`${userData.user.firstName.charAt(
                                 0
                              )}${userData.user.lastName.charAt(0)}`}</Typography>
                           </Avatar>
                           <Typography fontSize={18} fontWeight={600}>
                              {userData.user.firstName} {userData.user.lastName}
                           </Typography>
                        </Box>
                     </Box>
                  </Box>
                  <Box
                     sx={{
                        flex: 4,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 2,
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           borderBottom: 1,
                           borderColor: "divider",
                           boxShadow: 2,
                           bgcolor: "white",
                           p: 2,
                           height: "100%",
                           flexDirection: "column",
                           justifyContent: "space-between",
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignContent: "center",
                           }}
                        >
                           <Typography variant="h6" fontWeight={"bold"}>
                              Información de usuario
                           </Typography>
                           {!userData.userDetails?.implementationUser && (
                              <Box sx={{ display: "flex", flexDirection: "column" }}>
                                 <Tooltip title={userData.userDetails.disabled ? "Inhabilitado" : "Habilitado"}>
                                    <Switch
                                       checked={!userData?.userDetails.disabled}
                                       onChange={() => setConfirmModal(true)}
                                    />
                                 </Tooltip>
                              </Box>
                           )}
                        </Box>
                        <Divider sx={{ my: 1 }} />

                        <Grid container p={2} spacing={3}>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Nombre(s):</Typography>
                              <Typography>{userData.user.firstName}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Apellidos:</Typography>
                              <Typography>{userData.user.lastName}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Correo electrónico:</Typography>
                              <Typography>{userData.user.email}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Teléfono:</Typography>
                              <Typography>{userData.user.phoneNumber}</Typography>
                           </Grid>
                           <Grid item xs={4}>
                              <Typography fontWeight={600}>Fecha de creación:</Typography>
                              <Typography>{getDateFromISODate(userData.user.createdAt)}</Typography>
                           </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                           <Typography fontSize={14}>
                              Última modificación: {getDateFromISODate(userData.user.updatedAt)}
                           </Typography>
                           {!userData?.userDetails.disabled && !userData.userDetails?.implementationUser ? (
                              <Button variant="contained" onClick={handleModifyUserInfo}>
                                 Editar información
                              </Button>
                           ) : (
                              <></>
                           )}
                        </Box>
                     </Box>
                  </Box>
               </Box>
               {/* {!userData.userDetails?.implementationUser && (
                  <TableUserComponent
                     userInformation={userData}
                     selectedCompanyData={selectedCompany}
                     fetchData={fetchData}
                     setSeed={setUserInfoSeed}
                  />
               )} */}
            </>
         ) : (
            <Box sx={{ display: "flex", flex: 1, justifyContent: "center", mt: 6 }}>
               <CircularProgress size={54} />
            </Box>
         )}

         <Drawer anchor="right" open={openDrawer} hideBackdrop sx={{ maxWidth: 450 }}>
            <ModifyUserInfoDrawer
               open={openDrawer}
               setOpen={setOpenDrawer}
               dataToModify={userDataValues}
               refreshData={() => {}}
               refetch={() => fetchData()}
            />
         </Drawer>
         <ConfirmationModal
            open={confirmModal}
            setOpen={setConfirmModal}
            title={userData?.userDetails.disabled ? "Habilitar usuario" : "Inhabilitar usuario"}
            body={
               <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", gap: 1 }}>
                  <Typography>
                     {`¿Está seguro que desea ${
                        userData?.userDetails.disabled ? "habilitar" : "inhabilitar"
                     } al usuario ${userData?.user.firstName}
                           ${userData?.user.lastName}?`}
                  </Typography>
                  <Typography fontStyle={"italic"} color={"red"} fontSize={13}>
                     {`Una vez confirmado, el usuario ${
                        userData?.userDetails.disabled ? "tendrá" : "quedará sin"
                     } acceso al sistema.`}
                  </Typography>
               </Box>
            }
            onConfirm={() => disabledUser(userData?.userDetails._id, !userData?.userDetails.disabled)}
            cancelButton={false}
            textJustify="left"
         />
         <ClientTypeModal
            open={openModal}
            setOpen={setOpenModal}
            title="Reemplazo de usuario"
            subtitle={`${new Date()}`}
            onCancel={handleOnCloseModal}
            onConfirm={() => {}}
            blue
            body={
               <Box sx={{ p: 4, width: 900 }}>
                  <Typography mb={1} fontWeight={600}>
                     Información del nuevo usuario
                  </Typography>
                  <Typography>
                     Al realizar el reemplazo de usuario, se reasignarán los permisos y roles del usuario existente al
                     usuario nuevo registrado a continuación:
                  </Typography>
                  <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={userCreateSchemaNew}>
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                              <InputTextField
                                 label="Nombre(s)"
                                 id="firstName"
                                 name="firstName"
                                 type="text"
                                 fullWidth={true}
                                 variant="outlined"
                                 size="small"
                              />
                              <InputTextField
                                 label="Apellido(s)"
                                 id="lastName"
                                 name="lastName"
                                 type="text"
                                 fullWidth={true}
                                 size="small"
                                 variant="outlined"
                              />
                           </Box>
                           <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                              <InputTextField
                                 label="Teléfono"
                                 id="phoneNumber"
                                 name="phoneNumber"
                                 type="text"
                                 fullWidth={true}
                                 size="small"
                                 variant="outlined"
                              />
                              <InputTextField
                                 label="Correo electrónico"
                                 id="email"
                                 name="email"
                                 type="text"
                                 fullWidth={true}
                                 size="small"
                                 variant="outlined"
                              />
                           </Box>
                           <Typography mt={5}>
                              Al guardar la información registrada, el usuario anterior quedará sin los permisos
                           </Typography>
                           <Box sx={{ display: "flex", justifyContent: "space-between", pt: 3 }}>
                              <Button sx={{ height: "100%" }} variant="outlined" onClick={handleOnCloseModal}>
                                 Cancelar
                              </Button>
                              <Button variant="contained" color="primary" onClick={() => setConfirmModalReplace(true)}>
                                 Guardar
                              </Button>
                           </Box>
                           <ConfirmationModal
                              open={confirmModalReplace}
                              setOpen={setConfirmModalReplace}
                              generic
                              nota={
                                 <Typography>
                                    El usuario nuevo podrá acceder al sistema al verificar el correo electrónico
                                    registrado durante la próxima hora.
                                 </Typography>
                              }
                              onConfirm={() => {
                                 if (
                                    formProps.errors.firstName ||
                                    formProps.errors.lastName ||
                                    formProps.errors.email ||
                                    formProps.errors.phoneNumber
                                 )
                                    return showSnackBar("Faltan campos.", true);
                                 submitInformation(formProps.values);
                              }}
                              cancelButton={false}
                              onCancel={() => setConfirmModalReplace(false)}
                              textJustify="left"
                           />
                        </Form>
                     )}
                  </Formik>
               </Box>
            }
         />
      </>
   );
};
