import { Box, IconButton, Modal, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { CreateVoteModal } from "../../CreateVoteModal";
import CloseIcon from "@mui/icons-material/Close";
import { GovernanceSessionContext } from "../../../../../context/governanceContext/governanceSessionContext";
import { UserContext } from "../../../../../context/userContext";
import { VoteModal } from "../../VoteModal";
import { UpdateUserAttendance } from "../../../../../lib/gobCorpBEClient";
import AffairsVoteModal from "./AffairsVoteModal";
import PendingVotesModal from "./PendingVotesModal";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}
interface CreateVoteModalProps {
   votationOnCourse;
   setVotationOnCourse;
   setOpenModal;
}

function CustomTabPanel(props: Readonly<TabPanelProps>) {
   const { children, value, index, ...other } = props;
   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
   );
}

const NewVotesModal = (props: CreateVoteModalProps) => {
   const { user } = useContext(UserContext);
   const { isLoadingSession, membersWithCharge, session, state } = useContext(GovernanceSessionContext);

   const [openVotationModal, setOpenVotationModal] = useState(false);
   const { setOpenModal } = props;

   const handleClick = () => {
      setOpenModal(false);
   };

   const hasPermissions = useMemo(() => {
      if (isLoadingSession) return;
      let userCharges = membersWithCharge.find((member) => member._id === user.id);
      if (!userCharges) return;
      if (typeof userCharges.memberCharge === "string")
         return (userCharges.memberCharge as string).toLowerCase().includes("coordinador");
      const hasUserCharges = userCharges.memberCharge.some(
         (charge) =>
            charge.toLowerCase().includes("secretario") ||
            charge.toLowerCase().includes("presidente") ||
            charge.toLowerCase().includes("coordinador") ||
            charge.includes("Usuario de implementacion")
      );
      if (hasUserCharges && !session?.usersRegistry.find((userRegistry) => userRegistry.user === user.id).attended)
         UpdateUserAttendance(session._id, user.id).then((data) => data);

      return hasUserCharges;
   }, [membersWithCharge, isLoadingSession]);

   const [value, setValue] = React.useState(state.vote?.orderId || state.vote?.affair ? 1 : 0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <Stack>
         <Box
            sx={{
               py: 1,
               bgcolor: "#152c44",
               borderTopLeftRadius: 15,
               borderTopRightRadius: 15,
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Votaciones</Typography>
            <IconButton onClick={handleClick} sx={{ mr: 2 }}>
               <CloseIcon sx={{ color: "white" }} />
            </IconButton>
         </Box>
         <Stack
            sx={{
               bgcolor: "background.paper",
               borderBottomLeftRadius: 15,
               borderBottomRightRadius: 15,
               minHeight: "410px",
            }}
         >
            <Tabs
               value={value}
               onChange={handleChange}
               aria-label="votaciones"
               sx={{ borderBottom: 3.5, borderColor: "#EFEFEF" }}
            >
               {hasPermissions && (
                  <Tab
                     label={state.vote ? "Votación en curso" : "Crear votación"}
                     sx={{ px: 1.5, py: 0 }}
                     disabled={props.votationOnCourse}
                  />
               )}
               {hasPermissions && (
                  <Tab
                     label={state.vote ? "Votación en curso" : "Votación por asunto"}
                     sx={{ px: 1.5, py: 0 }}
                     disabled={props.votationOnCourse}
                  />
               )}
               <Tab label={"Mis votaciones pendientes"} sx={{ px: 1.5, py: 0 }} disabled={props.votationOnCourse} />
            </Tabs>
            {hasPermissions && (
               <CustomTabPanel index={0} value={value}>
                  <CreateVoteModal {...props} />
               </CustomTabPanel>
            )}
            {hasPermissions && (
               <CustomTabPanel index={1} value={value}>
                  <AffairsVoteModal {...props} />
               </CustomTabPanel>
            )}
            <CustomTabPanel index={hasPermissions ? 2 : 0} value={value}>
               <PendingVotesModal setOpenVotationModal={setOpenVotationModal} />
            </CustomTabPanel>
         </Stack>
         <Modal
            open={openVotationModal}
            onClose={() => setOpenVotationModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <Box
               sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 368,
                  boxShadow: 24,
                  borderRadius: 2,
                  zIndex: 1000,
               }}
            >
               <VoteModal user={user} setOpenModalCreateModal={setOpenVotationModal} />
            </Box>
         </Modal>
      </Stack>
   );
};

export default NewVotesModal;
