import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import BeneficiaryManualButton from "../../components/Beneficiary Controller/Subcomponents/BeneficiaryManualButton";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

const useGetTabs = () => {
   const location = useLocation();

   const availableTabs = useMemo(() => {
      const shouldShowAdditionalTabs =
         location.pathname.includes("accionistas") ||
         location.pathname.includes("series") ||
         location.pathname.includes("analisis-societario");

      return shouldShowAdditionalTabs
         ? ["Accionistas", "Análisis societario", "Series", "Control efectivo", "Beneficiario controlador"]
         : ["Accionistas", "Control efectivo", "Beneficiario controlador"];
   }, [location.pathname]);

   const options = useMemo(
      () =>
         availableTabs.reduce((acc, tab, index) => {
            const value = tab
               .toLowerCase()
               .normalize("NFD")
               .replace(/[\u0300-\u036f]/g, "")
               .replace(/\s+/g, "-");
            acc[index] = value;
            return acc;
         }, {}),
      [availableTabs]
   );

   return { availableTabs, options };
};

const BeneficiaryTabs = () => {
   const { availableTabs, options } = useGetTabs();
   const [value, setValue] = useState(0);
   const { companyId } = useParams();
   const navigate = useNavigate();
   const location = useLocation();

   useEffect(() => {
      for (const [key, value] of Object.entries(options) as any) {
         if (location.pathname.includes(value)) {
            setValue(Number(key));
            break;
         }
      }
   }, [location.pathname]);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
      navigate(`/beneficiario-controlador/${companyId}/${options[newValue]}`);
   };

   return (
      <>
         <Tabs
            value={value}
            onChange={handleChange}
            sx={{ flex: 1, borderBottom: 1, borderColor: "#CACDD8", justifyContent: "space-between" }}
         >
            {availableTabs.map((label, index) => (
               <Tab key={label} label={label} sx={index === value ? { fontWeight: 600 } : {}} />
            ))}
            <BeneficiaryManualButton />
         </Tabs>
         <Outlet />
      </>
   );
};

export default BeneficiaryTabs;
