import { GroupServiceUsers } from "../../../components/MiLecosy/ConsultiveGroup/GroupServiceUsers";
import { GroupServiceCompaniesTable } from "../../../components/MiLecosy/ConsultiveGroup/GroupServiceCompaniesTable";

const beneficiaryServiceId = "66d75b0724169eb3399c631b";

export const BeneficiaryGroupScreen = () => {
   return (
      <>
         <GroupServiceUsers serviceCode={"BC"} />
         <GroupServiceCompaniesTable serviceId={beneficiaryServiceId} />
      </>
   );
};
