import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { TabPanel } from "../ClientInfoScreenNew";
import { AddCircle } from "@mui/icons-material";
import { OfficeCompaniesTable } from "../../../components/MiLecosy/Office/OfficeCompaniesTable";
import CreateClientModal from "../../../components/MiLecosy/Office/CreateClientModal";

const ClientCompaniesScreen = () => {
   const anchorRef = useRef<HTMLButtonElement>(null);
   const [openModal, setOpenModal] = useState<boolean>(false);

   return (
      <>
         <Box>
            <Grid sx={{ mb: 2 }}>
               <Box
                  display="flex"
                  sx={{
                     borderBottom: 1,
                     borderColor: "divider",
                     borderTopRightRadius: 5,
                     borderTopLeftRadius: 5,
                     justifyContent: "space-between",
                  }}
                  bgcolor="white"
                  boxShadow={2}
               >
                  <Tabs value={0} onChange={() => {}} aria-label="basic tabs example">
                     <Tab sx={{ fontSize: 14, fontWeight: 600, ml: 2 }} label="Clientes" />
                  </Tabs>
                  <Button
                     variant="contained"
                     sx={{ minHeight: "100%", margin: 1, bgcolor: "#162C44", width: "180px" }}
                     size="small"
                     ref={anchorRef}
                     onClick={() => {
                        setOpenModal(!openModal);
                     }}
                  >
                     <AddCircle sx={{ marginRight: 1 }} />
                     <Typography variant="button" sx={{ mr: 1 }}>
                        Agregar cliente
                     </Typography>
                  </Button>
               </Box>
               <TabPanel value={0} index={0}>
                  <OfficeCompaniesTable />
               </TabPanel>
            </Grid>
            <CreateClientModal state={openModal} setState={setOpenModal} />
         </Box>
      </>
   );
};

export default ClientCompaniesScreen;
