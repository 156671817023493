import { useContext, useState } from "react";
import { CustomModalComponent } from "../../CustomModalComponent";
import { ClientCreationContext } from "../../../context/officeContexts/clientCreationContext";
import ClientTypeSelection from "./ClientTypeSelection";
import ClientCreationForms from "./ClientCreationForms";
import IncompletedClientsTables from "./IncompletedClientsTables";

interface CreateClientModalProps {
   state: boolean;
   setState: Function;
}

const CreateClientModal = (props: CreateClientModalProps) => {
   const { state, setState } = props;
   const { selectedClient, setSelectedClient } = useContext(ClientCreationContext);
   const [clientSelection, setClientSelection] = useState(false);

   const handleCancel = () => {
      setClientSelection(false);
      setSelectedClient(null);
      setState(false);
   };

   return (
      <CustomModalComponent
         open={state}
         setOpen={setState}
         onClose={handleCancel}
         title="Agregar nuevo cliente"
         timeStamp
         maxWidth="1000px"
      >
         {clientSelection ? (
            <ClientTypeSelection setClientSelection={setClientSelection} />
         ) : selectedClient ? (
            <ClientCreationForms />
         ) : (
            <IncompletedClientsTables onClose={handleCancel} setClientSelection={setClientSelection} />
         )}
      </CustomModalComponent>
   );
};

export default CreateClientModal;
