import { Box, Grid, Tab, Tabs } from "@mui/material";
import { TabPanel } from "../ClientInfoScreenNew";
import { ClientCompanyAdminInformationComponent } from "../../../components/ClientInfo/ClientAdminInformationComponent";
import { CompanyUsersTable } from "../../../components/MiLecosy/CompanyUsersTable";
import { a11yProps } from "../ServiceInfoScreen";
import { useContext } from "react";
import { UserContext } from "../../../context/userContext";
import { AddUserProvider } from "../../../context/addUserContext";

export const ClientScreen = () => {
   const { companySelected } = useContext(UserContext);

   return (
      <>
         <Box>
            <Grid sx={{ mb: 2 }}>
               <Box
                  display="flex"
                  sx={{
                     borderBottom: 1,
                     borderColor: "divider",
                     borderTopRightRadius: 5,
                     borderTopLeftRadius: 5,
                  }}
                  bgcolor="white"
                  boxShadow={2}
               >
                  <Tabs value={0} onChange={() => {}} aria-label="basic tabs example">
                     <Tab
                        sx={{ ml: 2, fontSize: 14, fontWeight: 600 }}
                        label="Información de administrador de sistema"
                        {...a11yProps(0)}
                     />
                  </Tabs>
               </Box>
               <TabPanel value={0} index={0}>
                  <ClientCompanyAdminInformationComponent companyData={companySelected} group={false} />
               </TabPanel>
            </Grid>
            <Grid sx={{ mb: 2 }}>
               <Box display="flex" flexDirection={"column"} boxShadow={2} bgcolor="white" borderRadius={1}>
                  <Tabs value={0} aria-label="basic tabs example">
                     <Tab
                        sx={{ ml: 2, fontSize: 14, fontWeight: 600 }}
                        label="Información de usuarios"
                        {...a11yProps(0)}
                     />
                  </Tabs>
                  <TabPanel value={0} index={0}>
                     <AddUserProvider>
                        <CompanyUsersTable />
                     </AddUserProvider>
                  </TabPanel>
               </Box>
            </Grid>
         </Box>
      </>
   );
};
