import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { TabPanel } from "./ClientInfoScreenNew";
import ErrorIcon from "@mui/icons-material/Error";
import { UserContext } from "../../context/userContext";
import RenderServiceContent from "../../components/MiLecosy/Office/Company/Services/RenderServiceContent";

const servicesOrder = [
   "Canal de denuncias",
   "Programa de cumplimiento",
   "Gobierno corporativo",
   "Beneficiario controlador",
];

interface ServiceInfoScreenProps {
   company?: any;
   services: any[];
}
export function a11yProps(index: number) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export const ServiceInfoScreen = (props: ServiceInfoScreenProps) => {
   const [value, setValue] = useState(0);
   const { isImplementationUser, user } = useContext(UserContext);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue);

   const filteredAndSortedServices = useMemo(() => {
      if (!props.services || !user.modules) return [];
      const filteredServices = props.services.filter((service) => {
         if (service.disable) return false;
         if (
            isImplementationUser() &&
            (service.serviceId.service === "Gobierno corporativo" ||
               service.serviceId.service === "Beneficiario controlador")
         )
            return true;
         return true;
      });
      return filteredServices.sort((a, b) => {
         const aKey = a.serviceId.service;
         const bKey = b.serviceId.service;
         return servicesOrder.indexOf(aKey) - servicesOrder.indexOf(bKey);
      });
   }, [props.services, isImplementationUser, user]);

   if (!props.services)
      return (
         <Box
            display="flex"
            sx={{
               borderRadius: 1,
               p: 4,
               justifyContent: "center",
               alignItems: "center",
               columnGap: 2,
            }}
            boxShadow={2}
            bgcolor="white"
         >
            <Typography>No se ha contratado ningún servicio</Typography>
            <ErrorIcon sx={{ width: "35px", height: "35px", color: "#162c44" }} />
         </Box>
      );

   return (
      <>
         {filteredAndSortedServices.length > 0 ? (
            <>
               <Box
                  display="flex"
                  sx={{ borderBottom: 1, borderColor: "divider", borderTopRightRadius: 5, borderTopLeftRadius: 5 }}
                  bgcolor="white"
                  boxShadow={2}
               >
                  <Tabs sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} value={value} onChange={handleChange}>
                     {filteredAndSortedServices.map((service, index) => (
                        <Tab key={index} sx={{ fontSize: 14, fontWeight: 600 }} label={service.serviceId.service} />
                     ))}
                  </Tabs>
               </Box>
               {filteredAndSortedServices.map((service, index) => (
                  <TabPanel index={index} key={index} value={value}>
                     <Box mb={2} display={"flex"} flexDirection={"column"} rowGap={2}>
                        <RenderServiceContent service={service} />
                     </Box>
                  </TabPanel>
               ))}
            </>
         ) : (
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         )}
      </>
   );
};
