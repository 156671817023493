import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { InputTextField } from "../../Inputs/InputTextField";
import { Form, Formik } from "formik";
import { tagSchema } from "../../../lib/validations/inputSchemas";
import { useContext, useState } from "react";
import { createTag } from "../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { SnackBarContext } from "../../../context/snackBarContext";

interface TagsModalProps {
   setOpen: Function;
}

export const TagsModal = (props: TagsModalProps) => {
   const { userDetails, getUserDetailsData, setNoteTags } = useContext(GovernanceContext);
   const [isLoading, setIsLoading] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleSubmit = async (values) => {
      try {
         setIsLoading(true);
         const newTag = await createTag(userDetails._id, { title: values.title, color: values.color });
         setIsLoading(false);
         getUserDetailsData();
         props.setOpen(false);
         setNoteTags((s) => [...s, newTag]);
         showSnackBar("Exito al crear la etiqueta", false);
      } catch (error) {
         console.log("values", error);
         showSnackBar("Error al crear la etiqueta", true);
      }
   };
   return (
      <Box sx={{ width: 500 }}>
         <Formik
            initialValues={{
               title: "",
               color: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={tagSchema}
         >
            <Form>
               <Box sx={{ p: 2, display: "flex", columnGap: 2 }}>
                  <Box sx={{ flex: 2 }}>
                     <Typography variant="body2" fontWeight={600}>
                        Nombre de la etiqueta
                     </Typography>
                     <InputTextField id="title" name="title" type="string" fullWidth size="small" />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                     <Typography variant="body2" fontWeight={600}>
                        Color
                     </Typography>
                     <InputTextField id="color" name="color" type="color" fullWidth size="small" />
                  </Box>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "flex-end",
                     bgcolor: "#F3F3F3",
                     p: 2,
                     borderBottomRightRadius: 5,
                     borderBottomLeftRadius: 5,
                  }}
               >
                  <Button sx={{ color: "grey" }} onClick={() => props.setOpen(false)}>
                     Cancelar
                  </Button>
                  <Button type="submit">
                     {!isLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                  </Button>
               </Box>
            </Form>
         </Formik>
      </Box>
   );
};
