import { Stack } from "@mui/material";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { useContext } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import ListComponent from "../../../ListComponent";

export const ShareholderResolutions = () => {
   const { colors, sessionResolutions } = useContext(GovernanceSessionContext);

   return <ShareholderList colors={colors} headers={ResolutionHeaders} rows={sessionResolutions} />;
};

export const ResolutionHeaders = [
   { headerTitle: "ASUNTO/VOTACIÓN", type: "text", bodyPosition: "center", headerPosition: "center" },
   {
      headerTitle: "DESCRIPCIÓN",
      type: "text",
      bodyPosition: "left",
      headerPosition: "center",
   },
   {
      headerTitle: "RESOLUCIÓN",
      type: "text",
      bodyPosition: "center",
      headerPosition: "center",
   },
];

export const ShareholderList = ({ headers, rows, colors }) => {
   return (
      <Stack>
         <ListComponent
            disableEmptyList
            title="RESOLUCIONES"
            titleTextProps={{
               textAlign: "center",
               fontSize: 12,
               fontWeight: 600,
            }}
            headers={headers}
            headerProps={{}}
            headerColumnProps={() => {
               return {
                  mx: 0.1,
                  color: getContrastYIQ(colors.primary),
                  bgcolor: colors.primary,
               };
            }}
            headerTextProps={{
               fontWeight: 600,
               fontSize: 12,
            }}
            rows={rows}
            rowProps={() => {
               return {};
            }}
            rowsColumnProps={(_row, index) => {
               return {
                  bgcolor: "#EFEFEF",
                  mx: 0.1,
                  mt: index === 0 ? 0 : 0.1,
                  px: 1,
               };
            }}
            subComponentProps={() => {
               return {
                  fontSize: "12px",
                  textAlign: "justify",
               };
            }}
         />
      </Stack>
   );
};
