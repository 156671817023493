import { useEffect, useState, useContext, useMemo } from "react";
import { TableNotesComponent } from "./TableNotesComponent";
import { LeftSideMenu } from "../SchedulerScreen/LeftSideMenu";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { NoteModal } from "./NoteModal";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getNotesByUser } from "../../../lib/gobCorpBEClient";
import { NoteTextFilterComponent } from "./NoteTextFilterComponent";
import { NoteDateFiltercomponent } from "./NoteDateFilterComponent";
import dayjs from "dayjs";
import { Info } from "@mui/icons-material";
import { CustomModalComponent } from "../../CustomModalComponent";
import { UserContext } from "../../../context/userContext";
import { getNameUserData, getUserProfilePics } from "../../../lib/usersBEClient";

export const useFetchData = () => {
   const {
      userDetails,
      tagsFilterNotes,
      setTextToFilter,
      textToFilter,
      startDate,
      finishDate,
      confirmModal,
      setConfirmModal,
      upDownDates,
      companySelected,
      refetch,
   } = useContext(GovernanceContext);
   const [allRows, setAllRows] = useState([]);
   const { user } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingFilter, setIsLoadingFilter] = useState(false);

   useEffect(() => {
      const fetchData = async () => {
         setIsLoading(true);
         const notesData = await getNotesByUser(user.id, companySelected);
         if (notesData) {
            //VERIFY OLD TAG MODEL
            //await verifyAndUpdateTags(notesData, "notes");
            setAllRows(notesData);
         }
         setIsLoading(false);
      };
      if (userDetails) fetchData();
   }, [userDetails, refetch]);

   const filteredNotesByDate = useMemo(() => {
      if (confirmModal && startDate !== null && finishDate !== null) {
         setIsLoadingFilter(true);
         const notesD = allRows.filter((note) => {
            return (
               startDate.startOf("day").isBefore(dayjs(new Date(note.createdAt)).endOf("day")) &&
               finishDate.endOf("day").isAfter(dayjs(new Date(note.createdAt)).startOf("day"))
            );
         });
         return notesD;
      } else {
         return allRows;
      }
   }, [allRows, startDate, finishDate, confirmModal]);

   const filteredNotesByTags = useMemo(() => {
      if (tagsFilterNotes.length > 0) {
         if (tagsFilterNotes.includes("Compartidas conmigo")) {
            const notesF = filteredNotesByDate.filter((note) => note.owner !== user.id);
            return notesF;
         }
         const notesF = filteredNotesByDate.filter((note) =>
            note.tags.some((tag) => tagsFilterNotes.includes(tag._id))
         );
         return notesF;
      } else {
         return filteredNotesByDate;
      }
   }, [tagsFilterNotes, filteredNotesByDate]);

   const filteredNotesByText = useMemo(() => {
      if (textToFilter !== "") {
         const notesF = filteredNotesByTags.filter((note) => {
            return (
               note.title.toLowerCase().includes(textToFilter.toLowerCase()) ||
               note.description.toLowerCase().includes(textToFilter.toLowerCase())
            );
         });
         return notesF;
      } else {
         return filteredNotesByTags;
      }
   }, [textToFilter, filteredNotesByTags]);

   const descendAcendRows = useMemo(() => {
      if (upDownDates) {
         const upRows = filteredNotesByText.sort((note, noteB) =>
            dayjs(new Date(note.createdAt)).isBefore(dayjs(new Date(noteB.createdAt))) ? -1 : 1
         );
         return upRows;
      } else {
         const downRows = filteredNotesByText.sort((note, noteB) =>
            dayjs(new Date(note.createdAt)).isAfter(dayjs(new Date(noteB.createdAt))) ? -1 : 1
         );
         return downRows;
      }
   }, [filteredNotesByText, upDownDates]);

   const finalRows = useMemo(() => {
      let array = [];
      for (let index = 0; index < descendAcendRows.length; index += 4) {
         array.push(descendAcendRows.slice(index, index + 4));
      }
      setIsLoadingFilter(false);
      return array;
      // eslint-disable-next-line
   }, [descendAcendRows, upDownDates]);
   return { isLoading, setTextToFilter, finalRows, isLoadingFilter, setConfirmModal, allRows };
};

const useUserData = (tasks) => {
   const [processedData, setPorcessedUsers] = useState([]);
   const { companySelected, user } = useContext(UserContext);
   const [isLoadingUsers, setIsLoadingUsers] = useState(false);

   useEffect(() => {
      const fetchUserData = async () => {
         setIsLoadingUsers(true);
         const userList = [];
         for (const task of tasks) {
            userList.push(...task.users);
            for (const comment of task.comments) userList.push(comment.user);
            if (task.owner) userList.push(task.owner);
         }
         const uniqueUsers = userList.filter((value, index, self) => {
            return self.indexOf(value) === index;
         });
         if (!uniqueUsers.includes(user.id)) uniqueUsers.push(user.id);

         const userData = await getNameUserData(uniqueUsers);
         const profilePicsResponse = await getUserProfilePics(uniqueUsers);
         const newUserList = [];
         for (const user of userData)
            newUserList.push({
               user: `${user.firstName} ${user.lastName}`,
               id: user._id,
               profilePicture: profilePicsResponse.find((u) => u.user === user._id)?.url,
            });
         setPorcessedUsers(newUserList);
         setIsLoadingUsers(false);
      };

      if (tasks && tasks.length > 0) fetchUserData();
   }, [companySelected, tasks.length]);
   return { processedData, isLoadingUsers };
};

export const NotesContainer = () => {
   const { setTagsFilterNotes, setTextToFilter, setfinishDate, setstartDate, setConfirmModal } =
      useContext(GovernanceContext);
   const { isLoading, finalRows, isLoadingFilter, allRows } = useFetchData();
   const { processedData: allUsers, isLoadingUsers } = useUserData(allRows);
   const [openModal, setOpenModal] = useState<number>(0);
   const [data, setData] = useState<any>();

   useEffect(() => {
      setTagsFilterNotes([]);
      setTextToFilter("");
      setstartDate(null);
      setfinishDate(null);
      setConfirmModal(false);
      // eslint-disable-next-line
   }, []);

   return (
      <Box>
         <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>
               <LeftSideMenu notes={true} />
            </Box>
            <Stack direction={"column"} sx={{ flex: 5 }}>
               <Box
                  sx={{ display: "flex", m: 2, width: "100%", justifyContent: "space-between", alignContent: "center" }}
               >
                  <NoteDateFiltercomponent />
                  <NoteTextFilterComponent />
                  <Button
                     variant="contained"
                     sx={{ width: 200, height: 40, mr: 2 }}
                     onClick={() => {
                        setOpenModal(2);
                        setData(undefined);
                     }}
                  >
                     <Typography>Agregar nota</Typography>
                  </Button>
               </Box>
               <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
                  {isLoading || isLoadingFilter ? (
                     <CircularProgress />
                  ) : finalRows.length > 0 ? (
                     <TableNotesComponent rows={finalRows} setOpenModal={setOpenModal} rowData={setData} />
                  ) : (
                     <Stack sx={{ alignItems: "center", mt: 5 }}>
                        <Info sx={{ fontSize: 60, color: "#4B556370" }} />
                        <Typography sx={{ color: "#4B556370" }}>No se encontraron notas</Typography>
                     </Stack>
                  )}
               </Box>
            </Stack>
         </Box>
         {!isLoadingUsers && (
            <CustomModalComponent
               title={openModal === 1 ? "Detalles de nota" : data ? "Modificar nota" : "Crear nota"}
               open={openModal === 1 || openModal === 2}
               setOpen={setOpenModal}
               timeStamp={openModal === 2}
            >
               <NoteModal users={allUsers} state={openModal} setState={setOpenModal} data={data} />
            </CustomModalComponent>
         )}
      </Box>
   );
};
