import { Route } from "react-router-dom";
import { PendingSignaturesScreen } from "../screens/Canal de Denuncias/PendingSignaturesScreen";
import { IncompetenceScreen } from "../screens/Canal de Denuncias/Documents/incompetenceScreen";
import { ProcessComplaintsScreen } from "../screens/Canal de Denuncias/processComplaintsScreen";
import { ComplaintInfoScreen } from "../screens/Canal de Denuncias/ComplaintInfoScreen";
import { CompletedComplaintsScreen } from "../screens/Canal de Denuncias/completedComplaintsScreen";
import { DisseminationMethodsNewScreen } from "../screens/Canal de Denuncias/disseminationMethodsNewScreen";
import { RecordClosureScreen } from "../screens/Canal de Denuncias/Documents/recordClosureScreen";
import { ResolutionScreen } from "../screens/Canal de Denuncias/Documents/resolution/resolutionScreen";
import { DenouncedInterview } from "../screens/Canal de Denuncias/Documents/Interviews/denouncedInterview";
import { ComplainantInterview } from "../screens/Canal de Denuncias/Documents/Interviews/complainantInterview";
import { AnonymousInterviewScreen } from "../screens/Canal de Denuncias/Documents/Interviews/anonymousInterview";
import { InexistentConstancyScreen } from "../screens/Canal de Denuncias/Documents/Interviews/InexistentConstancyScreen";
import { WitnessInterview } from "../screens/Canal de Denuncias/Documents/Interviews/witnessInterview";
import { ResolutionNotificaionScreen } from "../screens/Canal de Denuncias/Documents/resolution/resolutionNotificationScreen";
import { AbsenseConstancyScreen } from "../screens/Canal de Denuncias/Documents/Interviews/absenseConstancyScreen";
import { ComplaintResolutionScreen } from "../screens/Canal de Denuncias/Documents/resolution/complaintResolutionScreen";
import { InterviewNotificaionScreen } from "../screens/Canal de Denuncias/Documents/notifications/interviewNotificationScreen";
import { QueryBank } from "../screens/Canal de Denuncias/queryBankScreen";
import { PrivateRoute } from "../components/RouteComponents/PrivateRoute";
import { ComplaintStepperProvider } from "../context/complaintStepperContext";
import { ComplaintProvider } from "../context/complaintContext";
import { StatisticsScreenNew } from "../screens/Canal de Denuncias/statisticsScreenNew";

export const useComplaintChannelRoutes = () => {
   return (
      <Route
         path="canal-denuncias"
         element={
            <ComplaintProvider>
               <ComplaintStepperProvider>
                  <PrivateRoute section="Canal de denuncias" module={true} />
               </ComplaintStepperProvider>
            </ComplaintProvider>
         }
      >
         <Route element={<PrivateRoute section="Firmas" module={false} />}>
            <Route path="firmas" element={<PendingSignaturesScreen />} />
         </Route>
         <Route element={<PrivateRoute section="Difusión-Reportes-Banco" module={false} />}>
            <Route path="estadisticas-reportes/estadisticas" element={<StatisticsScreenNew />} />
            <Route path="difusion/plan-difusion" element={<DisseminationMethodsNewScreen />} />
            <Route path="banco-consultas" element={<QueryBank />} />
            <Route path="banco-consultas/:reportNumber" element={<ComplaintInfoScreen includeStepper={false} />} />
         </Route>
         <Route element={<PrivateRoute section="Denuncias externas" module={false} />}>
            <Route path="recepcion-denuncias/:reportNumber/incompetencia/:involved" element={<IncompetenceScreen />} />
            <Route path="recepcion-denuncias/denuncias-proceso" element={<ProcessComplaintsScreen />} />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber"
               element={<ComplaintInfoScreen includeStepper={true} />}
            />
            <Route path="recepcion-denuncias/denuncias-finalizadas" element={<CompletedComplaintsScreen />} />
            <Route
               path="recepcion-denuncias/denuncias-finalizadas/:reportNumber"
               element={<ComplaintInfoScreen includeStepper={true} />}
            />
            <Route path="recepcion-denuncias/:reportNumber/acuse-cierre" element={<RecordClosureScreen />} />
            <Route
               path="recepcion-denuncias/denuncias-finalizadas/:reportNumber/acuse-cierre"
               element={<RecordClosureScreen />}
            />
            <Route path="recepcion-denuncias/:reportNumber/acuse-resolucion" element={<ResolutionScreen />} />
            <Route path="recepcion-denuncias/:reportNumber/entrevistas/denunciado" element={<DenouncedInterview />} />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/entrevistas/denunciante"
               element={<ComplainantInterview />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/entrevistas/anonimo"
               element={<AnonymousInterviewScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/entrevistas/inexistente/:towards"
               element={<InexistentConstancyScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/incompetencia/:involved"
               element={<IncompetenceScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/entrevistas/testigo"
               element={<WitnessInterview />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/notificacion-resolucion-denuncia"
               element={<ResolutionNotificaionScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/inexistencia/:towards"
               element={<InexistentConstancyScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/entrevista/4"
               element={<AnonymousInterviewScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/incomparecencia/:towards"
               element={<AbsenseConstancyScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/resolucion-queja"
               element={<ComplaintResolutionScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/notificacion/:type/:number"
               element={<InterviewNotificaionScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/notificacion/5"
               element={<ResolutionNotificaionScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/:witness/entrevista/1"
               element={<WitnessInterview />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/:offender/entrevista/2"
               element={<DenouncedInterview />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/entrevista/3"
               element={<ComplainantInterview />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/resolucion-queja"
               element={<ComplaintResolutionScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/acuse-resolucion"
               element={<ResolutionScreen />}
            />
            <Route
               path="recepcion-denuncias/denuncias-proceso/:reportNumber/acuse-cierre"
               element={<RecordClosureScreen />}
            />
         </Route>
      </Route>
   );
};
