import { Button, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { Form, Formik } from "formik";
import { InputBaseMod } from "./Inputs/InputBase";
import { userLoginSchema } from "../lib/validations/inputSchemas";
import { SnackBarContext } from "../context/snackBarContext";
import { useContext, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { VerifyUser } from "../lib/usersBEClient";

interface ILoginModalProps {
   open: boolean;
   setOpen: Function;
   onConfirm: Function;
}

export default function LoginModal(props: ILoginModalProps) {
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate } = VerifyUser();
   const [isLoading, setIsLoading] = useState(false);
   const handleClose = () => {
      props.setOpen(false);
   };

   const verifyMasterUserCredentials = async (values, actions) => {
      try {
         setIsLoading(true);
         mutate(
            { email: values.email, password: values.password },
            {
               onError: (error: any) => {
                  setIsLoading(false);
                  return showSnackBar("Credenciales invalidas", true);
               },
               onSuccess: () => {
                  showSnackBar("Credenciales validas", false);
                  setIsLoading(false);
                  setTimeout(() => {
                     props.onConfirm();
                     props.setOpen(false);
                  }, 1000);
               },
            }
         );
      } catch (error: any) {
         if (error.response.status === 400) {
            showSnackBar("Ocurrió un error, favor de reintentar.", true);
            setIsLoading(false);
            return null;
         }
      }
   };

   return (
      <div>
         <Dialog open={props.open} onClose={handleClose}>
            <DialogTitle align="center">Accede a tu cuenta</DialogTitle>
            <Formik
               initialValues={{
                  email: "",
                  password: "",
               }}
               validationSchema={userLoginSchema}
               onSubmit={verifyMasterUserCredentials}
            >
               <Form>
                  <DialogContent sx={{ maxWidth: 500 }}>
                     <Divider />
                     <Box sx={{ display: "flex", flexDirection: "column", mx: 4, mt: 2, minWidth: 320 }}>
                        <Box sx={{ display: "flex", minHeight: 50 }}>
                           <InputBaseMod
                              id="email"
                              name="email"
                              type="email"
                              fullWidth
                              placeholder="USUARIO"
                              sx={{
                                 bgcolor: "#64748BC2",
                                 color: "white",
                                 borderTopRightRadius: 1,
                                 borderBottomRightRadius: 1,
                                 px: 3,
                              }}
                              icon={<PersonIcon sx={{ color: "white", minWidth: 50, minHeight: 40 }} />}
                           />
                        </Box>

                        <Box sx={{ display: "flex", minHeight: 60, my: 3 }}>
                           <InputBaseMod
                              id="password"
                              name="password"
                              type="password"
                              fullWidth
                              placeholder="CONTRASEÑA"
                              sx={{
                                 bgcolor: "#64748BC2",
                                 color: "white",
                                 borderTopRightRadius: 1,
                                 borderBottomRightRadius: 1,
                                 px: 3,
                              }}
                              icon={<LockIcon sx={{ color: "white", minWidth: 50, minHeight: 40 }} />}
                           />
                        </Box>
                     </Box>
                     <Divider />
                  </DialogContent>

                  <DialogActions>
                     <Box sx={{ display: "flex", justifyContent: "space-evenly", minWidth: "100%" }}>
                        <Button onClick={handleClose} variant="outlined">
                           Cancelar
                        </Button>
                        <Button type="submit" variant="contained" sx={{ bgcolor: "#142c44" }}>
                           {!isLoading ? "Acceder" : <CircularProgress size={24} sx={{ color: "white" }} />}
                        </Button>
                     </Box>
                  </DialogActions>
               </Form>
            </Formik>
         </Dialog>
      </div>
   );
}
