import { Box, Grid, Typography, Divider, Button, Drawer, CircularProgress } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { getDateFromISODate } from "../../const/globalConst";
import { UserContext } from "../../context/userContext";
import { SupportContext } from "../../context/supportContext";
import { useNavigate } from "react-router-dom";

interface ClientCompanyAdminInfoProps {
   group: boolean;
   companyData?: any;
}

export const ClientCompanyAdminInformationComponent = (props: ClientCompanyAdminInfoProps) => {
   const [adminUser, setadminUser] = useState<any>(null);
   const [isLoading, setisLoading] = useState(false);
   const navigate = useNavigate();
   const [openModifyCompanyAdminUser, setOpenModifyCompanyAdminUser] = useState(false);
   const { user, groupSelected } = useContext(UserContext);
   const { setSupportOpen, supportOpen, setUpdateData } = useContext(SupportContext);

   const fetchCompanyAdminUser = async () => {
      setisLoading(true);
      if (props.companyData) setadminUser(props.companyData.company_details?.admin);
      else if (groupSelected) setadminUser(groupSelected?.admin);
      setisLoading(false);
   };

   useEffect(() => {
      fetchCompanyAdminUser();
   }, [user, groupSelected]);

   return (
      <>
         <Box bgcolor="white" sx={{ borderBottomRightRadius: 5, borderBottomLeftRadius: 5 }} boxShadow={2} p={1}>
            {!isLoading && adminUser ? (
               <>
                  <Box m={4} display={"flex"} flexDirection={"column"}>
                     <Grid container rowSpacing={4} columnSpacing={6} px={1}>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Nombre(s):</Typography>
                           <Typography>{adminUser.firstName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Apellidos:</Typography>
                           <Typography>{adminUser.lastName}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Correo electrónico:</Typography>
                           <Typography>{adminUser.email}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Teléfono:</Typography>
                           <Typography>{adminUser.phoneNumber || "Sin datos"}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Tipo de acceso:</Typography>
                           <Typography>Full Access</Typography>
                        </Grid>
                        <Grid item xs={4}>
                           <Typography fontWeight={600}>Fecha de creación:</Typography>
                           <Typography>{adminUser.createdAt ? getDateFromISODate(adminUser.createdAt) : ""}</Typography>
                        </Grid>
                     </Grid>
                  </Box>
                  <Divider />
                  <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} m={2}>
                     <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                        <Typography fontWeight={600}>Última modificación:</Typography>
                        <Typography mx={0.5}>
                           {adminUser.updatedAt ? getDateFromISODate(adminUser.updatedAt) : ""}
                        </Typography>
                     </Box>
                     {adminUser._id === user.id ? (
                        <>
                           <Button
                              variant="contained"
                              onClick={() => {
                                 navigate("/configuracion");
                              }}
                           >
                              <Typography fontSize={14}>Actualizar información</Typography>
                           </Button>
                        </>
                     ) : (
                        <>
                           <Button
                              variant="contained"
                              onClick={() => {
                                 setSupportOpen(!supportOpen);
                                 setUpdateData(true);
                              }}
                           >
                              <Typography fontSize={14}>Actualizar información</Typography>
                           </Button>
                        </>
                     )}
                  </Box>
               </>
            ) : isLoading ? (
               <Box display={"flex"} justifyContent={"center"} p={5}>
                  <CircularProgress />
               </Box>
            ) : (
               <Box width={"100%"} display={"flex"} justifyContent={"center"} p={1}>
                  <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", color: "#2D4357" }}>
                     <Typography sx={{ p: 1 }} fontSize={"17px"}>
                        No existe administrador registrado.
                     </Typography>
                  </Box>
               </Box>
            )}

            <Drawer
               hideBackdrop
               anchor="right"
               open={openModifyCompanyAdminUser}
               onClose={() => setOpenModifyCompanyAdminUser(false)}
               sx={{ maxWidth: 450 }}
            >
               {/*<UpdateCompanyAdminUserDrawer CAMBIAR AQUI
                   isModify
                   setOpen={setOpenModifyCompanyAdminUser}
                   adminUserData={adminUser}
                   group={groupCheck}
                   updateAdminInfo={fetchCompanyAdminUser}
             />*/}
            </Drawer>
         </Box>
      </>
   );
};
