import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { SnackBarProvider } from "./context/snackBarContext";
import { SupportDrawerProvider } from "./context/supportContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ComplaintProvider } from "./context/complaintContext";
import { ComplaintStepperProvider } from "./context/complaintStepperContext";
import { SessionProvider } from "./context/sessionContext";
import { UserProvider } from "./context/userContext";
import { startOtelInstrumentation } from "./lib/OpenTelemetry/telemetryConfig";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
if (
   window.location.hostname === "test.web.lecosy.com.mx" ||
   window.location.hostname === "www.test.web.lecosy.com.mx" ||
   process.env.NODE_ENV === "production"
) {
   startOtelInstrumentation();
}

root.render(
   <React.StrictMode>
      <QueryClientProvider client={queryClient}>
         <UserProvider>
            <ComplaintProvider>
               <SessionProvider>
                  <SnackBarProvider>
                     <ComplaintStepperProvider>
                        <SupportDrawerProvider>
                           <App />
                        </SupportDrawerProvider>
                     </ComplaintStepperProvider>
                  </SnackBarProvider>
               </SessionProvider>
            </ComplaintProvider>
         </UserProvider>
      </QueryClientProvider>
   </React.StrictMode>
);
