import { Box, Button, Card, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../context/userContext";
import { GetManyGroupsById, getUrlS3 } from "../lib/usersBEClient";
import { GovernanceContext } from "../context/governanceContext/governanceContext";
import { ArrowBack, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { FilterComponent } from "../components/FilterComponent";
import { getConsultiveGroupById } from "../lib/gobCorpBEClient";
import _ from "lodash";

interface CompanySelectorModalProps {
   state: boolean;
   setState: Function;
}

interface CompanyCard {
   _id: string;
   logo: string;
   name: string;
}

export const CompanySelectorModal = (props: CompanySelectorModalProps) => {
   const { companySelected, setCompanySelected, setSelectedSection } = useContext(GovernanceContext);
   const [filteredCompanies, setFilteredCompanies] = useState([]);
   const [companyString, setCompanyString] = useState("");
   const [isLoading, setIsLoading] = useState(true);
   const [showCase, setShowCase] = useState(false);
   const [companies, setCompanies] = useState([]);
   const navigate = useNavigate();
   const { companies: companiesContext, user, groups } = useContext(UserContext);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return logoUrl;
   };

   const formatCompanies = async () => {
      setIsLoading(true);
      const companyData: any[] = [];

      const groupsFromCompany = companiesContext.map((c) => c.group);
      const uniqueGroups = _.uniq([...groupsFromCompany, ...groups.map((g) => g._id)]);

      if (uniqueGroups.length > 0) {
         const groupsResponse = await GetManyGroupsById(uniqueGroups);
         const consultiveGroups = await Promise.all(
            groupsResponse.map(async (group) => {
               const response = await getConsultiveGroupById(group._id);
               return response || null;
            })
         );
         const validConsultiveGroups = consultiveGroups.filter(Boolean);
         if (validConsultiveGroups.length > 0) {
            const groupData = await Promise.all(
               validConsultiveGroups.map(async (group) => {
                  const groupFound = groupsResponse.find((g) => g._id === group._id);
                  const groupLogo = await getDataS3(`group/${groupFound._id}`);
                  return {
                     _id: groupFound._id,
                     name: groupFound.name,
                     logo: groupLogo,
                  };
               })
            );
            companyData.push(...groupData);
         }
      }

      const filteredCompanies = companiesContext.filter((company) => !company.group);
      const companyDataPromises = filteredCompanies.map(async (company) => {
         const { _id, person_details, company_details } = company;
         const logo = await getDataS3(_id);

         const hasModule = company_details?.servicesDetails?.some(
            (s) => s?.serviceId === "64e7851d978b71bd4f011ee4" && !s.disable
         );
         if (hasModule) {
            return {
               _id,
               name: person_details?.comercialName || "Sin nombre",
               logo,
               hasModule,
            };
         }
         return null;
      });

      const companyDataa = (await Promise.all(companyDataPromises)).filter(Boolean);
      companyData.push(...companyDataa);

      setCompanies(companyData);
      setFilteredCompanies(companyData);
      setIsLoading(false);
   };

   useEffect(() => {
      formatCompanies();
   }, [props.state, user, companiesContext]);

   const theCompany: CompanyCard = useMemo(() => {
      return companies.find((c) => c._id === companyString);
   }, [companyString, showCase]);

   const handleClose = () => {
      if (companySelected) return props.setState(false);
      navigate("/inicio");
   };

   return (
      <>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               bgcolor: "white",
               width: 700,
               borderRadius: 2,
            }}
         >
            <Box
               sx={{
                  bgcolor: "#152C44",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  p: 2,
                  borderTopLeftRadius: 5,
                  borderTopRightRadius: 5,
               }}
            >
               <Typography fontWeight={600} variant="h6" sx={{ color: "white" }}>
                  {"Gobiernos corporativos disponibles"}
               </Typography>
               <IconButton sx={{ color: "#FAFAFA" }} onClick={handleClose}>
                  <Close />
               </IconButton>
            </Box>
            {!isLoading ? (
               <>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        px: 2,
                        py: 1,
                     }}
                  >
                     {showCase && theCompany ? (
                        <>
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton onClick={() => setShowCase(false)}>
                                 <ArrowBack />
                              </IconButton>
                              <Typography fontWeight={600} variant="subtitle1">
                                 Confirmar selección:
                              </Typography>
                           </Box>
                           <Stack
                              spacing={2}
                              sx={{
                                 width: "100%",
                                 justifyContent: "center",
                                 alignItems: "center",
                                 display: "flex",
                                 my: 2,
                              }}
                           >
                              <Card
                                 sx={{
                                    width: "30%",
                                    height: 160,
                                    display: "flex",
                                    flexDirection: "column",
                                    border: 1,
                                    borderColor: "#EEEEEF",
                                    "box-shadow": "0px 0px 2px 0px rgba(233,233,233,1)",
                                    bgcolor: "#C4CBD625",
                                 }}
                              >
                                 <Box
                                    sx={{
                                       flex: 3,
                                       display: "flex",
                                       justifyContent: "center",
                                       width: "100%",
                                    }}
                                 >
                                    <img
                                       defaultValue={theCompany.logo}
                                       src={theCompany.logo}
                                       alt="logo"
                                       style={{ maxWidth: "120px", maxHeight: "90px", alignSelf: "center" }}
                                    />
                                 </Box>
                                 <Box
                                    sx={{
                                       flex: 1,
                                       display: "flex",
                                       justifyContent: "center",
                                       bgcolor: "white",
                                    }}
                                 >
                                    <Typography
                                       fontWeight={600}
                                       fontSize={"15px"}
                                       sx={{
                                          display: "flex",
                                          textAlign: "center",
                                          color: "#46566A",
                                          alignItems: "center",
                                       }}
                                    >
                                       {theCompany.name}
                                    </Typography>
                                 </Box>
                              </Card>
                              <Typography fontSize={14} sx={{ alignSelf: "center", fontStyle: "italic" }}>
                                 Al confirmar, se mostrara el gobierno corporativo seleccionado.
                              </Typography>
                           </Stack>
                        </>
                     ) : (
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                           }}
                        >
                           <Box sx={{ px: 2, pt: 2 }}>
                              <Typography fontWeight={600} variant="subtitle1">
                                 Selecciona una empresa para mostrar información:
                              </Typography>
                              <FilterComponent
                                 originaltableOneRows={companies}
                                 tableOneFilteredRows={filteredCompanies}
                                 setTableOneFilteredRows={setFilteredCompanies}
                                 filters={[]}
                                 fullWidth
                                 searchBar={<></>}
                                 disableRefresh
                              />
                           </Box>
                           <Grid container sx={{ rowGap: 2, mb: 2, maxHeight: 350, minHeight: 150, overflowY: "auto" }}>
                              {filteredCompanies.length === 0 ? (
                                 <Typography
                                    sx={{
                                       width: "100%",
                                       textAlign: "center",
                                       my: 2,
                                    }}
                                 >
                                    No se encontró una empresa con ese nombre
                                 </Typography>
                              ) : (
                                 filteredCompanies.map((company, index) => (
                                    <Grid key={index} xs={4} sx={{ px: 2 }}>
                                       <Card
                                          sx={{
                                             width: "100%",
                                             height: 160,
                                             display: "flex",
                                             flexDirection: "column",
                                             ":hover": { cursor: "pointer" },
                                             pointerEvents: company._id === companySelected ? "none" : undefined,
                                             opacity: company._id === companySelected ? 0.7 : 1,
                                             border: 1,
                                             borderColor: "#EEEEEF",
                                             "box-shadow": "0px 0px 2px 0px rgba(233,233,233,1)",
                                             bgcolor: "#C4CBD625",
                                          }}
                                          onClick={() => {
                                             setCompanyString(company._id);
                                             setShowCase(true);
                                          }}
                                       >
                                          {company._id === companySelected && (
                                             <Typography
                                                fontWeight={600}
                                                fontSize={14}
                                                sx={{ alignSelf: "center", color: "#152C44" }}
                                             >
                                                Selección actual
                                             </Typography>
                                          )}
                                          <Box
                                             sx={{
                                                flex: 3,
                                                display: "flex",
                                                justifyContent: "center",
                                                width: "100%",
                                             }}
                                          >
                                             <img
                                                defaultValue={company.logo}
                                                src={company.logo}
                                                alt="logo"
                                                style={{ maxWidth: "120px", maxHeight: "90px", alignSelf: "center" }}
                                                loading="lazy"
                                             />
                                          </Box>
                                          <Box
                                             sx={{
                                                flex: 1,
                                                display: "flex",
                                                justifyContent: "center",
                                                bgcolor: "white",
                                             }}
                                          >
                                             <Typography
                                                fontWeight={600}
                                                fontSize={"15px"}
                                                sx={{
                                                   display: "flex",
                                                   textAlign: "center",
                                                   color: "#46566A",
                                                   alignItems: "center",
                                                }}
                                             >
                                                {company.name}
                                             </Typography>
                                          </Box>
                                       </Card>
                                    </Grid>
                                 ))
                              )}
                           </Grid>
                        </Box>
                     )}
                  </Box>
               </>
            ) : (
               <Box sx={{ display: "flex", justifyContent: "center", width: "100%", my: 7 }}>
                  <CircularProgress sx={{ alignSelf: "center" }} />
               </Box>
            )}
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
               }}
            >
               <Button
                  disabled={showCase}
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     flex: 1,
                     height: 40,
                     bgcolor: "#F7F9FB",
                     ":hover": { bgcolor: "#F7F9FB" },
                  }}
                  onClick={handleClose}
               >
                  Cancelar
               </Button>
               {showCase && theCompany && (
                  <Button
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: 40,
                        flex: 1,
                        bgcolor: "#F7F9FB",
                        ":hover": { bgcolor: "#F7F9FB" },
                     }}
                     onClick={() => {
                        navigate(`${companyString}`);
                        setShowCase(false);
                        setSelectedSection("Dashboard");
                        setCompanySelected(companyString);
                        props.setState(false);
                     }}
                  >
                     Confirmar
                  </Button>
               )}
            </Box>
         </Box>
      </>
   );
};
